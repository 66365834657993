import React, { useCallback, useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button/Button";
import { insertEmbeddingsToFaissDBAction } from "../../redux/actions/DataOutputActions";
import { toast } from "react-toastify";
function FaissDb() {
  const {
    emebeddingData,
    chunksData,
    isLoading,
    pineconedata,
    vectorData,
    chartImageUrl,
  } = useSelector((state) => state.DataOutputSlice);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [embeddingFaissStorageValue, setembeddingFaissStorageValue] = useState({
    collection_name: "",
    metric: "",
    embeddings: emebeddingData?.id,
  });

  const handleChangeFaissValue = (e) => {
    const { value, name } = e.target;
    setembeddingFaissStorageValue((prev) => ({ ...prev, [name]: value }));

    // Validate field
    const pattern =
      name === "collection_name" && e.target.getAttribute("pattern");
    const regex = new RegExp(pattern);
    if (!value || (pattern && !regex.test(value))) {
      setErrors((prev) => ({ ...prev, [name]: true }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: false }));
    }
    // validateFields();
  };

  const validateFields = useCallback(() => {
    const newErrors = {};
    const fields = [
      { name: "collection_name", pattern: "^[a-z0-9-]+$" },
      { name: "metric" },
    ];

    fields.forEach(({ name, pattern }) => {
      const value = embeddingFaissStorageValue[name];
      if (!value || (pattern && !new RegExp(pattern).test(value))) {
        newErrors[name] = true;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [embeddingFaissStorageValue]);

  const handleStoreEmbeddingToFaissDB = async () => {
    try {
      if (!validateFields()) {
        return;
      }

      const insertResponse = await dispatch(
        insertEmbeddingsToFaissDBAction(embeddingFaissStorageValue)
      );

      if (insertResponse?.payload?.response?.data) {
        const errorData = insertResponse.payload.response.data;
        Object.keys(errorData).forEach((backError) => {
          errorData[backError].forEach((errorMessage) => {
            toast.error(errorMessage);
          });
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <>
      <TextField
        label="Collection Name"
        type="text"
        name="collection_name"
        id="collection_name"
        fullWidth
        value={embeddingFaissStorageValue?.collection_name || ""}
        onChange={handleChangeFaissValue}
        size="small"
        inputProps={{ pattern: "^[a-z0-9-]+$" }}
        error={!!errors.collection_name}
        helperText={
          errors.collection_name ? "Invalid collection name format." : ""
        }
      />
      <FormControl sx={{ minWidth: 220 }} size="small">
        <InputLabel id="metric-label">Metric</InputLabel>
        <Select
          labelId="metric-label"
          name="metric"
          value={embeddingFaissStorageValue.metric || ""}
          label="Metric"
          onChange={handleChangeFaissValue}
          error={!!errors.metric}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="IndexFlatL2">IndexFlatL2</MenuItem>
          <MenuItem value="IndexFlatIP">IndexFlatIP</MenuItem>
        </Select>
      </FormControl>
      <div
        style={{
          width: "100%",
          marginBottom: "25px",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          IsExtracted={
            emebeddingData && Object.keys(emebeddingData)?.length > 0
              ? false
              : true
          }
          // IsExtracted={validateFormField}
          activeBtn={
            emebeddingData && Object.keys(emebeddingData)?.length ? true : false
          }
          label={"Insert All Chunks"}
          onClick={handleStoreEmbeddingToFaissDB}
        />
      </div>
    </>
  );
}

export default FaissDb;
