import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  curated_file_download,
  summary_download,
  qa_pairs_download,
  tables_download,
  zipped_download,
} from "../../services/postServices";
import {
  get_download_curatedConetent,
  get_download_qaPairs,
  get_download_summary,
  get_download_tables,
  get_zipped_download,
} from "../../services/getServices";
import axios from "axios";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { HOST } from "../../API/axios";

export const downloadCuratedContent = createAsyncThunk(
  "downloadCuratedContent",
  async (imageId) => {
    try {
      const workspace = JSON.parse(sessionStorage.getItem("workspace"));
      const workspaceId = workspace?.workspace_id;

      if (!workspaceId) {
        throw new Error("Workspace ID not found in local storage");
      }
      const payload = { workspace_id: workspaceId, file_id: imageId };
      const response = await curated_file_download(payload);

      if (response && response?.data && response?.status === 200) {
        const res = await get_download_curatedConetent(
          response.data.download_url
        );

        if (res && res?.data && res.status === 200) {
          const blob = new Blob([res.data]);
          const url = window.URL.createObjectURL(blob);

          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.target = "_blank";
          anchor.download = "curated_content.txt";
          anchor.style.display = "none";
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
          window.URL.revokeObjectURL(url);
          toast.success("Curated Content Downloaded Successfully.");
        } else {
          toast.error("Failed to download curated content");
          throw new Error("Failed to download curated content");
        }
      } else {
        throw new Error("Download URL not found in the response");
      }
    } catch (error) {
      // toast.error(error.response.data.detail);
      toast.error(
        "Failed to download curated content! Please verify if you have performed pipeline execution correctly."
      );
    }
    throw new Error("Download URL not found in the response");
  }
);

export const downloadSummary = createAsyncThunk(
  "downloadSummary",
  async (imageId) => {
    try {
      const workspace = JSON.parse(sessionStorage.getItem("workspace"));
      const workspaceId = workspace?.workspace_id;
      const fileId = sessionStorage.getItem("file_id");

      if (!workspaceId) {
        throw new Error("Workspace ID not found in local storage");
      }
      const payload = { workspace_id: workspaceId, file_id: imageId };
      const response = await summary_download(payload);

      if (response && response?.data && response?.status === 200) {
        const res = await get_download_summary(response.data.download_url);

        if (res && res?.data && res.status === 200) {
          const blob = new Blob([res.data]);
          const url = window.URL.createObjectURL(blob);

          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.target = "_blank";
          anchor.download = "summarry.txt";
          anchor.style.display = "none";
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
          window.URL.revokeObjectURL(url);
          toast.success("Summary Downloaded Successfully.");
        } else {
          throw new Error("Failed to download Summary content");
        }
      } else {
        throw new Error("Download URL not found in the response");
      }
    } catch (error) {
      // toast.error(error.response.data.detail);
      toast.error(
        "Failed to download the summary! Please verify if you have saved the summary."
      );
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
    }
    throw new Error("Download URL not found in the response");
  }
);

export const downloadQnaPairs = createAsyncThunk(
  "downloadQnaPairs",
  async (imageId) => {
    try {
      // Retrieve workspace ID from sessionStorage
      const workspace = JSON.parse(sessionStorage.getItem("workspace"));
      const workspaceId = workspace?.workspace_id;
      const fileId = sessionStorage.getItem("file_id");

      if (!workspaceId) {
        throw new Error("Workspace ID not found in local storage");
      }

      // Call API to initiate QA pairs download
      const payload = { workspace_id: workspaceId, file_id: imageId };
      const response = await qa_pairs_download(payload);

      if (!response || !response.data || !response.data.download_url) {
        throw new Error("Download URL not found in the response");
      }

      // Get the download URL from the response
      const downloadUrl = response.data.download_url;

      // Get the Bearer token from sessionStorage
      const authToken = sessionStorage.getItem("token");

      if (!authToken) {
        throw new Error("Authorization token not found");
      }

      // Fetch the file from the download URL with the Authorization header
      const fileResponse = await fetch(downloadUrl, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (!fileResponse.ok) {
        throw new Error(`Failed to download Questions and Answer pairs`);
      }

      // Retrieve the file blob
      const blob = await fileResponse.blob();

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Questions_and_Answer_pairs.xlsx"; // Set the desired filename

      // Append the link to the body and click it programmatically
      document.body.appendChild(link);
      link.click();

      // Clean up by removing the link element and revoking the URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);

      toast.success("Questions and Answer pairs Downloaded Successfully.");
    } catch (error) {
      toast.error(
        `Failed to download Question & Answer pair! Please verify if you have saved QnA pair.`
      );
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      throw error; // Rethrow the error to be caught by the caller if needed
    }
  }
);

export const downloadTables = createAsyncThunk(
  "downloadTables",
  async (imageId) => {
    try {
      const workspace = JSON.parse(sessionStorage.getItem("workspace"));
      const fileId = JSON.parse(sessionStorage.getItem("file_id"));
      const workspaceId = workspace?.workspace_id;
      const authToken = sessionStorage.getItem("token");
      // Fetch the file from the download URL with the Authorization header
      const tableId = sessionStorage.getItem("table_id");
      const fileResponse = await fetch(
        `${HOST}aura-studio/data_download/table/download/${tableId}/`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (!fileResponse.ok) {
        throw new Error(`Failed to download tables`);
      }

      // Retrieve the file blob
      const blob = await fileResponse.blob();

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Tables.xlsx"; // Set the desired filename

      // Append the link to the body and click it programmatically
      document.body.appendChild(link);
      link.click();

      // Clean up by removing the link element and revoking the URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);

      toast.success("Tables Downloaded Successfully.");
    } catch (error) {
      toast.error(
        "Failed to download tables! Please verify if you have extracted the tables."
      );
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
    }
  }
);

export const downloadAllZipped = createAsyncThunk(
  "downloadAllZipped",
  async (Id) => {
    try {
      const workapce = JSON.parse(sessionStorage.getItem("workspace"));
      const fileId = JSON.parse(sessionStorage.getItem("file_id"));
      const authToken = sessionStorage.getItem("token");

      if (!authToken) {
        throw new Error("Authorization token not found");
      }

      const payload = {
        workspace_id: workapce?.workspace_id,
        file_id: Id,
      };

      const response = await fetch(
        `${HOST}aura-studio/data_download/zippedcuratedcontent/download/`,
        {
          method: "POST", // or 'GET' depending on your API
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error(
          "Failed to download all files. Please check if the file was uploaded."
        );
      }

      const responseData = await response.json();

      if (!responseData || !responseData.download_url) {
        throw new Error("Download URL not found in the response");
      }

      const downloadUrl = responseData.download_url;

      const fileResponse = await fetch(downloadUrl, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (!fileResponse.ok) {
        throw new Error("Failed to download all files!");
      }

      const blob = await fileResponse.blob();

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Allfiles.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);

      toast.success("All Files Downloaded Successfully.");
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal server error!");
      } else {
        toast.error("Failed to download all files!");
      }

      throw error;
    }
  }
);

export const downloadJson = createAsyncThunk(
  "downloadJson",
  async (imageId) => {
    try {
      const workspace = JSON.parse(sessionStorage.getItem("workspace"));
      const fileId = JSON.parse(sessionStorage.getItem("file_id"));
      const workspaceId = workspace?.workspace_id;
      const authToken = sessionStorage.getItem("token");
      // Fetch the file from the download URL with the Authorization header
      const tableId = sessionStorage.getItem("table_id");
      const fileResponse = await fetch(
        `${HOST}aura-studio/data_download/json-table/download/${tableId}/`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (!fileResponse.ok) {
        throw new Error(`Failed to download tables`);
      }

      // Retrieve the file blob
      const blob = await fileResponse.blob();

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "json_tables.json"; // Set the desired filename

      // Append the link to the body and click it programmatically
      document.body.appendChild(link);
      link.click();

      // Clean up by removing the link element and revoking the URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);

      toast.success("Tables Downloaded Successfully.");
    } catch (error) {
      toast.error(
        "Failed to download tables! Please verify if you have extracted the tables."
      );
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
    }
  }
);
