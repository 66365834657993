import { createSlice } from "@reduxjs/toolkit";
import {
  ConfigTextCleaning,
  ConfigTextLength,
  ConfigTextQuality,
  ConfigSensetiveInfo,
  ConfigProfanityCheck,
  ConfigTables,
  getConfigTextCleaning,
  getConfigTextQuality,
  getConfigTextLength,
  getConfigSensetiveInfo,
  getConfigProfanityCheck,
  putConfigTextCleaning,
  putConfigTextLength,
  putConfigTextQuality,
  putConfigSensetiveInfo,
  putConfigProfanityCheck,
  getConfigTables,
  putConfigTables
  
} from "../actions/subWorkspaceAction";

const ConfigurationSlice = createSlice({
  name: "ConfigurationSlice",
  initialState: {
    data: {},
    textCleaningConfigs: {},
    textQualityConfigs: {},
    sensetiveInfoConfigs: {},
    profanityCheckConfig: {},
    tableConfigs:{},
    status: "",
    isLoading: false,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(ConfigTextCleaning.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(ConfigTextCleaning.fulfilled, (state, { payload }) => {
        state.status = "success";
        // state.data = payload;
        state.isLoading = false;
      })
      .addCase(ConfigTextCleaning.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(ConfigTextQuality.pending, (state) => {
        state.isLoading = true;
        state.status = "loading";
      })
      .addCase(ConfigTextQuality.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isLoading = false;
        // state.data = payload;
      })
      .addCase(ConfigTextQuality.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(ConfigTextLength.pending, (state) => {
        state.isLoading = true;
        state.status = "loading";
      })
      .addCase(ConfigTextLength.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        // state.data = payload;
      })
      .addCase(ConfigTextLength.rejected, (state, action) => {
        state.isLoading = false;
        state.status = "failed";
        state.error = action.error.message;
      });
    builder
      .addCase(ConfigSensetiveInfo.pending, (state) => {
        state.isLoading = true;
        state.status = "loading";
      })
      .addCase(ConfigSensetiveInfo.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        // state.data = payload;
      })
      .addCase(ConfigSensetiveInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.status = "failed";
        state.error = action.error.message;
      });
    builder
      .addCase(ConfigProfanityCheck.pending, (state) => {
        state.isLoading = true;
        state.status = "loading";
      })
      .addCase(ConfigProfanityCheck.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        // state.data = payload;
      })
      .addCase(ConfigProfanityCheck.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
       builder
      .addCase(ConfigTables.pending, (state) => {
        state.isLoading = true;
        state.status = "loading";
      })
      .addCase(ConfigTables.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isLoading = false;
        // state.data = payload;
      })
      .addCase(ConfigTables.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getConfigTextCleaning.pending, (state) => {
        state.isLoading = true;
        state.status = "loading";
      })
      .addCase(getConfigTextCleaning.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isLoading = false;
        state.data = payload.data;
        state.textCleaningConfigs = payload.data;
      })
      .addCase(getConfigTextCleaning.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getConfigTextQuality.pending, (state) => {
        state.isLoading = true;
        state.status = "loading";
      })
      .addCase(getConfigTextQuality.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        state.data = payload.data;
        state.textQualityConfigs = payload.data;
      })
      .addCase(getConfigTextQuality.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getConfigTextLength.pending, (state) => {
        state.isLoading = true;
        state.status = "loading";
      })
      .addCase(getConfigTextLength.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isLoading = false;
        state.data = payload.data;
      })
      .addCase(getConfigTextLength.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getConfigSensetiveInfo.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getConfigSensetiveInfo.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isLoading = false;
        state.data = payload.data;
        state.sensetiveInfoConfigs = payload.data;
      })
      .addCase(getConfigSensetiveInfo.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getConfigProfanityCheck.pending, (state) => {
        state.isLoading = true;
        state.status = "loading";
      })
      .addCase(getConfigProfanityCheck.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        state.data = payload.data;
        state.profanityCheckConfig = payload.data;
      })
      .addCase(getConfigProfanityCheck.rejected, (state, action) => {
        state.isLoading = false;
        state.status = "failed";
        state.error = action.error.message;
      });
      builder
      .addCase(getConfigTables.pending, (state) => {
        state.isLoading = true;
        state.status = "loading";
      })
      .addCase(getConfigTables.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        state.data = payload.data;
        state.profanityCheckConfig = payload.data;
      })
      .addCase(getConfigTables.rejected, (state, action) => {
        state.isLoading = false;
        state.status = "failed";
        state.error = action.error.message;
      });

    builder
      .addCase(putConfigTextCleaning.pending, (state) => {
        state.isLoading = true;
        state.status = "loading";
      })
      .addCase(putConfigTextCleaning.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isLoading = false;
        state.data = payload;
      })
      .addCase(putConfigTextCleaning.rejected, (state, action) => {
        state.isLoading = false;
        state.status = "failed";
        state.error = action.error.message;
      });

    builder
      .addCase(putConfigTextQuality.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(putConfigTextQuality.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isLoading = false;
        state.data = payload;
      })
      .addCase(putConfigTextQuality.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(putConfigTextLength.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(putConfigTextLength.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        state.data = payload;
      })
      .addCase(putConfigTextLength.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(putConfigSensetiveInfo.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(putConfigSensetiveInfo.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
      })
      .addCase(putConfigSensetiveInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.status = "failed";
        state.error = action.error.message;
      });
    builder
      .addCase(putConfigProfanityCheck.pending, (state) => {
        state.isLoading = true;
        state.status = "loading";
      })
      .addCase(putConfigProfanityCheck.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        state.data = payload;
      })
      .addCase(putConfigProfanityCheck.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
       builder
      .addCase(putConfigTables.pending, (state) => {
        state.isLoading = true;
        state.status = "loading";
      })
      .addCase(putConfigTables.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        state.data = payload;
      })
      .addCase(putConfigTables.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default ConfigurationSlice.reducer;
