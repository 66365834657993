import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import KeyWordGenerationPage from "./KeyWordGeneration";
import TopicGenerationPage from "./TopicGenerations";
import s from "./Metadata.module.scss";
import ExtractPDF from "../../components/Common/PDFRenderer/ExtractPDF";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel, Tooltip } from "@mui/material";
import Button from "../../components/Button/Button";
import MedataForm from "./MedataForm";
import { useDispatch, useSelector } from "react-redux";
import nometadataimg from "../../assets/noMetaDataFound.png";
import {
  getCreateTopicMetadataAction,
  GetMetaDataAction,
} from "../../redux/actions/DataOutputActions";
import { getChunkAction } from "../../redux/actions/DataOutputActions";
import { setfolderChunkData } from "../../redux/slices/DataOutputSlice";
import NoFileAvailable from "../DataReview/NoFileAvailable";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const MetaDataTabs = () => {
  const dispatch = useDispatch();
  const [showKeywordGeneration, setShowKeywordGeneration] = useState(true);
  const [showTopicGeneration, setShowTopicGeneration] = useState(true);
  const [extractedText, setextractedText] = useState("");
  const [fileIDSerial, setfileIDSerial] = useState(0);
  const { visualizeKeywords, visulazieTopic } = useSelector(
    (state) => state.DataOutputSlice
  );
  const { fileId, isFilePresent, fileName, fileData, folderId, imgId } =
    useSelector((state) => state.PipelineExecutionSlice);

  useEffect(() => {
    if (isFilePresent) {
      dispatch(GetMetaDataAction(fileId));
    }
  }, [isFilePresent, fileId]);

  useEffect(() => {
    if (isFilePresent) {
      dispatch(getCreateTopicMetadataAction(fileId));
    }
  }, [isFilePresent, fileId]);
  return (
    <>
      <div className={s.metadataContainer}>
        <h5 className={s.metaTitle}>Metadata for chunk</h5>
        {!isFilePresent ? (
          <NoFileAvailable isChunksPage={true} />
        ) : (
          <>
            <MedataForm fileIDSerial={fileIDSerial} />
            <div className={s.MetadataPreview}>
              <div className="outer-extract-chunk-container">
                <ExtractPDF
                  setextractedText={setextractedText}
                  setfileIDSerial={setfileIDSerial}
                  isChunks={true}
                />
              </div>
              <div style={{ width: "50%" }} className={s.metadatFormView}>
                <div className={s.chooseModelCheckBox}>
                  <p className={s.outputContentTitle}>Visualize keywords</p>
                  <div className={s.titleverticalLine}></div>
                  <p className={s.outputContentTitle}>Visualize Topic Name</p>
                </div>
                <div className={s.chunkOutputBox}>
                  <div className={s.verticalLine}></div>
                  {showKeywordGeneration &&
                  visualizeKeywords &&
                  visualizeKeywords.keywords ? (
                    <div style={{ width: "50%" }}>
                      {Object.entries(visualizeKeywords.keywords).map(
                        ([chunkName, keywords], index) => (
                          <div key={index} className={s.KeywordoutputChunkBox}>
                            <div>
                              <p className={s.chunkTitlePara}>
                                Chunk {index + 1}
                              </p>
                            </div>
                            <p>{keywords.join(", ")}</p>
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <div className={s.noMetadataContainer}>
                      <img
                        src={nometadataimg}
                        alt="No metadata found"
                        className={s.noMetadataImage}
                      />
                      <p>No visualize keywords available</p>
                    </div>
                  )}

                  {showTopicGeneration &&
                  visulazieTopic &&
                  visulazieTopic?.topics ? (
                    <div style={{ width: "50%" }}>
                      {visulazieTopic?.topics.map((topic, index) => (
                        <div key={index} className={s.KeywordoutputChunkBox}>
                          <div>
                            {/* <p className={s.chunkTitlePara}>{topic.chunk}</p> */}
                            <p className={s.chunkTitlePara}>
                              Chunk {index + 1}
                            </p>
                          </div>
                          <p>{topic.topic_name}</p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className={s.noMetadataContainer}>
                      <img
                        src={nometadataimg}
                        alt="No metadata found"
                        className={s.noMetadataImage}
                      />
                      <p>No visualize topics available</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MetaDataTabs;
