import React, { useState, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import s from "./DataIngestion.module.scss";
import Button from "../../components/Button/Button";
import {
  createImageFolderAction,
  dataExtrationAction,
  deleteDataSourceAction,
  deleteImageFolderAction,
  fileUpload,
  fileValidationAction,
  getFileUpload,
  getFoldeAndFileListAction,
} from "../../redux/actions/dataIngestionActions";
import {
  Table,
  FormGroup,
  Label,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import chooseFile from "../../assets/choose-file-image.jpg";
import noFileImage from "../../assets/no-file-available.jpg";
import DeleteModel from "../../components/Common/DeleteModal/DeleteModal";
import { toast } from "react-toastify";
import Loader from "../../components/Common/LoadingModal/LoadingModal";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { styled, TextField } from "@material-ui/core";
// import docImage from "../../assets/docImage.png";

import deleteIcon from "../../assets/deleteIcon.png";
import PropTypes from "prop-types";
import disableDeleteIcon from "../../assets/disabledeleteIcon.png";

import DropdownMenu from "../../components/Common/DropdownMenu/dropdownMenu";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import nofileAvailableLottie from "../../assets/lottie/nodatafound.json";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LottieFiles from "../../components/Common/Lottie/LottieFIles";
import {
  convertFileSizeToBytes,
  fileExtnsion,
  folderExtension,
  formatBytes,
  getFileIcon,
  videofileExtension,
} from "../../ENUM";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};
const Notifications = function () {
  const dispatch = useDispatch();
  const history = useHistory();
  const [combinedData, setcombinedData] = useState([]);
  const [folderName, setFoldeName] = useState("");
  const [videoType, setVideoType] = useState([]);
  const [folderVideoType, setFolderVideoType] = useState([]);

  const [isInsideImageFolder, setisInsideImageFolder] = useState();

  const [updatedData, setupdatedData] = useState();
  const [isDisabled, setIsDisabled] = useState(true);

  const { data, isLoading, isActiveBtn, folderFileList, validFileName } =
    useSelector((state) => state.dataIngestionSlice);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const folderId = query.get("id");

  const [isOpenDeleteModel, setIsOpenDeleteModel] = useState(false);
  const [folderDeleteFlag, setfolderDeleteFlag] = useState();
  const [isFolderExtracted, setIsFolderExtracted] = useState(false);
  const [newFolderData, setNewFolderData] = useState({});
  const [progress, setProgress] = useState({});

  const [file_id, setFile_id] = useState({});
  const handleCloseDeleteModel = () => {
    setIsOpenDeleteModel(!isOpenDeleteModel);
  };
  const handleOpenDeleteModel = (fileId, folderFlag) => {
    setFile_id(fileId);
    setfolderDeleteFlag(folderFlag);
    setIsOpenDeleteModel(!isOpenDeleteModel);
  };

  const updateProgress = (fileId, value) => {
    setProgress((prevProgress) => ({
      ...prevProgress,
      [fileId]: value,
    }));
  };

  useEffect(() => {
    // if (folderId) {
    setupdatedData(
      folderFileList?.folders?.find((item) => item.id == folderId)
    );
    // }
    if (folderFileList) {
      const reverseFolderList = folderFileList?.folders?.slice()?.reverse();
      const reverseFileList = folderFileList?.files?.slice()?.reverse();
      setcombinedData(reverseFolderList?.concat(reverseFileList));
      // setcombinedData(folderFileList?.files?.concat(folderFileList?.folders));
    }
  }, [folderId, folderFileList]);

  useEffect(() => {
    dispatch(getFileUpload());
  }, [dispatch]);

  useEffect(() => {
    if (data) {
      sessionStorage.setItem("file_id", JSON.stringify(data[0]?.file_id));
    }
  }, [data]);

  useEffect(() => {
    dispatch(getFoldeAndFileListAction());
  }, []);

  const handleExtract = async (fileId, fileExtension, fileSize) => {
    let value = {};
    if (videofileExtension.includes(fileExtension)) {
      let videoTypeValue = videoType.find((item) => item.name === fileId);
      value = {
        file_id: fileId,
        video_type: videoTypeValue?.value,
      };
    } else {
      value = {
        file_id: fileId,
      };
    }

    // Calculate estimated extraction time and interval duration
    const estimatedExtractionTime = 30000; // Example estimated time for a large file in milliseconds
    const maxFileSize = 10000000; // Maximum file size for the estimated time in bytes
    const totalTime = Math.max(
      estimatedExtractionTime,
      (fileSize / maxFileSize) * estimatedExtractionTime
    );
    const intervalDuration = totalTime / 100; // Update every 1% of the total time

    // let intervalId;
    try {
      // updateProgress(fileId, 0);
      // let progressValue = 0;
      // intervalId = setInterval(() => {
      //   setProgress((prevProgress) => {
      //     progressValue = Math.min(prevProgress[fileId] + 1, 90);
      //     return {
      //       ...prevProgress,
      //       [fileId]: progressValue,
      //     };
      //   });
      // }, intervalDuration);

      const res = await dispatch(dataExtrationAction(value));

      if (res.payload.status === 200 || res.payload.status === 201) {
        // clearInterval(intervalId);
        // updateProgress(fileId, 100);

        dispatch(getFileUpload());
        dispatch(getFoldeAndFileListAction());
      }
    } catch (error) {
      console.error("Extraction error: ", error);
      // clearInterval(intervalId);
      // updateProgress(fileId, -1);
    }
  };

  const handleFileInputChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const originalFileName = e.target.files[0]?.name;
    const validTypes = folderId ? folderExtension : fileExtnsion;

    const invalidFiles = selectedFiles.filter(
      (file) => !validTypes.includes(file.type)
    );

    if (invalidFiles.length > 0) {
      toast.error("Invalid file type selected.");
    } else {
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      if (folderId) {
        let value = {
          workspace_id: workspace?.workspace_id,
          original_file_name: originalFileName,
          folder_id: folderId,
        };
        dispatch(fileValidationAction(value)).then((res) => {
          if (res?.payload?.status === 200 || res?.payload?.status === 201) {
            handleFiles(selectedFiles);
          }
        });
      } else {
        let value = {
          workspace_id: workspace?.workspace_id,
          original_file_name: originalFileName,
        };
        dispatch(fileValidationAction(value)).then((res) => {
          if (res?.payload?.status === 200 || res?.payload?.status === 201) {
            handleFiles(selectedFiles);
          }
        });
      }
    }
  };

  const handleFiles = (selectedFiles) => {
    if (selectedFiles.length) {
      const workspace = JSON.parse(sessionStorage.getItem("workspace"));
      const workspace_id = workspace.workspace_id;
      selectedFiles.forEach((selectedFile) => {
        if (selectedFiles[0].size === 0) {
          toast.error(`File is empty: ${selectedFile.name}`);
        } else {
          const fileId = new Date().getTime(); // Generate a unique ID for progress tracking

          const onUploadProgress = (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentCompleted = Math.floor((loaded * 100) / total);
            // Dispatch progress update action if needed
            // dispatch(setProgress({ fileId, progress: percentCompleted }));
            setProgress({ fileId, progress: percentCompleted });
          };
          if (folderId) {
            dispatch(
              fileUpload({
                selectedFile,
                workspace_id,
                folderId,
              })
            ).then((res) => {
              if (res.payload) {
                dispatch(getFileUpload());
                dispatch(getFoldeAndFileListAction());
              }
            });
          } else {
            dispatch(
              fileUpload({ selectedFile, workspace_id, onUploadProgress })
            ).then((res) => {
              if (res.payload) {
                dispatch(getFileUpload());
                dispatch(getFoldeAndFileListAction());
              }
            });
          }
        }
      });
    }
  };

  const handleDeleteDataSource = () => {
    if (folderDeleteFlag === 0) {
      dispatch(deleteImageFolderAction(file_id)).then((res) => {
        if (res.payload.status === 200 || res.payload.status === 201) {
          handleCloseDeleteModel();
          toast.success("Folder deleted successfully.");
          dispatch(getFileUpload());
          dispatch(getFoldeAndFileListAction());
        }
      });
    } else {
      dispatch(deleteDataSourceAction(file_id)).then((res) => {
        if (res.payload.status === 200 || res.payload.status === 201) {
          handleCloseDeleteModel();
          toast.success("File deleted successfully.");
          dispatch(getFileUpload());
          dispatch(getFoldeAndFileListAction());
        }
      });
    }
  };

  // deleteImageFolderAction

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    handleFiles(acceptedFiles);
    if (rejectedFiles && rejectedFiles.length > 0) {
      rejectedFiles.forEach((file) => {
        if (file.errors && file.errors.length > 0) {
          file.errors.forEach((error) => {
            if (error.code === "file-invalid-type") {
              toast.error(`File type not accepted: ${file.file.name}`);
            }
          });
        }
      });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone(
    folderId
      ? {
          onDrop,
          accept: {
            "image/jpeg": [".jpg", ".jpeg"],
            "image/png": [".png"],
            "image/gif": [".gif"],
          },
        }
      : {
          onDrop,

          accept: {
            "application/pdf": [".pdf"],
            "application/msword": [".doc"],
            "text/plain": [".txt"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              [".docx"],
            "image/jpeg": [".jpg", ".jpeg"],
            "image/png": [".png"],
            "image/gif": [".gif"],
          },
        }
  );

  const handleCreateFolder = (name) => {
    // dispatch(fileValidationAction(name)).then((res) => {
    //   if (res.payload.status === 200 || res.payload.status === 201) {
    dispatch(createImageFolderAction(name)).then((res) => {
      if (res.payload.status == 201 || res.payload.status == 200) {
        dispatch(getFoldeAndFileListAction());
      }
    });
  };
  //   });
  // };

  const handleExtractFolder = async (folder_id, fileExtension) => {
    let filteredFileList = folderFileList?.folders?.find(
      (item) => item.id == folder_id
    );

    let allSuccessful = true;

    for (const file of filteredFileList?.files) {
      if (!file.IsExtracted) {
        let value = {};
        let foldefileExtension = file?.original_file_name?.split(".").pop();
        if (videofileExtension.includes(foldefileExtension)) {
          let videoTypeValue = folderVideoType.find(
            (item) => item.name === file?.id
          );
          value = {
            file_id: file?.id,
            video_type: videoTypeValue?.value,
          };
        } else {
          value = {
            file_id: file?.id,
          };
        }

        try {
          const res = await dispatch(dataExtrationAction(value));
          if (res.payload.status !== 200 && res.payload.status !== 201) {
            allSuccessful = false;
            break;
          }
        } catch (error) {
          console.error("Error extracting file:", error);
          allSuccessful = false;
          break;
        }
      }
    }

    if (allSuccessful) {
      try {
        await dispatch(getFileUpload());
        await dispatch(getFoldeAndFileListAction());
        setIsFolderExtracted(true);
      } catch (error) {
        console.error("Error dispatching actions:", error);
      }
    }
  };

  const handleVideoType = (e) => {
    const { value, name } = e.target;
    if (!folderId) {
      setVideoType((prevState) => {
        const existingIndex = prevState.findIndex((item) => item.name === name);

        if (existingIndex !== -1) {
          return prevState.map((item, index) =>
            index === existingIndex ? { ...item, value } : item
          );
        } else {
          return [...prevState, { name, value }];
        }
      });
    } else {
      setFolderVideoType((prevState) => {
        const existingIndex = prevState.findIndex((item) => item.name === name);

        if (existingIndex !== -1) {
          return prevState.map((item, index) =>
            index === existingIndex ? { ...item, value } : item
          );
        } else {
          return [...prevState, { name, value }];
        }
      });
    }
  };

  return (
    <>
      <Loader isOpen={isLoading} />
      <DeleteModel
        isOpen={isOpenDeleteModel}
        handleCloseDeleteModel={handleCloseDeleteModel}
        onClick={handleDeleteDataSource}
        deleteType="file"
      />
      <div className={s.IngetionContainer}>
        <div className={s.leftPane}>
          <div className={` ${isDragActive ? s.dragOver : ""}`}>
            {/* <div className={s.dragContent}>
              <p>Drag & Drop your files here!</p>
            </div> */}

            <div className={s.imageContainer}>
              <img
                style={{ height: "200px" }}
                src={process.env.PUBLIC_URL + chooseFile}
                alt="choose file"
              />
            </div>
            {/* <div className={s.dragContent}> */}
            {/* </div> */}

            <div className={s.buttonWrapper}>
              <DropdownMenu
                setNewFolderData={setNewFolderData}
                hanldeCreateFolder={handleCreateFolder}
                folderId={folderId}
                setFoldeName={setFoldeName}
                onChange={handleFileInputChange}
                getRootProps={getRootProps}
              />
            </div>
          </div>
          {/* <p>
            Note : To perform operation on image file please create folder and
            upload image inside the folder.
          </p> */}
        </div>

        <div className={s.verticalLine}></div>

        {/* {MyTable()} */}
        <div className={s.rightPane}>
          {combinedData?.length ? (
            <div>
              <div style={{ marginBottom: "20px" }}>
                <h5 style={{ marginLeft: "10px" }}>Data Source Ingestion</h5>
              </div>
              <div style={{ marginBottom: "40px" }}>
                <div className={s.dataSourceContainer}>
                  <div className={s.choosefileBox}>
                    <div className={s.fileListBox}>
                      <p
                        className={s.fileListTitle}
                        style={{ marginLeft: "10px" }}
                      >
                        {folderId ? (
                          <>
                            <div className={s.listTextBox}>
                              <p
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setisInsideImageFolder(false);
                                }}
                              >
                                List
                              </p>{" "}
                              <div
                                onClick={() => {
                                  history.push("/template/notifications");
                                }}
                                className={s.backTextBox}
                              >
                                <KeyboardBackspaceIcon />
                                <p>Back</p>
                              </div>
                            </div>
                          </>
                        ) : (
                          "List"
                        )}
                      </p>
                      <div className={s.fileBoxContainer}>
                        {folderId ? (
                          updatedData?.files?.length > 0 ? (
                            Array.isArray(updatedData?.files) &&
                            updatedData?.files?.map((file, index) => {
                              const hasExtension =
                                file?.original_file_name?.split(".")?.length >
                                1;
                              const fileExtension = file?.original_file_name
                                ?.split(".")
                                .pop();
                              return (
                                <div key={index} className={s.fileBox}>
                                  <div className={s.fileListContainer}>
                                    <div className={s.contentBox}>
                                      <div className={s.fileList}>
                                        {getFileIcon(
                                          file.original_file_name || file?.name
                                        )}
                                        <p className={s.fileName}>
                                          {file?.original_file_name ||
                                            file?.name}
                                          <p className={s.fileSize}>
                                            {hasExtension &&
                                              formatBytes(file.file_size)}
                                          </p>
                                        </p>
                                      </div>
                                      <div className={s.BtnMainContainer}>
                                        {videofileExtension.includes(
                                          fileExtension
                                        ) && (
                                          <FormControl
                                            sx={{
                                              width: "150px",
                                              marginRight: "10px",
                                            }}
                                            size="small"
                                          >
                                            <InputLabel id="demo-simple-select-label">
                                              Content Type
                                            </InputLabel>
                                            <Select
                                              name={file.id}
                                              // disabled={file?.IsExtracted}
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              value={
                                                folderVideoType.find(
                                                  (type) =>
                                                    type.name === file.id
                                                )?.value || file.video_type
                                              }
                                              label="Content Type"
                                              onChange={(e) => {
                                                handleVideoType(e);
                                              }}
                                            >
                                              <MenuItem value={"audio"}>
                                                audio
                                              </MenuItem>
                                              <MenuItem value={"text"}>
                                                text
                                              </MenuItem>
                                            </Select>
                                          </FormControl>
                                        )}
                                        <div className={s.ButtonContainer}>
                                          <button
                                            style={{ visibility: "hidden" }}
                                            disabled={file?.IsExtracted}
                                            className={
                                              !file?.IsExtracted
                                                ? `${s.extractBtn} `
                                                : `${s.extractBtn} ${s.disabledBtn}`
                                            }
                                          >
                                            Extract
                                          </button>
                                        </div>
                                      </div>
                                      {!file.IsPipelineExecuted ? (
                                        <img
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            !file.IsPipelineExecuted &&
                                              handleOpenDeleteModel(file.id);
                                          }}
                                          height={28}
                                          src={deleteIcon}
                                          alt="doc"
                                        />
                                      ) : (
                                        <img
                                          style={{ cursor: "not-allowed" }}
                                          height={28}
                                          src={disableDeleteIcon}
                                          alt="doc"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div>
                              <div className={s.noFilesContainer}>
                                {/* <img
                                  style={{ height: "250px", width: "320px" }}
                                  src={process.env.PUBLIC_URL + noFileImage}
                                  alt="no file chosen!"
                                /> */}
                                <LottieFiles
                                  file={nofileAvailableLottie}
                                  height={200}
                                  width={300}
                                />
                              </div>
                              <div className={s.nodataSourceText}>
                                <p>No file uploaded. Please choose file.</p>
                              </div>
                            </div>
                          )
                        ) : (
                          Array.isArray(combinedData) &&
                          combinedData?.map((file, index) => {
                            let isVideoTypePresent =
                              videoType
                                .find((type) => type.name === file.id)
                                ?.value.includes("audio") ||
                              videoType
                                .find((type) => type.name === file.id)
                                ?.value.includes("text");
                            let isFolderExtracted =
                              Array.isArray(file?.files) &&
                              file.files.length > 0
                                ? file.files.every((item) => item.IsExtracted)
                                : false;
                            const fileExtension = file?.original_file_name
                              ?.split(".")
                              .pop();
                            const hasExtension =
                              file?.original_file_name?.split(".").length > 1;
                            return (
                              <div key={index} className={s.fileBox}>
                                <div className={s.fileListContainer}>
                                  <div className={s.contentBox}>
                                    <div className={s.fileList}>
                                      {getFileIcon(
                                        file.original_file_name || file?.name
                                      )}
                                      <p className={s.fileName}>
                                        {file?.original_file_name || file?.name}
                                        {hasExtension && (
                                          <div className={s.fileDetails}>
                                            <p className={s.fileSize}>
                                              {formatBytes(file.file_size)}
                                            </p>

                                            <div
                                              className={
                                                s.verticalLineInfileDetail
                                              }
                                            ></div>
                                            <p className={s.fileSize}>
                                              {file?.storage_type}
                                            </p>
                                          </div>
                                        )}
                                      </p>
                                    </div>
                                    <div className={s.BtnMainContainer}>
                                      <div className={s.ButtonContainer}>
                                        {!hasExtension ? (
                                          <>
                                            <button
                                              onClick={() => {
                                                history.push(
                                                  `/template/notifications?id=${file?.id}`
                                                );
                                              }}
                                              className={`${s.extractBtn} ${s.openButton}`}
                                            >
                                              Open
                                            </button>
                                            <button
                                              disabled={isFolderExtracted}
                                              onClick={() =>
                                                handleExtractFolder(
                                                  file.id,
                                                  fileExtension
                                                )
                                              }
                                              className={
                                                !isFolderExtracted
                                                  ? `${s.extractBtn} `
                                                  : `${s.extractBtn} ${s.disabledBtn}`
                                              }
                                            >
                                              Extract
                                            </button>
                                          </>
                                        ) : (
                                          <>
                                            {videofileExtension.includes(
                                              fileExtension
                                            ) && (
                                              <FormControl
                                                sx={{
                                                  width: "150px",
                                                  marginRight: "10px",
                                                }}
                                                size="small"
                                              >
                                                <InputLabel id="demo-simple-select-label">
                                                  Content Type
                                                </InputLabel>
                                                <Select
                                                  name={file.id}
                                                  // disabled={file?.IsExtracted}
                                                  labelId="demo-simple-select-label"
                                                  id="demo-simple-select"
                                                  value={
                                                    videoType.find(
                                                      (type) =>
                                                        type.name === file.id
                                                    )?.value || file.video_type
                                                  }
                                                  label="Content Type"
                                                  onChange={(e) => {
                                                    handleVideoType(e);
                                                  }}
                                                >
                                                  <MenuItem value={"audio"}>
                                                    audio
                                                  </MenuItem>
                                                  <MenuItem value={"text"}>
                                                    text
                                                  </MenuItem>
                                                </Select>
                                              </FormControl>
                                            )}
                                            <button
                                              // disabled={file?.IsExtracted}
                                              // disabled={
                                              //   !isVideoTypePresent &&
                                              //   file?.IsExtracted
                                              // }
                                              onClick={() =>
                                                handleExtract(
                                                  file.id,
                                                  fileExtension,
                                                  file?.file_size
                                                )
                                              }
                                              className={
                                                !file?.IsExtracted
                                                  ? // isVideoTypePresent &&
                                                    // !file?.IsExtracted
                                                    `${s.extractBtn} `
                                                  : `${s.extractBtn} ${s.disabledBtn}`
                                              }
                                            >
                                              {file?.IsExtracted
                                                ? "Extracted"
                                                : "Extract"}
                                            </button>
                                            {/* <CircularProgressWithLabel
                                              value={progress[file.id] || 0}
                                            /> */}
                                          </>
                                        )}
                                      </div>
                                    </div>

                                    {!file.IsPipelineExecuted ? (
                                      <img
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          !file.IsPipelineExecuted &&
                                          Object.keys(file).find(
                                            (item) => item == "files"
                                          )
                                            ? handleOpenDeleteModel(file.id, 0)
                                            : handleOpenDeleteModel(file.id, 1);
                                        }}
                                        height={28}
                                        src={deleteIcon}
                                        alt="doc"
                                      />
                                    ) : (
                                      <img
                                        style={{ cursor: "not-allowed" }}
                                        height={28}
                                        src={disableDeleteIcon}
                                        alt="doc"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className={s.noFilesContainer}>
                <img
                  style={{ height: "250px", width: "320px" }}
                  src={process.env.PUBLIC_URL + noFileImage}
                  alt="no file chosen!"
                />
              </div>
              <div className={s.nodataSourceText}>
                <p>No data source to show , Please upload data source.</p>
                {/* <p style={{ fontSize: "14px" }}>
                  Note : To perform operation on image file, please create
                  folder and upload image inside the folder.
                </p> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Notifications;
