import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ApexTree from "apextree";
import pdfImage from "../../assets/pdfimg.png";
import docxImage from "../../assets/docImage.png";
import botImage from "../../assets/data-processing_11938713.png";
import ReactDOMServer from "react-dom/server";
import chatbotImage from "../../assets/chatbot.png";
import summaryImage from "../../assets/summary.png";
import qnaImage from "../../assets/qna.png";
import "./tokenConsumption.css";
import { borderRadius, height } from "@mui/system";
import TokenTracingTable from "./TokenTracingTable";
import zIndex from "@mui/material/styles/zIndex";
import filterIcon from "../../assets/filter.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%", // Set the modal width to 90% of the screen
  height: "95%", // Set the modal height to 90% of the screen
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

export default function TokenConsumptionViewModel({ open, setOpen }) {
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
            Token consumption chart
          </Typography>
          <Box
            id="modal-modal-description"
            sx={{ height: "70vh", overflowY: "auto" }}
          >
            <TokenConsumptionTreeView />{" "}
            {/* Render the TreeComponent inside the modal */}
          </Box>
          {/* <Button
            onClick={handleClose}
            sx={{ mt: 2 }}
            variant="contained"
            color="primary"
          >
            Close
          </Button> */}
        </Box>
      </Modal>
    </div>
  );
}

const TokenConsumptionTreeView = () => {
  const treeRef = React.useRef(null);
  const [filterVisible, setfilterVisible] = React.useState(false);
  function addClassToParentIfTokenContainerExists() {
    const tokenContainers = document.querySelectorAll(".token-container");
    tokenContainers.forEach((tokenContainer) => {
      if (
        tokenContainer.parentElement &&
        tokenContainer.parentElement.parentElement
      ) {
        tokenContainer.parentElement.parentElement.classList.add(
          "parent-token-container"
        );
      }
    });
  }

  console.log("filterVisible-->>", filterVisible);

  React.useEffect(() => {
    setTimeout(() => {
      addClassToParentIfTokenContainerExists();
    }, 1000);
  }, []);

  const sampledata = [
    {
      question: "What is the capital of France?",
      token: 2,
      completionToken: 10,
      totalToken: 12,
      tokenCost: 0.5,
      latency: "200ms",
      creationTime: "2024-09-02 12:30:45",
    },
    {
      question: "How does photosynthesis work?",
      token: 2,
      completionToken: 20,
      totalToken: 22,
      tokenCost: 0.3,
      latency: "150ms",
      creationTime: "2024-09-02 12:32:10",
    },
    {
      question: "Explain the theory of relativity.",
      token: 5,
      completionToken: 25,
      totalToken: 30,
      tokenCost: 0.7,
      latency: "250ms",
      creationTime: "2024-09-02 12:33:55",
    },
    {
      question: "What are black holes?",
      token: 4,
      completionToken: 36,
      totalToken: 40,
      tokenCost: 0.2,
      latency: "180ms",
      creationTime: "2024-09-02 12:35:20",
    },
    {
      question: "How does photosynthesis work?",
      token: 5,
      completionToken: 65,
      totalToken: 70,
      tokenCost: 0.3,
      latency: "150ms",
      creationTime: "2024-09-02 12:32:10",
    },
    {
      question: "What is the capital of France?",
      token: 6,
      completionToken: 23,
      totalToken: 29,
      tokenCost: 0.5,
      latency: "200ms",
      creationTime: "2024-09-02 12:30:45",
    },
    {
      question: "What are black holes?",
      token: 4,
      completionToken: 30,
      totalToken: 34,
      tokenCost: 0.2,
      latency: "180ms",
      creationTime: "2024-09-02 12:35:20",
    },
  ];

  const totals = {
    token: 0,
    completionToken: 0,
    totalToken: 0,
    tokenCost: 0,
    latency: 0,
  };

  sampledata.forEach((row) => {
    totals.token += row.token || 0;
    totals.completionToken += row.completionToken || 0;
    totals.totalToken += row.totalToken || 0;
    totals.tokenCost += row.tokenCost || 0;
    totals.latency += row.latency || 0;
  });

  const tableString = ReactDOMServer.renderToString(
    <TokenTracingTable data={sampledata} />
  );
  //   const tableString = <TokenTracingTable data={data} />;

  //   var filterVisibile = false;

  window.handleElementClick = function () {
    // const filterPopup = document.getElementById("filterPopup");
    // console.log("filterPopup-->>", filterPopup);
    // filterVisibile = true;
    setfilterVisible(true);
    // filterPopup.classList.remove("hidden");
  };

  // Close the popup when clicking the close button
  //   document.querySelector(".close-btn")?.addEventListener("click", function () {
  //     document.getElementById("filterPopup").classList.add("hidden");
  //   });

  // Optional: Close the popup when clicking outside of the content area
  //   window.onclick = function (event) {
  //     const filterPopup = document.getElementById("filterPopup");
  //     if (event.target == filterPopup) {
  //       filterPopup.classList.add("hidden");
  //     }
  //   };

  //   // Apply and Clear filter button actions can be handled here
  //   document
  //     .querySelector(".apply-filter")
  //     .addEventListener("click", function () {
  //       // Implement filter application logic
  //       console.log("Filters applied");
  //       document.getElementById("filterPopup").classList.add("hidden");
  //     });

  //   document
  //     .querySelector(".clear-filter")
  //     .addEventListener("click", function () {
  //       document
  //         .querySelectorAll('.filter-option input[type="checkbox"]')
  //         .forEach((checkbox) => {
  //           checkbox.checked = false;
  //         });
  //       console.log("Filters cleared");
  //     });

  React.useEffect(() => {
    const data = {
      id: "ms",
      data: {
        imageURL: pdfImage,
        count: 5,
        name: "Data source",
      },
      options: {
        nodeBGColor: "#cdb4db",
        nodeBGColorHover: "#cdb4db",
      },
      children: [
        // Nodes for PDF sources
        {
          id: "kb",
          data: {
            imageURL: pdfImage,
            name: "Text.pdf",
          },
          options: {
            nodeBGColor: "#f8ad9d",
            nodeBGColorHover: "#f8ad9d",
          },
          children: [
            {
              id: "kc",
              data: {
                count: 12,
                name: "Tables",
              },
              options: {
                nodeBGColor: "#53b3ab",
                nodeBGColorHover: "#53b3ab",
              },
            },
            {
              id: "cr",
              data: {
                count: 2000,
                name: "Chunks",
              },
              options: {
                nodeBGColor: "#53b3ab",
                nodeBGColorHover: "#53b3ab",
              },
            },
            {
              id: "em",
              data: {
                count: 3000,
                name: "Embeddings",
              },
              options: {
                nodeBGColor: "#53b3ab",
                nodeBGColorHover: "#53b3ab",
              },
              children: [
                {
                  id: "kbc",
                  data: {
                    count: 2000,
                    tokens: "Total Tokens",
                    name: "text-embedding-ada-002",
                  },
                  options: {
                    nodeBGColor: "#d6ccc2",
                    nodeBGColorHover: "#d6ccc2",
                  },
                },
              ],
            },
            {
              id: "bot1",
              data: {
                imageURL: chatbotImage,
                name: "Yavi bot",
              },
              options: {
                nodeBGColor: "#8ecae6",
                nodeBGColorHover: "#8ecae6",
              },
              children: [
                {
                  id: "gpt3.5-min",
                  data: {
                    tokens: "Total Tokens",
                    count: "145 M",
                    name: "gpt3.5-min",
                  },
                  options: {
                    nodeBGColor: "#d6ccc2",
                    nodeBGColorHover: "#d6ccc2",
                  },
                },
                {
                  id: "gpt3.52",
                  data: {
                    tokens: "Total Tokens",
                    count: "560 M",
                    name: "gpt3.5",
                  },
                  options: {
                    nodeBGColor: "#d6ccc2",
                    nodeBGColorHover: "#d6ccc2",
                  },
                },

                {
                  id: "gpt4o",
                  data: {
                    count: 900,
                    tokens: "Total Tokens",
                    name: "gpt4o",
                  },
                  options: {
                    nodeBGColor: "#d6ccc2",
                    nodeBGColorHover: "#d6ccc2",
                  },
                },
              ],
            },
            {
              id: "qna1",
              data: {
                imageURL: qnaImage,
                name: "QnA",
              },
              options: {
                nodeBGColor: "#8ecae6",
                nodeBGColorHover: "#8ecae6",
              },
              children: [
                {
                  id: "gpt3.51",
                  data: {
                    tokens: "Total Tokens",
                    count: "560 M",
                    name: "gpt3.5",
                  },
                  options: {
                    nodeBGColor: "#d6ccc2",
                    nodeBGColorHover: "#d6ccc2",
                  },
                },
                {
                  id: "gpt4o",
                  data: {
                    count: 900,
                    tokens: "Total Tokens",
                    name: "gpt4o",
                  },
                  options: {
                    nodeBGColor: "#d6ccc2",
                    nodeBGColorHover: "#d6ccc2",
                  },
                },
              ],
            },
            {
              id: "sum1",
              data: {
                imageURL: summaryImage,
                name: "Summary",
              },
              options: {
                nodeBGColor: "#8ecae6",
                nodeBGColorHover: "#8ecae6",
              },
              children: [
                {
                  id: "gpt3.5",
                  data: {
                    tokens: "Total Tokens",
                    count: "237",
                    name: "gpt3.5",
                  },
                  options: {
                    nodeBGColor: "#d6ccc2",
                    nodeBGColorHover: "#d6ccc2",
                  },
                },
              ],
            },
          ],
        },
        // {
        //   id: "fn",
        //   data: {
        //     imageURL: pdfImage,
        //     name: "final_notification.pdf",
        //   },
        //   options: {
        //     nodeBGColor: "#f8ad9d",
        //     nodeBGColorHover: "#f8ad9d",
        //   },
        //   children: [
        //     {
        //       id: "tbl",
        //       data: {
        //         count: 12,
        //         name: "Tables",
        //       },
        //       options: {
        //         nodeBGColor: "#f8ad9d",
        //         nodeBGColorHover: "#f8ad9d",
        //       },
        //     },
        //     {
        //       id: "chnk",
        //       data: {
        //         count: 2000,
        //         name: "Chunks",
        //       },
        //       options: {
        //         nodeBGColor: "#c9cba3",
        //         nodeBGColorHover: "#c9cba3",
        //       },
        //     },
        //     {
        //       id: "embd",
        //       data: {
        //         count: 3000,
        //         name: "Embeddings",
        //       },
        //       options: {
        //         nodeBGColor: "#53b3ab",
        //         nodeBGColorHover: "#53b3ab",
        //       },
        //     },
        //     {
        //       id: "bot1",
        //       data: {
        //         imageURL: botImage,
        //         name: "Yavi bot",
        //       },
        //       options: {
        //         nodeBGColor: "#53b3ab",
        //         nodeBGColorHover: "#53b3ab",
        //       },
        //       children: [
        //         {
        //           id: "gpt3.5-min",
        //           data: {
        //             imageURL: botImage,
        //             name: "gpt3.5-min",
        //           },
        //           options: {
        //             nodeBGColor: "#53b3ab",
        //             nodeBGColorHover: "#53b3ab",
        //           },
        //         },
        //         {
        //           id: "gpt3.5",
        //           data: {
        //             imageURL: botImage,
        //             name: "gpt3.5",
        //           },
        //           options: {
        //             nodeBGColor: "#53b3ab",
        //             nodeBGColorHover: "#53b3ab",
        //           },
        //         },
        //         {
        //           id: "gpt4o",
        //           data: {
        //             imageURL: botImage,
        //             name: "gpt4o",
        //           },
        //           options: {
        //             nodeBGColor: "#53b3ab",
        //             nodeBGColorHover: "#53b3ab",
        //           },
        //         },
        //       ],
        //     },
        //     {
        //       id: "qna1",
        //       data: {
        //         imageURL: botImage,
        //         name: "QnA",
        //       },
        //       options: {
        //         nodeBGColor: "#53b3ab",
        //         nodeBGColorHover: "#53b3ab",
        //       },
        //     },
        //     {
        //       id: "sum1",
        //       data: {
        //         imageURL: botImage,
        //         name: "Summary",
        //       },
        //       options: {
        //         nodeBGColor: "#53b3ab",
        //         nodeBGColorHover: "#53b3ab",
        //       },
        //     },
        //   ],
        // },
      ],
    };

    const options = {
      contentKey: "data",
      width: "100%",
      height: "100%",
      nodeWidth: 230,
      nodeHeight: 150,
      fontColor: "#000",
      borderColor: "#333",
      borderRadius: "20px",
      childrenSpacing: 120,
      siblingSpacing: 60,
      direction: "left",
      nodeTemplate: (content) => `
     <div class="${
       content.tokens ? "node-container token-container" : " node-container"
     }" id="filterBox">
        ${
          content.tokens
            ? `<div class="node-tokens">${content.tokens}</div>`
            : ""
        }
        ${
          !content.count
            ? `<img class="node-image" src="${content.imageURL}" alt="" />`
            : `<p class="node-count">${content.count}</p>`
        }
        <div class="node-name">
          <p>${content.name}</p>
        </div>
        <div class="tooltip-content">
          <div class="tooltip-header">
            <p>gpt4o</p>
            <div
              class="filter-container"
              id="filterBox"
              onclick="handleElementClick()"
            >
              <img src="${filterIcon}" class="filter-icon" alt="filter" style="height: 15px;" />
              <p>Filter</p>
               
            </div>
          </div>
          <table class="tooltip-table">
            <thead>
              <tr class="tooltip-row header">
                <th class="tooltip-column">Sr.No</th>
                <th class="tooltip-column">Question</th>
                <th class="tooltip-column">Prompt Token</th>
                <th class="tooltip-column">Completion Token</th>
                <th class="tooltip-column">Total Token</th>
                <th class="tooltip-column">Token Cost</th>
                <th class="tooltip-column">Latency</th>
                <th class="tooltip-column">Creation Time</th>
              </tr>
            </thead>
            <tbody>
              ${sampledata
                .map(
                  (row, index) => `
                  <tr class="tooltip-row">
                    <td class="tooltip-column">${index + 1}</td>
                    <td class="tooltip-column">${row.question}</td>
                    <td class="tooltip-column">${row.token}</td>
                    <td class="tooltip-column">${row.completionToken}</td>
                    <td class="tooltip-column">${row.totalToken}</td>
                    <td class="tooltip-column">${row.tokenCost}</td>
                    <td class="tooltip-column">${row.latency}</td>
                    <td class="tooltip-column">${row.creationTime}</td>
                  </tr>
                `
                )
                .join("")}
              <tr class="tooltip-row totals-row">
                <td class="tooltip-column">Total</td>
                <td class="tooltip-column"></td>
                <td class="tooltip-column">${totals.token}</td>
                <td class="tooltip-column">${totals.completionToken}</td>
                <td class="tooltip-column">${totals.totalToken}</td>
                <td class="tooltip-column">$${totals.tokenCost.toFixed(2)}</td>
                <td class="tooltip-column"></td>
                <td class="tooltip-column"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    `,
      canvasStyle:
        "box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em; background: #f7f8fb;  height: 90vh;",
      enableToolbar: true,
    };

    if (treeRef.current) {
      const tree = new ApexTree(treeRef.current, options);
      tree.render(data);
    }
  }, []);

  return (
    <div
      id="svg-tree"
      style={{ height: "84vh", zIndex: -1 }}
      ref={treeRef}
    ></div>
  );
};

{
  /* <div id="filterPopup" class="filter-popup hidden">
<div class="filter-popup-content">
    <span class="close-btn">&times;</span>
    <h3>Filter Options</h3>
    <div class="filter-option">
    <input type="checkbox" id="filterOption1" name="filterOption1" value="option1">
    <label for="filterOption1">Option 1</label>
    </div>
    <div class="filter-option">
    <input type="checkbox" id="filterOption2" name="filterOption2" value="option2">
    <label for="filterOption2">Option 2</label>
    </div>
    
    <div class="filter-buttons">
    <button class="apply-filter">Apply Filter</button>
    <button class="clear-filter">Clear Filter</button>
    </div>
</div>
</div> */
}
