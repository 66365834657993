import { createSlice } from "@reduxjs/toolkit";
import {
  RenderExtractedTable,
  ReviewProfanityCheckAction,
  ReviewSensetiveInfoCheckAction,
  ReviewTextCleaningAction,
  ReviewTextQualityAction,
  convertExceltoJson,
  getTablesJsonView,
  getUrlToRenderImageFolderAction,
  getVideoFileAction,
  saveEditedTableAction,
} from "../actions/DataReviewAction";
import { extract_table } from "../../services/postServices";

const DataReviewSlice = createSlice({
  name: "DataReviewSlice",
  initialState: {
    data: {},
    extractedTableData: {},
    status: "",
    isLoading: false,
    imageUrls: [],
    isActiveBtn: false,
    audiosrc: "",
  },
  reducers: {
    setFileUrlsAndOtherData(state, { payload }) {
      state.audiosrc = payload?.audiosrc;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(ReviewTextCleaningAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(ReviewTextCleaningAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isLoading = false;
        state.data = payload?.data;
      })
      .addCase(ReviewTextCleaningAction.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
    builder
      .addCase(ReviewTextQualityAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(ReviewTextQualityAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isLoading = false;
        state.data = payload?.data;
      })
      .addCase(ReviewTextQualityAction.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
    builder
      .addCase(ReviewSensetiveInfoCheckAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(
        ReviewSensetiveInfoCheckAction.fulfilled,
        (state, { payload }) => {
          state.status = "success";
          state.isLoading = false;
          state.data = payload?.data;
        }
      )
      .addCase(ReviewSensetiveInfoCheckAction.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
    builder
      .addCase(ReviewProfanityCheckAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(ReviewProfanityCheckAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isLoading = false;
        state.data = payload?.data;
      })
      .addCase(ReviewProfanityCheckAction.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
    builder
      .addCase(RenderExtractedTable.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(RenderExtractedTable.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isLoading = false;
        state.extractedTableData = payload?.data;
      })
      .addCase(RenderExtractedTable.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
    builder
      .addCase(getTablesJsonView.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getTablesJsonView.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isLoading = false;
        state.data = payload?.data;
      })
      .addCase(getTablesJsonView.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
    builder
      .addCase(convertExceltoJson.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(convertExceltoJson.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isLoading = false;
        //  state.data = payload?.data;
      })
      .addCase(convertExceltoJson.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
    builder
      .addCase(getUrlToRenderImageFolderAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(
        getUrlToRenderImageFolderAction.fulfilled,
        (state, { payload }) => {
          state.status = "success";
          state.isLoading = false;
          state.imageUrls = payload?.data;
        }
      )
      .addCase(getUrlToRenderImageFolderAction.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
    builder
      .addCase(saveEditedTableAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(saveEditedTableAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isLoading = false;
        state.imageUrls = payload?.data;
      })
      .addCase(saveEditedTableAction.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
    builder
      .addCase(getVideoFileAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getVideoFileAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isLoading = false;
        state.imageUrls = payload?.data;
      })
      .addCase(getVideoFileAction.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});
export const { setFileUrlsAndOtherData } = DataReviewSlice.actions;

export default DataReviewSlice.reducer;
