import React from "react";
import { ResponsiveTree } from "@nivo/tree";
import data from "./Sampledata";

const NodeTooltip = ({ node }) => (
  <div
    style={{
      padding: "10px",
      background: "white",
      border: "1px solid #ccc",
      borderRadius: "3px",
      boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
    }}
  >
    <strong>{node.data.name}</strong>
    {node.data.number !== undefined && (
      <p>
        number of {node.data.name}: <strong>{node.data.number}</strong>
      </p>
    )}
  </div>
);

const CustomNodeLabel = ({ node }) => {
  return (
    <text
      x={node.x}
      y={node.y}
      dy="0.32em"
      textAnchor="middle"
      style={{
        fontSize: "16px",
        fill: "#000",
      }}
    >
      {node.data.name}
    </text>
  );
};

const MyResponsiveTree = () => (
  <div style={{ width: "100%", overflowX: "auto" }}>
    <div style={{ width: "1500px", height: "500px", fontSize: "16px" }}>
      <ResponsiveTree
        data={data}
        layout="top-to-bottom"
        labelsPosition="outward"
        orientLabel={false}
        identity="name"
        activeNodeSize={24}
        inactiveNodeSize={6}
        nodeColor={{ scheme: "tableau10" }}
        fixNodeColorAtDepth={1}
        linkThickness={2}
        activeLinkThickness={8}
        inactiveLinkThickness={2}
        linkColor={{
          from: "target.color",
          modifiers: [["opacity", 0.4]],
        }}
        margin={{ top: 90, right: 90, bottom: 90, left: 90, fontSize: 16 }}
        motionConfig="stiff"
        meshDetectionRadius={80}
        nodeTooltip={NodeTooltip}
        nodeLabel={CustomNodeLabel}
        labelsSkipRadius={10}
        onNodeClick={function noRefCheck() {}}
        onNodeMouseEnter={function noRefCheck() {}}
        onNodeMouseLeave={function noRefCheck() {}}
        onNodeMouseMove={function noRefCheck() {}}
        renderNode={function noRefCheck() {}}
        // theme={{
        // //   labels: {
        // //     text: {
        // //       outlineColor: '#ffffff',
        // //       outlineWidth: 2
        // //     }
        // //   },
        //   legend: {
        //     "text": {
        //         "fontSize": 12,
        //         "fill": "#333333",
        //         "outlineWidth": 0,
        //         "outlineColor": "transparent"
        //     }
        // },
        // }}
        theme={{
          background: "#ffffff",
          text: {
            fontSize: 11,
            fill: "#333333",
            outlineWidth: 0,
            outlineColor: "transparent",
          },
          axis: {
            domain: {
              line: {
                stroke: "#777777",
                strokeWidth: 1,
              },
            },
            legend: {
              text: {
                fontSize: 12,
                fill: "#333333",
                outlineWidth: 0,
                outlineColor: "transparent",
              },
            },
            ticks: {
              line: {
                stroke: "#777777",
                strokeWidth: 1,
              },
              text: {
                fontSize: 11,
                fill: "#333333",
                outlineWidth: 0,
                outlineColor: "transparent",
              },
            },
          },
          grid: {
            line: {
              stroke: "#dddddd",
              strokeWidth: 1,
            },
          },
          legends: {
            title: {
              text: {
                fontSize: 11,
                fill: "#333333",
                outlineWidth: 0,
                outlineColor: "transparent",
              },
            },
            text: {
              fontSize: 11,
              fill: "#333333",
              outlineWidth: 0,
              outlineColor: "transparent",
            },
            ticks: {
              line: {},
              text: {
                fontSize: 10,
                fill: "#333333",
                outlineWidth: 0,
                outlineColor: "transparent",
              },
            },
          },
          annotations: {
            text: {
              fontSize: 13,
              fill: "#333333",
              outlineWidth: 2,
              outlineColor: "#ffffff",
              outlineOpacity: 1,
            },
            link: {
              stroke: "#000000",
              strokeWidth: 1,
              outlineWidth: 2,
              outlineColor: "#ffffff",
              outlineOpacity: 1,
            },
            outline: {
              stroke: "#000000",
              strokeWidth: 2,
              outlineWidth: 2,
              outlineColor: "#ffffff",
              outlineOpacity: 1,
            },
            symbol: {
              fill: "#000000",
              outlineWidth: 2,
              outlineColor: "#ffffff",
              outlineOpacity: 1,
            },
          },
          tooltip: {
            wrapper: {},
            container: {
              background: "#ffffff",
              color: "#333333",
              fontSize: 12,
            },
            basic: {},
            chip: {},
            table: {},
            tableCell: {},
            tableCellValue: {},
          },
        }}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        mode="dendogram"
        legends={[
          {
            anchor: "top-left",
            direction: "row",
            justify: false,
            translateX: 0,
            translateY: -50,
            itemWidth: 100,
            itemHeight: 20,
            itemsSpacing: 10,
            symbolSize: 20,
            itemDirection: "left-to-right",
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  </div>
);

export default MyResponsiveTree;
