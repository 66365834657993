import React, { useState, useEffect } from "react";
import axios from "axios";
import "./TextCleaning.css";
import ConfigureChecks from "../../../components/Common/ConfigureChecks/ConfigureChecks";
import Button from "../../../components/Button/Button";
import {
  ConfigTextQuality,
  getConfigTextQuality,
  putConfigTextQuality,
} from "../../../redux/actions/subWorkspaceAction";
import { getAuthToken } from "../../../services/HTTTPS";
import { useDispatch, useSelector } from "react-redux";
import { configurationStatus } from "../../../ENUM";
import { toast } from "react-toastify";
import Loader from "../../../components/Common/LoadingModal/LoadingModal";

const TextQualityPage = () => {
  const dispatch = useDispatch();
  let workspace = JSON.parse(sessionStorage.getItem("workspace"));
  //  const config = useSelector(state => state.ConfigurationSlice.getConfigTextQuality);
  const { data, isLoading } = useSelector((state) => state.ConfigurationSlice);
  //console.log("data", data);

  const [options, setOptions] = useState({
    isLengthbasedFiltering: 0,
    isGrammarandSyntaxAnalysis: 0,
    isPunctuationCheck: 0,
    isRedundancyDetection: 0,
    isSpellingandTypographicalErrors: 0,
    isContextualRelevance: 0,
    isNamedEntityRecognition: 0,
    isAmbiguityDetection: 0,
  });

  //console.log("options------------->>>", options);

  useEffect(() => {
    dispatch(getConfigTextQuality());
  }, []);

  useEffect(() => {
    setOptions({
      isLengthbasedFiltering: data?.isLengthbasedFiltering || 0,
      isGrammarandSyntaxAnalysis: data?.isGrammarandSyntaxAnalysis || 0,
      isPunctuationCheck: data?.isPunctuationCheck || 0,
      isRedundancyDetection: data?.isRedundancyDetection || 0,
      isSpellingandTypographicalErrors:
        data?.isSpellingandTypographicalErrors || 0,
      isContextualRelevance: data?.isContextualRelevance || 0,
      isNamedEntityRecognition: data?.isNamedEntityRecognition || 0,
      isAmbiguityDetection: data?.isAmbiguityDetection || 0,
      workspace_id: workspace?.workspace_id,
    });
  }, [data]);

  //console.log("options------------->>>2", options);

  const handleSwitchChange = (optionName, isChecked) => {
    const newValue = isChecked ? 1 : 0;
    setOptions((prevOptions) => ({
      ...prevOptions,
      [optionName]: newValue,
    }));
  };

  const handleSave = () => {
    //console.log("clicked");
    const authToken = getAuthToken();
    if (authToken) {
      if (!data?.config_id) {
        dispatch(ConfigTextQuality(options)).then((res) => {
          if (res.payload.status === 200 || res.payload.status === 201) {
            dispatch(getConfigTextQuality());
            toast.success(configurationStatus?.Create);
          }
        });
      } else {
        const configId = data?.config_id;
        dispatch(putConfigTextQuality({ options, configId })).then((res) => {
          if (res.payload.status === 200 || res.payload.status === 201) {
            dispatch(getConfigTextQuality());
            toast.success(configurationStatus?.Create);
          }
        });
      }
    } else {
      //console.log("no auth token");
    }
  };

  const handleReset = () => {
    const configId = data?.config_id;
    setOptions({
      isLengthbasedFiltering: 0,
      isGrammarandSyntaxAnalysis: 0,
      isPunctuationCheck: 0,
      isRedundancyDetection: 0,
      isSpellingandTypographicalErrors: 0,
      isContextualRelevance: 0,
      isNamedEntityRecognition: 0,
      isAmbiguityDetection: 0,
      workspace_id: workspace.workspace_id,
      config_id: configId,
    });
  };
  return (
    <div
      style={{
        // paddingTop: "10px",
        paddingInline: "30px",
        // marginBottom: "20px",
      }}
    >
      <Loader isOpen={isLoading} />
      <h5>
        Optimizing text quality for analysis through cleaning, ensuring accuracy
        and relevance
      </h5>
      <div className="textQualityBox">
        <ConfigureChecks
          onClick={() =>
            handleSwitchChange(
              "isPunctuationCheck",
              options.isPunctuationCheck === 0
            )
          }
          option={options.isPunctuationCheck === 1}
          label="Punctuation Check"
          title = "Flag or remove texts with inconsistent or excessive use of punctuation, which can affect readability and comprehension."
        />
        <ConfigureChecks
          onClick={() =>
            handleSwitchChange(
              "isRedundancyDetection",
              options.isRedundancyDetection === 0
            )
          }
          option={options.isRedundancyDetection === 1}
          label="Redundancy Detection"
          title = "Employ algorithms to identify and eliminate redundant phrases or sentences that do not contribute new information."
        />
        <ConfigureChecks
          onClick={() =>
            handleSwitchChange(
              "isSpellingandTypographicalErrors",
              options.isSpellingandTypographicalErrors === 0
            )
          }
          option={options.isSpellingandTypographicalErrors === 1}
          label="Spelling Typographical Error"
          title = "Implement a spell-checking mechanism to identify and correct misspelled words and typographical errors."
        />
        {/* <ConfigureChecks
          onClick={() =>
            handleSwitchChange(
              "isLengthbasedFiltering",
              options.isLengthbasedFiltering === 0
            )
          }
          option={options.isLengthbasedFiltering === 1}
          label="Lengthbased Filtering"
        /> */}
        {/* <ConfigureChecks
          onClick={() =>
            handleSwitchChange(
              "isNamedEntityRecognition",
              options.isNamedEntityRecognition === 0
            )
          }
          option={options.isNamedEntityRecognition === 1}
          label="Named Entity Recognition"
        /> */}
      </div>

      <div className="ButtonContainer">
        <Button label="Update" activeBtn onClick={handleSave} />
        <Button label="Reset" onClick={handleReset} />
      </div>
    </div>
  );
};

export default TextQualityPage;
