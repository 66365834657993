import React, { useState } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import UploadJson from "./FineTuneJson";
import RaftJson from "./FineTuneRaftJson";


export default function CenteredTabs() {
  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Json File" />
        <Tab label="Create JSON File" />
      </Tabs>
      {value === 0 && <UploadJson />}
      {value === 1 && <RaftJson />}
    </Box>
  );
}
