import React, { useEffect, useRef, useState } from "react";
import s from "./Chatbot.module.scss";
import { HOST, IFRAME_URL } from "../../API/axios";
import { useDispatch, useSelector } from "react-redux";
// import Dropdown from "../../components/Common/ShowConfiguration/ConfigrationDetails";
import { getEmbeddingsAction } from "../../redux/actions/DataOutputActions";
import { setFileAvailability } from "../../redux/slices/pipelinExecutionSlice";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
function ChatBot() {
  const [age, setAge] = React.useState("");
  const pageRefs = useRef([]);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { emebeddingData } = useSelector((state) => state.DataOutputSlice);
  const { fileId } = useSelector((state) => state.PipelineExecutionSlice);
  const authToken = sessionStorage.getItem("token");
  const userData = JSON.parse(sessionStorage.getItem("user"));
  const workspace = JSON.parse(sessionStorage.getItem("workspace"));
  const [chatParams, setChatParams] = useState({
    model: "gpt-4o-mini",
    user_id: userData?.user_id,
    data_source: "",
    embeddings_id: "",
    file_id: "",
    HOST: HOST,
    refreshToken: userData.refresh,
    workspaceId: workspace.workspace_id,
  });

  useEffect(() => {
    if (pathname === "/template/QnA") {
      dispatch(
        setFileAvailability({
          fileIdPresent: false,
          fileId: "",
        })
      );
    }
  }, [pathname]);

  useEffect(() => {
    setChatParams((pre) => ({
      ...pre,
      embeddings_id: emebeddingData?.id,
      file_id: fileId,
      token: authToken,
      refreshToken: userData?.refresh,
      workspaceId: workspace?.workspace_id,
    }));
  }, [emebeddingData, fileId]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const headers = {
    Authorization: `Bearer ${authToken}`,
    "Content-Type": "application/json",
  };

  useEffect(() => {
    // if (chunksData && Object.keys(chunksData)?.length) {
    dispatch(getEmbeddingsAction(fileId));

    // }
  }, [fileId]);

  const handlChatParams = (e) => {
    const { name, value } = e.target;

    setChatParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
  };
  const queryParams = new URLSearchParams(chatParams).toString();

  return (
    <>
      <div className={s.iframeChatContainer}>
        {/* <div className={s.chatbotHeader}>
          <p className={s.chatbotTitle}>Yavi.Bot</p>
          <div className={s.chatbotHeaderDropdownsBox}>
            <FormControl sx={{ m: 0.5, minWidth: 240 }} size="small">
              <InputLabel id="select_model">Select Model</InputLabel>
              <Select
                labelId="select_model"
                id="select_model"
                value={chatParams.model}
                label="Select Model"
                onChange={handlChatParams}
                name="model"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="gpt-4o-mini">gpt-4o-mini</MenuItem>
                <MenuItem value="gpt-3.5-turbo">GPT 3.5</MenuItem>
                <MenuItem value="gpt-4o">gpt-4o</MenuItem>
              </Select>
            </FormControl>

            <Dropdown />
          </div>
        </div> */}
        {/* {isFilePresent ? ( */}
        <iframe
          width="100%"
          height="100%"
          src={`${IFRAME_URL}?${queryParams}`}
        />
        {/* ) : ( */}
        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            background: "#fff",
            height: "90%",
          }}
        >
          <img height={200} width={270} src={noGraphAvailable} />
          <p style={{ fontWeight: "600", textAlign: "center" }}>
            Please select data source to perform next step.
          </p>
        </div> */}
        {/* <NoFileAvailable isChunksPage /> */}
        {/* )} */}
      </div>
    </>
  );
}

export default ChatBot;
