// Components/Toggler.js
import React, { useState } from "react";
import s from "./Toggler.module.scss";

const Toggler = ({ name, checked, onChange }) => {
  const [value, setValue] = useState(checked ? checked : false);

  const handleChange = () => {
    const newValue = !value;
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <label className={s["dynamic-form-toggler"]}>
      <input
        type="checkbox"
        name={name}
        checked={value}
        onChange={handleChange}
      />
      <span className={s["dynamic-form-slider"]}></span>
    </label>
  );
};

export default Toggler;
