import DynamicForm from "./DynamicForm";
import React from "react";
import {AzureStorage} from "./data";
 
const Detail = ()=>{
    return(
        <>
        <DynamicForm formStructure={AzureStorage} workspaceId = '123'/>
        </>
    )
}

export default Detail;