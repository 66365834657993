import React, { useState, useEffect, useRef, useCallback } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  getIninsertedePineconeData,
  insertPineconeConfigEmbeddingsAction,
  pinecodeInsertVectorAction,
} from "../../redux/actions/DataOutputActions";
import { toast } from "react-toastify";
import Button from "../../components/Button/Button";

function PineConeStorageField({ embeddingModel, embeddingStoragName }) {
  const dispatch = useDispatch();
  const {
    emebeddingData,
    chunksData,
    isLoading,
    pineconedata,
    vectorData,
    chartImageUrl,
  } = useSelector((state) => state.DataOutputSlice);
  const {
    fileId,
    isFilePresent,
    fileName,
    imageId,
    fileData,
    folderId,
    isImageSelected,
  } = useSelector((state) => state.PipelineExecutionSlice);
  const [errors, setErrors] = useState({});
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [embeddingPineconeStorageValue, setembeddingPineconeStorageValue] =
    useState({
      api_key: "",
      index_name: "",
      dimension: "",
      metric: "",
      spec_cloud: "",
      spec_region: "",
      namespace: "",
      embeddings: emebeddingData?.id,
    });

  console.log(
    "embeddingPineconeStorageValue->>",
    embeddingPineconeStorageValue
  );
  console.log("pineconedata->>", pineconedata);

  const regions = {
    GCP: ["us-central1"],
    default: ["us-east-1", "us-west-2", "eu-west-1"],
  };

  const availableRegions =
    regions[embeddingPineconeStorageValue?.spec_cloud] || regions.default;

  useEffect(() => {
    const allValuesFilled = Object.values(embeddingPineconeStorageValue).every(
      (value) =>
        (typeof value === "string" && value.trim() !== "") ||
        (typeof value === "number" && !isNaN(value))
    );
    setIsButtonEnabled(allValuesFilled);
  }, [embeddingPineconeStorageValue]);

  const handleEmbeddingStoreValue = (e) => {
    const { value, name } = e.target;
    setembeddingPineconeStorageValue((prev) => ({ ...prev, [name]: value }));

    // Validate field
    const pattern =
      (name === "api_key" || name === "index_name" || name === "namespace") &&
      e.target.getAttribute("pattern");
    const regex = new RegExp(pattern);
    if (!value || (pattern && !regex.test(value))) {
      setErrors((prev) => ({ ...prev, [name]: true }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: false }));
    }
    // validateFields();
  };
  useEffect(() => {
    if (emebeddingData) {
      setembeddingPineconeStorageValue((pre) => ({
        ...pre,
        dimension: 1536,
        embeddings: emebeddingData?.id,
      }));
    }
  }, [emebeddingData]);

  useEffect(() => {
    if (emebeddingData?.id) {
      const payload = {
        embeddings: emebeddingData?.id,
      };
      dispatch(getIninsertedePineconeData(payload));
    }
  }, [embeddingStoragName, emebeddingData]);

  // useEffect(() => {
  //   if (pineconedata && pineconedata?.ids) {
  //     const filteredPineconedata = pineconedata?.ids[0];
  //     setembeddingPineconeStorageValue((pre) => ({
  //       ...pre,
  //       api_key: filteredPineconedata?.api_key || "",
  //       index_name: filteredPineconedata?.index_name || "",
  //       dimension: filteredPineconedata?.dimension || "1536",
  //       metric: filteredPineconedata?.metric || "",
  //       spec_cloud: filteredPineconedata?.spec_cloud || "",
  //       spec_region: filteredPineconedata?.spec_region || "",
  //       namespace: filteredPineconedata?.namespace || "",
  //       embeddings: filteredPineconedata?.embeddings || emebeddingData?.id,
  //     }));
  //   }
  // }, [pineconedata?.ids, fileId]);

  useEffect(() => {
    if (embeddingModel === "text-embedding-ada-002") {
      setembeddingPineconeStorageValue((pre) => ({
        ...pre,
        dimension: 1536,
      }));
    }
  }, [embeddingModel]);

  const validateFields = useCallback(() => {
    const newErrors = {};
    const fields = [
      { name: "api_key", pattern: "^[A-Za-z0-9-]+$" },
      { name: "index_name", pattern: "^[a-z0-9-]+$" },
      { name: "dimension" },
      { name: "metric" },
      { name: "spec_cloud" },
      { name: "spec_region" },
      { name: "namespace", pattern: "^[a-z0-9-]+$" },
    ];

    fields.forEach(({ name, pattern }) => {
      const value = embeddingPineconeStorageValue[name];
      if (!value || (pattern && !new RegExp(pattern).test(value))) {
        newErrors[name] = true;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [embeddingPineconeStorageValue]);

  const handleAllEmbeddingsToStoreInDatabase = async () => {
    try {
      if (validateFields()) {
        const insertResponse = await dispatch(
          insertPineconeConfigEmbeddingsAction(embeddingPineconeStorageValue)
        );

        if (
          insertResponse.payload.status === 200 ||
          insertResponse.payload.status === 201
        ) {
          const pineconeInstanceID = insertResponse.payload?.data?.id;

          const payload = {
            pincone_instance_id: pineconeInstanceID,
          };

          await dispatch(pinecodeInsertVectorAction(payload));
        } else if (insertResponse) {
          Object.keys(insertResponse.payload?.response?.data)?.forEach(
            (backError) => {
              insertResponse.payload?.response?.data[backError].forEach(
                (errorMessage) => {
                  toast.error(errorMessage);
                }
              );
            }
          );
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  return (
    <>
      <TextField
        label="api_key"
        type="text"
        name="api_key"
        id="api_key"
        fullWidth
        value={embeddingPineconeStorageValue?.api_key || ""}
        onChange={handleEmbeddingStoreValue}
        size="small"
        inputProps={{ pattern: "^[A-Za-z0-9-]+$" }}
        error={!!errors.api_key}
        helperText={errors.api_key ? "Invalid API key format." : ""}
      />

      <TextField
        label="Index Name"
        type="text"
        name="index_name"
        id="index_name"
        fullWidth
        value={embeddingPineconeStorageValue?.index_name || ""}
        onChange={handleEmbeddingStoreValue}
        size="small"
        inputProps={{ pattern: "^[a-z0-9-]+$" }}
        // inputProps={{ pattern: "^[a-z0-9-]+$" }}
        error={!!errors.index_name}
        helperText={errors.index_name ? "Invalid index name format." : ""}
      />
      <TextField
        label="Dimension"
        name="dimension"
        disabled
        type="number"
        id="Dimension"
        placeholder="1536"
        fullWidth
        inputProps={{ pattern: "^[A-Za-z0-9-]+$" }}
        value={embeddingPineconeStorageValue?.dimension || ""}
        onChange={handleEmbeddingStoreValue}
        size="small"
        error={!!errors.dimension}
        helperText={errors.dimension ? "Dimension cannot be empty." : ""}
      />
      <FormControl sx={{ minWidth: 220 }} size="small">
        <InputLabel id="metric-label">Metric</InputLabel>
        <Select
          labelId="metric-label"
          name="metric"
          value={embeddingPineconeStorageValue.metric || ""}
          label="Metric"
          onChange={handleEmbeddingStoreValue}
          error={!!errors.metric}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="euclidean">Euclidean</MenuItem>
          <MenuItem value="dotproduct">Dot Product</MenuItem>
          <MenuItem value="cosine">Cosine</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ minWidth: 220 }} size="small">
        <InputLabel id="chunking-algorithm-label">Spec Cloud</InputLabel>
        <Select
          labelId="chunking-algorithm-label"
          id="chunking-algorithm-select"
          value={embeddingPineconeStorageValue?.spec_cloud || ""}
          label="spec_cloud"
          name="spec_cloud"
          onChange={handleEmbeddingStoreValue}
          error={!!errors.spec_cloud}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="GCP">GCP</MenuItem>
          <MenuItem value="aws">AWS</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ minWidth: 220 }} size="small">
        <InputLabel id="chunking-algorithm-label">Spec Region</InputLabel>
        <Select
          labelId="chunking-algorithm-label"
          id="chunking-algorithm-select"
          name="spec_region"
          value={embeddingPineconeStorageValue?.spec_region || ""}
          label="spec_region"
          onChange={handleEmbeddingStoreValue}
          error={!!errors.spec_region}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {availableRegions.map((region, index) => (
            <MenuItem key={index} value={region}>
              {region}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="Namespace"
        type="text"
        id="namespace"
        name="namespace"
        fullWidth
        value={embeddingPineconeStorageValue?.namespace || ""}
        onChange={handleEmbeddingStoreValue}
        size="small"
        inputProps={{ pattern: "^[a-z0-9-]+$" }}
        error={!!errors.namespace}
        helperText={errors.namespace ? "Invalid namespace format." : ""}
      />
      <div
        style={{
          width: "100%",
          marginBottom: "25px",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          IsExtracted={
            emebeddingData && Object.keys(emebeddingData)?.length > 0
              ? false
              : true
          }
          // IsExtracted={validateFormField}
          activeBtn={
            emebeddingData && Object.keys(emebeddingData)?.length ? true : false
          }
          label={"Insert All Chunks"}
          onClick={handleAllEmbeddingsToStoreInDatabase}
        />
      </div>
    </>
  );
}

export default PineConeStorageField;
