import { createSlice } from "@reduxjs/toolkit";
import {
  GenerateQaPairs,
  GenerateSummary,
  getsaveQaPairs,
  getsaveSummary,
  saveQaPairs,
  saveSummary,
} from "../actions/InsightsActions";

const InsightsSlice = createSlice({
  name: "InsightsSlice",
  initialState: {
    generatedData: {},
    // genratedQapairs: [],
    // savedQaPairs:[],
    savedData: {},
    // savedQnA: [],
    status: "",
    isFilePresent: false,
    fileId: "",
    isLoading: false,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(GenerateSummary.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(GenerateSummary.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.generatedData = payload.data;
        state.isLoading = false;
      })
      .addCase(GenerateSummary.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(saveSummary.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(saveSummary.fulfilled, (state, { payload }) => {
        state.status = "success";
        // state.data = payload;
        state.isLoading = false;
      })
      .addCase(saveSummary.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(GenerateQaPairs.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(GenerateQaPairs.fulfilled, (state, { payload }) => {
        state.status = "success";
        // state.data = payload;
        state.isLoading = false;
      })
      .addCase(GenerateQaPairs.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getsaveSummary.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getsaveSummary.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.savedData = payload?.data;
        state.isLoading = false;
      })
      .addCase(getsaveSummary.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
    builder
      .addCase(saveQaPairs.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(saveQaPairs.fulfilled, (state, { payload }) => {
        state.status = "success";
        // state.savedQnA = payload;
        state.isLoading = false;
      })
      .addCase(saveQaPairs.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getsaveQaPairs.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getsaveQaPairs.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isLoading = false;
        // state.savedQaPairs = payload.data;
      })
      .addCase(getsaveQaPairs.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});
export const { setFileAvailabilityDownload } = InsightsSlice.actions;
export default InsightsSlice.reducer;
