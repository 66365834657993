import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import { pdfjs, Document, Page } from "react-pdf";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "./PDFRender.css";
import { HOST } from "../../../API/axios";
import { useDispatch, useSelector } from "react-redux";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ZoomInIcon from "@mui/icons-material/ZoomIn";

import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import {
  RenderPdfFileAction,
  getUrlToRenderImageFolderAction,
  getVideoFileAction,
  renderDocumentsAction,
  renderImageFiles,
} from "../../../redux/actions/DataReviewAction";
import axios from "axios";
import Loader from "../LoadingModal/LoadingModal";
import multipleImage from "./../../multipleImage.json";
import ReactPlayer from "react-player";
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function PDFRender({
  extractedText,
  currentPage,
  setCurrentPage,
  fileExtension,
  fileData,
  fileId,
  fileName,
  folderId,
}) {
  const token = sessionStorage.getItem("token");
  const authToken = sessionStorage.getItem("token");

  const videoSrcRef = useRef(null);
  const nestedFileExtension =
    fileData &&
    fileData[currentPage - 1]?.original_file_name?.split(".")?.pop();
  const dispatch = useDispatch();
  const pdfContainerRef = useRef(null);
  const pageRefs = useRef([]);
  const [videoSrc, setVideoSrc] = useState(null);
  const [docss, setDocss] = useState([]);
  const [filteredImageUrl, setFilteredImageUrls] = useState([]);
  // console.log("filteredImageUrl-->>", filteredImageUrl);
  const [audioSrc, setAudioSrc] = useState(); // state for managing the audio source file
  const [imgloading, setimgloading] = useState(false);
  const [imageCache, setImageCache] = useState({});
  const [zoomLevel, setZoomLevel] = useState(1);
  const totalPages = fileData?.length;
  const { imageUrls } = useSelector((state) => state.DataReviewSlice);
  // console.log("imageUrls-->>", imageUrls);

  useEffect(() => {
    if (imageUrls && Object.keys(imageUrls).length) {
      fetchImages(0);
      return () => {};
    }
  }, [imageUrls, fileId]);
  useEffect(() => {
    setImageCache({});
    return () => {};
  }, [fileId]);
  useEffect(() => {
    if (fileData?.length > 0) {
      dispatch(getUrlToRenderImageFolderAction(folderId)).then((res) => {
        if (res.payload.status == 201 || res.payload.status == 200) {
          fetchImages(0);
        }
      });
    }
    return () => {};
  }, [fileData]);

  useEffect(() => {
    const handleNextClick = () => {
      setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePrevClick = () => {
      setCurrentPage((prevPage) => prevPage - 1);
    };

    const addEventListeners = () => {
      const nextElement = document.querySelector("#pdf-pagination-next");
      const prevElement = document.querySelector("#pdf-pagination-prev");
      // console.log("nextElement", nextElement);

      if (nextElement) {
        nextElement.addEventListener("click", handleNextClick);
      }
      if (prevElement) {
        prevElement.addEventListener("click", handlePrevClick);
      }
    };

    const removeEventListeners = () => {
      const nextElement = document.querySelector("#pdf-pagination-next");
      const prevElement = document.querySelector("#pdf-pagination-prev");

      if (nextElement) {
        nextElement.removeEventListener("click", handleNextClick);
      }
      if (prevElement) {
        prevElement.removeEventListener("click", handlePrevClick);
      }
    };

    if (docss?.length) {
      const observer = new MutationObserver((mutationsList, observer) => {
        let elementsFound = false;

        for (let mutation of mutationsList) {
          if (mutation.type === "childList") {
            const nextElement = document.querySelector("#pdf-pagination-next");
            const prevElement = document.querySelector("#pdf-pagination-prev");
            const togglePagination = document.querySelector(
              "#pdf-toggle-pagination"
            );

            if (togglePagination) {
              togglePagination.style.display = "none"; // Corrected from styles to style
            }

            if (nextElement && prevElement) {
              addEventListeners();
              elementsFound = true;
              break;
            }
          }
        }

        if (elementsFound) {
          observer.disconnect(); // Stop observing once the elements are found
        }
      });

      observer.observe(document.body, { childList: true, subtree: true });

      // Cleanup function
      return () => {
        removeEventListeners();
        observer.disconnect(); // Ensure the observer is disconnected on cleanup
      };
    }
    return () => {};
  }, [docss]);

  useEffect(() => {
    if (fileId) {
      setDocss([
        {
          uri: `${HOST}aura-studio/dataingestion/retrieve_file/${fileId}/`,
        },
      ]);
    }
    return () => {};
  }, [fileId]);

  const fetchImages = async (num, useCache = true) => {
    // console.log("fetchImages called with imgurls:", num);
    try {
      if (useCache && imageCache[num]) {
        setFilteredImageUrls(imageCache[num]);
        return;
      }

      setimgloading(true);

      const url = imageUrls[num]?.file_url;

      if (!url) {
        throw new Error("Invalid URL");
      }

      dispatch(renderImageFiles(url)).then((response) => {
        console.log("response--->>video", response);

        if (response.payload?.status !== 200) {
          setimgloading(false);
          throw new Error(
            `Failed to fetch image, status code: ${response.payload?.status}`
          );
        }

        const blob = response.payload?.data;
        const objectUrl = URL.createObjectURL(blob);

        // Update cache
        setImageCache((prevCache) => ({
          ...prevCache,
          [num]: objectUrl,
        }));

        setFilteredImageUrls(objectUrl);
        setimgloading(false);
      });

      // const response = await axios.get(url, {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      //   responseType: "blob",
      // });
    } catch (error) {
      setimgloading(false);
      console.error("Error during image fetching:", error.message);
    }
  };

  const headers = {
    Authorization: `Bearer ${authToken}`,
    "Content-Type": "application/json",
  };
  //--------------------------- useffect to call the api for the render current content  end ---------------------------//

  const renderWordFile = useCallback(() => {
    return (
      <div className="wordRender">
        <iframe
          className="document-viewer"
          src={`https://docs.google.com/gview?url=${HOST}aura-studio/dataingestion/retrieve_any_file/${fileId}&embedded=true`}
          style={{ width: "100%", height: "60vh" }}
        ></iframe>
      </div>
    );
  }, [fileName]);

  const renderPDFfile = () => {
    return (
      <div
        ref={pdfContainerRef}
        className="pdfContainer"
        style={{
          height: "60vh",
          overflowY: "scroll",
          position: "relative",
        }}
      >
        <div ref={pageRefs}>
          <DocViewer
            key={fileId}
            documents={docss}
            pluginRenderers={DocViewerRenderers}
            prefetchMethod="GET"
            requestHeaders={headers}
          />
        </div>
      </div>
    );
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage);
      let pre = prevPage - 1;
      fetchImages(pre, true);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      fetchImages(currentPage, true);
    }
  };

  const handleZoomIn = () => {
    setZoomLevel((prevZoomLevel) => Math.min(prevZoomLevel + 0.1, 2));
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoomLevel) => Math.max(prevZoomLevel - 0.1, 0.1));
  };

  const handleResetZoom = () => {
    setZoomLevel(1);
  };

  const imageController = () => {
    return (
      <div className="control-box">
        <p className="image-name">
          {fileData[currentPage - 1]?.original_file_name}
        </p>
        <div className="controls">
          <button
            onClick={handlePrevPage}
            className="prev-control"
            disabled={currentPage === 1}
          >
            <ArrowCircleLeftIcon />
          </button>
          <div>
            Page: {currentPage} / {totalPages}
          </div>
          <button
            className="prev-control"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <ArrowCircleRightIcon />
          </button>
          <button
            className="prev-control"
            onClick={handleZoomIn}
            disabled={zoomLevel === 2}
            // disabled={currentPage === totalPages - 1}
          >
            <ZoomInIcon />
          </button>
          <button
            className="prev-control "
            onClick={handleZoomOut}
            disabled={zoomLevel === 0.7}
          >
            <ZoomOutIcon />
          </button>
          <button className="prev-control last-btn" onClick={handleResetZoom}>
            <AspectRatioIcon />
          </button>
        </div>
      </div>
    );
  };

  // const renderWordFile = useCallback(() => {
  //   return (
  //     <div className="wordRender">
  //       <iframe
  //         className="document-viewer"
  //         src={`https://docs.google.com/gview?url=${HOST}aura-studio/dataingestion/retrieve_any_file/${fileId}&embedded=true`}
  //         style={{ width: "100%", height: "60vh" }}
  //       ></iframe>
  //     </div>
  //   );
  // }, [fileName]);

  // const renderPDFfile = () => {
  //   return (
  //     <div
  //       ref={pdfContainerRef}
  //       className="pdfContainer"
  //       style={{
  //         height: "60vh",
  //         overflowY: "scroll",
  //         position: "relative",
  //       }}
  //     >
  //       <div ref={pageRefs}>
  //         <DocViewer
  //           key={fileId}
  //           documents={docss}
  //           pluginRenderers={DocViewerRenderers}
  //           prefetchMethod="GET"
  //           requestHeaders={headers}
  //         />
  //       </div>
  //     </div>
  //   );
  // };

  const renderAudioFile = () => {
    return (
      <div
        ref={pdfContainerRef}
        className="pdfContainer"
        style={{
          height: "60vh",
          overflowY: "scroll",
          position: "relative",
        }}
      >
        <div className="current-audio">
          {/* {audioSrc && ( */}
          <div className="AudioPlayer-container">
            <AudioPlayer
              key={
                nestedFileExtension === "mp3" ||
                nestedFileExtension === "ogg" ||
                nestedFileExtension === "wav"
                  ? filteredImageUrl
                  : audioSrc
              }
              src={
                nestedFileExtension === "mp3" ||
                nestedFileExtension === "ogg" ||
                nestedFileExtension === "wav"
                  ? filteredImageUrl
                  : audioSrc
              }
              className="AudioPlayer-audio"
            ></AudioPlayer>
          </div>
          {/* )} */}
        </div>
        <div className="AudioPlayer-description">
          <p>{extractedText}</p>
        </div>
      </div>
    );
  };

  const renderVideoFile = () => {
    // console.log("outside___audioSrc", audioSrc);
    if (
      audioSrc !== videoSrcRef.current ||
      nestedFileExtension != videoSrcRef.current
    ) {
      videoSrcRef.current = audioSrc || nestedFileExtension;
      // console.log("inside_audiosrc", audioSrc);
      return (
        <div
          ref={pdfContainerRef}
          className="pdfContainer"
          style={{
            height: "60vh",
            overflowY: "scroll",
            position: "relative",
          }}
        >
          <div className="current-video">
            {!fileData && (
              <div className="file-name-box">
                <p>{fileName}</p>
              </div>
            )}
            <ReactPlayer
              width="100%"
              height="100%"
              controls={true}
              url={
                nestedFileExtension === "mp4" ||
                nestedFileExtension === "webm" ||
                nestedFileExtension === "mov" ||
                nestedFileExtension === "avi" ||
                nestedFileExtension === "ogv"
                  ? filteredImageUrl
                  : audioSrc
              }
            />
          </div>
        </div>
      );
    }
  };

  const convertBlobToByteArray = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const arrayBuffer = reader.result;
        const byteArray = new Uint8Array(arrayBuffer);
        resolve(byteArray);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(blob);
    });
  };

  const createUrlFromByteArray = (byteArray, mimeType) => {
    const blob = new Blob([byteArray], { type: mimeType });
    return URL.createObjectURL(blob);
  };

  useEffect(() => {
    const handleAudioFile = async () => {
      try {
        setimgloading(true);
        const res = await dispatch(getVideoFileAction(fileId));
        if (res.payload.status === 200 || res.payload.status === 201) {
          const blob = res.payload.data;
          const objectUrl = URL.createObjectURL(blob);
          setVideoSrc(objectUrl);
          setAudioSrc(objectUrl);
          setimgloading(false);
        } else {
          setimgloading(false);
        }
      } catch (error) {
        setimgloading(false);
      }
    };

    const extensions = [
      ".mp3",
      ".wav",
      ".ogg",
      ".mp4",
      ".webm",
      ".mov",
      ".avi",
      ".ogv",
    ];
    if (extensions.includes(fileExtension)) {
      handleAudioFile();
    }

    return () => {
      if (audioSrc) {
        URL.revokeObjectURL(audioSrc);
      }
    };
  }, [fileExtension, fileId, dispatch]);

  const renderContent = () => {
    switch (fileExtension) {
      case ".doc":
      case ".docx":
        return renderWordFile();
      case ".mp3":
      case ".ogg":
      case ".wav":
        return renderAudioFile();
      case ".webm":
      case ".mov":
      case ".mp4":
      case ".avi":
      case ".ogv":
        return renderVideoFile();
      default:
        return renderPDFfile();
    }
  };

  return (
    <div className="main-container">
      {<Loader isOpen={imgloading} />}
      <p className="current-content">Current Content</p>

      {!fileData?.length > 0 ? (
        renderContent()
      ) : (
        <div>
          {imageController()}
          {(() => {
            switch (nestedFileExtension) {
              case "mp3":
              case "ogg":
              case "wav":
                return renderAudioFile();
              case "mp4":
              case "mov":
              case "webm":
                return renderVideoFile();
              default:
                return (
                  <div
                    ref={pdfContainerRef}
                    className="pdfContainer"
                    style={{
                      height: "60vh",
                      overflowY: "scroll",
                      position: "relative",
                    }}
                  >
                    <img
                      src={filteredImageUrl}
                      alt="Please wait..."
                      style={{
                        transform: `scale(${zoomLevel}) translateX(0%) translateY(0%)`,
                      }}
                      className="image-render"
                    />
                  </div>
                );
            }
          })()}
        </div>
      )}
    </div>
  );
}

export default PDFRender;

// const url = `${HOST}aura-studio/dataingestion/retrieve_file/${fileId}/`;

// if (!url) {
//   throw new Error("Invalid URL");
// }
// if (!token) {
//   throw new Error("Token is missing");
// }

// const response = await axios.get(url, {
//   headers: {
//     Authorization: `Bearer ${token}`,
//   },
//   responseType: "blob",
// });

// if (response.status !== 200) {
//   setimgloading(false);
//   throw new Error(
//     `Failed to fetch audio file, status code: ${response.status}`
//   );
// }
// const blob = response.data;
// const objectUrl = URL.createObjectURL(blob);
// setVideoSrc(objectUrl);
// setAudioSrc(objectUrl);
// setimgloading(false);
