import React, { useEffect, useState } from "react";
import EditableTable from "../../components/Common/EditableTable/EditableTable";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import NoFileAvailable from "./NoFileAvailable";
import Loader from "../../components/Common/LoadingModal/LoadingModal";
import {
  GetExtractedTablesDetailsAction,
  saveEditedTableAction,
} from "../../redux/actions/DataReviewAction";
import { HOST } from "../../API/axios";
import Button from "../../components/Button/Button";
import "./Tables.css";
import sampleJsonData from "../pipelineexecution/sampleJsonView";
import { Modal, Box } from "@mui/material";
import ReactJson from "react-json-view";
import { Height } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import axios from "axios";
import { tabelExtractionExtension } from "../../ENUM";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "90vh",
  bgcolor: "#f7f8fb",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
  borderRadius: "10px",
};
function Tables() {
  const [excelData, setExcelData] = useState({});
  const [showJson, setShowJson] = useState(false);
  const [modal, setModal] = useState(false);
  const [tablefileName, settablefileName] = useState("");
  const [foldertablefileName, setfoldertablefileName] = useState([]);
  const toggleModal = () => setModal(!modal);
  const [fetchDataComplete, setfetchDataComplete] = useState(false);
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [blobData, setblobData] = useState();

  const [tableFileName, setTableFileName] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [sheetsData, setSheetsData] = useState({});
  const [currentSheet, setCurrentSheet] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { fileId, isFilePresent, fileName, fileData, folderId } = useSelector(
    (state) => state.PipelineExecutionSlice
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const processFiles = async () => {
      setIsLoading(true);
      setSheetsData({});
      const folderTableName = [];

      const promises = fileData.map(async (file) => {
        const fileExtension = file.original_file_name?.split(".")?.pop();
        if (!tabelExtractionExtension.includes(fileExtension)) {
          try {
            const res = await dispatch(
              GetExtractedTablesDetailsAction(file.id)
            );
            if (res.payload.status === 200 || res.payload.status === 201) {
              const tableId = res.payload.data?.id;
              folderTableName.push(
                res.payload.data.extracted_tables_file.split("/").pop()
              );
              if (tableId) {
                await fetchDataAndDownloadFile(tableId);
              }
            }
          } catch (error) {
            console.error("Error processing file:", file.id, error);
          }
        }
      });

      await Promise.all(promises);

      setfoldertablefileName(folderTableName);
      setIsLoading(false);
    };

    if (fileData && fileData.length > 0) {
      processFiles();
    }
  }, [dispatch, fileData]);

  // useEfect for single file
  useEffect(() => {
    const processSingleFile = async () => {
      setIsLoading(true);
      setSheetsData({});

      try {
        const res = await dispatch(GetExtractedTablesDetailsAction(fileId));
        if (res.payload.status === 200 || res.payload.status === 201) {
          settablefileName(
            res.payload.data.extracted_tables_file.split("/").pop()
          );
          const tableId = res.payload?.data?.id;
          if (tableId) {
            await fetchDataAndDownloadFile(tableId);
          }
        }
      } catch (error) {
        console.error("Error processing file:", fileId, error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!fileData) {
      processSingleFile();
    }
  }, [fileId, dispatch]);

  const fetchDataAndDownloadFile = async (tableId) => {
    try {
      const workspace = JSON.parse(sessionStorage.getItem("workspace"));
      const authToken = sessionStorage.getItem("token");

      if (!workspace || !tableId || !authToken) {
        throw new Error("No tables available to extract.");
      }

      const apiUrl = `${HOST}aura-studio/dataextraction/tables-file/${tableId}/`;

      const fileResponse = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (!fileResponse.ok) {
        throw new Error(`Failed to load table (HTTP ${fileResponse.status})`);
      }

      const arrayBuffer = await fileResponse.arrayBuffer();

      const blob = new Blob([arrayBuffer], {
        type: fileResponse.headers.get("content-type"),
      });

      const extractedData = await readExcelFile(blob);
      if (extractedData?.Sheet1?.data?.length > 0) {
        toast.success("Table Rendered Successfully.");
      }

      if (extractedData) {
        setSheetsData((prevData) => {
          const mergedData = { ...prevData };

          const generateUniqueKey = (key, existingKeys) => {
            let newKey = key;
            let counter = 1;
            while (existingKeys.includes(newKey)) {
              newKey = `${key}_${counter}`;
              counter++;
            }
            return newKey;
          };

          const keyMap = {};

          for (const [key, value] of Object.entries(extractedData)) {
            if (!(key in keyMap)) {
              keyMap[key] = generateUniqueKey(key, Object.keys(mergedData));
            }
            const uniqueKey = keyMap[key];
            mergedData[uniqueKey] = value;
          }

          return mergedData;
        });
      }
    } catch (error) {
      console.error("Error fetching and downloading file:", error);
      toast.error("Failed to render table.");
    }
  };

  // useEffect(() => {
  //   const processSingleFile = async () => {
  //     setIsLoading(true);
  //     setSheetsData({});

  //     try {
  //       const res = await dispatch(GetExtractedTablesDetailsAction(fileId));
  //       if (res.payload.status === 200 || res.payload.status === 201) {
  //         setTableFileName(
  //           res.payload.data.extracted_tables_file.split("/").pop()
  //         );
  //         toast.success("Table Rendered Successfully.");

  //         const tableId = res.payload.data?.id;
  //         if (tableId) {
  //           await fetchDataAndDownloadFile(tableId, (data) => {
  //             setSheetsData(data);
  //           });
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Error processing file:", fileId, error);
  //       toast.error("Failed to process the file.");
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   if (!fileData) {
  //     processSingleFile();
  //   }
  // }, [fileId, fileData, dispatch]);

  // const fetchDataAndDownloadFile = async (tableId, onData) => {
  //   try {
  //     const workspace = JSON.parse(sessionStorage.getItem("workspace"));
  //     const authToken = sessionStorage.getItem("token");

  //     if (!workspace || !tableId || !authToken) {
  //       throw new Error("No tables available to extract.");
  //     }

  //     const apiUrl = `${HOST}aura-studio/dataextraction/tables-file/${tableId}/`;

  //     const fileResponse = await fetch(apiUrl, {
  //       headers: {
  //         Authorization: `Bearer ${authToken}`,
  //       },
  //     });

  //     if (!fileResponse.ok) {
  //       throw new Error(`Failed to load table (HTTP ${fileResponse.status})`);
  //     }

  //     const arrayBuffer = await fileResponse.arrayBuffer();
  //     const blob = new Blob([arrayBuffer], {
  //       type: fileResponse.headers.get("content-type"),
  //     });

  //     const extractedData = await readExcelFile(blob);

  //     if (extractedData) {
  //       onData(extractedData);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching and downloading file:", error);
  //     toast.error("Failed to render table.");
  //   }
  // };

  const readExcelFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const binaryStr = event.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        const sheetsData = {};

        workbook.SheetNames.forEach((sheetName) => {
          const worksheet = workbook.Sheets[sheetName];
          const sheetData = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            raw: false,
          });

          const headers = sheetData[0];
          const dataWithoutHeaders = sheetData.slice(1);

          const merges = worksheet["!merges"] || [];

          sheetsData[sheetName] = {
            headers: headers,
            data: dataWithoutHeaders,
            merges: merges.map(({ s, e }) => ({
              row: s.r,
              col: s.c,
              rowspan: e.r - s.r + 1,
              colspan: e.c - s.c + 1,
            })),
          };
        });

        resolve(sheetsData);
      };

      reader.onerror = (error) => reject(error);
      reader.readAsBinaryString(file);
    });
  };

  const handleSaveEditedTable = () => {
    const wb = XLSX.utils.book_new();
    Object.entries(sheetsData).forEach(([sheetName, sheetData]) => {
      const dataWithHeaders = [sheetData.headers, ...sheetData.data];

      if (dataWithHeaders && dataWithHeaders.length > 0) {
        const ws = XLSX.utils.aoa_to_sheet(dataWithHeaders);
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
      } else {
        console.warn(`Skipping empty sheet: ${sheetName}`);
      }
    });

    // Convert the workbook to a binary string
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    // Convert the binary string to a Blob
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

    dispatch(
      saveEditedTableAction({ blob, fileId, tablefileName, setIsLoading })
    );
  };
  const handleSaveButtonClick = async () => {
    // Iterate through fileData and trigger saving for each file
    for (let index = 0; index < fileData.length; index++) {
      const file = fileData[index];
      const { id, file_name } = file;
      let tablefileName = `${file_name.split(".")[0]}.xlsx`;
      // Await the completion of each save operation before proceeding to the next
      await singlehandleSaveEditedTable(index, id, tablefileName);
    }
  };

  const singlehandleSaveEditedTable = async (
    sheetIndex,
    fileId,
    tablefileName
  ) => {
    const wb = XLSX.utils.book_new();

    // Convert Object.entries to an array to access by index
    const sheetsArray = Object.entries(sheetsData);

    if (sheetsArray.length > sheetIndex) {
      const [sheetName, sheetData] = sheetsArray[sheetIndex];
      const dataWithHeaders = [sheetData.headers, ...sheetData.data];

      if (dataWithHeaders && dataWithHeaders.length > 0) {
        const ws = XLSX.utils.aoa_to_sheet(dataWithHeaders);
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
      } else {
        console.warn(`Skipping empty sheet: ${sheetName}`);
      }
      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
      const blob = new Blob([s2ab(wbout)], {
        type: "application/octet-stream",
      });

      await dispatch(
        saveEditedTableAction({ blob, fileId, tablefileName, setIsLoading })
      );
    } else {
      console.warn(`Sheet index ${sheetIndex} does not exist in sheetsData.`);
    }
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s?.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s?.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  const copyJsonToClipboard = () => {
    const jsonText = JSON.stringify(sampleJsonData, null, 2);
    navigator.clipboard
      .writeText(jsonText)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => console.error("Failed to copy JSON to clipboard", err));
  };

  return (
    <>
      <Loader isOpen={isLoading} />
      <div className="table-header">
        <h5 style={{ marginLeft: "20px" }}>
          Extracted tables for selected file :
          {sheetsData &&
            Object.keys(sheetsData)?.length > 0 &&
            Object.keys(sheetsData)?.length}
        </h5>
        <div className="json-button">
          {/* <Button   
       activeBtn
       label="Converte json"
        onClick={handleJsonView}>
       </Button> */}
          {/* <Button  onClick={handleOpen}
       activeBtn
       label={"View Json"}
     >
      </Button> */}
          <Modal
            open={open}
            onClose={handleClose}
            // aria-labelledby="json-modal-title"
            // aria-describedby="json-modal-description"
          >
            <Box sx={style}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h2>JSON Data</h2>
                <div style={{ borderRadius: ".5px" }}>
                  <button onClick={handleClose} style={{ marginTop: "-10x" }}>
                    <HighlightOffIcon style={{ fontSize: "small" }} />
                  </button>
                </div>
              </div>
              <div className="cls-btn-class">
                <button
                  onClick={copyJsonToClipboard}
                  className="close-button"
                  style={{ marginTop: "20px" }}
                >
                  <ContentCopyIcon />
                  {copied ? "Copied!" : "Copy JSON"}
                </button>
              </div>
              <ReactJson
                src={sampleJsonData}
                enableClipboard={(e) => console.log(e.source)}
              />
            </Box>
          </Modal>
        </div>
      </div>

      {isFilePresent ? (
        <>
          <div style={{ display: "flex", gap: "5px", marginBottom: "5px" }}>
            {/* {Object.keys(excelData)?.length > 0 && (
        <>
          <p>Total number of tables Extracted :</p>
          <p style={{ fontWeight: "600" }}>{` ${
            Object.keys(excelData)?.length
          } `}</p>
        </>
      )} */}
          </div>
          {Object.keys(sheetsData)?.length ? (
            <div>
              {/* <EditableTable excelData={excelData}  /> */}
              <EditableTable
                excelData={sheetsData}
                setSheetsData={setSheetsData}
                currentSheet={currentSheet}
              />
            </div>
          ) : (
            " "
          )}
          {Object.keys(sheetsData)?.length ? (
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                label={"Save"}
                onClick={
                  fileData?.length > 0
                    ? handleSaveButtonClick
                    : handleSaveEditedTable
                }
                activeBtn
              />
            </div>
          ) : (
            <NoFileAvailable isfile={false} />
          )}
        </>
      ) : (
        <NoFileAvailable isfile={false} />
      )}
    </>
  );
}

export default Tables;
