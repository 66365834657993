const sampleJsonData ={
 
  "Page 2 - Table 1": [
    {
      "Sr.\nNo": "Sr.\nNo",
      "Road": "Road",
      "Distance from the\ncentre line (mts)": "Distance from the\ncentre line (mts)"
    },
    {
      "Sr.\nNo": "1",
      "Road": "From Chhani GSFC to Jambuva bypass",
      "Distance from the\ncentre line (mts)": "20.0"
    },
    {
      "Sr.\nNo": "2",
      "Road": "From Pratapnagar road to end of Yamuna\nmill",
      "Distance from the\ncentre line (mts)": "18.0"
    },
    {
      "Sr.\nNo": "3",
      "Road": "From Genda Circle upto Vadodara branch\ncanal via Bapu ni Dargah",
      "Distance from the\ncentre line (mts)": "18.0"
    },
    {
      "Sr.\nNo": "4",
      "Road": "From Tarsali bus stand upto national\nhighway",
      "Distance from the\ncentre line (mts)": "15.0"
    },
    {
      "Sr.\nNo": "5.",
      "Road": "From Bird Circle to Jetalpur underpass",
      "Distance from the\ncentre line (mts)": "12.0"
    }
  ],
  "Page 5 - Table 1": [
    {
      "Sr.\nNo": "Sr.\nNo",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.",
      "Conceptualised Zone": "Conceptualised Zone",
      "Code": "Code",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "Permissible Use referred as code.\n(Codes detailed in Table 6.3)"
    },
    {
      "Sr.\nNo": "(1)",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "(2)",
      "Conceptualised Zone": "(3)",
      "Code": "(4)",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "(5)"
    },
    {
      "Sr.\nNo": "1",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Core Walled City",
      "Conceptualised Zone": "Core Walled Area",
      "Code": "CW",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "AS1, DW1, DW2, DW3, EDU1, H1, H2, HO, INST, M1,\nM2, PI, PU, REG, REC, SP&L, TEMP."
    },
    {
      "Sr.\nNo": "2",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Walled City, Nucleus Vistar 1, Nucleus Vistar 2, Existing Nucleus Node, Gamtal,\nGauthan, Residential area A for Alang ADA, Bhavanath for JuUDA, City Area A for\nJADA, BADA, RUDA, and City Area B for RUDA.",
      "Conceptualised Zone": "Gamtal",
      "Code": "GM",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": NaN
    },
    {
      "Sr.\nNo": "3",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Village Extension, Gamtal Extension, Natural Growth of Village, Buffer zone of Gamtal",
      "Conceptualised Zone": "Gamtal Extension",
      "Code": "GME",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "Those permissible in CW and EDU2, AS4"
    },
    {
      "Sr.\nNo": "4",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Residential Zone, Mix Use (Other than Thangadh), Residential Zone \u00ad1 (other than\nGUDA), Residential Zone \u00ad2, 3, 4, 5 for GUDA, City Area B for JADA & BADA, City Area\nC for JADA, BADA&RUDA, City Area E for BADA, Residential Area B for Alang ADA,\n&N.A. Residential Zone for Kalavad ADA,",
      "Conceptualised Zone": "Residential 1",
      "Code": "R1",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "Those permissible in CW and AS2, AS3, AS4, EDU2, H3,\nHO, M3, SE."
    },
    {
      "Sr.\nNo": "5",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Residential Zone R2 (other than GUDA), Residential Zone III ( VUDA ), City Area F\n(BADA)",
      "Conceptualised Zone": "Residential 2",
      "Code": "R2",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "Those permissible in R1."
    },
    {
      "Sr.\nNo": "6",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Residential Zone R3 (other than GUDA & VUDA), Recreation Zone (GUDA), REZ",
      "Conceptualised Zone": "Residential 3",
      "Code": "R3",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "AS1, AS2, AS3, AS4, DW1, DW2, DW3 (only for area\nfalling within 200 mts on either side of TOZ), EDU1,\nEDU2, H1, H2, H3, HO, INST, M1, M2, M3, PI, PU, REG,\nREC, SE, SP&L, TEMP."
    },
    {
      "Sr.\nNo": "7",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Residential Zone R1 GUDA",
      "Conceptualised Zone": "Residential 4",
      "Code": "R4",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "Those permissible in R1."
    },
    {
      "Sr.\nNo": "8",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Residential Renewal Zone (GUDA)",
      "Conceptualised Zone": "Residential 5",
      "Code": "R5",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "DW3, PU, PI."
    },
    {
      "Sr.\nNo": "9",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Residential Affordable Housing Zone \u2013 1 of AUDA",
      "Conceptualised Zone": "Residential Affordable\nHousing\u00ad1",
      "Code": "RAH1",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "\u2022 DW3, EDU1, EDU2, H1, H2, INST, PU, PI, and TEMP.\n\u2022 Maximum aggregated 10 % of total utilised FSI for\nM1 / M2 / AS1 / SE.\n\u2022 In AUDA, DW1 and DW2 type building shall be\npermitted in RAH\u00ad1 zone for layout of small sub\u00ad\nplotting having NA permission and layout approved\nprior to Dt.13/02/2009."
    },
    {
      "Sr.\nNo": "9a",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Residential Affordable Housing Zone (Overlay Zone), Residential Affordable Housing\nZone",
      "Conceptualised Zone": "Residential Affordable\nHousing",
      "Code": "RAH",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "\u2022 DW3, EDU1, EDU2, H1, H2, INST PU, PI and TEMP.\n\u2022 Maximum aggregated 10% of total utilised FSI for\nM1 / M2 / AS1 / SE."
    },
    {
      "Sr.\nNo": "10",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Restricted Residential Zone, Restricted Area, Flood Prone Area / Zone, Restricted\nZone RZ1, RZ2, RZ3, RZ4,RZ5 of VUDA, Hazard Prone Area, Defence Zone",
      "Conceptualised Zone": "Restricted Zone",
      "Code": "RZ",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "Those permissible in R1 with condition specified in the\nRegulation of Restricted Zone, H1 and H2."
    },
    {
      "Sr.\nNo": "11",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Local Commercial Zone, Commercial Zone excluding of UDA.",
      "Conceptualised Zone": "Commercial 1",
      "Code": "C1",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "AS1, AS2, AS3, AS4, DW1, DW2, DW3, EDUI, EDU2, H1,\nH2, H3, HO, INST, M1, M2, M3, PI, PU, REC, REG, SP&L,\nSE, ST and TEMP."
    },
    {
      "Sr.\nNo": "12",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Commercial Zone of UDA, Commercial Zone\u00adI, Commercial Zone \u00ad II, Commercial",
      "Conceptualised Zone": "Commercial 2",
      "Code": "C2",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "Those permissible in C1."
    }
  ],
  "Page 6 - Table 1": [
    {
      "Sr.\nNo": "Sr.\nNo",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.",
      "Conceptualised Zone": "Conceptualised Zone",
      "Code": "Code",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "Permissible Use referred as code.\n(Codes detailed in Table 6.3)"
    },
    {
      "Sr.\nNo": "(1)",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "(2)",
      "Conceptualised Zone": "(3)",
      "Code": "(4)",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "(5)"
    },
    {
      "Sr.\nNo": NaN,
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Zone\u00ad III (other than GUDA), Commercial Zone IV (other than GUDA)",
      "Conceptualised Zone": NaN,
      "Code": NaN,
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": NaN
    },
    {
      "Sr.\nNo": "13",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Commercial Zone III & IV of GUDA",
      "Conceptualised Zone": "Commercial \u00ad 3",
      "Code": "C3",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "Those permissible in C1."
    },
    {
      "Sr.\nNo": "14",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "General Commercial Zone (GCZ), General Commercial Zone",
      "Conceptualised Zone": "Commercial \u00ad 4",
      "Code": "C4",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "Those permissible in C1 and TO."
    },
    {
      "Sr.\nNo": "15",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Central Business District",
      "Conceptualised Zone": "Commercial \u2013 5",
      "Code": "C5",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "Those permissible in C1 and TO."
    },
    {
      "Sr.\nNo": "16",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Industrial Zone General, Industrial Zone, Non Obnoxious & Non Hazardous Industrial\nZone, Light Industrial Zone, Industrial\u00adA, GIDC.",
      "Conceptualised Zone": "Industrial Zone\u00ad 1",
      "Code": "IZ1",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "AS2, AS3, AS4, EDU2, H1, H2, H3, IN1, IN2, M1, M2,\nM3, PI, PU, REC, REG, SP&L, SE, ST and TEMP.\nHowever, in a building used for IN1 or IN2, maximum\naggregated 20% of utilized FSI shall be permitted for\nDW2/ DW3 with units of built\u00adup area upto 66sq.mt."
    },
    {
      "Sr.\nNo": "17",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Industrial Buffer Zone",
      "Conceptualised Zone": "Industrial Zone \u00ad 2",
      "Code": "IZ2",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "Those permissible in IZ1 and IN3 & IN4."
    },
    {
      "Sr.\nNo": "18",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "GIDC Estate 1",
      "Conceptualised Zone": "Industrial Zone \u00ad 3",
      "Code": "IZ3",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "IN1, IN2, IN3, M1, M2, and SE."
    },
    {
      "Sr.\nNo": "19",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "GIDC Estate 2",
      "Conceptualised Zone": "Industrial Zone \u00ad 4",
      "Code": "IZ4",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "Those permissible in IZ3 and ST."
    },
    {
      "Sr.\nNo": "20",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Heavy Industrial Zone, Industrial Zone\u00ad Special, Obnoxious & Hazardous Industrial\nZone",
      "Conceptualised Zone": "Industrial Zone \u00ad 5",
      "Code": "IZ5",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "REG, SE, ST, TR, PI, PU, I2, IN4, DW2 & DW3 both max.\nupto 20% of utilized FSI with units of built\u00adup area\nupto 66.0 sq.mt."
    },
    {
      "Sr.\nNo": "21",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Industrial\u00adB",
      "Conceptualised Zone": "Industrial Zone \u00ad 6",
      "Code": "IZ6",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "IN1, IN2, those permissible in C1, DW2 & DW3 both\nmax. upto 20% of utilized FSI with units of built\u00adup\narea upto 66.0 sq.mt."
    },
    {
      "Sr.\nNo": "22",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Mix Industrial Use",
      "Conceptualised Zone": "Industrial Zone \u00ad 7",
      "Code": "IZ7",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "AS2, AS3, AS4, DW1, DW2, DW3, EDU2, H1, H2, H3,\nIN1, IN2, M1, M2, M3, PI, PU, REC, REG, SP&L, SE, ST\nand TEMP."
    },
    {
      "Sr.\nNo": "23",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Education Zone, Educational Purpose, Education, Religious & other Facilities, Service\n& Institutional Purpose, Institutional, Knowledge Precinct, Institutional Zone II,\nInstitutional Zone III, Institutional Zone IV (other than GUDA), Special Node I (I.T.,\nFinance Services and Related Activities), Special Node 1Special Node 2(Technology\nand Bio tech Park), Special Node 2, Health Node, Knowledge Township (VUDA)",
      "Conceptualised Zone": "Knowledge and\nInstitutional Zone\u00ad 1",
      "Code": "KZ1",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "AS2, DW1, DW2, DW3, EDU1, EDU2, HO, INST, M1,\nM2, PU,PI, REC, REG, SP&L, TEMP."
    },
    {
      "Sr.\nNo": "24",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Institutional Zone \u2013IV (GUDA)",
      "Conceptualised Zone": "Knowledge and\nInstitutional Zone\u00ad2",
      "Code": "KZ2",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "Those permissible in KZ1."
    },
    {
      "Sr.\nNo": "25",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Essential Public Purpose, Public Service, Necessary Public Service, Public Purpose\n(other than RUDA), Public & Semi\u00adPublic Purpose, Public Utility, Public Utility &\nServices, Neighbourhood Centre & Town Centre, Public Purpose Building, Existing\nPubic Purpose, Public / Semi\u00adpublic, Parking, Public Use",
      "Conceptualised Zone": "Public Purpose Zone \u00ad 1",
      "Code": "PPZ1",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "PU, PI."
    },
    {
      "Sr.\nNo": "26",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Public Purpose (RUDA)",
      "Conceptualised Zone": "Public Purpose Zone \u00ad 2",
      "Code": "PPZ2",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "AG1, AG2, AG3, AS4, DW1, EDU1, EDU2, INST, PI, PU,"
    }
  ],
  "Page 7 - Table 1": [
    {
      "Sr.\nNo": "Sr.\nNo",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.",
      "Conceptualised Zone": "Conceptualised Zone",
      "Code": "Code",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "Permissible Use referred as code.\n(Codes detailed in Table 6.3)"
    },
    {
      "Sr.\nNo": "(1)",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "(2)",
      "Conceptualised Zone": "(3)",
      "Code": "(4)",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "(5)"
    },
    {
      "Sr.\nNo": NaN,
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": NaN,
      "Conceptualised Zone": NaN,
      "Code": NaN,
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "REC, REG, SP&L, ST."
    },
    {
      "Sr.\nNo": "27",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Recreational, Recreational Zone 1, Recreational Zone 2, Green Institutional Zone\u00adGI 1",
      "Conceptualised Zone": "Recreation Zone",
      "Code": "REZ",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "Botanical Garden, DW1, PI, PU, REG, REC, SP&L, TEMP,\nNatural Reserve and Sanctuary, Race Track, Shooting\nRange, Zoo, Nursery, Stadium."
    },
    {
      "Sr.\nNo": "28",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Parks, Gardens and Open Space",
      "Conceptualised Zone": "PGO",
      "Code": "P",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "REC, SP&L REG, PI, PU."
    },
    {
      "Sr.\nNo": "29",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Agriculture Zone, Agriculture, General Agriculture Zone",
      "Conceptualised Zone": "Agriculture \u2013 1",
      "Code": "AGZ1",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "AG1, AG2, AG3, AS4, DW1, EDU1, EDU2, HO, INST, IN3,\nPI, PU, REC, REG, SCK, ST, SP&L, TEMP and TR."
    },
    {
      "Sr.\nNo": "30",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Prime Agriculture, Agriculture (SP), Agriculture Special, High\u00adtech Agriculture, Hi\u00adTech\nAgriculture Use",
      "Conceptualised Zone": "Agriculture \u2013 2",
      "Code": "AGZ2",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "AG1, AG3, EDU1, EDU2, PI, PU.\n(However service apartment, bread and breakfast,\nguest house, hotel, motel, lodging and boarding shall\nnot be permitted.)"
    },
    {
      "Sr.\nNo": "31",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Restricted Agriculture Zone",
      "Conceptualised Zone": "Agriculture \u2013 3",
      "Code": "AGZ3",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "Zoo and Botanical Garden and Any activity under\ntaken by Government for infrastructure"
    },
    {
      "Sr.\nNo": "32",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Restricted Agriculture Zone (High Flood Zone)",
      "Conceptualised Zone": "Agriculture \u2013 4",
      "Code": "AGZ4",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "AG1, PI, PU, TEMP (However, service apartment,\nbread and breakfast, guest house, hotel, motel,\nlodging and boarding shall not be permitted)."
    },
    {
      "Sr.\nNo": "33",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Logistics Zone, Logistic Park, Transport Node, Transport & Communication, Public\nPurpose (ST).",
      "Conceptualised Zone": "Logistics Zone",
      "Code": "LZ",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "AS1, AS3, DW2 &DW3 (both max. upto 20% of utilised\nFSI with units of built\u00adup area upto 66.0 sq.mts for\nindustrial workers), H1, HO, INST, IN1, IN2, M1, M2,\nM3, PI, PU, REG, SE, ST, TR."
    },
    {
      "Sr.\nNo": "34",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Special Plan Development Zone of GIFT",
      "Conceptualised Zone": "Special Plan\nDevelopment Zone \u00ad 1",
      "Code": "SPD1",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "As per GIFT Master Plan."
    },
    {
      "Sr.\nNo": "35",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Special Plan Development Zone of Sabarmati Riverfront Development",
      "Conceptualised Zone": "Special Plan\nDevelopment Zone \u00ad 2",
      "Code": "SPD2",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "As per SRFDCL Master Plan."
    },
    {
      "Sr.\nNo": "36",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "SPD\u00ad Science Park",
      "Conceptualised Zone": "Special Plan\nDevelopment Zone \u00ad 3",
      "Code": "SPD3",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "Those permissible in R1."
    },
    {
      "Sr.\nNo": "37",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "SPD\u00adGandhi Ashram Special Development Area",
      "Conceptualised Zone": "Special Plan\nDevelopment Zone \u00ad 4",
      "Code": "SPD4",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "Those permissible in R1."
    },
    {
      "Sr.\nNo": "38",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Transit Oriented Zone, Smart City Node/ Transport Node, High density zone available\nin both the sides of 200 mtr. from the edge of the road.",
      "Conceptualised Zone": "Smart City & TOZ",
      "Code": "SPD5",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "Those permissible in R1, C1, REZ."
    },
    {
      "Sr.\nNo": "39",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Closed Textile Mill Zone",
      "Conceptualised Zone": "CZ",
      "Code": "CZ",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "Those permissible in KZ1, RAH."
    },
    {
      "Sr.\nNo": "40",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Forest Land",
      "Conceptualised Zone": "Forest Zone",
      "Code": "FZ",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "As per the permission of competent authority and\nsubject to Defence and Central Govt. Regulation."
    }
  ],
  "Page 8 - Table 1": [
    {
      "Sr.\nNo": "Sr.\nNo",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.",
      "Conceptualised Zone": "Conceptualised Zone",
      "Code": "Code",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "Permissible Use referred as code.\n(Codes detailed in Table 6.3)"
    },
    {
      "Sr.\nNo": "(1)",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "(2)",
      "Conceptualised Zone": "(3)",
      "Code": "(4)",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "(5)"
    },
    {
      "Sr.\nNo": "41",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Fishing Beach Zone",
      "Conceptualised Zone": "Special Zone 1",
      "Code": "SZ1",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "As per Environment Protection and CRZ criteria only\nTemporary use from wooden and cloth made\nmaterials for fishing purpose activity."
    },
    {
      "Sr.\nNo": "42",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Buffer Zone",
      "Conceptualised Zone": "Special Zone 2",
      "Code": "SZ2",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "As per IZ1"
    },
    {
      "Sr.\nNo": "43",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Hilly Area",
      "Conceptualised Zone": "Special Zone 3",
      "Code": "SZ3",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "As per Environment Regulation"
    },
    {
      "Sr.\nNo": "44",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Heritage Zone, Heritage Precinct(SZ4)",
      "Conceptualised Zone": "Special Zone 4",
      "Code": "SZ4",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "Subject to Heritage Regulations"
    },
    {
      "Sr.\nNo": "45",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Residential Tourist Accommodation Zone",
      "Conceptualised Zone": "Special Zone 5",
      "Code": "SZ5",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "DW1, DW2, DW3, HO, M1, M2, M3, PI, PU, REC, REG,\nand TO."
    },
    {
      "Sr.\nNo": "46",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Special Tourism Project Area",
      "Conceptualised Zone": "Special Zone 6",
      "Code": "SZ6",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "DW3, M2, M3, REC and TO."
    },
    {
      "Sr.\nNo": "47",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Vacant Land",
      "Conceptualised Zone": "Vacant Land",
      "Code": "VL",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "No development permission"
    },
    {
      "Sr.\nNo": "48",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Heritage Area",
      "Conceptualised Zone": "Heritage",
      "Code": "HA",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "DW1, DW2, DW3, M1, M2, HO,\nThe owners of these heritage structures and buildings\nshall be required to conserve the following original\naspects of their Buildings:\na)\nAll Facades\nb) Building Footprint\nc)\nCharacter of Open Spaces such as courtyards,\nkhadki, streets, etc."
    },
    {
      "Sr.\nNo": "49",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Mix Use Zone (Thangadh)",
      "Conceptualised Zone": "Mix Zone",
      "Code": "MUZ",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "AS1, AS2, AS3, AS4, DW1, DW2, DW3, EDU1, EDU2,\nH1, H2, H3, HO, IN1A, IN1 and IN2 for Pottery/Ceramic\nIndustries on Width of Proposed Road shall not be less\nthan 9.0 mts and 30 to 35 % land shall be kept open\nfor loading/unloading & Storage, INST, M1, M2, M3,\nPI, PU, REC, REG, SP&L, SE, ST and TEMP."
    },
    {
      "Sr.\nNo": "50",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "For D8 & D10 category development area.",
      "Conceptualised Zone": NaN,
      "Code": NaN,
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": NaN
    },
    {
      "Sr.\nNo": NaN,
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "a.\nGamtal Area",
      "Conceptualised Zone": "\u00ad\u00ad\u00ad",
      "Code": "GMD8",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "AS1, DW1, DW2, DW3, EDU1, HO, INST, M1, M2, PI,\nPU, REG, REC, SP&L and TEMP."
    },
    {
      "Sr.\nNo": NaN,
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "b.\nOutside the gamtal area but within and up to 200 mts from periphery of gamtal\nboundary.",
      "Conceptualised Zone": "\u00ad\u00ad\u00ad",
      "Code": "RMD8",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "Those permissible in GMD8 and AS2, AS3, AS4, EDU2,\nHO, M2, SE, SCK"
    },
    {
      "Sr.\nNo": NaN,
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "c.\nOutside the gamtal area but greater than 200 mts from periphery of gamtal\nboundary.",
      "Conceptualised Zone": NaN,
      "Code": "RMD9",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "Those permissible in CW and AS2, AS3, AS4, HO, IN1,\nIN2, IN3, IN4, M2, M3, SCK, SE, ST, AG1, AG2."
    },
    {
      "Sr.\nNo": "51",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "GIDC",
      "Conceptualised Zone": NaN,
      "Code": "GIDC",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "As specified by GIDC"
    },
    {
      "Sr.\nNo": NaN,
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Note:",
      "Conceptualised Zone": NaN,
      "Code": NaN,
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": NaN
    }
  ],
  "Page 9 - Table 1": [
    {
      "Sr.\nNo": "Sr.\nNo",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.",
      "Conceptualised Zone": "Conceptualised Zone",
      "Code": "Code",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "Permissible Use referred as code.\n(Codes detailed in Table 6.3)"
    },
    {
      "Sr.\nNo": "(1)",
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "(2)",
      "Conceptualised Zone": "(3)",
      "Code": "(4)",
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": "(5)"
    },
    {
      "Sr.\nNo": NaN,
      "Use Zone (Designation in Development Plan) or general land use either under\nRevenue Laws or GIDC Act.": "1.\nThe Use zone for Sr. no 50 and 51 are general land use uses controls or under GIDC Act. They are not designated under the GTP&UD Act.\nIn other than urban area, the regulations of D8 category shall be applicable with the condition of maximum building height permissible to be 16.5 mt. 2.",
      "Conceptualised Zone": NaN,
      "Code": NaN,
      "Permissible Use referred as code.\n(Codes detailed in Table 6.3)": NaN
    }
  ],
  "Page 10 - Table 1": [
    {
      "Sr.\nNo.": "Sr.\nNo.",
      "Use": "Use",
      "NaN": NaN,
      "Uses": "Uses"
    },
    {
      "Sr.\nNo.": NaN,
      "Use": "Classification",
      "NaN": NaN,
      "Uses": NaN
    },
    {
      "Sr.\nNo.": "(1)",
      "Use": "(2)",
      "NaN": NaN,
      "Uses": "(3)"
    },
    {
      "Sr.\nNo.": "1",
      "Use": "Dwelling\u20101",
      "NaN": NaN,
      "Uses": "Detached dwelling unit, Clinic not having indoor treatment facility."
    },
    {
      "Sr.\nNo.": "2",
      "Use": "Dwelling\u20102",
      "NaN": NaN,
      "Uses": "Semi\u2010detached dwelling unit, Row House, Tenement, Chawls, Farm House,\nClinic not having indoor treatment facility, Cottage Industry (not involving\nuse or installation of any kind which may create noise, vibration, fume dust\netc.)\nHome occupant and cottage industry shall not be permitted in tenement\ndwelling/ flats. Part of Residential building may be used for professional\nrequirements, as office, by advocate, doctors, architects, engineers and\nchartered accountants and Jari Industry."
    },
    {
      "Sr.\nNo.": "3",
      "Use": "Dwelling\u20103",
      "NaN": NaN,
      "Uses": "Apartment, Hostel, Dharamshala, Pre\u2010school and Affordable Housing"
    },
    {
      "Sr.\nNo.": "4",
      "Use": "Mercantile\u20101",
      "NaN": NaN,
      "Uses": "Shop, Business, Restaurant, Shopping Centre, Light Home Workshop, LPG\nCylinder Godown, Service Establishment , Clinic, Fitness Centre"
    },
    {
      "Sr.\nNo.": "5",
      "Use": "Mercantile\u20102",
      "NaN": NaN,
      "Uses": NaN
    },
    {
      "Sr.\nNo.": NaN,
      "Use": NaN,
      "NaN": "maximum seating capacity of 40 seats).",
      "Uses": NaN
    },
    {
      "Sr.\nNo.": "6",
      "Use": "Mercantile\u20103",
      "NaN": NaN,
      "Uses": "Wholesale Market, Indoor Hospital, Diamond Industry, Wholesale Market\nand their ancillary uses, Activity Related to I.T."
    },
    {
      "Sr.\nNo.": "8",
      "Use": "Educational\u20101",
      "NaN": NaN,
      "Uses": "Primary Schools, Secondary Schools, Higher Secondary Schools,"
    },
    {
      "Sr.\nNo.": "9",
      "Use": "Educational\u20102",
      "NaN": NaN,
      "Uses": "College, Polytechnic, University and ancillary use."
    },
    {
      "Sr.\nNo.": "10",
      "Use": "Assembly\u20101",
      "NaN": NaN,
      "Uses": "Community Hall, Banquet Hall, Town Hall"
    },
    {
      "Sr.\nNo.": "11",
      "Use": "Assembly\u20102",
      "NaN": NaN,
      "Uses": "Convention Centre, Exhibition Hall, Auditorium, Planetarium, Stadium,\nMuseum, Exhibition Halls, Wadi"
    },
    {
      "Sr.\nNo.": "12",
      "Use": "Assembly\u20103",
      "NaN": NaN,
      "Uses": "Theatre, Cinema, Multiplex, Drive\u2010in Cinema, Clubs, Golf Course"
    },
    {
      "Sr.\nNo.": "13",
      "Use": "Assembly\u20104",
      "NaN": NaN,
      "Uses": "Party Plot, Garden Restaurants"
    },
    {
      "Sr.\nNo.": "14",
      "Use": "Institutional",
      "NaN": NaN,
      "Uses": "Research Centres,\nDwelling\u00ad1,2 &3, Retail Shop and Restaurants as a part of Educational\nInstitute up to 20% of Utilised FSI"
    },
    {
      "Sr.\nNo.": "15",
      "Use": "Religious",
      "NaN": NaN,
      "Uses": "Temples, Church, Mosque, Gurudwara, Synagogue, Upashraya, Sant\nNiwas or any Religious Structure."
    },
    {
      "Sr.\nNo.": "16",
      "Use": "Hospitality",
      "NaN": NaN,
      "Uses": "Bed and Breakfast, Guest House, Lodging and Boarding, Hotel, Motel,\nServiced Apartment"
    },
    {
      "Sr.\nNo.": "20",
      "Use": "Service\nEstablishment",
      "NaN": NaN,
      "Uses": "Auto Repair, Workshop, Wood Workshop, Fabrication, Public\u2010Garage"
    },
    {
      "Sr.\nNo.": "21",
      "Use": "Industrial\u20101",
      "NaN": NaN,
      "Uses": "All type of Light, Service Industries, Small Factories, Warehouses,\nNewspaper Printing Press, Concrete Batching Plant, stone cutting and\npolishing; Poultry Farm, Dairy, Assembly Plant, Shipping Recycling Unit,\nScrape Processing/Cutting Unit, Scrap Shorting Yard, Industrial Row House"
    },
    {
      "Sr.\nNo.": "21A",
      "Use": "Industrial\u20101A",
      "NaN": NaN,
      "Uses": "All industries manufacturing tiles, feeting for bathroom and sanitary\nwares, ceramic, ceramic store, clay, glass and also ancillary industries\nrelated to manufacture of tiles, clay bricks, concrete blocks for paving and\nother materials and their utensils."
    },
    {
      "Sr.\nNo.": "22",
      "Use": "Industrial\u20102",
      "NaN": NaN,
      "Uses": "All Industries except Obnoxious and Hazardous Industries, Junk Yard,\nTextile Units, Ice Factory, Quarrying of Stone, Quarrying of Gravel ,\nQuarrying of Clay, Dumping of Solid Waste, Steel re\u00adrolling, Metal\nManufacturing/Sorting etc., Oil Processing/ Storage."
    },
    {
      "Sr.\nNo.": "23",
      "Use": "Industrial\u20103",
      "NaN": NaN,
      "Uses": "Slaughter House, Meat Processing Units, Leather Processing Units, Cold\nStorage, Oxygen Plant, Agro Industries, Packaging, Mechanical\nEngineering, Fly Ash."
    },
    {
      "Sr.\nNo.": "24",
      "Use": "Industrial\u20104",
      "NaN": NaN,
      "Uses": "Fuel Storage, Storage of inflammable materials, Thermal Power Plant,\nPower Plant, Gas Plant, Storage of Hazardous Materials, Obnoxious and\nHazardous Industries, Chemical Industries, Obnoxious and Hazardous\nUses, Dying House"
    },
    {
      "Sr.\nNo.": "25",
      "Use": "Storage",
      "NaN": NaN,
      "Uses": "Warehouse, Godown, Cold Storage, Timber Mart, Steel Stockyard, Ice"
    }
  ],
  "Page 11 - Table 1": [
    {
      "Sr.\nNo.": "Sr.\nNo.",
      "Use": "Use",
      "NaN": NaN,
      "Uses": "Uses"
    },
    {
      "Sr.\nNo.": NaN,
      "Use": "Classification",
      "NaN": "Code",
      "Uses": NaN
    },
    {
      "Sr.\nNo.": "(1)",
      "Use": "(2)",
      "NaN": NaN,
      "Uses": "(3)"
    },
    {
      "Sr.\nNo.": NaN,
      "Use": NaN,
      "NaN": NaN,
      "Uses": "Factory"
    },
    {
      "Sr.\nNo.": "25A",
      "Use": "Small Center\n(Kothar)",
      "NaN": "SCK",
      "Uses": "Building related to agricultural activities, small godowns / storage, having\nbuilding dimensions not exceeding 30 mtrs. X 12 mtrs."
    },
    {
      "Sr.\nNo.": "26",
      "Use": "Transport",
      "NaN": "TR",
      "Uses": "Truck Terminal, Bus Terminal (by private enterprise), Transport Terminal\nfor Goods and Passengers"
    },
    {
      "Sr.\nNo.": "27",
      "Use": "Agriculture\u20101",
      "NaN": "AG1",
      "Uses": "Horticulture, Repair and Sale of agricultural equipment, Saw Mill, Brick\nKiln, Dairy Development, Fisheries, Animal Rearing and Breeding, Natural\nResource and Sanctuary, Tannery, Concrete Batching Plant, Cemetery,\nBurial Ground, Regional Park, Way\u2010side Shop, Agricultural Vocational\nTraining Centre, Panjarapole, Roofing Tiles and Cement Pipes, Studio,\nAnimal Shed, Agro Based Storage, Farm House."
    },
    {
      "Sr.\nNo.": "28",
      "Use": "Agriculture\u20102",
      "NaN": "AG2",
      "Uses": "Agricultural Vocational Training, Mining and Quarrying, Dumping of Solid\nWaste, Shooting Range, Drive\u2010in Cinema, Golf Course, Ship Building or\nShip Breaking, Fishing, Aqua culture Processing of fish and industry based\non it port, Harbour ferry hovercraft service marine transport ,Telephone\nmicrowave or other means of communication facilities, salt pans and non\u00ad\npolluting salt processing activity Any Type activity permissible as per the\nprovision of the Environment Protection Act.1968 and Environment\n(Protection Rules\u00ad1968)"
    },
    {
      "Sr.\nNo.": "29",
      "Use": "Agriculture\u20103",
      "NaN": "AG3",
      "Uses": "Poultry Farm, Agro\u2010based Godowns"
    },
    {
      "Sr.\nNo.": "30",
      "Use": "Temporary Use",
      "NaN": "TEMP",
      "Uses": "Fair, Circus, Exhibition, Mela, Pandal, Fisheries related activities"
    },
    {
      "Sr.\nNo.": "31",
      "Use": "Public Utility",
      "NaN": "PU",
      "Uses": "Sub\u2010station, Bus Station and Terminals, Fuelling and eV Station and\nStorage with and without Service Station, Parking, Multi\u2010level Parking;\nInfrastructure for Water Supply, Purification Plant, Pumping Station,\nElectricity Sub\u2010station; Drainage, Sanitation, Domestic Garbage Disposal\nCollection, Solid Waste Transfer Station; Pumping Station, Electricity,\nPurification Plant, Fire Stations, Solar Power Plant or any development\nactivity carried out by appropriate authority for public purpose"
    },
    {
      "Sr.\nNo.": "32",
      "Use": "Public\u2010\nInstitutional",
      "NaN": "PI",
      "Uses": "Any offices for appropriate authority, government and semi government,\nPublic assembly, Public facilities and amenities like Health Public facilities\nand amenities like Transport and Communication, Public facilities and\namenities like public security, Public facilities and amenities like social\nwelfare Recreation of any Type, Post Office, Police \u2013Chowki, ward office"
    },
    {
      "Sr.\nNo.": "33",
      "Use": "Health\u00ad1",
      "NaN": "H1",
      "Uses": "PHC, Clinic, Dispensary"
    },
    {
      "Sr.\nNo.": "34",
      "Use": "Health\u00ad2",
      "NaN": "H2",
      "Uses": "Indoor Hospital up to 20 Bed, Surgical Hospital, Nursing Home"
    },
    {
      "Sr.\nNo.": "35",
      "Use": "Health\u00ad3",
      "NaN": "H3",
      "Uses": "Civil Hospital, Medical College and Research Centre, Hospital of more than\n20 Bed"
    },
    {
      "Sr.\nNo.": "36",
      "Use": "Health\u00ad4",
      "NaN": "H4",
      "Uses": "Hospital for infectious diseases, Mental Hospital"
    },
    {
      "Sr.\nNo.": "37",
      "Use": "Tourism Services",
      "NaN": "TO",
      "Uses": "Tourism Sponsored activity"
    },
    {
      "Sr.\nNo.": "38",
      "Use": "Recreation Use",
      "NaN": "REC",
      "Uses": "Natural Reserve and Sanctuary, Race Track, Shooting Range, Zoo,\nGardens, Recreation, Nursery, Botanical Garden, Green House, Play Fields,\nForest"
    },
    {
      "Sr.\nNo.": "39",
      "Use": "Sports and\nLeisure",
      "NaN": "SP&L",
      "Uses": "Sports Complex, Swimming Pool, Playfield, Camping Ground, Facility for\nwater sports, Theme/ Amusement Park, Aquarium, Zoo ,Botanical\nGarden, Exhibition and Mela, Motion Picture Hall"
    }
  ]

}

export default sampleJsonData