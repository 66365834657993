import { HOST } from "../API/axios";
import { FILE_API, GET_API, POST_API } from "./HTTTPS";

const BASE_URL = "aura-studio";

export const get_workspaces = () => {
  return GET_API(`${BASE_URL}/workspace/get_workspaces/`);
};
export const get_configuration_list = () => {
  return GET_API(`${BASE_URL}/workspace/get_workspaces/`);
};

export const get_text_cleaning = (id) => {
  return GET_API(
    `${BASE_URL}/workspace/workspace-configs/text_cleaning/${id}/`
  );
};

export const get_text_quality = (id) => {
  return GET_API(
    `${BASE_URL}/workspace/workspace-configs/text_quality_check/${id}/`
  );
};

export const get_text_length = (id) => {
  return GET_API(
    `${BASE_URL}/workspace/workspace-configs/text_length_check/${id}/`
  );
};

export const get_sensetive_info = (id) => {
  return GET_API(
    `${BASE_URL}/workspace/workspace-configs/text_sensitive_info_check/${id}/`
  );
};

export const get_text_profanity = (id) => {
  return GET_API(
    `${BASE_URL}/workspace/workspace-configs/text_profanity_check/${id}/`
  );
};

export const get_text_tables = (id) => {
  return GET_API(
    `${BASE_URL}/workspace/workspace-configs/text_extraction/${id}/`
  );
};

export const get_dataIngestion_file = (id) => {
  return GET_API(`${BASE_URL}/dataingestion/file/${id}/`);
};
export const get_file_upload = () => {
  return GET_API(`${BASE_URL}/dataingestion/files/`);
};

export const Get_Workspace_List = () => {
  return GET_API(`${BASE_URL}/workspace/get_workspaces/`);
};

export const workspace_dashboard = () => {
  return GET_API(`${BASE_URL}/workspace/dashboard/`);
};

// export const get_download_curatedConetent = (id) => {
//   return GET_API(
//     `${BASE_URL}/data_download/download/${id}/profanitycheck_curatedtext.txt/`
//   );
// };

// export const get_download_summary = (id) => {
//   return GET_API(
//     `${BASE_URL}/data_download/download_summary/${id}/summarizedtext.txt/`
//   );
// };

// export const get_download_qaPairs = (id) => {
//   return GET_API(
//     `${BASE_URL}/data_download/download_qa_pairs/${id}/quesanspair.txt/`
//   );
// };

// export const get_download_qaPairs =(id) =>{
//   return GET_API(`${BASE_URL}/data_download/download_qa_pairs/${id}/quesanspair.txt/`);
// }

// export const get_download_tables = (id) => {
//   return GET_API(
//     `${BASE_URL}/data_download/download_qa_pairs/${id}/quesanspair.txt/`
//   ); // need to change this url after updation
// };

// Download

export const get_download_curatedConetent = (download_url) => {
  const modifiedUrl1 = download_url.replace(HOST, "");
  const baseUrl = HOST;
  const modifiedUrl2 = download_url.substring(baseUrl.length);
  return GET_API(modifiedUrl2);
};

export const get_download_summary = (download_url) => {
  const modifiedUrl1 = download_url.replace(HOST, "");
  const baseUrl = HOST;
  const modifiedUrl2 = download_url.substring(baseUrl.length);
  return GET_API(modifiedUrl2);
};

export const get_download_qaPairs = (download_url) => {
  const baseUrl = HOST;
  const modifiedUrl2 = download_url.substring(baseUrl.length);
  return GET_API(modifiedUrl2);
};

export const get_download_tables = (id) => {
  return GET_API(`${BASE_URL}/data_download/table/download/${id}/`);
};
export const get_text_cleaning_data = (workspace_id, fileId) => {
  return GET_API(
    `${BASE_URL}/datacuration/get-text-cleaning/${workspace_id}/${fileId}/`
  );
};
export const get_text_quality_data = (workspace_id, file_id) => {
  return GET_API(
    `${BASE_URL}/datacuration/get-text-quality-check/${workspace_id}/${file_id}/`
  );
};
export const get_sensetiveInfo_data = (workspace_id, file_id) => {
  return GET_API(
    `${BASE_URL}/datacuration/get-sensitive-info-check/${workspace_id}/${file_id}/`
  );
};
export const get_profanityCheck_data = (workspace_id, file_id) => {
  return GET_API(
    `${BASE_URL}/datacuration/get-profanity-check/${workspace_id}/${file_id}/`
  );
};
export const get_save_summary = (workapceid, fileId) => {
  return GET_API(`${BASE_URL}/insights/get-summary/${workapceid}/${fileId}/`);
};

export const get_save_qaPairs = (workapceid, fileId) => {
  return GET_API(`${BASE_URL}/insights/get-qapairs/${workapceid}/${fileId}/`);
};
export const get_pdf_file = (fileId) => {
  return GET_API(`${BASE_URL}/dataingestion/pdf-files/${fileId}/`);
};
export const get_extracted_tables = (fileId) => {
  return GET_API(`${BASE_URL}/dataextraction/tables/4/`);
};

export const get_extracted_table_details = (fileId) => {
  return GET_API(`${BASE_URL}/dataextraction/table-extraction/${fileId}/`);
};
export const render_documents = (fileId) => {
  return GET_API(`${BASE_URL}/dataingestion/retrieve_file/${fileId}/`);
};

export const folder_and_file_list = (worskpace_id) => {
  return GET_API(`${BASE_URL}/dataingestion/files/${worskpace_id}`);
};
export const get_url_render_folder = (folder_id) => {
  return GET_API(
    `${BASE_URL}/dataingestion/retrieve_folder_files/${folder_id}`
  );
};
export const get_tables_json = (fileId) => {
  return GET_API(`${BASE_URL}/dataextraction/table-extraction/${fileId}/`);
};
export const get_video_file_data = (fileId) => {
  return FILE_API(`${BASE_URL}/dataingestion/retrieve_file/${fileId}/`);
};

export const retrive_chunks = (fileId) => {
  return GET_API(`${BASE_URL}/datachunks/retrieve-chunks/${fileId}/`);
};
export const retrive_embeddings = (fileId) => {
  return GET_API(`${BASE_URL}/embeddings/retrieve_embeddings/${fileId}/`);
};

export const download_embeddings = (fileId) => {
  return GET_API(`${BASE_URL}/data_download/embeddings/download/${fileId}/`);
};

export const retrive_metadata_configrations = (fileId) => {
  return GET_API(`${BASE_URL}/metadata/keyword_config/${fileId}/`);
};

export const retrive_metadata = (fileId) => {
  return GET_API(`${BASE_URL}/metadata/retrieve_keyword_metadata/${fileId}/`);
};

export const retrive_topic_configration = (fileId) => {
  return GET_API(`${BASE_URL}/metadata/topic_generation_config/${fileId}/`);
};

export const retrive_topic_metadata = (fileId) => {
  return GET_API(`${BASE_URL}/metadata/retrieve-topic-metadata/${fileId}/`);
};

export const retrive_Tables_chunks = (fileId) => {
  return GET_API(`${BASE_URL}/datachunks/retrieve-table-chunks/${fileId}/`);
};
export const get_knowledge_graph_data = (fileId) => {
  return GET_API(`${BASE_URL}/KnowledgeGraph/knowledge-graph/${fileId}/`);
};

export const render_chart_image = (fileId) => {
  return FILE_API(`${BASE_URL}/embeddings/download-chart/${fileId}/`);
};

export const get_chromaDB_data = (fileId) => {
  return GET_API(`${BASE_URL}/embeddings/store_embeddings/chromadb/${fileId}/`);
};
export const get_image_file = (url) => {
  return FILE_API(url);
};
export const get_fileData_chatbot = (fileId) => {
  return FILE_API(`${BASE_URL}/dataingestion/retrieve_file/${fileId}/`);
};
