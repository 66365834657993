// TabsComponent.js
import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import { Row } from "reactstrap";
import "react-tabs/style/react-tabs.css"; // Import the default styles
import "./tabs.css";
import TextCleaningPage from "./TextCleaning";
import TextQualityPage from "./TextQuality";
import TextLengthPage from "./TextLength";
import SensitiveInfoPage from "./SensetiveInfo";
import ProfanityCheckPage from "./ProfanityCheck";
  import TablesPage from "./Tables";
const TabsComponent = ({ activeTabIndex, handleTabClick }) => {
  return (
    <Tabs>
      <TabList className="tabList">
        <Tab>Text Cleaning</Tab>
        <Tab>Text Quality</Tab>
        {/* <Tab>Text Length</Tab> */}
        <Tab>Sensitive info</Tab>
        <Tab>Profanity Check</Tab>
        <Tab>Tables</Tab>
      </TabList>

      <TabPanel>
        <Row>
          <TextCleaningPage />
        </Row>
      </TabPanel>
      <TabPanel>
        <Row>
          <TextQualityPage />
        </Row>
      </TabPanel>
      {/* <TabPanel>
        <Row>
          <TextLengthPage />
        </Row>
      </TabPanel> */}
      <TabPanel>
        <Row>
          <SensitiveInfoPage />
        </Row>
      </TabPanel>
      <TabPanel>
        <Row>
          <ProfanityCheckPage />
        </Row>
      </TabPanel>
      <TabPanel>
        <Row>
         <TablesPage/>
        </Row>
      </TabPanel>
    </Tabs>
  );
};

export default TabsComponent;
