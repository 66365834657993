import React, { useEffect, useMemo, useState } from "react";
import s from "./KnowladgeGraph.module.scss";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Button from "../../components/Button/Button";
import KnowledgeGraphVisualization from "./KnowladgeGraphVisualization";
import { useDispatch, useSelector } from "react-redux";
import noGraphAvailable from "../../assets/No_Data_Found.jpg";
import graphData from "./relationshipdata.json";
import {
  createKnowledgeGraphAction,
  getKnowledgeGraphDataAction,
  updateKnowledgeGraphData,
} from "../../redux/actions/knowledgegraphAction";
import Loader from "../../components/Common/LoadingModal/LoadingModal";
import NoFileAvailable from "../DataReview/NoFileAvailable";

const KnowladgeGraph = () => {
  const dispatch = useDispatch();
  const {
    fileId,
    isFilePresent,
    fileName,
    imageId,
    fileData,
    folderId,
    isImageSelected,
  } = useSelector((state) => state.PipelineExecutionSlice);
  const { knowledgeGraphData, isLoading } = useSelector(
    (state) => state.knowledgeGraphSlice
  );
  const graphData = knowledgeGraphData?.relationships?.map((item, index) => ({
    ...item,
    id: index,
  }));
  const [hasServer, setHasServer] = useState(false);

  // const initialCheckedItems = useMemo(() => {
  //   const items = {};
  //   graphData?.forEach((item) => {
  //     items[item?.id] = item;
  //   });
  //   return items;
  // }, [graphData]);

  // const [checkedItems, setCheckedItems] = useState(initialCheckedItems);
  const [checkedItems, setCheckedItems] = useState(() => {
    const initialCheckedItems = {};
    graphData?.forEach((item) => {
      initialCheckedItems[item?.id] = false; // Initially unchecked
    });
    return initialCheckedItems;
  });

  // const [checkedItems, setCheckedItems] = useState(() => {
  //   const initialCheckedItems = {};
  //   graphData?.forEach((item) => {
  //     initialCheckedItems[item?.id] = item;
  //   });
  //   return initialCheckedItems;
  // });

  // useEffect(() => {
  //   const initialCheckedItems = {};
  //   graphData?.forEach((item) => {
  //     initialCheckedItems[item?.id] = item;
  //   });
  //   setCheckedItems(initialCheckedItems);
  // }, [graphData]);

  // const [checkedItems, setCheckedItems] = useState();
  const [formData, setFormData] = useState({
    db_type: "",
    file_id: fileId,
    // neo4j_url: "neo4j://137.117.45.236:7687",
    neo4j_url: "",
    // neo4j_username: "neo4j",
    neo4j_username: "",
    // neo4j_password: "neo4jYavi",
    neo4j_password: "",
  });

  // useEffect(() => {
  //   setCheckedItems((prev) => {
  //     graphData?.forEach((item) => {
  //       prev[item?.id] = item;
  //     });
  //   });
  // }, [graphData]);

  const handleRelationCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const updatedCheckedItems = { ...checkedItems };

    if (checked) {
      // Find the corresponding node object by ID
      const nodeToAdd = graphData.find((node) => node.id === parseInt(name));
      if (nodeToAdd) {
        updatedCheckedItems[nodeToAdd.id] = nodeToAdd;
      }
    } else {
      // Remove the node object from checkedItems by ID
      delete updatedCheckedItems[parseInt(name)];
    }

    setCheckedItems(updatedCheckedItems);
  };

  useEffect(() => {
    if (fileId) {
      setFormData((pre) => ({ ...pre, file_id: fileId }));
      dispatch(getKnowledgeGraphDataAction(fileId));
    }
  }, [fileId]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDbTypeChange = (event) => {
    setFormData((prevState) => ({
      ...prevState,
      db_type: event.target.value,
    }));
  };

  const handleCheckboxChange = (event) => {
    setHasServer(event.target.checked);
  };

  const handleCreateKnowledgeGraph = () => {
    dispatch(createKnowledgeGraphAction(formData)).then((res) => {
      if (res.payload.status == 200 || res.payload.status === 201) {
        dispatch(getKnowledgeGraphDataAction(fileId));
      }
    });
    // Add your submission logic here
  };

  const removeIdFromItems = (items) => {
    return Object.values(items).map(
      ({ id, ...itemWithoutId }) => itemWithoutId
    );
  };

  const handleUpdateKnowledgeGraphData = () => {
    const dataToSend = removeIdFromItems(checkedItems);
    const payload = {
      relationships: dataToSend,
    };

    dispatch(updateKnowledgeGraphData({ payload, fileId })).then((res) => {
      console.log("knowledge graph data", res);
      dispatch(getKnowledgeGraphDataAction(fileId));
    });
  };

  const NoDataFoundPage = (title) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <img height={200} width={270} src={noGraphAvailable} />

        <p style={{ fontWeight: "600", textAlign: "center" }}>
          {title == "data"
            ? "Nodes and relations not found for select data source."
            : title == "chart" &&
              "No chart available for the selected data source"}
        </p>
      </div>
    );
  };

  return (
    <>
      <Loader isOpen={isLoading} />
      <div className={s.knowladgeGraphContainer}>
        <h5>Knowledge Graph</h5>
        {!isFilePresent ? (
          <NoFileAvailable isChunksPage />
        ) : (
          <>
            <div className={s.knowladgeGraphHeader}>
              <div className={s.knowladgegraphDB}>
                <span className={s.knowladgeGraphSelect}>
                  Select Knowledge Graph DB
                </span>
                <FormControl sx={{ m: 2, minWidth: 240 }} size="small">
                  <InputLabel id="db-type-label">Knowledge graph db</InputLabel>
                  <Select
                    labelId="db-type-label"
                    id="db-type-select"
                    value={formData.db_type}
                    label="Knowledge graph db"
                    onChange={handleDbTypeChange}
                    name="db_type"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="neo4j">Neo 4j</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className={s.knowladgeGraphcheckbox}>
                <Checkbox
                  className={s.knowladgeGraphcheckboxInput}
                  id="has-server"
                  checked={hasServer}
                  onChange={handleCheckboxChange}
                />
                <label
                  className={s.knowladgeGraphcheckboxLabel}
                  htmlFor="has-server"
                >
                  Already have Neo4j server and DB
                </label>
              </div>

              <div className={s.knowladgeGraphbutton}>
                <Button
                  activeBtn={true}
                  IsExtracted={formData?.db_type ? false : true}
                  label={"Create Graph"}
                  onClick={handleCreateKnowledgeGraph}
                />
              </div>
            </div>
            {hasServer && (
              <div className={s.knowladgeGraphform}>
                <div className={s.knowladgeGraphformGroup}>
                  <label className={s.knowladgeGraphlabel} htmlFor="neo4j-uri">
                    {/* NEO4J_URI */}
                  </label>
                  <TextField
                    label="NEO4J URI"
                    id="neo4j-uri"
                    name="neo4jUri"
                    value={formData.neo4jUri}
                    onChange={handleInputChange}
                    size="small"
                    fullWidth
                  />
                </div>
                <div className={s.knowladgeGraphformGroup}>
                  <label
                    className={s.knowladgeGraphlabel}
                    htmlFor="neo4j-username"
                  >
                    {/* NEO4J_USERNAME */}
                  </label>
                  <TextField
                    label="NEO4J USERNAME"
                    id="neo4j-username"
                    name="username"
                    value={formData.username}
                    onChange={handleInputChange}
                    size="small"
                    fullWidth
                  />
                </div>
                <div className={s.knowladgeGraphformGroup}>
                  <label
                    className={s.knowladgeGraphlabel}
                    htmlFor="neo4j-password"
                  >
                    {/* NEO4J_PASSWORD */}
                  </label>
                  <TextField
                    id="neo4j-password"
                    label="NEO4J PASSWORD"
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    size="small"
                    fullWidth
                  />
                </div>
              </div>
            )}
            <div className={s.knowladgegraphVisualContainer}>
              <div className={s.knowladgegraphnode}>
                <p className={s.knowldgegraphTitle}>
                  Knowledge Graph Relations
                </p>
                <div className={s.knowladgeGraphRelationBox}>
                  <div className={s.KnowladgeGraphNodes}>
                    {!graphData?.length ? (
                      NoDataFoundPage("data")
                    ) : (
                      <>
                        <div className={s.nodesTitle}>
                          <b className={s.NodeTitle}>Allowed Nodes</b>
                          <b className={s.NodeTitle}>Allowed relationships</b>
                        </div>
                        {Array.isArray(graphData) &&
                          graphData?.map((node) => (
                            <div className={s.nodesData} key={node?.id}>
                              <Checkbox
                                checked={!!checkedItems[node?.id]}
                                onChange={handleRelationCheckboxChange}
                                name={node?.id?.toString()}
                              />
                              <div className={s.NodescontentBox}>
                                <p className={s.nodeSource}>{node.source}</p>
                                <p className={s.nodeRelationship}>
                                  {node?.relationship}
                                </p>
                              </div>
                            </div>
                          ))}
                      </>
                    )}
                  </div>
                  <div className={s.knowladgeGraphVerticalLine}></div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "10px",
                  }}
                >
                  {graphData?.length && (
                    <Button
                      IsExtracted={graphData?.length ? false : true}
                      onClick={handleUpdateKnowledgeGraphData}
                      activeBtn={true}
                      label={"Save"}
                    />
                  )}
                </div>
              </div>
              <div className={s.knowladgegraphOutput}>
                <p className={s.knowldgegraphTitle}>Knowledge Graph</p>
                {!graphData?.length ? (
                  NoDataFoundPage("chart")
                ) : (
                  <KnowledgeGraphVisualization />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default KnowladgeGraph;
