import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  genrate_qa_pairs,
  genrate_summary,
  save_qa_pairs,
  save_summary,
} from "../../services/postServices";
import { get_save_qaPairs, get_save_summary } from "../../services/getServices";
import { toast } from "react-toastify";
import { InsightLiveQnA } from "../../API/axios";

export const GenerateSummary = createAsyncThunk(
  "GenerateSummary",
  async (value) => {
    try {
      // let PAYLOAD = value

      // let fileId = JSON.parse(sessionStorage.getItem("file_id"));
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      let payload = {
        ...value,
        workspace_id: workspace.workspace_id,
        is_static: InsightLiveQnA,
      };
      const data = await genrate_summary(payload);

      if (data && data.response) {
        //console.log("data", data);
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);

export const saveSummary = createAsyncThunk("saveSummary", async (value) => {
  try {
    let fileId = JSON.parse(sessionStorage.getItem("file_id"));
    let workspace = JSON.parse(sessionStorage.getItem("workspace"));
    let payload = {
      ...value,
      // workspace_id: workspace.workspace_id,
      // file_id: fileId,
    };
    //console.log("payload", payload);
    const data = await save_summary(payload);

    if ((data && data.status === 200) || data.status === 201) {
      //console.log("data", data);
      toast.success("Summary Saved Successfully.");
      return data;
    } else {
      throw data;
    }
  } catch (error) {
    //console.log("error", error);
    if (error.response.status === 500) {
      toast.error(error.response?.statusText);
    } else {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      error.toast("Something went wrong while saving generated summary.");
    }
    return error;
  }
});
export const getsaveSummary = createAsyncThunk(
  "getsaveSummary",
  async (fileId) => {
    try {
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      if (fileId !== undefined) {
        const data = await get_save_summary(workspace?.workspace_id, fileId);
        //console.log("get configration data", data);
        if (data && data.status == 200) {
          // toast.success("Saved Summary Fetched Successfully.");
          return data;
        } else {
          toast.error("Something went wrong while fetching summary.");
          throw data;
        }
      } else {
        toast.error("Please choose upload pdf in data ingestion.");
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      //console.log("error----->>", error);
      return error;
    }
  }
);

export const GenerateQaPairs = createAsyncThunk(
  "GenerateQaPairs",
  async (value) => {
    try {
      // let PAYLOAD = value
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      let payload = {
        ...value,
        workspace_id: workspace.workspace_id,
        is_static: InsightLiveQnA,
      };
      const data = await genrate_qa_pairs(payload);

      if (data && data.response) {
        //console.log("data", data);
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);

export const saveQaPairs = createAsyncThunk("saveQaPairs", async (value) => {
  try {
    //console.log("payload", payload);
    const data = await save_qa_pairs(value);

    if ((data && data.status === 200) || data.status === 201) {
      //console.log("data", data);
      toast.success("Question and Answer pair saved successfully.");
      return data;
    } else {
      throw data;
    }
  } catch (error) {
    if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error. Please try again later.");
    }
    return error;
  }
});
export const getsaveQaPairs = createAsyncThunk(
  "getsaveQaPairs",
  async (fileId) => {
    try {
      // let PAYLOAD = value
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      const data = await get_save_qaPairs(workspace?.workspace_id, fileId);
      //console.log("data", data);
      if (data) {
        // //console.log("data",data);
        // toast.success("Saved Q And A pair fetched succefully.");
        return data;
      } else {
        toast.error("Something went wrong while fetching saved Q and A");
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        // toast.error("Internal Server Error. Please try again later.");
      }
      // toast.error(
      //   error?.response?.data?.error
      //     ? error?.response?.data?.error
      //     : "Questions and answers pairs not found."
      // );
      return error;
    }
  }
);
