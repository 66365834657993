import { createSlice } from "@reduxjs/toolkit";
import {
  logOutAction,
  loginAction,
  registerAction,
} from "../actions/AuthAction";

const token = sessionStorage.getItem("token");
const refreshToken = sessionStorage.getItem("refreshToken");
const authenticated = token && token;

const authSlice = createSlice({
  name: "user",
  initialState: {
    data: {
      accessToken: token,
      refreshToken: refreshToken,
    },
    status: "",
    isLoading: false,
    isFetching: false,
    isLogout: false,
    sessionExpired: false,
    isAuthenticated: Boolean(authenticated),
    errorMessage: "",
  },
  reducers: {
    loginSuccess(state) {
      state.isFetching = false;
      state.isAuthenticated = true;
      state.errorMessage = "";
    },
    sessionExpired(state) {
      state.sessionExpired = true;
    },
    loginFailure(state, action) {
      state.isFetching = false;
      state.isAuthenticated = false;
      state.errorMessage = action.payload;
      // state.errorMessage = action.payload.errorMessage;
    },
    logoutSuccess(state) {
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("_grecaptcha");
      sessionStorage.removeItem("currentPage");
      sessionStorage.removeItem("workspace");
      sessionStorage.removeItem("file_id");
      sessionStorage.removeItem("table_id");
      state.isAuthenticated = false;
    },
    setTokens(state, action) {
      state.data = action.payload;
      sessionStorage.setItem("token", action.payload.accessToken);
      sessionStorage.setItem("refreshToken", action.payload.refreshToken);
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(loginAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(loginAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(loginAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(logOutAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(logOutAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
        state.isLogout = true;
      })
      .addCase(logOutAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
        state.isLogout = false;
      });
    builder
      .addCase(registerAction.pending, (state) => {
        state.isLoading = true;
        state.status = "loading";
      })
      .addCase(registerAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isLoading = false;
        state.data = payload;
      })
      .addCase(registerAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});
export const {
  loginSuccess,
  loginFailure,
  logoutSuccess,
  sessionExpired,
  setTokens,
} = authSlice.actions;

export default authSlice.reducer;
