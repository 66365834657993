import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import s from "./DeleteModal.module.scss";
import Button from "../../Button/Button";
import { useDispatch } from "react-redux";
import {
  DeleteWorkspaceAction,
  WorkspaceListAction,
} from "../../../redux/actions/MainWorkspaceAction";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "10px",
  p: 3,
};

export default function DeleteModel({ isOpen, handleCloseDeleteModel, deleteType, onClick }) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(isOpen);

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleCloseDeleteModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={s.DeleteBox}>
            <p>
              {deleteType === "workspace"
                ? "Are you sure you want to delete this workspace?"
                : "Are you sure you want to delete this?"}
            </p>
            <div className={s.btnBox}>
              <Button label="Yes" onClick={onClick} />
              <Button label="No" activeBtn onClick={handleCloseDeleteModel} />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
