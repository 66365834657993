import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Row } from "reactstrap";
// import Tables from "./Tables";
import Summarize from "./Summarize";
import Qna from "./Qna";
// import Embeddings from "./Embeddings";

const Insightstabs = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0); // Track the active tab index
  const handleTabClick = (index) => {
    setActiveTabIndex(index);
  };

  return (
    <Tabs selectedIndex={activeTabIndex} onSelect={handleTabClick}>
      <TabList style={{ borderRadius: "10px", paddingInline: "25px" }}>
        <Tab>Summarize</Tab>
        <Tab>Q and A</Tab>
        {/* <Tab>Embeddings</Tab> */}
      </TabList>

      <TabPanel>
        <Row>
          <Summarize />
        </Row>
      </TabPanel>
      <TabPanel>
        <Row>
          <Qna />
        </Row>
      </TabPanel>
       {/* <TabPanel>
        <Row>
          <Embeddings />
        </Row>
      </TabPanel> */}
    </Tabs>
  );
};

export default Insightstabs;
