import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  FormText,
  Input,
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import Footer from "../../components/Footer/Footer.js";

import loginImage from "../../assets/registerImage.svg";
import SofiaLogo from "../../components/Icons/SofiaLogo.js";
import { registerUser } from "../../actions/register.js";
import hasToken from "../../services/authService";
import { registerAction } from "../../redux/actions/AuthAction.js";
import { useHistory } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import Loader from "../../components/Common/LoadingModal/LoadingModal.js";
import "../logo.css";
const Register = (props) => {
  const { isLoading } = useSelector((state) => state.authSlice);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [showConformPassword, setShowConformPassword] = useState(false);

  const [UserCred, setUserCred] = useState({
    email: "",
    user_name: "",
    password: "",
    password2: "",
  });

  const [errors, setErrors] = useState({});

  const changeCred = (event) => {
    const { name, value } = event.target;
    setUserCred((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors({ ...errors, [name]: "" });
  };

  const doRegister = (event) => {
    event.preventDefault();

    if (validateForm()) {
      dispatch(registerAction(UserCred)).then((res) => {
        if (res?.payload?.status === 201 || res?.payload?.status === 200) {
          toast.success("User registered successfully.");
          history.push("/login"); // Redirect to login page
        }
        if (res?.payload?.status === 400) {
          toast.error("email id and username already exist!");
        }
      });
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    // || !isValidEmail(UserCred.email)

    if (!UserCred.email) {
      newErrors.email = "Please enter a valid email address";
      valid = false;
    }

    function isValidEmail(email) {
      // Regular expression pattern for a valid email address
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    }

    // validation to check user name lenght
    if (!UserCred.user_name) {
      newErrors.user_name = "Please enter a user name";
      valid = false;
    } else if (!isNaN(UserCred.user_name)) {
      newErrors.user_name = "Please enter a valid username";
      valid = false;
    } else if (UserCred.user_name?.length > 50) {
      newErrors.user_name = "Username must not exceed 50 characters";
      valid = false;
    }

    if (
      !UserCred?.password || // Check if password is empty or undefined
      UserCred.password?.length < 8 || // Check if password length is less than 6
      !/(?=.*[A-Z])/.test(UserCred.password) || // Check if password contains at least one uppercase letter
      !/(?=.*\d)/.test(UserCred.password) || // Check if password contains at least one digit
      !/(?=.*[!@#$%^&*()_+{}|<>?.])/.test(UserCred.password) // Check if password contains at least one special character
    ) {
      // Set error message and mark as invalid
      newErrors.password =
        "Password must be at least 6 characters, contain at least one uppercase letter, one digit, and one special character";
      valid = false;
    }

    if (UserCred.password !== UserCred.password2) {
      newErrors.confirmPassword =
        "Password and confirm password should be same.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  // const { from } = props.location.state || { from: { pathname: '/' } }

  // if (hasToken(JSON.parse(sessionStorage.getItem('token')))) {
  //   return (
  //     <Redirect to={from} />
  //   );
  // }

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleConformPassword = () => {
    setShowConformPassword(!showConformPassword);
  };

  return (
    <div className="auth-page">
      <Loader isOpen={isLoading} />
      {/* <div className="auth-logo">
                  <SofiaLogo />
                </div> */}
      <Container className="col-12">
        <Row className="d-flex align-items-center">
          <Col xs={12} lg={6} className="left-column">
            <Widget className="widget-auth widget-p-lg">
              <div className="d-flex align-items-center justify-content-between py-3">
                <p className="auth-header mb-0">Sign Up</p>
                <div className="logo-block">
                  <SofiaLogo />
                </div>
                {/* <h3>AURA Data Studio</h3> */}
              </div>
              <form onSubmit={(event) => doRegister(event)} noValidate>
                <FormGroup className="my-3">
                  <FormText>Email</FormText>
                  <Input
                    id="email"
                    className="input-transparent pl-3"
                    value={UserCred.email}
                    onChange={(event) => changeCred(event)}
                    type="email"
                    required
                    name="email"
                    placeholder="Email"
                  />
                  {errors.email && (
                    <div className="text-danger" style={{ fontSize: "13px" }}>
                      {errors.email}
                    </div>
                  )}
                </FormGroup>
                <FormGroup className="my-3">
                  <FormText>Username</FormText>
                  <Input
                    id="user_name"
                    className="input-transparent pl-3"
                    value={UserCred.user_name}
                    onChange={(event) => changeCred(event)}
                    type="user_name"
                    required
                    name="user_name"
                    placeholder="Username"
                  />
                  {errors.user_name && (
                    <div className="text-danger" style={{ fontSize: "13px" }}>
                      {errors.user_name}
                    </div>
                  )}
                </FormGroup>
                <FormGroup className="my-3">
                  <FormText>Password</FormText>
                  <div style={{ position: "relative" }}>
                    <Input
                      id="password"
                      className="input-transparent pl-3"
                      value={UserCred.password}
                      onChange={(event) => changeCred(event)}
                      type={showPassword ? "text" : "password"}
                      required
                      name="password"
                      placeholder="Password"
                    />
                    <span
                      style={{
                        position: "absolute",
                        top: "17%",
                        right: "4%",
                      }}
                      onClick={handleTogglePassword}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </span>
                  </div>
                  {errors.password && (
                    <div className="text-danger" style={{ fontSize: "13px" }}>
                      {errors.password}
                    </div>
                  )}
                </FormGroup>
                <FormGroup className="my-3">
                  <FormText>Confirm Password</FormText>
                  <div style={{ position: "relative" }}>
                    <Input
                      id="password2"
                      className="input-transparent pl-3"
                      value={UserCred.password2}
                      onChange={(event) => changeCred(event)}
                      type={showConformPassword ? "text" : "password"}
                      required
                      name="password2"
                      placeholder="Confirm Password"
                    />
                    <span
                      style={{
                        position: "absolute",
                        top: "17%",
                        right: "4%",
                      }}
                      onClick={handleToggleConformPassword}
                    >
                      {showConformPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </span>
                  </div>
                  {errors.confirmPassword && (
                    <div className="text-danger" style={{ fontSize: "13px" }}>
                      {errors.confirmPassword}
                    </div>
                  )}
                </FormGroup>

                <div className="bg-widget d-flex justify-content-center">
                  <Button
                    className="rounded-pill my-3"
                    type="submit"
                    color="secondary-red"
                  >
                    Sign Up
                  </Button>
                </div>
                <p className="dividing-line my-3">&#8195;Or&#8195;</p>
                <Link to="/login">Already have an Account?</Link>
              </form>
            </Widget>
          </Col>
          <Col xs={0} lg={6} className="right-column">
            <div>
              <img src={loginImage} alt="Error page" />
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

Register.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
  };
}

export default withRouter(connect(mapStateToProps)(Register));
