// toaster msgs
import docImage from "./assets/wordfile.png";
// ../assets/wordfile.png
import pdfImage from "./assets/pdfimg.png";
import txtImage from "./assets/txtIcon.png";
import imagefile from "./assets/imagefile.png";
import videoFile from "./assets/videoIcon.png";
import folderImage from "./assets/folderIcon.png";
import deleteIcon from "./assets/deleteIcon.png";
import audioIcon from "./assets/audio.png";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";

export const configurationStatus = {
  Create: "Configuration set successfully.",
  Reset: "Configuration reset successfully.",
};

export const folderExtension = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "audio/mpeg",
  "audio/wav",
  "audio/mp3",
  "audio/ogg",
  "video/mp4",
  "video/mov",
  "video/webm",
  "video/avi",
  "video/ogv",
];

export const fileExtnsion = [
  "application/pdf",
  "text/plain",
  "application/msword",
  "audio/mpeg",
  "audio/wav",
  "audio/mp3",
  "audio/ogg",
  "video/mp4",
  "video/mov",
  "video/webm",
  "video/avi",
  "video/ogv",
];
export const tabelExtractionExtension = [
  "mp3",
  "mpeg",
  "wav",
  "ogg",
  "mp4",
  "mov",
  "webm",
  "avi",
  "ogv",
];

export const videofileExtension = ["mp4", "mov", "webm", "avi", "ogv"];

const convertFileSizeToBytes = (sizeString) => {
  const units = {
    KB: 1024,
    MB: 1024 * 1024,
    GB: 1024 * 1024 * 1024,
    TB: 1024 * 1024 * 1024 * 1024,
    B: 1, // Add handling for Bytes (B)
  };

  if (sizeString) {
    const size = parseFloat(sizeString);
    const unit = sizeString.replace(size, "").trim().toUpperCase();
    if (units[unit]) {
      return size * units[unit];
    }
    return size;
  }

  return 0;
};

export const formatBytes = (size, decimals = 2) => {
  const bytes = typeof size === "string" ? convertFileSizeToBytes(size) : size;

  if (bytes === 0) return "0 Bytes";

  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const dm = decimals < 0 ? 0 : decimals;
  return parseFloat(bytes / Math.pow(1024, i)).toFixed(dm) + " " + sizes[i];
};

export const getFileIcon = (fileName) => {
  const extension = fileName.split(".").pop();
  switch (extension) {
    case "pdf":
      return <img height={27} width={26} src={pdfImage} alt="pdf" />;
    case "txt":
      return <img height={27} width={26} src={txtImage} alt="txt" />;
    case "mp3":
    case "wav":
    case "ogg":
    case "acc":
    case "flac":
      return <img height={27} width={26} src={audioIcon} alt="mp3" />;
    case "doc":
    case "docx":
      return <img height={28} src={docImage} alt="doc" />;
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
    case "webp":
      return <img height={28} src={imagefile} alt="img" />;
    case "mp4":
    case "mov":
    case "webm":
    case "avi":
    case "ogv":
      return <img height={28} src={videoFile} alt="video" />;
    case "docx":
      return <TextSnippetIcon />;
    default:
      return <img height={28} src={folderImage} alt="doc" />;
  }
};
