import axios from "axios";
const API_BASE_URL = "http://nn-core-dataconnectors-be2.azurewebsites.net";

// api to get connection types in the sidebar
export const fetchConnectors = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/connectors`);
    const data = await response.json();
    return data.properties;
  } catch (error) {
    console.error("Error fetching connectors:", error);
    // throw error;
  }
};

// api to validate the form service to connect wit azure blob
export const saveObjectConnector = async (workspaceId, formValues) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/objects`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        workspaceId: workspaceId,
        connector_settings: formValues,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error saving data:", error);
    throw error;
  }
};

// api to ftech all the connectors to show the table rendered
export const fetchConnectorObjects = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/Connectorsobjects`);
    const data = await response.json();
    return data.objects.map((obj) => ({
      name: obj.connector_settings.connectorName,
      type: "Azure Blob Storage",
    }));
  } catch (error) {
    console.error("Error fetching connector objects:", error);
    throw error;
  }
};

// api to fetch the storage name at the dataingestion modal
export const fetchIngestionContainer = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/Connectorsobjects`);
    return response.data.objects;
  } catch (error) {
    console.error("Error fetching ingestion container:", error);
    // throw error;
  }
};

// api to ftech all the list of blob at dataingestion modal
export const fetchBlobListForConnector = async (connectorId) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/api/Connectorsobjects/${connectorId}/blobs`
    );
    return response.data.blobs;
  } catch (error) {
    console.error("Error fetching blob list:", error);
    // throw error;
  }
};

// api to upload the blob list
export const uploadBlob = async (token, blobUrl, objectId, workspaceId) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/api/uploadBlob`,
      {
        token,
        bloburl: blobUrl,
        objectid: objectId,
        workspace_id: workspaceId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error uploading blob:", error);
    // throw error;
  }
};
