import React, { createRef, useEffect, useRef, useState } from "react";
import { ReactTabulator } from "react-tabulator";
import "react-tabulator/lib/styles.css";
import "react-tabulator/lib/css/bootstrap/tabulator_bootstrap.min.css";
import s from "./EditableTable.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getTablesJsonView,
  convertExceltoJson,
} from "../../../redux/actions/DataReviewAction";
import ListIcon from "@mui/icons-material/List";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CloseIcon from "@mui/icons-material/Close";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ReactJson from "react-json-view";
import { Modal, Box } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { object } from "prop-types";
import { HotTable } from "@handsontable/react";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";
import { tabelExtractionExtension } from "../../../ENUM";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "90vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
  borderRadius: "10px",
};

const EditableTable = ({ excelData, currentSheet, setSheetsData }) => {
  // Track the current sheet

  const [mergeCells, setMergeCells] = useState([]);
  const hotTableRefs = useRef({});
  const [spreadsheetData, setspreadsheetData] = useState([]); // State to store current sheet data
  const [tableName, setTableName] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [folderjsonData, setfolderjsonData] = useState({});

  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [jsonview, setJsonview] = useState([]);

  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.DataReviewSlice);

  const { fileId, folderId, fileData } = useSelector(
    (state) => state.PipelineExecutionSlice
  );

  useEffect(() => {
    let jsonData = data.json_data;

    setfolderjsonData((prev) => ({
      ...prev,
      ...jsonData,
    }));
    return () => {};
  }, [data]);

  useEffect(() => {
    // Check if fileData is empty or undefined, then convert the single file
    if (!fileData || fileData?.length === 0) {
      dispatch(convertExceltoJson(fileId))
        .then((res) => {
          if (res.payload && res.payload.status === 200) {
            dispatch(getTablesJsonView(fileId));
          } else {
            console.error("Excel to JSON conversion failed:", res.payload);
          }
        })
        .catch((error) => {
          console.error("Error in Excel to JSON conversion:", error);
        });
    } else {
      // Process multiple files if fileData is not empty
      const processjsondata = async () => {
        for (const file of fileData) {
          const fileExtension = file.original_file_name?.split(".")?.pop();
          if (!tabelExtractionExtension.includes(fileExtension)) {
            try {
              const res = await dispatch(convertExceltoJson(file.id));
              if (res.payload && res.payload.status === 200) {
                await dispatch(getTablesJsonView(file.id));
              } else {
                console.error("Excel to JSON conversion failed:", res.payload);
              }
            } catch (error) {
              console.error("Error processing file:", file.id, error);
            }
          }
        }
      };
      processjsondata();
    }
  }, [dispatch, fileId, fileData, tabelExtractionExtension]);

  useEffect(() => {
    // Update table names when excelData changes
    setTableName(Object.keys(excelData));
  }, [excelData]);

  const handleOpen = (index, sheetName) => {
    let newfilteredJsonData = folderjsonData?.hasOwnProperty(sheetName)
      ? folderjsonData[sheetName]
      : "";
    setJsonview(newfilteredJsonData);

    setActiveIndex(index);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const copyJsonToClipboard = () => {
    try {
      const jsonText = JSON.stringify(folderjsonData);
      navigator.clipboard
        .writeText(jsonText)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        })
        .catch((err) => console.error("Failed to copy JSON to clipboard", err));
    } catch (err) {
      console.error("Failed to stringify JSON", err);
    }
  };
  useEffect(() => {
    if (currentSheet && excelData[currentSheet]) {
      const { data } = excelData[currentSheet];

      setspreadsheetData([...data]);
    }
  }, [currentSheet, excelData]);

  registerAllModules();

  const renderSheets = () => {
    return Object.keys(excelData).map((sheetName, index) => {
      const sheetData = excelData[sheetName];

      if (sheetData) {
        const data = [sheetData.headers, ...sheetData.data];
        const mergeCells = sheetData.merges;

        hotTableRefs.current[sheetName] =
          hotTableRefs.current[sheetName] || createRef();

        return (
          <div id={`table${index}`} key={sheetName} className={s.tableWrapper}>
            <div>
              <div className={s.tableTitleBox}>
                <h5>{sheetName}</h5>
                <button
                  className={s.viewJsonButton}
                  onClick={() => handleOpen(index, sheetName)}
                >
                  <VisibilityOutlinedIcon />
                  &nbsp; View Json
                </button>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="json-modal-title"
                  aria-describedby="json-modal-description"
                >
                  <Box sx={style}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h5 id="json-modal-title">JSON Data</h5>
                      <div style={{ borderRadius: ".5px" }}>
                        <button
                          onClick={handleClose}
                          style={{ marginTop: "-10px" }}
                        >
                          <HighlightOffIcon style={{ fontSize: "small" }} />
                        </button>
                      </div>
                    </div>
                    <div className={s.clsbtnclass}>
                      <button
                        onClick={copyJsonToClipboard}
                        className={s.closebutton}
                        style={{ marginTop: "20px" }}
                      >
                        <ContentCopyIcon />
                        {copied ? "Copied!" : "Copy JSON"}
                      </button>
                    </div>
                    <ReactJson
                      src={{ data: jsonview || "" }}
                      displayDataTypes={false}
                      enableClipboard={false}
                      collapsed={false}
                      name={false}
                    />
                  </Box>
                </Modal>
              </div>
              <HotTable
                ref={hotTableRefs.current[sheetName]}
                data={data}
                width={"auto"}
                height={"auto"}
                colHeaders={true}
                rowHeaders={true}
                contextMenu={true}
                mergeCells={mergeCells}
                autoWrapRow={true}
                autoWrapCol={true}
                afterChange={(changes) => {
                  if (changes) {
                    const newData = [...data];
                    changes.forEach(([row, col, oldValue, newValue]) => {
                      if (row > 0) {
                        newData[row][col] = newValue;
                      }
                    });
                    // setSheetsData({
                    //   ...excelData,
                    //   [sheetName]: { ...sheetData, data: newData.slice(1) },
                    // });
                  }
                }}
                className="htCenter"
                licenseKey="non-commercial-and-evaluation"
              />
            </div>
          </div>
        );
      }
      return null;
    });
  };

  return (
    <>
      <div>
        <div
          className={`${s.editableTableMainContainer} ${
            drawerOpen ? s.drawerOpen : ""
          }`}
        >
          <div className={`${s.tableListbox}`}>
            {drawerOpen ? (
              <ListIcon onClick={toggleDrawer} />
            ) : (
              <CloseIcon onClick={toggleDrawer} className={s.closeIcon} />
            )}
            <ul>
              {tableName?.map((item, index) => (
                <li
                  className={`${s.tableList} ${
                    activeIndex === index ? s.activeList : ""
                  }`}
                  onClick={() => handleClick(index)}
                  key={index}
                >
                  <div className={s.tableItemBox}>
                    <div className={s.tableItemNumber}>
                      <FiberManualRecordIcon />
                    </div>
                    <a className={s.tableItem} href={`#table${index}`}>
                      {item}
                    </a>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className={s.tableContainer}>
            {Object.keys(excelData)?.length > 0 && <>{renderSheets()}</>}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditableTable;
