import React, { useState, useRef, useEffect } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  InputAdornment,
} from "@mui/material";

import "../FineTuneJson.scss";
import json_icon from "../../../assets/json_icon.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import Button from "../../../components/Button/Button";

const AzureUploadJson = () => {
  const [errors, setErrors] = useState({}); // state to manage errors
  const [uploadedFiles, setUploadedFiles] = useState([]); // state for handling file uploads
  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState({
    // state to manage form data
    openApiKey: "",
    model: "",
    azureendpoint: "",
    apiversion: "",
    epoch: "",
    batchSize: "",
    learningRateMultiplier: "",
  });
  const [showApiKey, setShowApiKey] = useState(false); // state to manage visiblity icon
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // Clear the error when the user starts typing
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  // function to upload file
  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files).filter(
      (file) => file.type === "application/json"
    );
    setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  // function to validate form.
  const validateForm = () => {
    const newErrors = {};
    if (!formData.openApiKey.trim()) {
      newErrors.openApiKey = "OpenAI API Key is required";
    }
    if (!formData.model) {
      newErrors.model = "Model selection is required";
    }
    if (!formData.azureendpoint) {
      newErrors.azureendpoint = "azure end point is required";
    }
    if (!formData.apiversion) {
      newErrors.apiversion = "Api version is Required";
    }
    if (!formData.epoch) {
      newErrors.epoch = "Epoch is Required";
    }
    if (!formData.batchSize) {
      newErrors.batchSize = "Batch Size is required";
    } else if (isNaN(formData.batchSize) || formData.batchSize <= 0) {
      newErrors.batchSize = "Batch Size must be a positive number";
    }
    if (!formData.learningRateMultiplier) {
      newErrors.learningRateMultiplier = "Learning Rate Multiplier is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // function to submit form
  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      // Form is valid, proceed with submission
      console.log("Form submitted:", formData);
    } else {
      console.log("Form has errors");
    }
  };

  const toggleApiKeyVisibility = () => {
    setShowApiKey(!showApiKey);
  };

  return (
    <div className="Json-Container">
      <div className="Json-file-container">
        <div className="json-upload-button-container">
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileUpload}
            multiple
            accept=".json"
          />
          <button
            className="json-upload-button"
            activeBtn={true}
            onClick={triggerFileInput}
          >
            <DriveFolderUploadIcon /> &nbsp; &nbsp; Upload JSON
          </button>
        </div>
        <List>
          {uploadedFiles?.map(
            (
              file,
              index // list of uploaded files
            ) => (
              <React.Fragment key={index}>
                <ListItem
                  style={{
                    gap: "40px",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <img height={32} src={json_icon} alt="json" />
                  <ListItemText primary={file?.name} />
                </ListItem>
                {index < uploadedFiles?.length - 1 && (
                  <Divider style={{ height: "2px", backgroundColor: "#000" }} /> // divider line to seprate the files
                )}
              </React.Fragment>
            )
          )}
        </List>
      </div>
      <div className="json-seprater-line"></div>
      <div className="raft-json-form-container">
        <h5>Select Fine Tuning Configuration</h5>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            label="Azure API key"
            name="openApiKey"
            value={formData.openApiKey}
            size="small"
            onChange={handleChange}
            type={showApiKey ? "text" : "password"}
            error={!!errors.openApiKey}
            helperText={errors.openApiKey}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle api key visibility"
                    onClick={toggleApiKeyVisibility}
                    edge="end"
                  >
                    {showApiKey ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <FormControl fullWidth margin="normal" error={!!errors.model}>
            <InputLabel>Model Selection</InputLabel>
            <Select
              name="model"
              value={formData.model}
              onChange={handleChange}
              label="Model Selection"
              size="small"
            >
              <MenuItem value="gpt-3.5-turbo">GPT-3.5 Turbo</MenuItem>
              {/* <MenuItem value="gpt-4">GPT-4-o-mini</MenuItem> */}
              <MenuItem value="babbage-002">Babbage-002</MenuItem>
              <MenuItem value="davinic-002">davinic-002</MenuItem>
            </Select>
            {/* {errors.model} */}
          </FormControl>

          <TextField
            fullWidth
            margin="normal"
            label="Azure End Point"
            name="azureendpoint"
            // type="number"
            size="small"
            value={formData.azureendpoint}
            onChange={handleChange}
            error={!!errors.azureendpoint}
            helperText={errors.azureendpoint}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Api Version"
            name="apiversion"
            // type="number"
            size="small"
            value={formData.apiversion}
            onChange={handleChange}
            error={!!errors.apiversion}
            helperText={errors.apiversion}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Epoch"
            name="epoch"
            type="number"
            size="small"
            value={formData.epoch}
            onChange={handleChange}
            error={!!errors.epoch}
            helperText={errors.epoch}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Batch Size"
            name="batchSize"
            type="number"
            size="small"
            value={formData.batchSize}
            onChange={handleChange}
            error={!!errors.batchSize}
            helperText={errors.batchSize}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Learning Rate Multiplier"
            name="learningRateMultiplier"
            type="number"
            size="small"
            value={formData.learningRateMultiplier}
            onChange={handleChange}
            error={!!errors.learningRateMultiplier}
            helperText={errors.learningRateMultiplier}
          />
          <div className="json-save-button">
            <Button label={"Save"} activeBtn={true} type="submit" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AzureUploadJson;
