import { createSlice } from "@reduxjs/toolkit";
import {
  createKnowledgeGraphAction,
  getKnowledgeGraphDataAction,
  updateKnowledgeGraphData,
} from "../actions/knowledgegraphAction";

const knowledgeGraphSlice = createSlice({
  name: "workspace",
  initialState: {
    knowledgeGraphData: "",
    status: "idle",
    error: null,
    isLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createKnowledgeGraphAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(createKnowledgeGraphAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.knowledgeGraphData = payload?.data;
        state.isLoading = false;
      })
      .addCase(createKnowledgeGraphAction.rejected, (state, action) => {
        state.status = "failed";
        // state.error = action;
        state.isLoading = false;
      });
    builder
      .addCase(getKnowledgeGraphDataAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getKnowledgeGraphDataAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.knowledgeGraphData = payload?.data;
        state.isLoading = false;
      })
      .addCase(getKnowledgeGraphDataAction.rejected, (state, action) => {
        state.status = "failed";
        // state.error = action;
        state.isLoading = false;
      });
    builder
      .addCase(updateKnowledgeGraphData.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(updateKnowledgeGraphData.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.knowledgeGraphData = payload?.data;
        state.isLoading = false;
      })
      .addCase(updateKnowledgeGraphData.rejected, (state, action) => {
        state.status = "failed";
        // state.error = action;
        state.isLoading = false;
      });
  },
});

export default knowledgeGraphSlice.reducer;
