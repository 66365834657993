import React, { useState } from "react";
import Input from "./Input";
import Select from "./Select";
import Toggler from "./Toggler";
import s from "./DynamicForm.module.scss";

import { TextField } from "@mui/material";
// import { toast, ToastContainer } from "react-toastify";
import { toast, ToastContainer } from "react-toastify";

import { saveObjectConnector } from "./DynamicFormServices";

const findDefaultOption = (options, defaultValue) => {
  return (
    options.find((option) => option.title === defaultValue) ||
    options.find((option) => option.title.includes(defaultValue))
  );
};

const DynamicForm = ({ formStructure, workspaceId }) => {
  const initializeFormState = (data) => {
    const properties = data?.connectionSpecification?.properties || {};
    const requiredFields = data.connectionSpecification.required || [];
    const directOneOff = data.connectionSpecification.oneOf || [];

    Object.keys(properties).forEach((key) => {
      if (properties[key].oneOf) {
        const defaultOption = findDefaultOption(
          properties[key].oneOf,
          properties[key].default
        );
        if (defaultOption) {
          properties[key].selectedOption = defaultOption;
        }
      }
    });
    return {
      directOneOff,
      properties,
      title: data.connectionSpecification.title,
      required: requiredFields,
    };
  };

  const [formState, setFormState] = useState(
    initializeFormState(formStructure)
  );
  const [formValues, setFormValues] = useState({});

  const handleSelectChange = (event, key, oneOfOptions) => {
    const selectedOption = oneOfOptions.find(
      (option) => option.title === event.target.value
    );

    if (selectedOption) {
      setFormState((prevState) => ({
        ...prevState,
        properties: {
          ...prevState.properties,
          [key]: {
            ...prevState.properties[key],
            selectedOption: selectedOption,
          },
        },
      }));
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: event.target.value,
    }));
  };

  const handleInputChange = (event, key) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: event.target.value,
    }));
  };

  const handleToggleChange = (value, key) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const renderProperty = (property, key, requiredFields) => {
    const isRequired = requiredFields.includes(key);

    return (
      <div key={key} className={s["dynamic-form-group"]}>
        <label>
          {property.title ? property.title : key}
          {isRequired && <span style={{ color: "red" }}>*</span>}
        </label>
        {property?.properties ? (
          renderProperties(
            property.properties || {},
            property.required || property.requires || []
          )
        ) : property.items ? (
          <div
            style={{
              border: "1px solid black",
              padding: "20px",
              borderRadius: "15px",
            }}
          >
            {renderProperties(
              property.items.properties || {},
              property.items.required || property.items.requires || []
            )}
          </div>
        ) : property.enum ? (
          <Select
            name={key}
            defaultValue={property.default || ""}
            options={property.enum.map((option) => ({
              value: option,
              label: option,
            }))}
            onChange={(e) => handleInputChange(e, key)}
          />
        ) : property.type === "boolean" ? (
          <Toggler
            name={key}
            checked={property.default}
            required={isRequired}
            onChange={(value) => handleToggleChange(value, key)}
          />
        ) : (
          <Input
            type={property.type === "integer" ? "number" : property.type}
            name={key}
            placeholder=""
            required={isRequired}
            defaultValue={property.default ? property.default : ""}
            onChange={(e) => handleInputChange(e, key)}
          />
        )}
        <div className={s["dynamic-form-group-description"]}>
          {property.description}
        </div>
      </div>
    );
  };

  const renderProperties = (properties, requiredFields) => {
    return Object.keys(properties).map((key) => {
      const property = properties[key];
      const selectedOption = property.selectedOption;

      if (property.oneOf) {
        const defaultOption = findDefaultOption(
          property.oneOf,
          property.default
        );
        const defaultValue = defaultOption ? defaultOption.title : "";

        return (
          <div
            key={key}
            className={s["dynamic-form-group"]}
            style={{
              border: "1px solid black",
              padding: "20px",
              borderRadius: "15px",
            }}
          >
            <label>
              {property.title ? property.title : key}
              {requiredFields.includes(key) && (
                <span style={{ color: "red" }}>*</span>
              )}
            </label>
            <Select
              name={key}
              defaultValue={defaultValue}
              options={property.oneOf.map((option) => ({
                value: option.title,
                label: option.title,
              }))}
              onChange={(e) => handleSelectChange(e, key, property.oneOf)}
            />
            <div className={s["dynamic-form-group-description"]}>
              {property.description}
            </div>

            {renderProperties(
              selectedOption
                ? selectedOption.properties
                : defaultOption
                ? defaultOption.properties
                : {},
              selectedOption
                ? selectedOption.required
                  ? selectedOption.required
                  : selectedOption.requires
                  ? selectedOption.requires
                  : defaultOption
                  ? defaultOption.required
                    ? defaultOption.required
                    : defaultOption.requires
                    ? defaultOption.requires
                    : []
                  : []
                : []
            )}
          </div>
        );
      }

      return renderProperty(
        property,
        key,
        requiredFields ? requiredFields : []
      );
    });
  };

  const handleSave = async () => {
    try {
      const result = await saveObjectConnector(workspaceId, formValues);
      if (
        result.validation === true ||
        result.status === 200 ||
        result.status === 201
      ) {
        toast.success("Validation completed successfully! Connections saved.");
      } else if (result.validation === false) {
        toast.error("Validation failed");
      }
    } catch (error) {
      console.error("Error saving data:", error);
      toast.error("An error occurred while saving");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className={s["dynamic-form-container"]}>
        <div className={s["dynamic-form-wrapper"]}>
          <div className={s["dynamic-form-title"]}>{formState.title}</div>
          <div>
            <Input
              type="text"
              label="Connector Name"
              name="connectorName"
              onChange={(e) => handleInputChange(e, "connectorName")}
              value={formValues.connectorName || ""}
            />
          </div>
          {renderProperties(formState.properties, formState.required)}
          {formState.directOneOff.length > 0 &&
            renderProperties(
              { "": { oneOf: formState.directOneOff } },
              formState.required
            )}
        </div>
      </div>
      <div className={s["dynamic-form-button-container"]}>
        <button className={s["dynamic-form-button"]} onClick={handleSave}>
          Save
        </button>
      </div>
    </>
  );
};

export default DynamicForm;
