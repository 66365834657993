import { combineReducers } from "redux";
import navigation from "./navigation.js";
import register from "./register.js";
import auth from "./auth.js";
import authSlice from "../redux/slices/authSlice.js";
import ConfigurationSlice from "../redux/slices/subWorkspaceSlice.js";
import dataIngestionSlice from "../redux/slices/dataIngestionSlices.js";
import WorkspaceSlice from "../redux/slices/MainWorkspaceSlice.js";
import downloadSlice from "../redux/slices/downloadSlice.js";
import InsightsSlice from "../redux/slices/InsightsSlice.js";
import PipelineExecutionSlice from "../redux/slices/pipelinExecutionSlice.js";
import DataReviewSlice from "../redux/slices/DataReviewSlice.js";
import DataOutputSlice from "../redux/slices/DataOutputSlice.js";
import knowledgeGraphSlice from "../redux/slices/knowledgeGraphSlice.js";

export default combineReducers({
  register,
  auth,
  navigation,
  authSlice,
  ConfigurationSlice,
  dataIngestionSlice,
  WorkspaceSlice,
  downloadSlice,
  InsightsSlice,
  PipelineExecutionSlice,
  DataReviewSlice,
  DataOutputSlice,
  knowledgeGraphSlice,
});
