import React, { useEffect, useState } from 'react';
import './CardComponent.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

// const CardComponent = ({ type, investmentAmt, marketValue, overallGL, todaysGL }) => {
//   const [isHovered, setIsHovered] = useState(false);

//   const handleMouseEnter = () => {
//     setIsHovered(true);
//   };

//   const handleMouseLeave = () => {
//     setIsHovered(false);
//   };

//   return (
//     <div 
//       className={`investment-card ${isHovered ? 'hovered' : ''}`} 
//       onMouseEnter={handleMouseEnter} 
//       onMouseLeave={handleMouseLeave}
//     >
//       <div className="column type1">
//         <span className="type-text">{type}</span>
//       </div>
//       <div className="column investment-amt">{investmentAmt}</div>
//       <div className="column market-value">{marketValue}</div>
//       <div className="column overall-gl">{overallGL}</div>
//       <div className="column todays-gl">{todaysGL}</div>
//       {isHovered && (
//         <div className="hover-info">
//           <div className='card-info'>
//           <p>Tokens:5</p>
//           <p>Chunks:1</p>
//           <p>Embeddings:10</p> 
//           </div>
//           <div  className='card-info'>
//             <p>Tokens:5</p>
//           <p>Chunks:1</p>
//           <p>Embeddings:10</p> 
//           </div>
//           <div  className='card-info'>
//           <p>Tokens:5</p>
//           <p>Chunks:1</p>
//           <p>Embeddings:10</p> 
//           </div>
//           <div  className='card-info'>
//           <p>Tokens:5</p>
//           <p>Chunks:1</p>
//           <p>Embeddings:10</p> 
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };



const CardComponent = ({ type, investmentAmt, marketValue, overallGL, todaysGL, initiallyOpen}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(initiallyOpen);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    if (initiallyOpen) {
      setIsDropdownOpen(true);
    }
  }, [initiallyOpen]);

  return (
    <div className={`investment-card ${isDropdownOpen ? 'hovered' : ''}`}>
      <div className="column type1">
        <span className="type-text">{type}</span>
      </div>
      <div className="column investment-amt"  initiallyOpen={true}>{investmentAmt}</div>
      <div className="column market-value" initiallyOpen={false}>{marketValue}</div>
      <div className="column overall-gl"  initiallyOpen={false}>{overallGL}</div>
      <div className="column todays-gl"  initiallyOpen={false}>{todaysGL}</div>
      <div className="arrow" onClick={toggleDropdown}>
          {isDropdownOpen ?  <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
        </div>
      {isDropdownOpen && (
        <div className="dropdown-info">
          <div className='dropdown'>
           <div className='drop-child'>
             <div className='card-info' style={{marginLeft:"5px"}}>
            <p>Tokens: 5</p>
            <p>Chunks: 1</p>
            <p>Embeddings: 10</p>
          </div>
          <div className='card-info' >
            <p>Tokens: 5</p>
            <p>Chunks: 1</p>
            <p>Embeddings: 10</p>
          </div>
          <div className='card-info' >
            <p>Tokens: 5</p>
            <p>Chunks: 1</p>
            <p>Embeddings: 10</p>
          </div>
          <div className='card-info'>
            <p>Tokens: 5</p>
            <p>Chunks: 1</p>
            <p>Embeddings: 10</p>
          </div>
           </div>
          </div>
        </div>
      )}
    </div>
  );
};


const InvestmentHeader = () => (
  <div className="investment-header">
    <div className="column type2">Data Source</div>
    <div className="column investment-amt">Pdf's</div>
    <div className="column market-value">Doc/Docx</div>
    <div className="column overall-gl">Text</div>
    <div className="column todays-gl">Images</div>
    <div className="column action"></div>
  </div>
);

export { CardComponent, InvestmentHeader };

// import React, { useState } from 'react';
// import './CardComponent.css';
// import { Table, Pagination, PaginationItem, PaginationLink } from "reactstrap";
// import s from "../../myWorkspace/Tables.module.scss"

// const CardComponent = () => {
//      const [chunks, setChunks] = useState([
//     { text: 'Sample text content for chunk 1', tokens: 120, embedding: '' },
//     { text: 'Sample text content for chunk 2', tokens: 150, embedding: '' },
//     { text: 'Sample text content for chunk 3', tokens: 130, embedding: '' },
//     { text: 'Sample text content for chunk 4', tokens: 160, embedding: '' },
//     { text: 'Sample text content for chunk 5', tokens: 110, embedding: '' },
//     { text: 'Sample text content for chunk 6', tokens: 100, embedding: '' },
//     { text: 'Sample text content for chunk 7', tokens: 105, embedding: '' },
//     { text: 'Sample text content for chunk 8', tokens: 115, embedding: '' },
//   ]);

//   const [currentPage, setCurrentPage] = useState(0);
//   const itemsPerPage = 4;
//      const handlePageClick = (pageNumber) => {
//     setCurrentPage(pageNumber - 1);
//   };

//      const offset = currentPage * itemsPerPage;
//   const currentChunks = chunks.slice(offset, offset + itemsPerPage);
//   const pageCount = Math.ceil(chunks.length / itemsPerPage);
//   return (
//     <div >
//       <Table
           
//             responsive
//             hover
//           >
//             <thead>
//               <tr>
//                 <th style={{ width: "15%" }} scope="row">Type</th>
//                 <th style={{ width: "30%" }} scope="row">Pdf's</th>
//                 <th style={{ width: "25%" }} scope="row">No. of Tokens</th>
//                 <th style={{ width: "25%" }} scope="row">Embedding</th>
//               </tr>
//             </thead>
//             <tbody>
//               {currentChunks.map((chunk, index) => (
//                 <tr key={index}>
//                   <td>Chunk {offset + index + 1}</td>
//                   <td > 1 <a style={{color:"#327aff"}} ></a></td>
//                   <td>{chunk.tokens}</td>
//                   <td ><a style={{color: "#000"}} >  &nbsp;  5</a> &nbsp;  &nbsp; <a ></a></td>
//                 </tr>
//               ))}
//             </tbody>
//           </Table>

//           <Pagination
//             className="pagination-borderless"
//                     aria-label="Page navigation example"
//           >
//             <PaginationItem disabled={currentPage <= 0}>
//               <PaginationLink
//                 onClick={() => handlePageClick(currentPage)}
//                 previous
//                 href="#"
//               />
//             </PaginationItem>

//             {[...Array(pageCount)].map((page, index) => (
//               <PaginationItem active={index === currentPage} key={index}>
//                 <PaginationLink onClick={() => handlePageClick(index + 1)} href="#">
//                   {index + 1}
//                 </PaginationLink>
//               </PaginationItem>
//             ))}

//             <PaginationItem disabled={currentPage >= pageCount - 1}>
//               <PaginationLink
//                 onClick={() => handlePageClick(currentPage + 2)}
//                 next
//                 href="#"
//               />
//             </PaginationItem>
//           </Pagination>
//         </div>
//   );
// };



// export { CardComponent };
