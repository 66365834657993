import React, { useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button/Button";
import {
  getEmbeddingsDataOfChromaDBAction,
  insertEmbeddingsToChormaDBAction,
  insertEmbeddingsToFaissDBAction,
  storeEmbeddingsToChormaDBAction,
} from "../../redux/actions/DataOutputActions";
import { toast } from "react-toastify";
function ChromaDB({ embeddingStoragName }) {
  const {
    emebeddingData,
    chunksData,
    isLoading,
    pineconedata,
    chromaDB_data,
    vectorData,
    chartImageUrl,
  } = useSelector((state) => state.DataOutputSlice);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [embeddingChromaDBStorageValue, setembeddingChromaDBStorageValue] =
    useState({
      collection_name: "",
      metric: "",
      embedding_function: "",
      embeddings: emebeddingData?.id,
    });

  const handleChangeChromaDBValue = (e) => {
    const { value, name } = e.target;
    setembeddingChromaDBStorageValue((prev) => ({ ...prev, [name]: value }));

    // Validate field
    const pattern =
      name === "collection_name" && e.target.getAttribute("pattern");
    const regex = new RegExp(pattern);
    if (!value || (pattern && !regex.test(value))) {
      setErrors((prev) => ({ ...prev, [name]: true }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: false }));
    }
    // validateFields();
  };

  useEffect(() => {
    if (emebeddingData?.id) {
      const payload = {
        embeddings: emebeddingData?.id,
      };
      dispatch(getEmbeddingsDataOfChromaDBAction(payload));
    }
  }, [emebeddingData, embeddingStoragName]);

  const validateFields = useCallback(() => {
    const newErrors = {};
    const fields = [
      { name: "collection_name", pattern: "^[a-z0-9-]+$" },
      { name: "metric" },
      //   { name: "embedding_function" },
    ];

    fields.forEach(({ name, pattern }) => {
      const value = embeddingChromaDBStorageValue[name];
      if (!value || (pattern && !new RegExp(pattern).test(value))) {
        newErrors[name] = true;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [embeddingChromaDBStorageValue]);

  const handleAllEmbeddingsToStoreChromaDB = async () => {
    try {
      if (validateFields()) {
        const insertResponse = await dispatch(
          insertEmbeddingsToChormaDBAction(embeddingChromaDBStorageValue)
        );

        if (
          insertResponse.payload.status === 200 ||
          insertResponse.payload.status === 201
        ) {
          const pineconeInstanceID = insertResponse.payload?.data?.id;

          const payload = {
            pincone_instance_id: pineconeInstanceID,
          };

          await dispatch(storeEmbeddingsToChormaDBAction(payload));
        } else if (insertResponse) {
          Object.keys(insertResponse.payload?.response?.data)?.forEach(
            (backError) => {
              insertResponse.payload?.response?.data[backError].forEach(
                (errorMessage) => {
                  toast.error(errorMessage);
                }
              );
            }
          );
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <>
      <TextField
        label="Collection Name"
        type="text"
        name="collection_name"
        id="collection_name"
        fullWidth
        value={embeddingChromaDBStorageValue?.collection_name || ""}
        onChange={handleChangeChromaDBValue}
        size="small"
        inputProps={{ pattern: "^[a-z0-9-]+$" }}
        error={!!errors.collection_name}
        helperText={
          errors.collection_name ? "Invalid collection name format." : ""
        }
      />
      <FormControl sx={{ minWidth: 220 }} size="small">
        <InputLabel id="metric-label">Metric</InputLabel>
        <Select
          labelId="metric-label"
          name="metric"
          value={embeddingChromaDBStorageValue.metric || ""}
          label="Metric"
          onChange={handleChangeChromaDBValue}
          error={!!errors.metric}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="l2">Squared L2</MenuItem>
          <MenuItem value="ip">Inner Product</MenuItem>
          <MenuItem value="cosine">Cosine</MenuItem>
        </Select>
      </FormControl>
      {/* <TextField
        label="Collection  function"
        type="text"
        name="embedding_function"
        id="embedding_function"
        fullWidth
        value={embeddingChromaDBStorageValue?.embedding_function || ""}
        onChange={handleChangeChromaDBValue}
        size="small"
        inputProps={{ pattern: "^[a-z0-9-]+$" }}
        error={!!errors.embedding_function}
        helperText={
          errors.embedding_function ? "Invalid collection name format." : ""
        }
      /> */}
      <div
        style={{
          width: "100%",
          marginBottom: "25px",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          IsExtracted={
            emebeddingData && Object.keys(emebeddingData)?.length > 0
              ? false
              : true
          }
          // IsExtracted={validateFormField}
          activeBtn={
            emebeddingData && Object.keys(emebeddingData)?.length ? true : false
          }
          label={"Insert All Chunks"}
          onClick={handleAllEmbeddingsToStoreChromaDB}
        />
      </div>
    </>
  );
}

export default ChromaDB;
