import React from "react";

const Input = ({ type, placeholder, label, disabled, ...rest }) => {
  return (
    <div className={`form-group ${disabled ? "disabled" : ""}`}>
      {label && <label htmlFor={rest.id || rest.name}>{label}</label>}
      <input
        type={type}
        className="form-control"
        id={rest.id || rest.name}
        disabled={disabled}
        placeholder={placeholder}
        {...rest} // Spread all props here
      />
    </div>
  );
};

export default Input;
