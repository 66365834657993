import { createAsyncThunk } from "@reduxjs/toolkit";
import { loginSuccess, logoutSuccess } from "../slices/authSlice";
import axios from "axios";

import {
  login_service,
  register_service,
  user_logOut,
  user_logout,
} from "../../services/postServices";
import { get_logOut } from "../../services/getServices";
import { toast } from "react-toastify";

export const loginAction = createAsyncThunk("loginAction", async (value) => {
  //console.log(value);
  try {
    // let PAYLOAD = value
    const data = await login_service(value);
    // //console.log(data);
    if (data && data.response) {
      return data;
    } else {
      throw data;
    }
  } catch (error) {
    if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error. Please try again later.");
    }
    // else {
    //   toast.error("An error occurred. Please try again.");
    // }
    return error;
  }
});

export const registerAction = createAsyncThunk(
  "registerAction",
  async (value) => {
    try {
      // let PAYLOAD = value
      const data = await register_service(value);
      if (data) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      // if (error.response?.status === 400) {
      //   toast.error(error.response?.data?.email[0]);
      //   if (error.response?.data?.user_name[0]) {
      //     toast.error(error.response?.data?.user_name[0]);
      //   }

      //  }

      if (error.response) {
        const { status, data } = error.response;

        if (status === 400) {
          if (data?.email && data.email[0]) {
            toast.error(data.email[0]);
          }
          if (data?.user_name && data.user_name[0]) {
            toast.error(data.user_name[0]);
          }
        }
      }
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }

      return error;
    }
  }
);
export const logOutAction = createAsyncThunk("logOutAction", async () => {
  try {
    // let PAYLOAD = value
    const user = JSON.parse(sessionStorage.getItem("user"));
    const value = {
      refresh: user?.refresh,
    };
    const data = await user_logout(value);
    if (data.status === 205) {
      sessionStorage.clear();
      window.location.replace("/login");
      return data;
    } else {
      throw data;
    }
  } catch (error) {
    if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error. Please try again later.");
    }
    return error;
  }
});
