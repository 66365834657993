import React from "react";
import logoImage from "../../assets/NN logo-black.png";

class SofiaLogo extends React.Component {
  render() {
    const { className, margin } = this.props;

    // Define the style object with margin
    const imageStyle = {
      margin: margin || 0, // Use the specified margin or default to 0
    };

    return (
      <img
        className={this.props.className}
        src={process.env.PUBLIC_URL + logoImage} // Adjust the path accordingly
        alt="Logo"
        width={"100%"}
        height="65"
        style={imageStyle}
      />
    );
  }
}

export default SofiaLogo;
