import React, { useState, useEffect } from "react";
import axios from "axios";
import "./TextCleaning.css";
import { useDispatch, useSelector } from "react-redux";
import { getAuthToken } from "../../../services/HTTTPS";
import {
  ConfigSensetiveInfo,
  getConfigSensetiveInfo,
  putConfigSensetiveInfo,
} from "../../../redux/actions/subWorkspaceAction";
import ConfigureChecks from "../../../components/Common/ConfigureChecks/ConfigureChecks";
import Button from "../../../components/Button/Button";
import Loader from "../../../components/Common/LoadingModal/LoadingModal";
import { toast } from "react-toastify";
import { configurationStatus } from "../../../ENUM";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
// import { TextField } from "@material-ui/core";
// import Box from "@mui/material/Box";

const SensitiveInfoPage = () => {
  const DefaultEntities =
    "IN_PAN, IN_AADHAAR, IN_VEHICLE_REGISTRATION, UK_NHS, CREDIT_CARD, CRYPTO, DATE_TIME, EMAIL_ADDRESS, IBAN_CODE, IP_ADDRESS, PHONE_NUMBER, LOCATION";
  const dispatch = useDispatch();

  let workspace = JSON.parse(sessionStorage.getItem("workspace"));
  // const config = useSelector(state => state.ConfigurationSlice.getConfigSensetiveInfo);
  const { data, isLoading } = useSelector((state) => state.ConfigurationSlice);

  //console.log("data", data);

  const [customeEntities, setCustomeEntities] = useState("");
  const [options, setOptions] = useState({
    isPersonallyIdentifiableInformation: 0,
    isFinancialInformation: 0,
    isHealthRelatedData: 0,
    isLegalInformation: 0,
    isPasswordsandAuthenticationDetails: 0,
    isSensitiveCommunication: 0,
    default_entities: DefaultEntities,
    custom_entities_to_mask: customeEntities,
  });

  console.log("options---->><<>>", options);

  //console.log("options------------->>>", options);

  useEffect(() => {
    dispatch(getConfigSensetiveInfo());
  }, []);

  useEffect(() => {
    setOptions({
      isPersonallyIdentifiableInformation:
        data?.isPersonallyIdentifiableInformation || 0,
      isPasswordsandAuthenticationDetails:
        data?.isPasswordsandAuthenticationDetails || 0,
      isFinancialInformation: data?.isFinancialInformation || 0,
      isLegalInformation: data?.isLegalInformation || 0,
      isHealthRelatedData: data?.isHealthRelatedData || 0,
      isSensitiveCommunication: data?.isSensitiveCommunication || 0,
      custom_entities_to_mask:
        options.isPersonallyIdentifiableInformation === 1
          ? data?.custom_entities_to_mask
          : "",
      default_entities:
        options.isPersonallyIdentifiableInformation === 1
          ? data?.default_entities
          : "",
      workspace_id: workspace.workspace_id,
    });
  }, [data]);

  const handleSwitchChange = (optionName, isChecked) => {
    const newValue = isChecked ? 1 : 0;
    setOptions((prevOptions) => ({
      ...prevOptions,
      [optionName]: newValue,
    }));
  };

  useEffect(() => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      custom_entities_to_mask:
        options.isPersonallyIdentifiableInformation === 1
          ? customeEntities
          : "",
      default_entities:
        options.isPersonallyIdentifiableInformation === 1
          ? DefaultEntities
          : "",
    }));
  }, [options?.isPersonallyIdentifiableInformation]);

  // const handlChange = (e) => {
  //   setCustomeEntities(e.target.value.toUpperCase());
  //   setOptions((prevOptions) => ({
  //     ...prevOptions,
  //     default_entities: DefaultEntities,
  //     custom_entities_to_mask: e.target.value.toUpperCase(),
  //   }));
  // };

  useEffect(() => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      default_entities: DefaultEntities,
      custom_entities_to_mask: customeEntities,
    }));
  }, [customeEntities]);

  useEffect(() => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      default_entities: DefaultEntities,
      custom_entities_to_mask: customeEntities,
    }));
  }, []);

  const handleSave = () => {
    //console.log("clicked");
    const authToken = getAuthToken();
    console.log("save---options", options);

    if (authToken) {
      if (!data?.config_id) {
        dispatch(ConfigSensetiveInfo(options)).then((res) => {
          if (res.payload.status === 200 || res.payload.status === 201) {
            dispatch(getConfigSensetiveInfo());
            toast.success(configurationStatus?.Create);
          }
        });
      } else {
        const configId = data?.config_id;
        dispatch(putConfigSensetiveInfo({ options, configId })).then((res) => {
          if (res.payload.status === (200 || res.payload.status === 201)) {
            dispatch(getConfigSensetiveInfo());
            toast.success(configurationStatus?.Create);
          }
        });
      }
    } else {
      //console.log("no auth token");
    }
  };

  const handleReset = () => {
    // Reset all options to 0
    setOptions({
      isPersonallyIdentifiableInformation: 0,
      isFinancialInformation: 0,
      isHealthRelatedData: 0,
      isLegalInformation: 0,
      isPasswordsandAuthenticationDetails: 0,
      isSensitiveCommunication: 0,
      workspace_id: workspace.workspace_id,
    });
  };

  return (
    <>
      <div style={{ paddingInline: "30px" }}>
        <Loader isOpen={isLoading} />
        <h5>
          Identifying and removing sensitive information to ensure data privacy
          and compliance
        </h5>
        <div className="textQualityBox additionaltextBox">
          <ConfigureChecks
            onClick={() =>
              handleSwitchChange(
                "isPersonallyIdentifiableInformation",
                options.isPersonallyIdentifiableInformation === 0
              )
            }
            option={options.isPersonallyIdentifiableInformation === 1}
            label="Personally Identifiable Information (PII) Data "
            title="Identify and remove texts containing personal identifiers such as Social Security Number, Birth Date, names, addresses, phone numbers, and email addresses."
          />
          {options?.isPersonallyIdentifiableInformation === 1 && (
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "25ch" },
              }}
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                multiline
                maxRows={10}
                rows={4}
                style={{ width: "100%" }}
                id="outlined-basic"
                disabled
                label="Default Entities"
                value={DefaultEntities.toUpperCase()}
                variant="outlined"
              />
              <TextField
                multiline
                maxRows={10}
                rows={4}
                style={{ width: "100%" }}
                onChange={(e) => {
                  setCustomeEntities(e.target.value.toUpperCase());
                }}
                // rows={6}
                id="outlined-basic"
                // disabled
                label="Custom Entities To Mask"
                value={
                  options?.custom_entities_to_mask
                    ? options?.custom_entities_to_mask
                    : customeEntities.toUpperCase()
                }
                variant="outlined"
              />
            </Box>
          )}
          {/* <ConfigureChecks
            onClick={() =>
              handleSwitchChange(
                "isPasswordsandAuthenticationDetails",
                options.isPasswordsandAuthenticationDetails === 0
              )
            }
            option={options.isPasswordsandAuthenticationDetails === 1}
            label="PWD & Auth "
          /> */}
          {/* <ConfigureChecks
            onClick={() =>
              handleSwitchChange(
                "isFinancialInformation",
                options.isFinancialInformation === 0
              )
            }
            option={options.isFinancialInformation === 1}
            label="Financial Data"
          /> */}
          {/* <ConfigureChecks
            onClick={() =>
              handleSwitchChange(
                "isLegalInformation",
                options.isLegalInformation === 0
              )
            }
            option={options.isLegalInformation === 1}
            label="Legal Data"
          /> */}

          {/* <TextField
            placeholder="MultiLine with rows: 2 and rowsMax: 4"
            multiline
            rows={2}
            maxRows={4}
          /> */}
        </div>

        <div className="ButtonContainer">
          <Button label="Update" activeBtn onClick={handleSave} />
          <Button label="Reset" onClick={handleReset} />
        </div>
      </div>
    </>
  );
};

export default SensitiveInfoPage;
