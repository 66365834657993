import React, { useState, useEffect, useRef, useCallback } from "react";
import "../Chunks/ChunksCreation.scss";
import "./Embeddings.css";
import Button from "../../components/Button/Button";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateChunks,
  createEmbeddingsActions,
  createEmbeddingsChartAction,
  downloadEmbeddingsAction,
  getChunkAction,
  getChunkTablesAction,
  getEmbeddingsAction,
  getIninsertedePineconeData,
  insertPineconeConfigEmbeddingsAction,
  pinecodeInsertVectorAction,
  renderChartImageAction,
} from "../../redux/actions/DataOutputActions";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import ExtractPDF from "../../components/Common/PDFRenderer/ExtractPDF";
import MyScatterPlot from "../../components/EmbeddingsChart/EmbeddingsChart";
import NoFileAvailable from "../DataReview/NoFileAvailable";
import Loader from "../../components/Common/LoadingModal/LoadingModal";
import { toast } from "react-toastify";
import { setfolderChunkData } from "../../redux/slices/DataOutputSlice";
import FaissDb from "./FaissDb";
import ChromaDB from "./ChromaDB";
import PineConeStorageField from "./PineconeDB";
import TokenConsumptionViewModel from "../TokenConsumption/tokenConsumption";

// import TSNE from "tsne-js";
// import { Chart, ScatterController, LinearScale, PointElement } from "chart.js";

// Chart.register(ScatterController, LinearScale, PointElement);

const Embeddings = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [extractedText, setextractedText] = useState("");
  const [embeddingStoragName, setEmbeddingStoragName] = useState("pinecone");

  const {
    emebeddingData,
    chunksData,
    isLoading,
    pineconedata,
    vectorData,
    chartImageUrl,
  } = useSelector((state) => state.DataOutputSlice);
  const {
    fileId,
    isFilePresent,
    fileName,
    imageId,
    fileData,
    folderId,
    isImageSelected,
  } = useSelector((state) => state.PipelineExecutionSlice);

  const [embeddingModel, setEmbeddingModel] = useState("");

  useEffect(() => {
    if (emebeddingData?.id) {
      const chartPayload = {
        text_embedding_id: emebeddingData?.id,
      };
      let embeddingId = emebeddingData?.id;
      // dispatch(createEmbeddingsChartAction(chartPayload));
      dispatch(renderChartImageAction(embeddingId));
    }
    setEmbeddingModel(emebeddingData?.embedding_model);
    // setembeddingPineconeStorageValue((pre) => ({
    //   ...pre,
    //   dimension: 1536,
    // }));
  }, [emebeddingData]);

  // useEffect(() => {
  //   if (pineconedata && pineconedata?.ids) {
  //     const filteredPineconedata = pineconedata.ids;
  //     setembeddingPineconeStorageValue((pre) => ({
  //       ...pre,
  //       api_key: filteredPineconedata.api_key || "",
  //       index_name: filteredPineconedata.index_name || "",
  //       dimension: filteredPineconedata.dimension || "",
  //       metric: filteredPineconedata.metric || "",
  //       spec_cloud: filteredPineconedata.spec_cloud || "",
  //       spec_region: filteredPineconedata.spec_region || "",
  //       namespace: filteredPineconedata.namespace || "",
  //       embeddings: filteredPineconedata.embeddings || "",
  //     }));
  //   } else {

  //   }
  // }, [pineconedata]);

  // useEffect(() => {
  //   if(emebeddingData?.id){

  //     const embeddingId = emebeddingData?.id;
  //     dispatch(getIninsertedePineconeData(embeddingId));
  //   }
  // }, [emebeddingData?.id]);

  const handleEmbeddingsModelValue = (event) => {
    setEmbeddingModel(event.target.value);
    // if (event.target.value === "text-embedding-ada-002") {
    //   setembeddingPineconeStorageValue((pre) => ({
    //     ...pre,
    //     dimension: 1536,
    //   }));
    // }
  };

  useEffect(() => {
    if (isFilePresent) {
      dispatch(getChunkAction(fileId));
      dispatch(getChunkTablesAction(fileId));
    }
  }, [isFilePresent, fileId, imageId]);

  useEffect(() => {
    // if (chunksData && Object.keys(chunksData)?.length) {
    let chunk_id = chunksData?.id;
    dispatch(getEmbeddingsAction(fileId));
    // }
  }, [chunksData]);
  useEffect(() => {}, [emebeddingData]);

  // useEffect(() => {
  //   const chartPayload = {
  //     text_embedding_id: 13,
  //   };
  //   dispatch(createEmbeddingsChartAction(chartPayload));
  // }, []);

  const handleCreateEmebddings = async () => {
    try {
      let payload = {
        // file_id: chunksData?.id,
        file_id: fileId,
        embedding_model: embeddingModel,
      };

      let embeddingId;

      await dispatch(createEmbeddingsActions(payload)).then((res) => {
        if (res.payload.status === 200 || res.payload.status === 201) {
          embeddingId = res.payload?.data?.id;
        }
      });
      const chartPayload = {
        text_embedding_id: embeddingId,
      };
      const createChartResponse = await dispatch(
        createEmbeddingsChartAction(chartPayload)
      );

      if (
        createChartResponse.payload.status === 200 ||
        createChartResponse.payload.status === 201
      ) {
        // const embeddingId = embeddingId;
        await dispatch(renderChartImageAction(embeddingId));
      }
    } catch (error) {
      console.error("Error creating embeddings or chart:", error);
      // Handle the error, e.g., show a notification or update the state
    }
  };

  const handleDownloadEmbeddings = () => {
    dispatch(downloadEmbeddingsAction(emebeddingData?.id))
      .then((res) => {
        if (res.payload?.status === 200 || res.payload?.status === 201) {
          const data = res.payload?.data;

          if (data && typeof data === "object") {
            const blob = new Blob([JSON.stringify(data, null, 2)], {
              type: "application/json",
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            const today = new Date().toISOString().slice(0, 10);
            const embeddingfileName = `${
              fileName || "default"
            }-embeddings-${today}.json`;
            link.download = embeddingfileName;
            link.click();
            window.URL.revokeObjectURL(url);
          } else {
            toast.error("Data is not valid or is missing required fields.");
            console.error("Data is not valid or is missing required fields.");
          }
        } else {
          console.error("Error downloading embeddings:", res);
        }
      })
      .catch((error) => {
        console.error("Error downloading embeddings:", error);
      });
  };

  const handleOpen = () => setOpen(true);

  return (
    <>
      <Loader isOpen={isLoading} />
      <TokenConsumptionViewModel open={open} setOpen={setOpen} />
      <div className="chunk-container">
        {!isFilePresent ? (
          <NoFileAvailable isChunksPage={true} />
        ) : (
          <>
            <h5 className="chunk-title">Embeddings Criteria</h5>
            <div className="embedding-output-box">
              <div className="embedding-chunks-box">
                <ExtractPDF
                  chunksData={chunksData}
                  setextractedText={setextractedText}
                  isChunks
                />
              </div>
              <div className="embedding-chart">
                <MyScatterPlot chartImageUrl={chartImageUrl} />
              </div>
              <div className="embeddings-ouput-control-box">
                <p className="embeddings-output-title">Embeddings output</p>
                <div className="embeddings-control">
                  <FormControl sx={{ minWidth: 220 }} size="small">
                    <InputLabel id="chunking-algorithm-label">
                      Embedding Models
                    </InputLabel>
                    <Select
                      labelId="chunking-algorithm-label"
                      id="chunking-algorithm-select"
                      value={embeddingModel || ""}
                      label="Embedding Model"
                      onChange={handleEmbeddingsModelValue}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="text-embedding-ada-002">
                        text-embedding-ada-002
                      </MenuItem>
                      <MenuItem value="text-embedding-3-small">
                        text-embedding-3-small
                      </MenuItem>
                      <MenuItem value="text-embedding-3-large">
                        text-embedding-3-large
                      </MenuItem>
                    </Select>
                    {/* <p
                      style={{
                        fontSize: "14px",
                        color: "blue",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleOpen();
                      }}
                    >
                      Token consumption
                    </p> */}
                  </FormControl>

                  <div className="embedding-output-btn">
                    <Button
                      onClick={handleCreateEmebddings}
                      label={"Create"}
                      IsExtracted={
                        chunksData &&
                        Object.keys(chunksData)?.length > 0 &&
                        embeddingModel
                          ? false
                          : true
                      }
                      activeBtn={
                        chunksData &&
                        Object.keys(chunksData)?.length &&
                        embeddingModel
                      }
                    />
                    <Button
                      onClick={handleDownloadEmbeddings}
                      IsExtracted={
                        emebeddingData &&
                        Object.keys(emebeddingData)?.length > 0
                          ? false
                          : true
                      }
                      activeBtn={
                        emebeddingData && Object.keys(emebeddingData)?.length
                      }
                      label={"Download"}
                    />
                  </div>
                  {/* <hr /> */}
                  <FormControl
                    sx={{ minWidth: 220, marginTop: "10px" }}
                    size="small"
                  >
                    <InputLabel id="chunking-algorithm-label">
                      Select Embeddings Storage
                    </InputLabel>
                    <Select
                      labelId="chunking-algorithm-label"
                      id="chunking-algorithm-select"
                      value={embeddingStoragName}
                      label="Select Embeddings Storage"
                      onChange={(e) => {
                        setEmbeddingStoragName(e.target.value);
                      }}
                    >
                      <MenuItem value="pinecone">Pinecone DB</MenuItem>
                      <MenuItem value="Chroma_DB">Chroma DB</MenuItem>
                      <MenuItem value="Faiss_DB">Faiss DB</MenuItem>
                    </Select>
                  </FormControl>
                  {embeddingStoragName === "pinecone" ? (
                    <PineConeStorageField
                      embeddingModel={embeddingModel}
                      embeddingStoragName={embeddingStoragName}
                    />
                  ) : embeddingStoragName === "Faiss_DB" ? (
                    <FaissDb />
                  ) : (
                    embeddingStoragName === "Chroma_DB" && (
                      <ChromaDB embeddingStoragName={embeddingStoragName} />
                    )
                  )}
                </div>
              </div>
            </div>
            <div
              className="App"
              style={{
                fontFamily: "Arial, sans-serif",
                maxWidth: "800px",
                margin: "0 auto",
                padding: "20px",
              }}
            ></div>
          </>
        )}
      </div>
    </>
  );
};

export default Embeddings;
