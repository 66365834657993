import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  text_cleaning,
  text_quality,
  text_length,
  text_sensitive_info,
  text_profanity,
  text_tables,
} from "../../services/postServices";

import {
  get_text_cleaning,
  get_sensetive_info,
  get_text_profanity,
  get_text_length,
  get_text_quality,
  get_text_tables,
} from "../../services/getServices";

import {
  put_text_cleaning,
  put_text_length,
  put_text_quality,
  put_sensetive_info,
  put_text_profanity,
  put_text_tables,
} from "../../services/putServices";
import { toast } from "react-toastify";

let workspace = JSON.parse(sessionStorage.getItem("workspace"));

// action for text_cleaning
export const ConfigTextCleaning = createAsyncThunk(
  "ConfigTextCleaning",
  async (value) => {
    try {
      // let PAYLOAD = value
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      let payload = { ...value, workspace_id: workspace.workspace_id };
      const data = await text_cleaning(payload);

      if (data && data.response) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error. Please try again later.");
     } 
      return error;
    }
  }
);

// action for text_quality
export const ConfigTextQuality = createAsyncThunk(
  "ConfigTextQuality",
  async (value) => {
    try {
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      let payload = { ...value, workspace_id: workspace.workspace_id };
      const data = await text_quality(payload);

      if (data && data.response) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error. Please try again later.");
     } 
      return error;
    }
  }
);

// action for text_length
export const ConfigTextLength = createAsyncThunk(
  "ConfigTextLength",
  async (value) => {
    try {
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      let payload = { ...value, workspace_id: workspace.workspace_id };
      const data = await text_length(payload);

      if (data && data.response) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error. Please try again later.");
     } 
      return error;
    }
  }
);

// action for text_sensetive_info
export const ConfigSensetiveInfo = createAsyncThunk(
  "ConfigSensetiveInfo",
  async (value) => {
    try {
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      let payload = { ...value, workspace_id: workspace.workspace_id };
      const data = await text_sensitive_info(payload);

      if (data && data.response) {
        return data;
      } else {
      
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error. Please try again later.");
     } 
      return error;
    }
  }
);

// action for text_profanity
export const ConfigProfanityCheck = createAsyncThunk(
  "ConfigProfanityCheck",
  async (value) => {
    try {
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      let payload = { ...value, workspace_id: workspace.workspace_id };
      const data = await text_profanity(payload);

      if (data && data.response) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error. Please try again later.");
     } 
      return error;
    }
  }
);

export const ConfigTables = createAsyncThunk(
  "ConfigTables",
  async (value) => {
    try {
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      let payload = { ...value, workspace_id: workspace.workspace_id };
      const data = await text_tables(payload);

      if (data && data.response) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error. Please try again later.");
     } 
      return error;
    }
  }
);

export const getConfigTextCleaning = createAsyncThunk(
  "getConfigTextCleaning",
  async () => {
    try {
      // let PAYLOAD = value
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      const data = await get_text_cleaning(workspace.workspace_id);

      if (data) {
        // console.log("data",data);
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error. Please try again later.");
     } 
      return error;
    }
  }
);

export const getConfigTextQuality = createAsyncThunk(
  "getConfigTextQuality",
  async () => {
    try {
      // let PAYLOAD = value
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      const data = await get_text_quality(workspace?.workspace_id);

      if (data) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error. Please try again later.");
     } 
      return error;
    }
  }
);

export const getConfigTextLength = createAsyncThunk(
  " getConfigTextLength",
  async () => {
    try {
      // let PAYLOAD = value
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      const data = await get_text_length(workspace?.workspace_id);

      if (data) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error. Please try again later.");
     } 
      return error;
    }
  }
);

export const getConfigSensetiveInfo = createAsyncThunk(
  " getConfigSensetiveInfo",
  async () => {
    try {
      // let PAYLOAD = value
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      const data = await get_sensetive_info(workspace?.workspace_id);

      if (data) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error. Please try again later.");
     } 
      return error;
    }
  }
);

export const getConfigProfanityCheck = createAsyncThunk(
  "getConfigProfanityCheck",
  async () => {
    try {
      // let PAYLOAD = value
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      const data = await get_text_profanity(workspace?.workspace_id);

      if (data) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error. Please try again later.");
     } 
      return error;
    }
  }
);
export const getConfigTables = createAsyncThunk(
  "getConfigTables",
  async () => {
    try {
      // let PAYLOAD = value
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      const data = await get_text_tables(workspace?.workspace_id);

      if (data) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error. Please try again later.");
     } 
      return error;
    }
  }
);

export const putConfigTextCleaning = createAsyncThunk(
  "putConfigTextCleaning",
  async (value) => {
    try {
      const { options, configId } = value;
      const data = await put_text_cleaning(options, configId);

      if (data && data.response) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error. Please try again later.");
     } 
      return error;
    }
  }
);

export const putConfigTextQuality = createAsyncThunk(
  "putConfigTextQuality",
  async (value) => {
    try {
      const { configId, options } = value;
      const data = await put_text_quality(options, configId);

      if (data && data.response) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error. Please try again later.");
     } 
      return error;
    }
  }
);

export const putConfigTextLength = createAsyncThunk(
  "putConfigTextLength",
  async (value) => {
    try {
      const { configId, options } = value;
      const data = await put_text_length(configId, options);

      if (data && data.response) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error. Please try again later.");
     } 
      return error;
    }
  }
);

export const putConfigSensetiveInfo = createAsyncThunk(
  "putConfigSensetiveInfo",
  async (value) => {
    try {
      const { options, configId } = value;
      const data = await put_sensetive_info(options, configId);

      if (data && data.response) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error. Please try again later.");
     } 
      return error;
    }
  }
);

export const putConfigProfanityCheck = createAsyncThunk(
  "putConfigProfanityCheck",
  async (value) => {
    try {
      const { options, configId } = value;
      const data = await put_text_profanity(options, configId);

      if (data && data.response) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error. Please try again later.");
     } 
      return error;
    }
  }
);

export const putConfigTables = createAsyncThunk(
  "putConfigTables",
  async (value) => {
    try {
      const { options, configId } = value;
      const data = await put_text_tables(options, configId);

      if (data && data.response) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error. Please try again later.");
     } 
      return error;
    }
  }
);