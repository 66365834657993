import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  FormText,
  Input,
} from "reactstrap";
import Widget from "../../components/Widget/Widget";
import Footer from "../../components/Footer/Footer";
import { loginUser } from "../../actions/auth";
import hasToken from "../../services/authService";
import Axios from "axios"; // Import Axios for making HTTP requests

import loginImage from "../../assets/loginImage.svg";
import SofiaLogo from "../../components/Icons/SofiaLogo.js";
import { loginAction } from "../../redux/actions/AuthAction.js";
import { useHistory } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ReCAPTCHA from "react-google-recaptcha";
import Loader from "../../components/Common/LoadingModal/LoadingModal.js";
import { toast } from "react-toastify";
import "../logo.css";

const Login = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoading } = useSelector((state) => state.authSlice);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  // const [recaptchaToken, setRecaptchaToken] = useState("");

  const [UserCred, setUserCred] = useState({
    email: "",
    password: "",
  });

  const [recaptchaValue, setRecaptchaValue] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserCred((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  const handleRecaptchaChange = (value) => {
    if (value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        recaptcha: "",
      }));
    }
    setRecaptchaValue(value);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    let formErrors = {};
    const { email, password } = UserCred;

    // Simple validation checks
    if (!email) {
      formErrors.email = "Email is required";
    } else if (!isValidEmail(email)) {
      formErrors.email = "Invalid email format";
    }

    if (!password) {
      formErrors.password = "Password is required";
    }

    // Check if recaptchaToken is empty
    if (!recaptchaValue) {
      formErrors.recaptcha = "Please complete the CAPTCHA";
    }

    // Update errors state with validation results
    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      e.preventDefault();
      dispatch(loginAction(UserCred)).then((res) => {
        console.log("res--->>>", res);
        if (res.payload?.status === 201 || res.payload?.status === 200) {
          const token = res.payload?.data?.access;
          console.log("Hii you are loggded in...");
          sessionStorage.setItem("token", res.payload.data);

          sessionStorage.setItem("refresh", res.payload.data.refresh);
          if (!recaptchaValue) {
            // Show an error message or prevent form submission
            return;
          }

          sessionStorage.setItem("user", JSON.stringify(res.payload.data));
          // if (!recaptchaValue) {
          //   // Show an error message or prevent form submission
          //   return;
          // }

          history.push("/template/workspace");
        } else {
          toast.error(res.payload?.response?.data?.detail);
          // toast.error("check email id & password");

          // Handle registration failure here if needed
        }
      });
    }
  };

  const isValidEmail = (email) => {
    // Basic email format validation using regex
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  // const { from } = props.location.state || { from: { pathname: "/template" } };
  // if (hasToken(JSON.parse(sessionStorage.getItem("token")))) {
  //   return <Redirect to={from} />;
  // }

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="auth-page">
      <Loader isOpen={isLoading} />
      {/* <div className="auth-logo">
        <SofiaLogo />
      </div> */}

      <Container className="col-12">
        <Row className="d-flex align-items-center">
          <Col xs={12} lg={6} className="left-column">
            <Widget className="widget-auth widget-p-lg">
              {/* <h3>Aura-Studio</h3> */}
              <div className="d-flex align-items-center justify-content-between py-3">
                <p className="auth-header mb-0">Login</p>
                <div className="logo-block">
                  <SofiaLogo />
                </div>
                {/* <h3>AURA Data Studio</h3> */}
              </div>
              <form onSubmit={(event) => handleLogin(event)} noValidate>
                <FormGroup className="my-3">
                  <FormText>Email</FormText>
                  <Input
                    id="email"
                    className="input-transparent pl-3"
                    value={UserCred.email}
                    onChange={handleChange}
                    type="email"
                    required
                    name="email"
                    placeholder="Email Address"
                  />
                  {errors.email && (
                    <span style={{ fontSize: "13px" }} className="text-danger">
                      {errors.email}
                    </span>
                  )}
                </FormGroup>
                <FormGroup className="my-3">
                  <div className="d-flex justify-content-between">
                    <FormText>Password</FormText>
                    {/* <Link to="/error">Forgot password?</Link> */}
                  </div>
                  <div style={{ position: "relative" }}>
                    <Input
                      id="password"
                      className="input-transparent pl-3"
                      value={UserCred.password}
                      onChange={handleChange}
                      type={showPassword ? "text" : "password"}
                      required
                      name="password"
                      placeholder="Password"
                    />
                    <span
                      style={{
                        position: "absolute",
                        top: "17%",
                        right: "4%",
                      }}
                      onClick={handleTogglePassword}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </span>
                  </div>
                  {errors.password && (
                    <span style={{ fontSize: "13px" }} className="text-danger">
                      {errors.password}
                    </span>
                  )}
                </FormGroup>
                <FormGroup className="my-4">
                  <ReCAPTCHA
                    sitekey="6LcumM8pAAAAACIdcdAFgEiVfMzgDRPZ5EOun-Lx"
                    onChange={handleRecaptchaChange}
                  />
                  {errors.recaptcha && (
                    <span style={{ fontSize: "13px" }} className="text-danger">
                      {errors.recaptcha}
                    </span>
                  )}
                </FormGroup>
                <div className="bg-widget d-flex justify-content-center">
                  <Button
                    className="rounded-pill my-3"
                    type="submit"
                    color="secondary-red"
                  >
                    Login
                  </Button>
                </div>
                <p className="dividing-line my-3">&#8195;Or&#8195;</p>
                <Link to="/register">Don’t have an account? Sign Up here</Link>
              </form>
              {/* {UserCred.error && <p style={{ color: 'red', marginTop: '10px' }}>{UserCred.error}</p>} Display error message */}
            </Widget>
          </Col>
          <Col xs={0} lg={6} className="right-column">
            <div>
              <img src={loginImage} alt="Error page" />
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
  };
}

export default withRouter(connect(mapStateToProps)(Login));
