import { createSlice } from "@reduxjs/toolkit";
import {
  downloadCuratedContent,
  downloadQnaPairs,
  downloadSummary,
  downloadTables,
  getdownloadCuratedContent,
  getdownloadSummary,
  getdownloadQnaPairs,
  downloadAllZipped,
  downloadJson,
} from "../actions/downloadActions";

const downloadSlice = createSlice({
  name: "downloadSlice",
  initialState: {
    data: {},
    status: "",
    isLoading: false,
    isFilePresent: false,
    fileId: "",
  },
  reducers: {
    setFileAvailability(state, action) {
      state.isFilePresent = action.payload?.fileIdPresent;
      state.fileId = action.payload?.fileId;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(downloadCuratedContent.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(downloadCuratedContent.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(downloadCuratedContent.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.isLoading = false;
      });
    builder
      .addCase(downloadSummary.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(downloadSummary.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(downloadSummary.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.isLoading = false;
      });
    builder
      .addCase(downloadQnaPairs.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(downloadQnaPairs.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(downloadQnaPairs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.isLoading = false;
      });
    builder
      .addCase(downloadTables.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(downloadTables.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(downloadTables.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.isLoading = false;
      });
    builder
      .addCase(downloadAllZipped.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(downloadAllZipped.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(downloadAllZipped.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
      builder
        .addCase(downloadJson.pending, (state) => {
          state.status = "loading";
          state.isLoading = true;
        })
        .addCase(downloadJson.fulfilled, (state, { payload }) => {
          state.status = "success";
          state.data = payload;
          state.isLoading = false;
        })
        .addCase(downloadJson.rejected, (state, action) => {
          state.status = "failed";
          state.isLoading = false;
          state.error = action.error.message;
        });
  },
});

 export const { setFileAvailabilityDownload } = downloadSlice.actions;

export default downloadSlice.reducer;
