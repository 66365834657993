import React, { useEffect, useState } from "react";
import "./ChunksCreation.scss";
import Button from "../../components/Button/Button";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PDFRender from "../../components/Common/PDFRenderer/PDFRender";
import ExtractPDF from "../../components/Common/PDFRenderer/ExtractPDF";
import { useDispatch, useSelector } from "react-redux";
import ChunkStepper from "./ChunkStepper";
import MetaDataTabs from "../MetaData/MetaDataTabs";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  CreateChunksAction,
  CreateChunksTablesAction,
  getChunkAction,
  getChunkTablesAction,
} from "../../redux/actions/DataOutputActions";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import Tooltip from "@mui/material/Tooltip";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ConfigrationTooltip from "../../components/Common/ShowConfiguration/ConfigrationTooltip";
import recursive_split_by_chart from "../../assets/dummyDataImages/recursive-split-by-char.png";
import split_by_char from "../../assets/dummyDataImages/split-by-char.png";
import split_by_token from "../../assets/dummyDataImages/split-by-token.png";
import split_by_pages from "../../assets/dummyDataImages/split-by-pages.png";
import NoFileAvailable from "../DataReview/NoFileAvailable";
import Loader from "../../components/Common/LoadingModal/LoadingModal";
import {
  setfolderChunkData,
  setFolderTableChunkData,
} from "../../redux/slices/DataOutputSlice";
import { toast } from "react-toastify";

const ChunksCreation = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const path = query.get("path");
  const [extractedText, setextractedText] = useState("");
  const { pathname } = useLocation();
  const { chunksData } = useSelector((state) => state.DataOutputSlice);
  const [chunkLength, setChunkLength] = useState();
  const [chunkOverlap, setChunkOverlap] = useState();
  // const [folderChunkData, setfolderChunkData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [fileExtension, setFileExtension] = useState("");
  const [chunkingAlgorithm, setChunkingAlgorithm] = useState();
  const [chunksDataCopy, setchunksDataCopy] = useState({});
  const { fileId, isFilePresent, fileName, fileData, folderId, imgId } =
    useSelector((state) => state.PipelineExecutionSlice);
  const { data, isLoading, ChunksTablesData } = useSelector(
    (state) => state.DataOutputSlice
  );

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  useEffect(() => {
    if (chunksData) {
      setChunkLength(chunksData.chunk_size);
      setChunkOverlap(chunksData.chunk_overlap);
      setChunkingAlgorithm(chunksData.chunk_algorithm || "");
    }
  }, [chunksData, isFilePresent]);

  useEffect(() => {
    const getFileExtension = (fileName) => {
      const parts = fileName?.split(".");
      return parts?.length > 1 ? "." + parts.pop() : "";
    };

    setFileExtension(getFileExtension(fileName));
  }, [fileName]);

  useEffect(() => {
    const processchunk = async () => {
      let folderChunkData = [];
      for (const file of fileData) {
        try {
          if (chunkingAlgorithm === "RecursiveJsonSplitter") {
            await dispatch(getChunkTablesAction(fileId));
          } else {
            await dispatch(getChunkAction(fileId)).then((res) => {
              // console.log("res-->", res);
              if (res.payload) {
                folderChunkData.push(res.payload?.data);
              }
            });
          }
        } catch (error) {
          console.error("Error creating chunks:", error);
        }
        dispatch(setfolderChunkData({ data: folderChunkData }));
      }
    };

    if (isFilePresent && fileData?.length) {
      processchunk();
    } else {
      dispatch(setfolderChunkData({ data: [] }));
      dispatch(getChunkAction(fileId));
      dispatch(getChunkTablesAction(fileId));
    }
  }, [isFilePresent, fileId, fileData]);

  const handleChunkLengthChange = (event) => {
    setChunkLength(Number(event.target.value));
  };

  const handleChunkOverlapChange = (event) => {
    setChunkOverlap(Number(event.target.value));
  };

  const handleChunkingAlgorithmChange = (event) => {
    setChunkingAlgorithm(event.target.value);
  };

  const handleCreateChunks = async () => {
    let folderChunkData = [];
    if (!fileData) {
      const Value = {
        chunk_algorithm: chunkingAlgorithm,
        file_id: pathname === "/template/Chunks" && imgId ? imgId : fileId,
        chunk_size: chunkLength,
        chunk_overlap: chunkOverlap,
      };
      if (chunkingAlgorithm === "RecursiveJsonSplitter") {
        await dispatch(CreateChunksTablesAction(Value)).then((res) => {
          if (res.payload.status === 200 || res.payload.status === 201) {
            dispatch(getChunkTablesAction(fileId));
          }
        });
      } else {
        await dispatch(CreateChunksAction(Value)).then((res) => {
          if (res.payload.status === 200 || res.payload.status === 201) {
            toast.success("Chunks created successfully.");
          }
        });
      }
      dispatch(setfolderChunkData({ data: [] }));
    } else {
      for (const file of fileData) {
        const Value = {
          chunk_algorithm: chunkingAlgorithm,
          file_id: file.id,
          chunk_size: chunkLength,
          chunk_overlap: chunkOverlap,
        };
        try {
          if (chunkingAlgorithm === "RecursiveJsonSplitter") {
            await dispatch(CreateChunksTablesAction(Value));
          } else {
            await dispatch(CreateChunksAction(Value)).then((res) => {
              // console.log("res-->", res);
              if (res.payload) {
                folderChunkData.push(res.payload?.data);
              }
            });
          }
        } catch (error) {
          console.error("Error creating chunks:", error);
        }
        dispatch(setfolderChunkData({ data: folderChunkData }));
      }
    }
  };

  useEffect(() => {
    const chunkFileProcess = async () => {
      let folderChunkData = [];
      for (const file of fileData) {
        try {
          await dispatch(getChunkAction(file.id)).then((res) => {
            // console.log("res-->", res);
            if (res.payload) {
              folderChunkData.push(res.payload?.data);
            }
          });
        } catch (error) {
          console.error("Error processing file:", file.id, error);
        }
      }
      // console.log("imgData-->>", imgData);
      dispatch(setfolderChunkData({ data: folderChunkData }));
    };

    if (fileData && fileData.length > 0) {
      chunkFileProcess();
    }
  }, [fileData, dispatch]);

  useEffect(() => {
    const chunkTableFileProcess = async () => {
      let folderTableChunkData = [];
      for (const file of fileData) {
        try {
          await dispatch(getChunkTablesAction(file.id)).then((res) => {
            if (res.payload) {
              folderTableChunkData.push(res.payload?.data);
            }
          });
        } catch (error) {
          console.error(
            "Error processing table chunks for file:",
            file.id,
            error
          );
        }
      }
      dispatch(setFolderTableChunkData({ data: folderTableChunkData }));
    };

    if (fileData && fileData.length > 0) {
      chunkTableFileProcess();
    }
  }, [fileData, dispatch]);

  const tooltipDummyData = () => {
    return (
      <>
        <div className="tooltip-dummy-data-cotainer">
          <div className="tooltip-dummy-data-inner-container">
            <p className="dummy-data-title">Recursive split by character</p>
            <p className="dummy-data-subtitle">
              ( Splits text recursively based on characters, ideal for
              maintaining hierarchical structure)
            </p>
            <div className="dummy-data-content">
              <img
                className="dummy-data-content-image"
                src={recursive_split_by_chart}
                alt="char"
              />
            </div>
          </div>
          <div className="tooltip-dummy-data-inner-container">
            <p className="dummy-data-title">Split by character.</p>
            <p className="dummy-data-subtitle">
              ( Divides text into chunks of specified character length, suitable
              for simple, uniform splitting.)
            </p>
            <div className="dummy-data-content">
              <img
                className="dummy-data-content-image"
                src={split_by_char}
                alt="char"
              />
            </div>
          </div>
          <div className="tooltip-dummy-data-inner-container">
            <p className="dummy-data-title">Split by Token.</p>
            <p className="dummy-data-subtitle">
              ( Splits text based on token count, optimal for maintaining
              context in language models.)
            </p>
            <div className="dummy-data-content">
              <img
                className="dummy-data-content-image"
                src={split_by_token}
                alt="char"
              />
            </div>
          </div>
          <div className="tooltip-dummy-data-inner-container">
            <p className="dummy-data-title">Split by Pages.</p>
            <p className="dummy-data-subtitle">
              ( Breaks text into page-sized chunks, useful for documents with
              natural page divisions.)
            </p>
            <div className="dummy-data-content">
              <img
                className="dummy-data-content-image"
                src={split_by_pages}
                alt="char"
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Loader isOpen={isLoading} />
      {path === "metadata" ? (
        <MetaDataTabs />
      ) : (
        <div className="chunk-container">
          {!isFilePresent ? (
            <NoFileAvailable isChunksPage={true} />
          ) : (
            <>
              <h5 className="chunk-title">Define Chunks</h5>
              <div className="chunk-box">
                <div className="chunk-setting-algo">
                  <FormControl sx={{ minWidth: 220 }} size="small">
                    <InputLabel id="chunking-algorithm-label">
                      Chunking Algorithm
                    </InputLabel>
                    <Select
                      labelId="chunking-algorithm-label"
                      id="chunking-algorithm-select"
                      value={chunkingAlgorithm || ""}
                      label="Chunking Algorithm"
                      onChange={handleChunkingAlgorithmChange}
                    >
                      <MenuItem value="RecursiveCharacterTextSplitter">
                        Recursively split by character
                      </MenuItem>
                      <MenuItem value="CharacterTextSplitter">
                        Split by character
                      </MenuItem>
                      <MenuItem value="TokenTextSplitter">
                        Split by Tokens
                      </MenuItem>
                      <MenuItem value="PageTextSplitter">
                        Split by Pages
                      </MenuItem>
                      <MenuItem value="SemanticChunker">
                        Split by Sentences
                      </MenuItem>
                      <MenuItem value="CodeSplitter">Split Code</MenuItem>
                      <MenuItem value="RecursiveJsonSplitter">
                        Recursively split JSON
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <Tooltip
                    // classes={classes}
                    // style={{ background: "#fff" }}
                    // title={getTooltipMessage(chunkingAlgorithm)}
                    title={tooltipDummyData()}
                    placement="right"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "transparent",
                          color: "inherit",
                        },
                      },
                    }}
                  >
                    <InfoOutlinedIcon
                      sx={{
                        cursor: "pointer",
                        fontSize: "20px",
                      }}
                    />
                  </Tooltip>
                </div>
                <div className="chunk-setting-size">
                  <TextField
                    label="Chunk Size"
                    type="number"
                    id="chunk-size-input"
                    value={chunkLength || " "}
                    onChange={handleChunkLengthChange}
                    size="small"
                    InputLabelProps={{
                      shrink: (chunkLength || chunksData?.chunk_size) && true,
                    }}
                  />
                </div>
                <div className="chunk-setting-overlap">
                  <TextField
                    label="Chunk overlap"
                    type="number"
                    id="chunk-overlap-input"
                    value={chunkOverlap || " "}
                    onChange={handleChunkOverlapChange}
                    size="small"
                    fullWidth
                    InputLabelProps={{
                      shrink:
                        (chunksData?.chunk_overlap || chunkOverlap) && true,
                    }}
                    variant="outlined"
                  />
                </div>
                <div className="chunk-btn">
                  {/* chunkLength > 0 || chunkOverlap > 0; */}
                  <Button
                    label="Create Chunks"
                    activeBtn={chunkingAlgorithm && chunkLength && chunkOverlap}
                    IsExtracted={
                      chunkLength > 0 && chunkOverlap > 0 ? false : true
                    }
                    onClick={handleCreateChunks}
                  />
                </div>
              </div>

              <div className="chunk-preview">
                <div className="chunk-preview-column">
                  <div className="chunk-preview-content">
                    <div className="outer-extract-chunk-container">
                      <PDFRender
                        fileExtension={fileExtension}
                        fileData={fileData}
                        folderId={folderId}
                        extractedText={extractedText}
                        fileId={fileId}
                        fileName={fileName}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                      />
                    </div>
                    <div className="outer-extract-chunk-container">
                      <ExtractPDF
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        setextractedText={setextractedText}
                        isChunks={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ChunksCreation;
