import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axiosInstance from "../API/axios";
import errorData from "./../components/error.json";
import { showToast } from "../components/error";

const clearSessionData = () => {
  sessionStorage.clear();
};

// export const HOST = "http://137.117.45.236:5005/";

export const getAuthToken = () => {
  return sessionStorage.getItem("token");
};

export const GET_API = async (URL) => {
  // const history = useHistory();
  try {
    const API = URL;

    const authToken = getAuthToken();
    const response = await axiosInstance.get(API, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response;
  } catch (error) {
    if (error.response.status in errorData) {
      const errorMessage = errorData[error?.response?.status];
      showToast(errorMessage);
    }
    throw error;
  }
};

export const POST_API = async (URL, PAYLOAD, onUploadProgress) => {
  try {
    const authToken = getAuthToken();
    const response = await axiosInstance.post(URL, PAYLOAD, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      onUploadProgress, // Ensure this is correctly passed
    });

    return response; // Return response data
  } catch (error) {
    throw error;
  }
};

export const PUT_API = async (URL, PAYLOAD) => {
  try {
    const API = URL;
    const authToken = getAuthToken();
    const response = await axiosInstance.put(API, PAYLOAD, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
export const DELETE_API = async (URL) => {
  try {
    const API = URL;
    const authToken = getAuthToken();

    const response = await axiosInstance.delete(API, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const FILE_API = async (URL, PAYLOAD) => {
  try {
    const authToken = getAuthToken();
    const response = await axiosInstance.get(URL, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      responseType: "blob",
    });

    return response;
  } catch (error) {
    if (error.response.status in errorData) {
      const errorMessage = errorData[error?.response?.status];
      showToast(errorMessage);
    }
    throw error;
  }
};
