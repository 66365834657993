import React, { useEffect, useState } from "react";
import s from "./summarize.module.scss";
import Button from "../../components/Button/Button";
import TextArea from "../../components/Common/TextArea/TextArea";
import { useDispatch, useSelector } from "react-redux";
import {
  GenerateSummary,
  getsaveSummary,
  saveSummary,
} from "../../redux/actions/InsightsActions";
import Loader from "../../components/Common/LoadingModal/LoadingModal";
import { toast } from "react-toastify";
import { Box, Modal } from "@mui/material";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "10px",
  p: 3,
};

const Summarize = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { data, isLoading, savedData, generatedData } = useSelector(
    (state) => state.InsightsSlice
  );
  const {
    fileId,
    isFilePresent,
    fileName,
    fileData,
    imageId,
    isImageSelected,
  } = useSelector((state) => state.PipelineExecutionSlice);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [summaryValue, setSummaryValue] = useState("");
  const [updatedSummaryData, setUpdatedSummaryData] = useState({});

  const [limit, setLimit] = useState(50);

  const query = new URLSearchParams(useLocation().search);
  const imgId = query.get("id");

  useEffect(() => {
    setUpdatedSummaryData({
      savedData,
      generatedData,
    });
  }, [savedData, generatedData]);

  useEffect(() => {
    setUpdatedSummaryData({});
  }, [fileId]);

  // Handler function to update the summary value
  const handleSummaryChange = (event) => {
    setSummaryValue(event.target.value);
  };

  // Handler function to update the limit value
  const handleLimitChange = (event) => {
    setLimit(Number(event.target.value));
  };

  // Handler function to generate the summary
  const generateSummary = () => {
    const Value = {
      number_of_words: limit,
      file_id: pathname === "/template/Insights" && imgId ? imgId : fileId,
    };
    dispatch(GenerateSummary(Value)).then((res) => {
      if (res.payload.status === 200 || res.payload.status === 201) {
        toast.success("Summary generated successfully.");
        setSummaryValue(res.payload?.data);
      } else {
        toast.error("Please extract the file!");
      }
    });
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const savedSummary = () => {
    dispatch(saveSummary(summaryValue)).then(() => {
      handleCloseDialog();
      dispatch(getsaveSummary(imgId ? imgId : fileId));
    });
  };

  return (
    <div className={s.container}>
      <Loader isOpen={isLoading} />
      <div className={s.GenerateSummaryBox}>
        <div className={s.GenerateSummary}>
          <p>Summary in: </p>
          <input
            type="number"
            className={s.wordLimitInput}
            value={limit}
            onChange={handleLimitChange}
          />
          <p>Words</p>
        </div>
        <div className={s.GSBtn}>
          <Button
            label={"Generate Summary"}
            activeBtn={true}
            IsExtracted={!isImageSelected}
            onClick={generateSummary}
            //  IsExtracted = {!isFilePresent}
            disabled={!isFilePresent}
          />
        </div>
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <div className={s.summaryBox}>
          <p className={s.summaryBoxLabel}>Generated Summary</p>
          <TextArea
            onChange={handleSummaryChange}
            width={"90%"}
            value={
              updatedSummaryData?.generatedData?.summarized_text
                ? updatedSummaryData?.generatedData?.summarized_text
                : " "
            }
            isDisabled={true}
          />
          <p className={s.summaryBoxhelperText}>
            Click on the Generate summary to view latest summary.
          </p>
        </div>
        <div className={s.summaryBox}>
          <p className={s.summaryBoxLabel}>Saved Summary</p>
          <TextArea
            onChange={handleSummaryChange}
            width={"90%"}
            value={
              updatedSummaryData?.savedData?.summarizedtext
                ? updatedSummaryData?.savedData?.summarizedtext
                : " "
            }
            isDisabled={true}
          />
          <p className={s.summaryBoxhelperText}>
            Saved content will be downloaded in download.
          </p>
        </div>
      </div>
      {generatedData?.summarized_text && (
        <div className={s.ButtonContainer}>
          <Button label="Save" activeBtn onClick={handleOpenDialog} />
        </div>
      )}
      <Modal
        open={isDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={s.DeleteBox}>
            <p>
              Are you sure you want to replace saved summary with generated
              summary?
            </p>
            <div className={s.btnBox}>
              <Button label="Yes" activeBtn onClick={savedSummary} />
              <Button label="No" onClick={handleCloseDialog} />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Summarize;
