import React, { useState, useEffect } from "react";

import "./TextCleaning.css";
import ConfigureChecks from "../../../components/Common/ConfigureChecks/ConfigureChecks";
import { object } from "prop-types";
import Button from "../../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { getAuthToken } from "../../../services/HTTTPS";
import { toast } from "react-toastify";
import {
  ConfigTextCleaning,
  getConfigTextCleaning,
  putConfigTextCleaning,
} from "../../../redux/actions/subWorkspaceAction";
import Tooltip from "@material-ui/core/Tooltip";
import { configurationStatus } from "../../../ENUM";
import Loader from "../../../components/Common/LoadingModal/LoadingModal";
import { getFileUpload } from "../../../redux/actions/dataIngestionActions";
import { GetExtractedTablesDetailsAction } from "../../../redux/actions/DataReviewAction";
import ConfigrationTooltip from "../../../components/Common/ShowConfiguration/ConfigrationTooltip";
import ShowConfiguration from "../../../components/Common/ShowConfiguration/ShowConfiguration";
const TextCleaningPage = () => {
  let workspace = JSON.parse(sessionStorage.getItem("workspace"));
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector((state) => state.ConfigurationSlice);
  //console.log("isLoading----->>>", isLoading);
  // const config = useSelector((state) => state.ConfigurationSlice);

  //console.log("data", data);

  const [options, setOptions] = useState({
    isLowercase: 0,
    isRemoveSpecialChar: 0,
    isTokenization: 0,
    isRemoveStopWords: 0,
    isStemming: 0,
    isLemmatization: 0,
    isContractions: 0,
    isRemoveHTMLTags: 0,
    isSpellCheck: 0,
    isNumericalData: 0,
    isRemoveSpecialwords: 0,
    ListOfSpecialWords: "",
  });

  //console.log("options------------->>>", options);

  useEffect(() => {
    //console.log("u called");
    dispatch(getConfigTextCleaning());
    dispatch(getFileUpload()).then((res) => {
      //console.log("res", res);
      if (res.payload?.status === 200 || res.payload?.status === 200) {
        console.log("No need to call table extraction detail...");
        // dispatch(GetExtractedTablesDetailsAction()).then((res) => {
        //   if (res.payload?.response?.status === 404) {
        //     sessionStorage.removeItem("table_id");
        //   }
        // });
      }
    });
  }, []);

  useEffect(() => {
    setOptions({
      isLowercase: data?.isLowercase || 0,
      isRemoveSpecialChar: data?.isRemoveSpecialChar || 0,
      isTokenization: data?.isTokenization || 0,
      isRemoveStopWords: data?.isRemoveStopWords || 0,
      isStemming: data?.isStemming || 0,
      isLemmatization: data?.isLemmatization || 0,
      isContractions: data?.isContractions || 0,
      isRemoveHTMLTags: data?.isRemoveHTMLTags || 0,
      isSpellCheck: data?.isSpellCheck || 0,
      isNumericalData: data?.isNumericalData || 0,
      isRemoveSpecialwords: data?.isRemoveSpecialwords || 0,
      ListOfSpecialWords: "",
      workspace_id: workspace?.workspace_id,
    });
  }, [data]);

  //console.log("options------------->>>2", options);

  const handleSwitchChange = (optionName, isChecked) => {
    //console.log("optionName---->>", optionName);
    const newValue = isChecked ? 1 : 0;
    setOptions((prevOptions) => ({
      ...prevOptions,
      [optionName]: newValue,
    }));
  };

  const handleSave = () => {
    //console.log("clicked");
    const authToken = getAuthToken();
    if (authToken) {
      if (!data?.config_id) {
        dispatch(ConfigTextCleaning(options)).then((res) => {
          if (res.payload.status == 201) {
            //console.log("res.payload---->>>", res.payload);
            toast.success(configurationStatus?.Create);
            dispatch(getConfigTextCleaning());
          }
        });
      } else {
        const configId = data?.config_id;
        dispatch(putConfigTextCleaning({ options, configId })).then((res) => {
          if (res.payload.status === 200) {
            dispatch(getConfigTextCleaning());
            toast.success(configurationStatus?.Create);
          }
        });
      }
    } else {
      //console.log("no auth token");
    }
  };

  const handleReset = () => {
    setOptions({
      isLowercase: 0,
      isRemoveSpecialChar: 0,
      isTokenization: 0,
      isRemoveStopWords: 0,
      isStemming: 0,
      isLemmatization: 0,
      isContractions: 0,
      isRemoveHTMLTags: 0,
      isSpellCheck: 0,
      isNumericalData: 0,
      isRemoveSpecialwords: 0,
      ListOfSpecialWords: "",
      workspace_id: workspace.workspace_id,
    });
  };

  return (
    <div
      style={{
        // paddingTop: "10px",
        paddingInline: "30px",
        // marginBottom: "20px",
      }}
    >
      <Loader isOpen={isLoading} />
      <h5 style={{ marginRight: "50px", marginLeft: "10px" }}>
        Enhance insights by refining text data, removing noise and
        inconsistencies.
      </h5>
      {/* {config && (
        <> */}
      <div className="textCleaningBox">
        <ConfigureChecks
          onClick={() =>
            handleSwitchChange("isLowercase", options.isLowercase === 0)
          }
          option={options.isLowercase === 1}
          label="Lowercase"
          title="Convert all text to lowercase to ensure consistency and avoid case-sensitive discrepancies."
        />

        <ConfigureChecks
          onClick={() =>
            handleSwitchChange(
              "isRemoveSpecialChar",
              options.isRemoveSpecialChar === 0
            )
          }
          option={options.isRemoveSpecialChar === 1}
          label="Remove Special Chars"
          title=" Eliminate special characters, punctuation, and symbols that may not contribute to the model's understanding. "
        />
        <ConfigureChecks
          onClick={() =>
            handleSwitchChange(
              "isRemoveHTMLTags",
              options.isRemoveHTMLTags === 0
            )
          }
          option={options.isRemoveHTMLTags === 1}
          label="Remove HTML Tags"
          title="Strip HTML tags from text data if it originates from web pages or other HTML-based sources."
        />
        <ConfigureChecks
          onClick={() =>
            handleSwitchChange(
              "isRemoveStopWords",
              options.isRemoveStopWords === 0
            )
          }
          option={options.isRemoveStopWords === 1}
          label="Remove Stop Words"
          title="Eliminate stop words that don't carry significant meaning."
        />

        {/* <ConfigureChecks
          onClick={() =>
            handleSwitchChange("isSpellCheck", options.isSpellCheck === 0)
          }
          option={options.isSpellCheck === 1}
          label="Spell Check"
        /> */}
        <ConfigureChecks
          onClick={() =>
            handleSwitchChange("isContractions", options.isContractions === 0)
          }
          option={options.isContractions === 1}
          label="Contraction"
          title="Expand contractions for consistency and to avoid ambiguity."
        />
        <ConfigureChecks
          onClick={() =>
            handleSwitchChange("isNumericalData", options.isNumericalData === 0)
          }
          option={options.isNumericalData === 1}
          label="Numeric Data"
          title="Address numerical data appropriately, such as normalizing or replacing numbers with a placeholder, depending on the task."
        />

        <ConfigureChecks
          onClick={() =>
            handleSwitchChange("isLemmatization", options.isLemmatization === 0)
          }
          option={options.isLemmatization === 1}
          label="Lemmatization"
          title="Similar to stemming, lemmatization reduces words to their base or dictionary form, enhancing semantic understanding."
        />
        <ConfigureChecks
          onClick={() =>
            handleSwitchChange("isStemming", options.isStemming === 0)
          }
          option={options.isStemming === 1}
          label="Stemming"
          title="Reduce words to their root form to handle variations and ensure uniformity in the text data."
        />

        <ConfigureChecks
          onClick={() =>
            handleSwitchChange("isTokenization", options.isTokenization === 0)
          }
          option={options.isTokenization === 1}
          label="Tokenization"
          title="Break down the text into individual tokens or words to facilitate further analysis and processing."
        />
      </div>
      {/* </>
      )} */}
      <div className="ButtonContainer">
        <Button label="Update" activeBtn onClick={handleSave} />
        <Button label="Reset" onClick={handleReset} />
      </div>
    </div>
  );
};

export default TextCleaningPage;
