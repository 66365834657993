import React, { useEffect, useRef, useState } from "react";
import ReactPDF, {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  usePDF,
} from "@react-pdf/renderer";

import "./PDFRender.css";
import ShowConfiguration from "../ShowConfiguration/ShowConfiguration";
import { useDispatch, useSelector } from "react-redux";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { Padding, WindowOutlined } from "@mui/icons-material";
import axios from "axios";
import { ReviewTextCleaningAction } from "../../../redux/actions/DataReviewAction";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import noGraphAvailable from "../../../assets/No_Data_Found.jpg";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getChunkAction } from "../../../redux/actions/DataOutputActions";
// import noGraphAvailable from "../../assets/No_Data_Found.jpg";
import { Tabs, Tab } from "@mui/material";
import {
  setfileIDSerial,
  setfolderChunkData,
} from "../../../redux/slices/DataOutputSlice";
import { object } from "prop-types";

const ExtractPDF = (props) => {
  const {
    currentPage,
    pageData,
    configs,
    setextractedText,
    fileExtension,
    fileName,
    UpdatedImgFolderData,
    isChunks,
  } = props;
  const dispatch = useDispatch();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const { pathname } = useLocation();
  const query = useQuery();

  const path = query.get("path");
  const { fileId, isFilePresent, fileData, folderId } = useSelector(
    (state) => state.PipelineExecutionSlice
  );
  const [chunkPage, setChunkPage] = useState(1);
  const {
    chunksData,
    folderChunkData,
    folderTableChunkData,
    chunksTable,
    ChunksTablesData,
  } = useSelector((state) => state.DataOutputSlice);

  const [totalPages, setTotalPages] = useState();
  const [singlelpageData, setsinglelPageData] = useState({});
  const [paragraphs, setParagraphs] = useState([]);

  const [boldedIndex, setBoldedIndex] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  const handlePrevPage = () => {
    if (chunkPage > 0) {
      const prevPage = chunkPage - 1;
      setChunkPage(prevPage);
      let pre = prevPage - 1;
      dispatch(setfileIDSerial({ data: prevPage }));
    }
  };

  const handleNextPage = () => {
    if (chunkPage < fileData?.length) {
      const nextPage = chunkPage + 1;
      setChunkPage(nextPage);
      dispatch(setfileIDSerial({ data: nextPage }));
      // processChunksFilewise(nextPage);
    }
  };

  // useEffect(() => {
  //   processChunksFilewise(0);
  // }, [fileData, isChunks]);

  const processChunksFilewise = (num) => {
    let fileIdForChunk = fileData[num - 1]?.id;
    dispatch(getChunkAction(fileIdForChunk));
  };

  const handleIconClick = (index) => {
    setBoldedIndex(index);
  };

  useEffect(() => {
    if (pageData) {
      setsinglelPageData(pageData);
    }
    if (UpdatedImgFolderData && UpdatedImgFolderData?.length > 0 && fileData) {
      setsinglelPageData(
        UpdatedImgFolderData?.reduce((acc, item, index) => {
          const key = Object.keys(item)?.length && Object.keys(item)[0];

          if (UpdatedImgFolderData?.length === 1) {
            acc[1] = Object.values(item)[0];
          } else {
            acc[index + 1] = item[key];
          }

          return acc;
        }, {})
      );
    }
  }, [UpdatedImgFolderData, pageData, fileData]);

  useEffect(() => {
    if (isChunks && chunksData && chunksData.chunks) {
      const chunks = Object.entries(chunksData.chunks).map(
        ([key, value]) => value?.text
      );
      setParagraphs(chunks);
    }
  }, [isChunks, chunksData]);

  const getTextContent = (pageNumber) => {
    const content = singlelpageData;
    return content && content[pageNumber]?.text
      ? content[pageNumber]?.text
      : "";
  };
  const renderedText = UpdatedImgFolderData && getTextContent(currentPage);

  useEffect(() => {
    if (renderedText) {
      setextractedText(renderedText);
    } else if (fileData?.length) {
      setextractedText(
        folderChunkData &&
          folderChunkData[currentPage ? currentPage - 1 : chunkPage - 1]?.text
      );
    } else if (chunksData && Object.keys(chunksData)?.length) {
      setextractedText(
        chunksData && Object.keys(chunksData)?.length ? chunksData?.text : ""
      );
    }
  }, [renderedText, chunksData, folderChunkData]);

  useEffect(() => {
    if (pageData && Object.keys(pageData)?.length) {
      const firstValue = Object.values(pageData)[0];

      setextractedText(renderedText && renderedText);
    }
  }, [pageData]);

  const styles = {
    container: {
      marginBottom: "30px",
      paddingBottom: "30px",
      padding: "20px",
      boxShadow:
        "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
    },
    paragraph: {
      fontSize: "14px",
      paddingTop: "10px",
    },
    pageNumber: {
      textAlign: "end",
      fontStyle: "italic",
      borderBottom: "1px solid grey",
      fontSize: "14px",
    },
    pagination: {
      position: "absolute",
      border: "2px solid red",
      height: "20px",
      height: "100px",
      top: "100%",
    },
  };

  useEffect(() => {
    if (pathname === "/template/Embeddings") {
      dispatch(setfolderChunkData({ data: "" }));
    }
  }, [chunksData]);

  const renderParagraphs = () => {
    if (!chunksData || !chunksData.chunks || !Array.isArray(paragraphs)) {
      return null;
    }

    let chunks = [];
    if (folderChunkData?.length) {
      const fileChunks =
        folderChunkData[currentPage ? currentPage - 1 : chunkPage - 1]?.chunks;
      chunks = fileChunks ? Object.values(fileChunks) : [];
    } else {
      chunks = Object.values(chunksData?.chunks || {});
    }

    if (!chunks?.length) {
      return <p>No data available</p>;
    }

    const overlapLength = chunksData?.chunk_overlap || 0;
    let previousHighlightedText = "";

    return chunks.map((chunk, index) => {
      if (typeof chunk?.text !== "string") return null;

      const currentChunk = previousHighlightedText + chunk?.text;
      const newHighlightedText = chunk?.text?.slice(-overlapLength);

      // Split currentChunk into non-highlighted and highlighted parts
      const nonHighlightedPart = currentChunk.slice(
        0,
        currentChunk?.length - overlapLength
      );

      // Update the previous highlighted text for the next chunk
      previousHighlightedText = newHighlightedText;

      return (
        <React.Fragment key={index}>
          <div style={styles.pageNumber}>
            Chunk-{index + 1} of {paragraphs?.length}
          </div>
          <p style={styles.paragraph}>
            {index > 0 && (
              <span style={{ backgroundColor: "yellow" }}>
                {currentChunk?.slice(0, overlapLength)}
              </span>
            )}
            {nonHighlightedPart}
            <span style={{ backgroundColor: "yellow" }}>
              {newHighlightedText}
            </span>
          </p>
        </React.Fragment>
      );
    });
  };

  const renderTableChunks = () => {
    if (
      !ChunksTablesData ||
      !ChunksTablesData?.json_tables ||
      !Object.keys(ChunksTablesData?.json_tables)?.length
    ) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img height={200} width={270} src={noGraphAvailable} />
          <p style={{ fontWeight: "600", textAlign: "center" }}>
            Table chunks not found for the given file, please create chunks by
            selecting "Recursively split JSON" embedding model.
          </p>
        </div>
      );
    }

    return Object.entries(ChunksTablesData?.table_chunks).map(
      ([tableName, tableData]) => (
        <div key={tableName}>
          <h6>{tableName}</h6>
          <pre
            style={{
              padding: "10px",
              // borderRadius: "5px",
              overflow: "auto",
              textWrap: "wrap",
              borderBottom: "2px solid #dddddd",
            }}
          >
            {JSON.stringify(tableData?.text, null, 2)}
          </pre>
        </div>
      )
    );
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const dummyText = chunksData?.chunks?.["chunk 1"] || "";

  return (
    <>
      <div className="main-container">
        <div
          className={`extractPdf-title ${isChunks ? "reduced-padding" : ""}`}
        >
          {!isChunks ? (
            <>
              <p>Updated Content</p>
              <p className="config-title">
                Configurations <ShowConfiguration configs={configs} />
              </p>
            </>
          ) : (
            <>
              <div className="chunk-header-container">
                <Tabs value={tabValue} onChange={handleTabChange}>
                  <Tab
                    label="Text Chunks"
                    sx={{ textTransform: "capitalize" }}
                    // className="tab-label"
                  />
                  <Tab
                    sx={{ textTransform: "capitalize" }}
                    label="Table Chunks"
                  />
                </Tabs>
                {path === "metadata" && fileData?.length && (
                  <div className="chunk-header-pagination">
                    <button
                      onClick={handlePrevPage}
                      className="prev-control"
                      disabled={chunkPage === 1}
                    >
                      <ArrowCircleLeftIcon />
                    </button>
                    <div>
                      Page: {chunkPage} / {fileData?.length}
                    </div>
                    <button
                      className="prev-control"
                      onClick={handleNextPage}
                      disabled={chunkPage === fileData?.length}
                    >
                      <ArrowCircleRightIcon />
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div
          id="pdf-container"
          className="Extract-pdf-box"
          style={{
            width: "100%",
            height: "60vh",
            overflowY: "scroll",
            position: "relative",
            padding: "15px",
          }}
        >
          {fileExtension === ".docx" || fileExtension === ".doc" ? (
            !singlelpageData ||
            (typeof singlelpageData !== "object" ? (
              <p>No data available</p>
            ) : (
              Object.keys(singlelpageData)?.map((key, index) => (
                <div key={key} style={styles.container}>
                  <div style={styles.pageNumber}>
                    Page {index + 1} of{" "}
                    {typeof singlelpageData !== "object"
                      ? ""
                      : Object.keys(singlelpageData)?.length - 1}
                  </div>
                  <p style={styles.paragraph}>{singlelpageData[key]}</p>
                </div>
              ))
            ))
          ) : isChunks ? (
            tabValue === 0 ? (
              <>
                {chunksData && Object.keys(chunksData)?.length ? (
                  renderParagraphs(dummyText)
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <img height={200} width={270} src={noGraphAvailable} />
                    <p style={{ fontWeight: "600", textAlign: "center" }}>
                      Chunks not found for the given file, please create chunk.
                    </p>
                  </div>
                )}
              </>
            ) : (
              renderTableChunks()
            )
          ) : (
            <div style={styles.container}>
              <div style={styles.pageNumber}>
                Page {currentPage} of{" "}
                {typeof singlelpageData !== "object"
                  ? ""
                  : Object.keys(singlelpageData)?.length - 1}
              </div>
              <p style={styles.paragraph}>{renderedText}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ExtractPDF;
