import React, { useEffect, useState } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "reactstrap";
import s from "./ContentHeader.module.scss";

import ReplyIcon from "@mui/icons-material/Reply";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Dropdown from "../ShowConfiguration/ConfigrationDetails";
import { useDispatch } from "react-redux";
import { setFileAvailability } from "../../../redux/slices/pipelinExecutionSlice";

function ContentHeader({ toggleModal }) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [isExxtractedFileInput, setisExxtractedFileInput] = useState(false);
  const history = useHistory();
  let workspace = JSON.parse(sessionStorage.getItem("workspace"));

  useEffect(() => {
    if (
      pathname === "/template/pipelineexecution" ||
      pathname === "/template/DataReview" ||
      pathname === "/template/Insights" ||
      pathname === "/template/download" ||
      pathname === "/template/Embeddings" ||
      pathname === "/template/MetaData" ||
      pathname === "/template/ChunksCreation" ||
      pathname === "/template/KnowledgeGraph" ||
      pathname === "/template/QnA"
    ) {
      setisExxtractedFileInput(true);
    } else {
      setisExxtractedFileInput(false);
      dispatch(
        setFileAvailability({
          fileIdPresent: false,
          fileId: "",
        })
      );
    }
  }, [pathname]);

  const capitalizeFirstLetterOfWords = (str) => {
    if (!str) return "";
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  let capitalizedWorkspaceName = capitalizeFirstLetterOfWords(
    workspace?.workspace_name
  );

  const styles = {
    marginTop: pathname === "/template/workspace" ? "0" : "30px",
    marginBottom: "40px",
  };
  return (
    <>
      <div
        className={
          pathname === "/workspace"
            ? "d-flex justify-content-between align-items-center mb-3"
            : "d-flex justify-content-between align-items-center mb-3"
        }
        style={styles}
      >
        {pathname === "/template/workspace" ? (
          <></>
        ) : (
          <>
            <div className={s.leftSideTitle}>
              <h4
                style={{ display: "inline-block" }}
              >{`Workspace : ${capitalizedWorkspaceName}`}</h4>{" "}
            </div>
            <div className={s.Backtoworkspace}>
              <p
                onClick={() => {
                  history.push("/template/workspace");
                }}
                className={s.backName}
              >
                <KeyboardBackspaceIcon />
                {" Back to My Workspace"}
              </p>
            </div>
          </>
        )}
      </div>
      {pathname === "/template/pipelineexecution" ||
      pathname === "/template/DataReview" ||
      pathname === "/template/Insights" ||
      pathname === "/template/download" ||
      pathname === "/template/Embeddings" ||
      pathname === "/template/MetaData" ||
      pathname === "/template/ChunksCreation" ||
      pathname === "/template/KnowledgeGraph" ||
      pathname === "/template/QnA" ? (
        <Dropdown />
      ) : (
        ""
      )}
    </>
  );
}

export default ContentHeader;
