import React from "react";

import s from "./TextArea.module.scss";

function TextArea({ onChange, value, width, isDisabled, placeHolder, height }) {
  return (
    <div style={{ width: "100%" }}>
      <textarea
        // placeholder="orignal content"
        style={{ width: width, height: height }}
        value={value}
        onChange={onChange}
        rows={7}
        className={s.summaryTextArea}
        disabled={isDisabled}
        placeHolder={placeHolder}
      />
    </div>
  );
}

export default TextArea;
