import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import s from "./Connectors.module.scss";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import business from "../../assets/businessman.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  fetchConnectorObjects,
  fetchConnectors,
} from "../DynamicForm/DynamicFormServices";
import Loader from "../../components/Common/LoadingModal/LoadingModal";
import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Label,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Button } from "@mui/material";
const Connectors = () => {
  const history = useHistory();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [serviceTypes, setServiceTypes] = useState([]);
  const [storageData, setStorageData] = useState([]);
  const [itemsPerPage] = useState(5);

  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 5; // Items per page

  const pagesCount = Math.ceil(storageData.length / pageSize);

  const setPage = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
  };
  useEffect(() => {
    fetchConnectors();
  }, []);

  useEffect(() => {
    const getConnectors = async () => {
      try {
        const data = await fetchConnectors();
        setServiceTypes(data);
      } catch (error) {
        console.error("Error fetching connectors:", error);
      }
    };
    getConnectors();
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    document.body.classList.toggle("sidebar-open");
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleServiceToggle = (name) => {
    setSelectedService((prevSelected) => {
      const newSelected = prevSelected === name ? "" : name;
      if (newSelected) {
        history.push("/template/DynamicForm");
      }
      return newSelected;
    });
  };
  // Get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = storageData.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    const getConnectorObjects = async () => {
      try {
        const formattedData = await fetchConnectorObjects();
        setStorageData(formattedData);
      } catch (error) {
        console.error("Error fetching connector objects:", error);
      }
    };
    getConnectorObjects();
  }, []);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const filteredServiceTypes = serviceTypes?.filter((service) =>
    service.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      {storageData?.length && (
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <button
            onClick={toggleSidebar}
            className={s["dcui-connectorsButton"]}
          >
            Create Connections
          </button>
        </div>
      )}
      {!storageData?.length ? (
        <div className={`${s["dcui-serviceConnectionContainer"]}`}>
          <div
            className={`${s["dcui-mainContent"]} ${
              isSidebarOpen ? s["dcui-blurred"] : ""
            }`}
          >
            <div className={s["dcui-avatarContainer"]}>
              <img
                src={business}
                alt="User avatar"
                className={s["dcui-avatar"]}
              />
            </div>
            <h5>Create your first service connection</h5>
            <p>Service connections help you share configurations.</p>
            <div style={{ marginTop: "10px" }}>
              <button
                onClick={toggleSidebar}
                className={s["dcui-connectorsButton"]}
              >
                Create Connections
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`${s["dcui-tableBox"]}  ${
            isSidebarOpen ? s["dcui-TablesidebarOpen"] : ""
          }`}
        >
          <div className={s["dcui-tableContainer"]}>
            <Table
              className={`table-striped table-borderless table-hover ${s.statesTable}`}
              responsive
              hover
            >
              <thead>
                <tr>
                  <th className={s.ColBox}>Storage Name</th>
                  <th className={s.ColBox}>Storage Type</th>
                </tr>
              </thead>
              <tbody>
                {storageData
                  .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                  .map((item) => (
                    <tr key={uuidv4()}>
                      <td>
                        <span style={{ color: "#0000ca" }}>{item.name}</span>
                      </td>
                      <td>{item.type}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <Pagination
              className="pagination-borderless"
              aria-label="Page navigation example"
            >
              <PaginationItem disabled={currentPage <= 0}>
                <PaginationLink
                  onClick={(e) => setPage(e, currentPage - 1)}
                  previous
                  href="#top"
                />
              </PaginationItem>
              {[...Array(pagesCount)]?.map((page, i) => (
                <PaginationItem active={i === currentPage} key={i}>
                  <PaginationLink onClick={(e) => setPage(e, i)} href="#top">
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                <PaginationLink
                  onClick={(e) => setPage(e, currentPage + 1)}
                  next
                  href="#top"
                />
              </PaginationItem>
            </Pagination>
          </div>
        </div>
      )}

      <div
        // style={{ position: "relative" }}
        className={`${isSidebarOpen ? s["dcui-sidebarOpen"] : ""}`}
      >
        <div
          className={`${s["dcui-sidebar"]} ${
            isSidebarOpen ? s["dcui-open"] : ""
          }`}
        >
          <div className={s["dcui-sidebarHeader"]}>
            <h5>New service connection</h5>
            <button className={s["dcui-closeBtn"]} onClick={toggleSidebar}>
              <HighlightOffIcon fontSize="small" />
            </button>
          </div>
          <TextField
            id="outlined-search"
            placeholder="Choose a service or connection type"
            type="search"
            className={s["dcui-searchInput"]}
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <ul className={s["dcui-serviceList"]}>
            {filteredServiceTypes?.map((service, index) => (
              <li key={service.name}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedService === service.name}
                      onChange={() => handleServiceToggle(service.name)}
                      name={service.name}
                      disabled={index !== 0}
                    />
                  }
                  label={
                    <div
                      className={`${s["dcui-serviceLabel"]} ${
                        index !== 0 ? s["dcui-disabled"] : ""
                      }`}
                    >
                      <span>{service.title}</span>
                    </div>
                  }
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Connectors;
