import { POST_API, PUT_API } from "./HTTTPS";

const BASE_URL = "aura-studio";

export const login_service = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/nnusers/accounts/login/`, PAYLOAD);
};

export const register_service = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/nnusers/register/`, PAYLOAD);
};

export const text_cleaning = (PAYLOAD) => {
  return POST_API(
    `${BASE_URL}/workspace/workspace-configs/text_cleaning/`,
    PAYLOAD
  );
};

export const text_quality = (PAYLOAD) => {
  return POST_API(
    `${BASE_URL}/workspace/workspace-configs/text_quality_check/`,
    PAYLOAD
  );
};

export const text_length = (PAYLOAD) => {
  return POST_API(
    `${BASE_URL}/workspace/workspace-configs/text_length_check/`,
    PAYLOAD
  );
};

export const text_sensitive_info = (PAYLOAD) => {
  return POST_API(
    `${BASE_URL}/workspace/workspace-configs/text_sensitive_info_check/`,
    PAYLOAD
  );
};

export const text_profanity = (PAYLOAD) => {
  return POST_API(
    `${BASE_URL}/workspace/workspace-configs/text_profanity_check/`,
    PAYLOAD
  );
};

export const text_tables = (PAYLOAD) => {
  return POST_API(
    `${BASE_URL}/workspace/workspace-configs/text_extraction/`,
    PAYLOAD
  );
};

export const file_upload = (PAYLOAD, onUploadProgress) => {
  return POST_API(
    `${BASE_URL}/dataingestion/upload/`,
    PAYLOAD,
    onUploadProgress
  );
};
export const data_extraction = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/dataextraction/data_extraction/`, PAYLOAD);
};

export const image_folder = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/dataingestion/create-folder/`, PAYLOAD);
};

export const create_workspace = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/workspace/create_workspace/`, PAYLOAD);
};

export const curated_file_download = (PAYLOAD) => {
  return POST_API(
    `${BASE_URL}/data_download/curatedcontent/download/`,
    PAYLOAD
  );
};

export const summary_download = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/data_download/summary/download/`, PAYLOAD);
};

export const qa_pairs_download = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/data_download/qapairs/download/ `, PAYLOAD);
};

export const zipped_download = (PAYLOAD) => {
  return POST_API(
    `${BASE_URL}/data_download/zippedcuratedcontent/download/`,
    PAYLOAD
  );
};

export const genrate_summary = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/insights/summary/`, PAYLOAD);
};

export const save_summary = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/insights/save_summary/`, PAYLOAD);
};

// pipeline execution

export const textCleaning_curation = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/datacuration/text_cleaning/`, PAYLOAD);
};
export const textQualityCheck_curation = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/datacuration/text_quality_check/`, PAYLOAD);
};
export const sensetiveInfoCheck_curation = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/datacuration/sensitive_info_check/`, PAYLOAD);
};
export const profanityCheck_curation = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/datacuration/profanity_check/`, PAYLOAD);
};

export const save_textCleaning_curation = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/datacuration/save_text_cleaning/`, PAYLOAD);
};

export const save_textQualityCheck_curation = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/datacuration/save_text_quality_check/`, PAYLOAD);
};

export const save_sensetiveInfoCheck_curation = (PAYLOAD) => {
  return POST_API(
    `${BASE_URL}/datacuration/save_sensitive_info_check/`,
    PAYLOAD
  );
};

export const save_profanityCheck_curation = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/datacuration/save_profanity_check/`, PAYLOAD);
};
export const genrate_qa_pairs = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/insights/quesans/`, PAYLOAD);
};

export const save_qa_pairs = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/insights/save_quesans/`, PAYLOAD);
};
export const extract_table = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/dataextraction/extract_tables/`, PAYLOAD);
};
export const validation_execution = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/workspace/validate-configuration/`, PAYLOAD);
};
export const user_logout = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/nnusers/accounts/logout/`, PAYLOAD);
};
export const perform_curation_all = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/datacuration/pipeline_execution/`, PAYLOAD);
};
export const file_validation_api = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/dataingestion/validate-file/`, PAYLOAD);
};

export const converte_excel_to_json = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/dataextraction/convert_excel_to_json/`, PAYLOAD);
};
export const save_edited_table = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/dataextraction/save_edited_tables/`, PAYLOAD);
};

export const create_chunks = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/datachunks/create-chunks/`, PAYLOAD);
};

export const create_embeddings = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/embeddings/create_embeddings/`, PAYLOAD);
};
export const keyword_config = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/metadata/keyword_config/`, PAYLOAD);
};

export const create_topic_configration = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/metadata/topic_generation_config/`, PAYLOAD);
};

export const create_topic_metadata = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/metadata/create_topic_metadata/`, PAYLOAD);
};
export const meta_data_creation = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/metadata/create_keyword_metadata/`, PAYLOAD);
};
export const insert_pinecone_config = (PAYLOAD) => {
  return POST_API(
    `${BASE_URL}/embeddings/store_embeddings/pinecone/insert_config/`,
    PAYLOAD
  );
};
export const pinecode_insert_vectors = (PAYLOAD) => {
  return POST_API(
    `${BASE_URL}/embeddings/store_embeddings/pinecone/insert_vectors/`,
    PAYLOAD
  );
};
export const get_inserted_pinecode_data = (PAYLOAD) => {
  return POST_API(
    `${BASE_URL}/embeddings/store_embeddings/pinecone/get_ids/`,
    PAYLOAD
  );
};
export const create_Tables_chunks = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/datachunks/create-table-chunks/`, PAYLOAD);
};
export const create_embedding_chart = (PAYLOAD) => {
  return POST_API(`${BASE_URL}/embeddings/generate_embedding_chart/`, PAYLOAD);
};
export const insert_embedding_faiss_db = (PAYLOAD) => {
  return POST_API(
    `${BASE_URL}/embeddings/store_embeddings/faiss/insert_vectors/`,
    PAYLOAD
  );
};
export const insert_embedding_chromaDb = (PAYLOAD) => {
  return POST_API(
    `${BASE_URL}/embeddings/store_embeddings/chromadb/insert_config/`,
    PAYLOAD
  );
};
export const store_embedding_chromaDb = (PAYLOAD) => {
  return POST_API(
    `${BASE_URL}/embeddings/store_embeddings/chromadb/insert_vectors/`,
    PAYLOAD
  );
};
export const create_knowledge_graph = (PAYLOAD) => {
  return POST_API(
    `${BASE_URL}/KnowledgeGraph/create-knowledge-graph/`,
    PAYLOAD
  );
};
export const update_knowledge_graph = (PAYLOAD, fileId) => {
  return PUT_API(
    `${BASE_URL}/KnowledgeGraph/knowledge-graph/update/${fileId}/`,
    PAYLOAD
  );
};
