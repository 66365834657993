import React, { useState, useEffect } from "react";
import "./TextCleaning.css";
import Button from "../../../components/Button/Button";
import Loader from "../../../components/Common/LoadingModal/LoadingModal";
import { useDispatch, useSelector } from "react-redux";
import ConfigureChecks from "../../../components/Common/ConfigureChecks/ConfigureChecks";
import { toast } from "react-toastify";
import { configurationStatus } from "../../../ENUM";
import { getAuthToken } from "../../../services/HTTTPS";
import { ConfigTables, getConfigTables, putConfigTables } from "../../../redux/actions/subWorkspaceAction";
const TablesPage = () => {
     let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      //  const { data, isLoading } = useSelector((state) => state.ConfigurationSlice);
      const dispatch = useDispatch();
     const { data, isLoading } = useSelector((state) => state.ConfigurationSlice);

  console.log("data" ,data);

    const [options, setOptions] = useState({
    extract_text_without_tables: 0,
    
  });

useEffect(()=>{
  dispatch(getConfigTables());
},[])

 useEffect(() => {
    setOptions({
      extract_text_without_tables: data?.extract_text_without_tables || 0,
       workspace_id: workspace?.workspace_id,
    })
  }, [data]);
  
     const handleSwitchChange = (optionName, isChecked) => {
    //console.log("optionName---->>", optionName);
    const newValue = isChecked ? 1 : 0;
    setOptions((prevOptions) => ({
      ...prevOptions,
      [optionName]: newValue,
    }));
  };

    const handleSave = () =>{
  const authToken = getAuthToken();
  console.log("authToken",authToken);
  if(authToken){
    if(!data?.config_id){
      dispatch(ConfigTables(options)).then((res)=>{
        if(res.payload.status === 200 || res.payload.status ===201){
          dispatch(getConfigTables());
           toast.success(configurationStatus?.Create);
        }
      });
    }else{
      const configId = data?.config_id;
      dispatch(putConfigTables({ options, configId })).then((res) => {
          if (res.payload.status === 200 || res.payload.status == 201) {
            dispatch(getConfigTables());
            toast.success(configurationStatus?.Create);
          }
        });
    }
  } else {
      // console.log("no auth token");
    }
    };

    const handleReset = () =>{
      setOptions((prev) => ({
      ...prev,
      extract_text_without_tables: 0,
      workspace_id: workspace.workspace_id,
    }));
    }

  

    
  return (
    <div style={{ paddingInline: "30px" }}>
      <Loader isOpen={isLoading} />
      <h5>
       Extraction data with tables and without tables
      </h5>
       <div className="ProfanityCheckBox">
        <ConfigureChecks
          onClick={() =>
            handleSwitchChange(
              "extract_text_without_tables",
              options.extract_text_without_tables === 0
            )
          }
          option={options.extract_text_without_tables === 1}
          label="Extract data without tables"
          title = "Data will be extracted without tables"
        />
       </div>
      <div className="ButtonContainer">
        <Button label="Update" activeBtn onClick={handleSave} />
        <Button label="Reset" onClick={handleReset} />
      </div>
    </div>
  );
};

export default TablesPage;
