import axios from "axios";
import { HOST } from "./axios";

export const refreshAccessToken = async (refreshToken) => {
  try {
    const response = await axios.post(
      `${HOST}aura-studio/nnusers/accounts/token/refresh/`,
      refreshToken
    );
    sessionStorage.setItem("token", response?.data?.access);
    return response.data.access;
  } catch (error) {
    throw error;
  }
};
