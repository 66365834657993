import { createSlice } from "@reduxjs/toolkit";
import {
  CreateWorkspaceAction,
  DeleteWorkspaceAction,
  EditWorkspaceAction,
  WorkspaceDashboardAction,
  WorkspaceListAction,
} from "../actions/MainWorkspaceAction";

const workspaceSlice = createSlice({
  name: "workspace",
  initialState: {
    WorkspaceData: null,
    dashboardData: null,
    status: "idle",
    error: null,
    isLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(WorkspaceListAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(WorkspaceListAction.fulfilled, (state, action) => {
        state.status = "success";
        state.WorkspaceData = action.payload?.data;
        state.isLoading = false;
      })
      .addCase(WorkspaceListAction.rejected, (state, action) => {
        state.status = "failed";
        // state.error = action;
        state.isLoading = false;
      });
    builder
      .addCase(CreateWorkspaceAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(CreateWorkspaceAction.fulfilled, (state, action) => {
        state.status = "success";
        // state.WorkspaceData = action.payload?.data;
        state.isLoading = false;
      })
      .addCase(CreateWorkspaceAction.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.isLoading = false;
      });
    builder
      .addCase(EditWorkspaceAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(EditWorkspaceAction.fulfilled, (state, action) => {
        state.status = "success";
        // state.WorkspaceData = action.payload?.data;
        state.isLoading = false;
      })
      .addCase(EditWorkspaceAction.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.isLoading = false;
      });
    builder
      .addCase(DeleteWorkspaceAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(DeleteWorkspaceAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        // state.WorkspaceData = action.payload?.data;
        state.isLoading = false;
      })
      .addCase(DeleteWorkspaceAction.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.isLoading = false;
      });
    builder
      .addCase(WorkspaceDashboardAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(WorkspaceDashboardAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.dashboardData = payload;
        state.isLoading = false;
      })
      .addCase(WorkspaceDashboardAction.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.isLoading = false;
      });
  },
});

export default workspaceSlice.reducer;
