import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Label,
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import WorkspaceModal from "./newWorkspace.js";
import "./myworkspace.css";
import axios from "axios";
import WorkspacesIcon from "@mui/icons-material/Workspaces";

import s from "./Tables.module.scss";
import mock from "../tables/mock.js";
import searchIcon from "../../assets/tables/searchIcon.svg";
import cloudIcon from "../../assets/tables/cloudIcon.svg";
import printerIcon from "../../assets/tables/printerIcon.svg";
import optionsIcon from "../../assets/tables/optionsIcon.svg";
import funnelIcon from "../../assets/tables/funnelIcon.svg";

// import data_processing from "../../assets/data-processing_12594296.png";
import data_processing from "../../assets/data-processing_11938713.png";
import dustbin_image from "../../assets/icons8-delete-96.png";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import deleteBtn from "../../assets/deleteIcon.png";

import SwitchAccessShortcutIcon from "@mui/icons-material/SwitchAccessShortcut";

import editIcon from "../../assets/tables/editIcon.svg";
import deleteIcon from "../../assets/tables/deleteIcon.svg";
// import DeleteIcon from "@mui/icons-material/Delete";
import LoopIcon from "@mui/icons-material/Loop";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import StartTwoToneIcon from "@mui/icons-material/StartTwoTone";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteWorkspaceAction,
  WorkspaceDashboardAction,
  WorkspaceListAction,
} from "../../redux/actions/MainWorkspaceAction.js";
import Loader from "../../components/Common/LoadingModal/LoadingModal.js";
import { toast } from "react-toastify";
import DeleteModel from "../../components/Common/DeleteModal/DeleteModal.js";
import nodatafound from "../../assets/No_Data_Found.jpg";
import "./cards.css";
const WorkSpace = ({ onWorkspaceSelection }) => {
  const { WorkspaceData, dashboardData, isLoading, status } = useSelector(
    (state) => state.WorkspaceSlice
  );

  // console.log("dashboardData----->>", dashboardData);

  // console.log("WorkspaceData-->>", WorkspaceData);
  const [sortedDataArray, setSortedDataArray] = useState([]);

  const history = useHistory();
  const dispatch = useDispatch();
  const [firstTableCurrentPage, setFirstTableCurrentPage] = useState(0);
  const [firstTablePagesCount, setfirstTablePagesCount] = useState();
  const [updateModal, setupdateModal] = useState(false);
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const [editWorkspacesValue, setEditWorkspacesValue] = useState({});
  const [isChecked, setisChecked] = useState([]);
  const [isOpenDeleteModel, setIsOpenDeleteModel] = useState(false);
  const [workspaceDeleteData, setworkspaceDeleteData] = useState({});
  // console.log("isOpenDeleteModel---->>>", isOpenDeleteModel);
  const handleOpenDeleteModel = (deleteValue) => {
    setIsOpenDeleteModel(true);
    setworkspaceDeleteData((prevOptions) => ({
      ...prevOptions,
      workspace_id: deleteValue.id,
      workspace_name: deleteValue.workspace_name,
      short_description: deleteValue.short_description,
      detail_description: deleteValue.detail_description,
      workspace_type: deleteValue.workspace_type,
      is_deleted: 1,
    }));
  };
  useEffect(() => {
    const compareDates = (a, b) => {
      const dateA = new Date(a.created_on);
      const dateB = new Date(b.created_on);
      // return dateA - dateB;
      return dateB - dateA;
    };
    setSortedDataArray(WorkspaceData?.slice()?.sort(compareDates));
  }, [WorkspaceData]);

  const handleCloseDeleteModel = () => {
    setIsOpenDeleteModel(!isOpenDeleteModel);
  };

  const toggleUpdateModal = () => setupdateModal(!updateModal);
  const pageSize = 4;
  useEffect(() => {
    if (sortedDataArray) {
      setfirstTablePagesCount(Math.ceil(sortedDataArray?.length / pageSize));
    }
  }, [sortedDataArray]);

  useEffect(() => {
    dispatch(WorkspaceListAction());
    dispatch(WorkspaceDashboardAction());
  }, []);

  const setFirstTablePage = (e, index) => {
    e.preventDefault();
    setFirstTableCurrentPage(index);
  };

  // Updated handleCheckboxChange function
  const handleCheckboxChange = (event, workspace) => {
    // console.log("clicked");
    setisChecked(event.target.checked);
    event.target.checked && onWorkspaceSelection(workspace?.workspace_name);
    let workspaceData = {
      workspace_id: workspace.id,
      workspace_name: workspace.workspace_name,
    };
    sessionStorage.setItem("workspace", JSON.stringify(workspaceData));
    history.push("/template/tables");
    // console.log("window.location", window.location);
  };
  const handleWorkspaceCreated = (workspaceData) => {
    // Add newly created workspace to firstTable state
    setSortedDataArray((prevTable) => [
      ...prevTable,
      {
        id: workspaceData.id, // Check if workspaceData.id is undefined
        name: workspaceData.workspaceName,
        shortDescription: workspaceData.shortDescription,
        createdOn: new Date().toISOString(),
      },
    ]);
    toggleModal();
  };
  function flipCard(card) {
    card.querySelector(".card-inner").style.transform = "rotateY(180deg)";
  }

  const DateFormat = () => {
    const date = new Date("2024-04-12T04:42:02.978127Z");
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")} ${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

    return formattedDate;
  };

  const handleEdit = (editvalues) => {
    // console.log("editvalues-------->>>>", editvalues);
    setEditWorkspacesValue(editvalues);
    toggleUpdateModal();
  };

  const handleDeleteWorkspace = () => {
    dispatch(DeleteWorkspaceAction(workspaceDeleteData)).then((res) => {
      if (res.payload) {
        toast.success("Workspace deleted Succesfully.");
        handleCloseDeleteModel();
        dispatch(WorkspaceListAction());
        dispatch(WorkspaceDashboardAction());
      }
    });
  };

  const handleNavigateQnA = (item) => {
    history.push("/template/QnA");
  };
  return (
    <div>
      <Loader isOpen={isLoading} />
      <DeleteModel
        isOpen={isOpenDeleteModel}
        handleCloseDeleteModel={handleCloseDeleteModel}
        onClick={handleDeleteWorkspace}
        deleteType="workspace"
      />
      {/* <ContentHeader toggleModal={toggleModal}/> */}
      <Modal
        isOpen={modal}
        toggle={toggleModal}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={toggleModal}>New My Workspace Setup</ModalHeader>
        <ModalBody>
          <WorkspaceModal
            isOpen={modal}
            toggleModal={toggleModal}
            // onWorkspaceCreated={handleWorkspaceCreated}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={updateModal}
        toggle={toggleUpdateModal}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={toggleUpdateModal}>
          {"Edit My Workspace > Setup"}
        </ModalHeader>
        <ModalBody>
          <WorkspaceModal
            isEdit={true}
            isOpen={updateModal}
            toggleModal={toggleUpdateModal}
            editWorkspacesValue={editWorkspacesValue}
            onWorkspaceCreated={handleWorkspaceCreated}
          />
        </ModalBody>
      </Modal>
      <div className={s.cardMainContainer}>
        <div className={`${s.cardContainer} ${s.cardContainer1}`}>
          <div className={s.contentContainer}>
            <WorkspacesIcon className={s.cardContainerText1} />
            <div>
              <h5 className={`${s.cardContainerText1} ${s.cardContainerText}`}>
                Total Workspaces
              </h5>
              <h1>{dashboardData?.total_workspaces_count}</h1>
            </div>
          </div>
        </div>
        <div className={`${s.cardContainer} ${s.cardContainer2}`}>
          <div className={s.contentContainer}>
            <ToggleOnIcon className={s.cardContainerText2} />
            <div>
              <h5 className={`${s.cardContainerText2} ${s.cardContainerText}`}>
                Active Workspaces
              </h5>
              <h1>{dashboardData?.active_workspaces_count}</h1>
            </div>
          </div>
        </div>
        <div className={`${s.cardContainer} ${s.cardContainer3}`}>
          <div className={s.contentContainer}>
            <ToggleOffIcon className={s.cardContainerText3} />
            <div>
              <h5 className={`${s.cardContainerText3} ${s.cardContainerText}`}>
                Inactive Workspaces
              </h5>
              <h1>{dashboardData?.inactive_workspaces_count}</h1>
            </div>
          </div>
        </div>
        <div className={`${s.cardContainer} ${s.cardContainer4}`}>
          <div className={s.contentContainer}>
            <FolderCopyIcon className={s.cardContainerText4} />
            <div>
              <h5 className={`${s.cardContainerText4} ${s.cardContainerText}`}>
                Total File Processed
              </h5>
              <h1>{dashboardData?.processed_files_count}</h1>
            </div>
          </div>
        </div>
      </div>

      <Row className="mb-4">
        <Col>
          <Widget>
            <div className={s.tableHeader}>
              <div className={s.tableTitle}>
                <div className="headline-2">Active Workspaces</div>
                {/* <div className="d-flex">
                <a href="/#">
                  <img src={searchIcon} alt="Search" />
                </a>
                <a href="/#">
                  <img
                    className="d-none d-sm-block"
                    src={cloudIcon}
                    alt="Cloud"
                  />
                </a>
                <a href="/#">
                  <img src={printerIcon} alt="Printer" />
                </a>
                <a href="/#">
                  <img
                    className="d-none d-sm-block"
                    src={optionsIcon}
                    alt="Options"
                  />
                </a>
                <a href="/#">
                  <img src={funnelIcon} alt="Funnel" />
                </a>
              </div> */}
              </div>
              <div>
                <Button
                  color="primary"
                  onClick={toggleModal}
                  className={s.Backtoworkspace}
                >
                  Create New Workspace
                </Button>
              </div>
            </div>
            <div className="widget-table-overflow display-flex">
              {!WorkspaceData?.length > 0 ? (
                <div className={s.nodatafoundbox}>
                  <img
                    src={process.env.PUBLIC_URL + nodatafound}
                    height={230}
                    width={300}
                    alt="nodatafound"
                  />
                  <h5>No data found please create new workspace</h5>
                </div>
              ) : (
                <>
                  <Table
                    className={`table-striped table-borderless table-hover ${s.statesTable}`}
                    responsive
                    hover
                  >
                    <thead>
                      <tr>
                        {/* <th className={s.checkboxCol}></th> */}
                        {/* <th className={s.ColBox}></th> */}
                        <th
                          style={{ width: "16%" }}
                          scope="row"
                          className={s.ColBox}
                        >
                          NAME
                        </th>
                        <th
                          style={{ width: "20%" }}
                          scope="row"
                          s
                          className={s.ColBox}
                        >
                          Short description
                        </th>
                        <th
                          style={{ width: "16%" }}
                          scope="row"
                          s
                          className={s.ColBox}
                        >
                          created On
                        </th>
                        <th
                          style={{ width: "35%" }}
                          scope="row"
                          s
                          className={s.ColBox}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(sortedDataArray) &&
                        sortedDataArray
                          ?.slice(
                            firstTableCurrentPage * pageSize,
                            (firstTableCurrentPage + 1) * pageSize
                          )
                          ?.map((item) => (
                            <tr key={uuidv4()}>
                              {/* <td
                                style={{
                                  color: "#4d53e0",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => handleCheckboxChange(e, item)}
                              >
                                Initiate Data processing
                              </td> */}
                              {/* className="d-flex align-items-center" */}
                              <td>
                                <span
                                  onClick={() => {
                                    handleEdit(item);
                                  }}
                                  // className="ml-3"
                                  style={{
                                    color: "#0000ca",
                                    cursor: "pointer",
                                  }}
                                >
                                  {item?.workspace_name}
                                </span>
                              </td>
                              <td className={s.truncateText}>
                                {item?.short_description}
                              </td>
                              <td>{DateFormat(item?.created_on)}</td>
                              <td>
                                {item.is_deleted == 0 && (
                                  <div className={s.actionButtons}>
                                    <p
                                      onClick={(e) =>
                                        handleCheckboxChange(e, item)
                                      }
                                      className={s.editIconbox}
                                    >
                                      <img
                                        className={s.actionIcon}
                                        alt="DP"
                                        src={
                                          // process.env.PUBLIC_URL +
                                          data_processing
                                        }
                                      />
                                      Initiate Data Processing
                                    </p>
                                    <p
                                      className={s.deleteIcon}
                                      style={{
                                        color: "#ff3f3f",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleOpenDeleteModel(item)
                                      }
                                    >
                                      <img
                                        src={deleteBtn}
                                        alt="delete"
                                        height={21}
                                        style={{ marginRight: "5px" }}
                                      />
                                      Delete
                                    </p>
                                    {/* <p
                                      // className={s.deleteIcon}
                                      style={{
                                        // color: "#ff3f3f",
                                        color: "#327aff",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleNavigateQnA(item)}
                                    >
                                      Q and A
                                    </p> */}
                                  </div>
                                )}
                              </td>
                              {/* <td>{item.state}</td> */}
                            </tr>
                          ))}
                    </tbody>
                  </Table>
                  <Pagination
                    className="pagination-borderless"
                    aria-label="Page navigation example"
                  >
                    <PaginationItem disabled={firstTableCurrentPage <= 0}>
                      <PaginationLink
                        onClick={(e) =>
                          setFirstTablePage(e, firstTableCurrentPage - 1)
                        }
                        previous
                        href="#top"
                      />
                    </PaginationItem>
                    {[...Array(firstTablePagesCount)]?.map((page, i) => (
                      <PaginationItem
                        active={i === firstTableCurrentPage}
                        key={i}
                      >
                        <PaginationLink
                          onClick={(e) => setFirstTablePage(e, i)}
                          href="#top"
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem
                      disabled={
                        firstTableCurrentPage >= firstTablePagesCount - 1
                      }
                    >
                      <PaginationLink
                        onClick={(e) =>
                          setFirstTablePage(e, firstTableCurrentPage + 1)
                        }
                        next
                        href="#top"
                      />
                    </PaginationItem>
                  </Pagination>
                </>
              )}
            </div>
          </Widget>
        </Col>
      </Row>
    </div>
  );
};

export default WorkSpace;
