// PipelineExecution.js

import React, { useEffect, useState } from "react";
import "./pipelineexecutions.scss";
import ForwardIcon from "@mui/icons-material/Forward";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Button from "../../components/Button/Button";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Dropdown from "../../components/Common/ShowConfiguration/ConfigrationDetails";
import sampleJsonData from "./sampleJsonView";
// import rightArrow from "../../assets/right-arrow.png";
// import rightArrow from "../../assets/arrow_5948669.png";
import rightArrow from "../../assets/right-arrow.png";
import { useDispatch, useSelector } from "react-redux";
import {
  ExtractTableAction,
  PerformCurationAllAction,
  ValidationExecutionFlow,
} from "../../redux/actions/PipelineExecutionAction";
import { toast } from "react-toastify";
import Loader from "../../components/Common/LoadingModal/LoadingModal";
import {
  getConfigProfanityCheck,
  getConfigSensetiveInfo,
  getConfigTextCleaning,
  getConfigTextQuality,
} from "../../redux/actions/subWorkspaceAction";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ShowConfiguration from "../../components/Common/ShowConfiguration/ShowConfiguration";
import { tabelExtractionExtension } from "../../ENUM";
const CurationProcessStep = ({ srNo, label, ForwardIcon, configs }) => {
  return (
    <div className="pipeline-box">
      <div className="pipeline-step">
        <div className="pipeline-button-box">
          <span className="BorderBottom"></span>
          <div className="pipeline-button" style={{ fontWeight: "600" }}>
            {label}
          </div>
          <div className="step-bottom">
            <p className="step-button">Step</p>
            <ShowConfiguration configs={configs} />
          </div>
        </div>
        {ForwardIcon && (
          <img alt="right-arrow" src={rightArrow} width={50} height="100%" />
        )}
      </div>
    </div>
  );
};

const PipelineExecution = () => {
  const { isLoading, isFilePresent, fileId, fileData, fileName } = useSelector(
    (state) => state.PipelineExecutionSlice
  );
  const [tablefileExtension, settablefileExtension] = useState("");

  const storedFileId = sessionStorage.getItem("file_id");
  const dispatch = useDispatch();
  const {
    textCleaningConfigs,
    textQualityConfigs,
    sensetiveInfoConfigs,
    profanityCheckConfig,
  } = useSelector((state) => state.ConfigurationSlice);

  useEffect(() => {
    dispatch(getConfigTextCleaning());
    dispatch(getConfigTextQuality());
    dispatch(getConfigSensetiveInfo());
    dispatch(getConfigProfanityCheck());
  }, []);

  useEffect(() => {
    const getFileExtension = (fileName) => {
      const parts = fileName?.split(".");
      return parts?.length > 1 ? parts.pop() : "";
    };

    settablefileExtension(getFileExtension(fileName));
  }, [fileName]);

  const [isValidationExecutionFlow, setisValidationExecutionFlow] =
    useState(false);
  const [isFileIdPresent, setIsFileIdPresent] = useState(false);
  const [tableExtracted, setisTableExtracted] = useState(false);

  useEffect(() => {
    if (storedFileId) {
      setIsFileIdPresent(true);
    }
  }, [storedFileId]);

  let file_id = !Array.isArray(fileId) ? fileId : 1;

  const handleAlPipelineExecution = async () => {
    let allSuccessful = true;
    if (!fileData?.length > 0) {
      dispatch(PerformCurationAllAction(fileId)).then((res) => {
        if (res.payload.status === 201 || res.payload.status === 200) {
          toast.success("Curation performed successfully.");
        }
      });
    } else {
      for (const file of fileData) {
        // if (!file.IsPipelineExecuted) {
        try {
          const res = await dispatch(PerformCurationAllAction(file.id));
          if (res.payload.status !== 200 && res.payload.status !== 201) {
            allSuccessful = false;
            break;
          }
        } catch (error) {
          console.error("Error extracting file:", error);
          allSuccessful = false;
          break;
        }
        // }
      }
      if (allSuccessful) {
        toast.success("Curation performed successfully.");
      }
    }
  };

  const handleTableProcessing = async () => {
    let allSuccessful = true;

    if (fileData && fileData.length > 0) {
      for (const file of fileData) {
        const fileExtension = file.original_file_name?.split(".")?.pop();
        if (!tabelExtractionExtension.includes(fileExtension)) {
          try {
            const res = await dispatch(ExtractTableAction(file.id));
            if (res.payload.status !== 200 && res.payload.status !== 201) {
              allSuccessful = false;
              break;
            }
          } catch (error) {
            console.error("Error extracting file:", error);
            allSuccessful = false;
            break;
          }
        }
      }

      if (allSuccessful) {
        toast.success("Tables Extracted Successfully.");
      } else {
        toast.error("Failed to extract tables for some files.");
      }
    } else {
      try {
        const res = await dispatch(ExtractTableAction(fileId));

        if (res.payload.status === 200 || res.payload.status === 201) {
          toast.success("Tables Extracted Successfully.");
        } else {
          if (res.payload.response.status === 400) {
            toast.error(
              res.payload?.response?.data?.error ||
                "No table found for selected file."
            );
          } else {
            toast.error("Failed to extract table.");
          }
        }
      } catch (error) {
        console.error("Error extracting file:", error);
        toast.error("Error occurred while extracting the table.");
      }
    }
  };

  useEffect(() => {
    dispatch(ValidationExecutionFlow()).then((res) => {
      if (res.payload.status === 200 || res.payload.status === 201) {
        setisValidationExecutionFlow(true);
      }
    });
  }, []);

  const handleJsonView = () => {
    const newTab = window.open();
    newTab.document.write(
      `<pre>${JSON.stringify(sampleJsonData, null, 2)}</pre>`
    );
    newTab.document.close();
  };
  // const fileDetails = sessionStorage.getItem('selectedFilename','fileid');
  // console.log("fileDetails", fileDetails);
  return (
    <>
      <Loader isOpen={isLoading} />
      <div className="MainContainer">
        <div className="HeaderContainer">
          <h5 className="CurationText">Curation Process Steps</h5>
        </div>
        <div className="pipeline-container">
          <div className="pipeline-inner-container">
            <CurationProcessStep
              srNo={1}
              label={"Text Cleaning"}
              ForwardIcon={true}
              configs={textCleaningConfigs}
            />
            <CurationProcessStep
              srNo={2}
              label={"Text Quality"}
              ForwardIcon={true}
              configs={textQualityConfigs}
            />
            <CurationProcessStep
              srNo={4}
              label={"Sensitive Info​"}
              ForwardIcon={true}
              configs={sensetiveInfoConfigs}
            />
            <CurationProcessStep
              srNo={5}
              label={"Profanity Check"}
              ForwardIcon={false}
              configs={profanityCheckConfig}
            />
          </div>

          <button
            className={`All-btn ${isFilePresent ? "" : "all-btn-disabled"}`}
            style={{ cursor: isFilePresent ? "pointer" : "not-allowed" }}
            onClick={isFilePresent ? handleAlPipelineExecution : null}
            disabled={!isFilePresent}
          >
            All
          </button>
        </div>

        <div className="table-extraction-box">
          <h5
            style={{
              paddingInline: "20px",
              marginTop: "20px",
              marginLeft: "10px",
            }}
          >
            Tables Extraction Process
          </h5>
          <div className="pipeline-step table-extraction">
            <div className="pipeline-button-box">
              <div className="pipeline-button">Extract Table</div>
              <span className="BorderBottom"></span>
              <div className="step-bottom">
                <button
                  disabled={
                    !isFilePresent ||
                    tabelExtractionExtension.includes(tablefileExtension)
                  }
                  className={`GoBtn ${
                    isFilePresent &&
                    !tabelExtractionExtension.includes(tablefileExtension)
                      ? ""
                      : "all-btn-disabled"
                  }`}
                  style={{ cursor: isFilePresent ? "pointer" : "not-allowed" }}
                  onClick={handleTableProcessing} // Re-enabled onClick handler
                >
                  Extract
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PipelineExecution;
