import React, { useState, useRef, useEffect } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Button from "../../../components/Button/Button";
import "../FineTuneJson.scss";
import { useDispatch, useSelector } from "react-redux";
import pdfImage from "../../../assets/pdfimg.png";
import { getFoldeAndFileListAction } from "../../../redux/actions/dataIngestionActions";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const AzureOpenAiRaft = () => {
  const { folderFileList } = useSelector((state) => state.dataIngestionSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFoldeAndFileListAction());
  }, [dispatch]);

  const [errors, setErrors] = useState({});
  const [firstformData, setfirstFormData] = useState({
    Questionsperchunk: "",
    llmmodel: "",
    embeddingsmodel: "",
    chunksize: "",
    Distractordocs: "",
    openApiKey: "",
  });

  const [secondformdata, setSecondFormdata] = useState({
    model: "",
    epoch: "",
    learningRateMultiplier: "",
    batchSize: "",
  });
  const [pdfFiles, setPdfFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [showSecondForm, setShowSecondForm] = useState(false);
  const [showApiKey, setShowApiKey] = useState(false); // state to manage visiblity icon

  useEffect(() => {
    if (folderFileList) {
      const allFiles = [
        ...(folderFileList?.files || []),
        ...(folderFileList?.folders || []).flatMap(
          (folder) => folder?.files || []
        ),
      ];

      const pdfs = allFiles.filter((file) =>
        file?.original_file_name.toLowerCase().endsWith(".pdf")
      );

      setPdfFiles(pdfs);
    }
  }, [folderFileList]);

  const handleFileSelection = (file) => {
    setSelectedFile(file.id === selectedFile?.id ? null : file);
  };

  const handlefirstFormChange = (event) => {
    const { name, value } = event.target;
    setfirstFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };
  const handleSecondFormChange = (event) => {
    const { name, value } = event.target;
    setSecondFormdata((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };
  const validateFirstForm = () => {
    const newErrors = {};
    if (!firstformData.Questionsperchunk) {
      newErrors.Questionsperchunk = "Questions per chunk is required";
    } else if (
      isNaN(firstformData.Questionsperchunk) ||
      firstformData.Questionsperchunk <= 0
    ) {
      newErrors.Questionsperchunk = "Questions must be a positive number";
    }
    if (!firstformData.llmmodel) {
      newErrors.llmmodel = "LLM Model is required";
    }
    if (!firstformData.chunksize) {
      newErrors.chunksize = "Chunk Size is required";
    } else if (isNaN(firstformData.chunksize) || firstformData.chunksize <= 0) {
      newErrors.chunksize = "Chunk Size must be a positive number";
    }
    if (!firstformData.embeddingsmodel) {
      newErrors.embeddingsmodel = "Embedding Model is required";
    }
    if (!firstformData.Distractordocs) {
      newErrors.Distractordocs = "Distractor Docs is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateSecondForm = () => {
    const newErrors = {};
    if (!secondformdata.model) {
      newErrors.model = "Model Selection is required";
    }
    if (!secondformdata.epoch) {
      newErrors.epoch = "Epoch is required";
    } else if (isNaN(secondformdata.epoch) || secondformdata.epoch <= 0) {
      newErrors.epoch = "Epoch must be a positive number";
    }
    if (!secondformdata.learningRateMultiplier) {
      newErrors.learningRateMultiplier = "Learning Rate Multiplier is required";
    } else if (
      isNaN(secondformdata.learningRateMultiplier) ||
      secondformdata.learningRateMultiplier <= 0
    ) {
      newErrors.learningRateMultiplier =
        "Learning Rate Multiplier must be a positive number";
    }
    if (!secondformdata.batchSize) {
      newErrors.batchSize = "Batch Size is required";
    } else if (
      isNaN(secondformdata.batchSize) ||
      secondformdata.batchSize <= 0
    ) {
      newErrors.batchSize = "Batch Size must be a positive number";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleNextClick = (event) => {
    event.preventDefault();
    if (validateFirstForm()) {
      console.log("Form submitted:", firstformData);
      setShowSecondForm(true);
    } else {
      console.log("Form has errors");
    }
  };

  const handleBack = () => {
    setShowSecondForm(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateSecondForm()) {
      console.log("Form submitted:", secondformdata);
      // Proceed with form submission
    } else {
      console.log("Form has errors");
    }
  };

  const toggleApiKeyVisibility = () => {
    setShowApiKey(!showApiKey);
  };

  return (
    <div className="Json-Container">
      <div className="Json-file-container">
        <h5> Choose processed File </h5>
        <List style={{ gap: "20px" }}>
          {pdfFiles.map((file, index) => (
            <React.Fragment style={{ gap: "20px" }} key={file.id}>
              <ListItem style={{ gap: "20px" }}>
                <Checkbox
                  checked={file.id === selectedFile?.id}
                  onChange={() => handleFileSelection(file)}
                />
                <img src={pdfImage} alt="pdf icon" height={28} />
                <ListItemText primary={file.original_file_name} />
              </ListItem>
              {index < pdfFiles.length - 1 && (
                <Divider variant="fullWidth" component="li" />
              )}
            </React.Fragment>
          ))}
        </List>
      </div>
      <div className="json-seprater-line"></div>
      <div className="raft-json-form-container">
        {!showSecondForm ? (
          <>
            <h5>Select JSON Configurations</h5>
            <form onSubmit={(e) => e.preventDefault()}>
              <TextField
                fullWidth
                margin="normal"
                label="Questions per chunk"
                name="Questionsperchunk"
                value={firstformData.Questionsperchunk}
                size="small"
                onChange={handlefirstFormChange}
                type="number"
                error={!!errors.Questionsperchunk}
                helperText={errors.Questionsperchunk}
              />
              <FormControl fullWidth margin="normal" error={!!errors.llmmodel}>
                <InputLabel>LLM Model</InputLabel>
                <Select
                  name="llmmodel"
                  value={firstformData.llmmodel}
                  onChange={handlefirstFormChange}
                  label="LLM Model"
                  size="small"
                >
                  <MenuItem value="gpt-3.5-turbo">GPT-3.5</MenuItem>
                  <MenuItem value="gpt-4">GPT-4</MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth
                margin="normal"
                label="Chunk Size"
                name="chunksize"
                type="number"
                size="small"
                value={firstformData.chunksize}
                onChange={handlefirstFormChange}
                error={!!errors.chunksize}
                helperText={errors.chunksize}
              />
              <FormControl
                fullWidth
                margin="normal"
                error={!!errors.embeddingsmodel}
              >
                <InputLabel>Embedding Model</InputLabel>
                <Select
                  name="embeddingsmodel"
                  value={firstformData.embeddingsmodel}
                  onChange={handlefirstFormChange}
                  label="Embedding Model"
                  size="small"
                >
                  <MenuItem value="gpt-3.5-turbo">
                    text-embedding-3-large
                  </MenuItem>
                  <MenuItem value="babbage-002">
                    text-embedding-3-small
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth
                margin="normal"
                label="Distractor Docs"
                name="Distractordocs"
                type="number"
                size="small"
                value={firstformData.Distractordocs}
                onChange={handlefirstFormChange}
                error={!!errors.Distractordocs}
                helperText={errors.Distractordocs}
              />
              <div className="json-save-button">
                <Button
                  label={"Next"}
                  activeBtn={true}
                  onClick={handleNextClick}
                  type="button"
                />
              </div>
            </form>
          </>
        ) : (
          <>
            <h5>Select Fine Tune Configurations</h5>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                margin="normal"
                label="OpenAI API Key"
                name="openApiKey"
                value={firstformData.openApiKey}
                size="small"
                onChange={handlefirstFormChange}
                type={showApiKey ? "text" : "password"}
                error={!!errors.openApiKey}
                helperText={errors.openApiKey}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle api key visibility"
                        onClick={toggleApiKeyVisibility}
                        edge="end"
                      >
                        {showApiKey ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormControl fullWidth margin="normal" error={!!errors.model}>
                <InputLabel>Model Selection</InputLabel>
                <Select
                  name="model"
                  value={secondformdata.model}
                  onChange={handleSecondFormChange}
                  label="Model Selection"
                  size="small"
                >
                  <MenuItem value="gpt-3.5-turbo">gpt-3.5-turbo</MenuItem>
                  <MenuItem value="gpt-4">babbage-002</MenuItem>
                  <MenuItem value="gpt-4">davinci-002</MenuItem>
                  {/* <MenuItem value="gpt-4">GPT-4</MenuItem> */}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                margin="normal"
                label="Epoch"
                name="epoch"
                type="number"
                size="small"
                value={secondformdata.epoch}
                onChange={handleSecondFormChange}
                error={!!errors.epoch}
                helperText={errors.epoch}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Learning Rate Multiplier"
                name="learningRateMultiplier"
                type="number"
                size="small"
                value={secondformdata.learningRateMultiplier}
                onChange={handleSecondFormChange}
                error={!!errors.learningRateMultiplier}
                helperText={errors.learningRateMultiplier}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Batch Size"
                name="batchSize"
                type="number"
                size="small"
                value={secondformdata.batchSize}
                onChange={handleSecondFormChange}
                error={!!errors.batchSize}
                helperText={errors.batchSize}
              />
              <div className="json-save-button">
                <Button label={"Back"} activeBtn={true} onClick={handleBack} />
                <Button label={"Save"} activeBtn={true} type="submit" />
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default AzureOpenAiRaft;
