import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PDFRender from "../../components/Common/PDFRenderer/PDFRender";
import ExtractPDF from "../../components/Common/PDFRenderer/ExtractPDF";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateKeywordsAction,
  CreateMetaDataAction,
  CreateTopicAction,
  CreateTopicMetadataAction,
  GetCreateKeywordsAction,
  getCreateTopicAction,
  getCreateTopicMetadataAction,
  GetMetaDataAction,
} from "../../redux/actions/DataOutputActions";
import "./MetaDataForm.scss";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import ConfigureChecks from "../../components/Common/ConfigureChecks/ConfigureChecks";
import TopicGenerationPage from "./TopicGenerations";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import NoFileAvailable from "../DataReview/NoFileAvailable";

function MedataForm() {
  const { visualizeKeywords, fileIDSerial } = useSelector(
    (state) => state.DataOutputSlice
  );
  console.log("visualizeKeywords", visualizeKeywords);
  const { fileId, isFilePresent, fileName, fileData, folderId, imgId } =
    useSelector((state) => state.PipelineExecutionSlice);
  const { pathname } = useLocation();
  const [showKeywordGeneration, setShowKeywordGeneration] = useState(true);

  const [showTopicGeneration, setShowTopicGeneration] = useState(false);
  const dispatch = useDispatch();

  // state for

  const [keywordConfig, setKeywordConfig] = useState({
    top_n: "",
    keyphrase_ngram_range: "",
    diversity: "",
    number_of_candidates: "",
    use_mmr: false,
    use_max_sum: false,
  });
  // console.log("keywordConfig-->>", keywordConfig);

  const [topicConfig, setTopicConfig] = useState({
    // CTFIDF Model
    ctfidf_reduce_frequent_words: false,
    ctfidf_bm25_weighting: false,

    // Representation Model
    representation_top_n_words: 0,
    representation_diversity: 0,

    // Embedding Model
    embedding_model: 0,

    // Vectorizer Model
    vectorizer_stop_words: "",
    vectorizer_lowercase: 0,

    // UMAP Model
    umap_n_neighbors: 2,
    umap_n_components: 1,
    umap_min_dist: 0.0,
    umap_metric: "",
    umap_learning_rate: 0.05,

    // HDB Scan Model
    hdbscan_min_cluster_size: 2,
    hdbscan_cluster_selection_method: "",
    hdbscan_metric: "",
    hdbscan_allow_single_cluster: false,
    hdbscan_prediction_data: false,
  });

  // console.log("keywordConfig", keywordConfig);

  const handleKeywordCheckbox = (event) => {
    setShowKeywordGeneration(event.target.checked);
  };
  const handleTopicCheckbox = (event) => {
    setShowTopicGeneration(event.target.checked);
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setKeywordConfig((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleTopicConfigChange = (event) => {
    const { name, value, type, checked } = event.target;
    setTopicConfig((prevConfig) => ({
      ...prevConfig,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleCreateChunks = async () => {
    // const file_id = pathname === "/template/Metadata" && imgId ? imgId : fileId;

    if (showKeywordGeneration) {
      const keywordValue = {
        file_id: fileData ? fileData[fileIDSerial - 1].id : fileId,
        ...keywordConfig,
      };
      // console.log("Dispatching CreateKeywordsAction with value:", keywordValue);

      await dispatch(CreateKeywordsAction(keywordValue))
        .then((res) => {
          if (res.payload.status === 200 || res.payload.status === 201) {
            const keyword_config = showKeywordGeneration ? 1 : 0;
            const metadataValue = {
              file_id: fileData ? fileData[fileIDSerial - 1].id : fileId,
              keyword_config: keyword_config,
            };
            return dispatch(CreateMetaDataAction(metadataValue)); // Return the promise
          } else {
            console.error(
              "CreateKeywordsAction failed:",
              res.payload.status,
              res.payload.data
            );
            return Promise.reject(new Error("Keyword creation failed")); // Reject the chain
          }
        })
        .catch((error) => {
          console.error("Error creating keywords:", error);
        });
    }

    if (showTopicGeneration) {
      const topicValue = {
        file_id: fileData ? fileData[fileIDSerial - 1].id : fileId,
        ...topicConfig,
      };
      // console.log("Dispatching CreateTopicAction with value:", topicValue);

      await dispatch(CreateTopicAction(topicValue))
        .then((res) => {
          if (res.payload.status === 200 || res.payload.status === 201) {
            const TopicmetadataValue = {
              file_id: fileData ? fileData[fileIDSerial - 1].id : fileId,
            };
            return dispatch(CreateTopicMetadataAction(TopicmetadataValue)); // Return the promise
          } else {
            console.error(
              "CreateTopicAction failed:",
              res.payload.status,
              res.payload.data
            );
            return Promise.reject(new Error("Topic creation failed")); // Reject the chain
          }
        })
        .catch((error) => {
          console.error("Error creating topics:", error);
        });
    }
  };

  useEffect(() => {
    if (isFilePresent && (showKeywordGeneration || showTopicGeneration)) {
      if (showKeywordGeneration) {
        dispatch(GetCreateKeywordsAction(fileId));
      }
      if (showTopicGeneration) {
        dispatch(getCreateTopicAction(fileId));
      }
    }
  }, [isFilePresent, fileId, showKeywordGeneration, showTopicGeneration]);

  return (
    <>
      {!isFilePresent ? (
        <NoFileAvailable isChunksPage={true} />
      ) : (
        <>
          <div className="meta-form-container">
            <div className="meta-form-checkbox">
              <div>
                <FormControlLabel
                  value="KeywordGeneration"
                  control={
                    <Checkbox
                      checked={showKeywordGeneration}
                      onChange={handleKeywordCheckbox}
                    />
                  }
                  label="Keyword generation"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="TopicGeneration"
                  control={
                    <Checkbox
                      checked={showTopicGeneration}
                      onChange={handleTopicCheckbox}
                    />
                  }
                  label="Topic Generation"
                  labelPlacement="end"
                />
              </div>
              <div>
                <Button
                  label="Generate"
                  activeBtn={showKeywordGeneration || showTopicGeneration}
                  onClick={handleCreateChunks}
                />
              </div>
            </div>

            <div className="meta-form-box">
              <div className={"keyword-generation-inputs-box"}>
                {showKeywordGeneration && (
                  <>
                    <h5 style={{ marginTop: "18px" }}>
                      Keyword Generation Configuration
                    </h5>
                    <div className="keyword-generation-inputs">
                      <TextField
                        label="Top N"
                        type="number"
                        sx={{ width: "10%" }}
                        id="Top N"
                        placeholder="Ex: 1"
                        name="top_n"
                        value={keywordConfig.top_n}
                        onChange={handleInputChange}
                        size="small"
                      />
                      <TextField
                        label="Keyphrase N-gram Range"
                        type="text"
                        sx={{ width: "20%" }}
                        id="Keyphrase N-gram Range"
                        name="keyphrase_ngram_range"
                        placeholder="Ex: (1,2)"
                        value={keywordConfig.keyphrase_ngram_range}
                        onChange={handleInputChange}
                        size="small"
                      />
                      <TextField
                        label="Diversity"
                        type="number"
                        id="Diversity"
                        sx={{ width: "10%" }}
                        name="diversity"
                        placeholder="Ex: 0.3"
                        value={keywordConfig.diversity}
                        onChange={handleInputChange}
                        size="small"
                      />
                      <TextField
                        label="Number of Candidates"
                        type="number"
                        id="Number of Candidates"
                        placeholder="Ex: 10"
                        name="number_of_candidates"
                        value={keywordConfig.number_of_candidates}
                        onChange={handleInputChange}
                        size="small"
                      />
                      <FormControlLabel
                        value="Use-MMR"
                        sx={{ textWrap: "nowrap" }}
                        control={
                          <Checkbox
                            checked={keywordConfig.use_mmr}
                            onChange={handleInputChange}
                            name="use_mmr"
                          />
                        }
                        label="Use MMR"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        sx={{ textWrap: "nowrap" }}
                        value="Use-MaxSum"
                        control={
                          <Checkbox
                            checked={keywordConfig.use_max_sum}
                            onChange={handleInputChange}
                            name="use_max_sum"
                          />
                        }
                        label="Use MaxSum"
                        labelPlacement="end"
                      />
                    </div>
                  </>
                )}
              </div>
              {showTopicGeneration && (
                <div className="topicname-generation-inputs-box">
                  <h5 style={{ marginTop: "18px", paddingInline: "30px" }}>
                    Topic Generation Configuration
                    <Tooltip
                      Content
                      title="For best results, select all the models"
                      arrow
                    >
                      <InfoOutlinedIcon
                        fontSize="small"
                        style={{ marginLeft: "5px", cursor: "pointer" }}
                      />
                    </Tooltip>
                  </h5>
                  <div className="topic-generation-box">
                    <div className="first-row-models">
                      <div className="embedding-model-container">
                        <p className="topic-generation-subtitle">
                          CTFIDF Model{" "}
                          <Tooltip
                            Content
                            title="BERTopic uses c-TF-IDF, a modified TF-IDF, for analyzing documents within clusters to enhance topic differentiation."
                            arrow
                          >
                            <InfoOutlinedIcon
                              fontSize="small"
                              style={{ marginLeft: "5px", cursor: "pointer" }}
                            />
                          </Tooltip>
                        </p>

                        <div className="topic-generation-checkbox-container">
                          <FormControlLabel
                            value="Reduce Frequent Words"
                            sx={{ textWrap: "nowrap" }}
                            control={
                              <Checkbox
                                checked={
                                  topicConfig.ctfidf_reduce_frequent_words
                                }
                                onChange={handleTopicConfigChange}
                                name="ctfidf_reduce_frequent_words"
                              />
                            }
                            label="Reduce Frequent Words"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="bm25_weighting"
                            sx={{ textWrap: "nowrap" }}
                            control={
                              <Checkbox
                                checked={topicConfig.ctfidf_bm25_weighting}
                                onChange={handleTopicConfigChange}
                                name="ctfidf_bm25_weighting"
                              />
                            }
                            label="BM25 Weighting"
                            labelPlacement="end"
                          />
                        </div>
                      </div>
                      <div className="embedding-model-container">
                        <p className="topic-generation-subtitle">
                          Representation Model
                          <Tooltip
                            Content
                            title="MMR addresses keyword redundancy by ensuring informative and distinct keywords for document representation."
                            arrow
                          >
                            <InfoOutlinedIcon
                              fontSize="small"
                              style={{ marginLeft: "5px", cursor: "pointer" }}
                            />
                          </Tooltip>
                        </p>
                        <div className="representation-model-input-box">
                          <TextField
                            label="Top N"
                            type="number"
                            name="representation_top_n_words"
                            value={topicConfig.representation_top_n_words}
                            onChange={handleTopicConfigChange}
                            size="small"
                          />
                          <TextField
                            label="Diversity"
                            type="number"
                            name="representation_diversity"
                            value={topicConfig.representation_diversity}
                            onChange={handleTopicConfigChange}
                            size="small"
                          />
                        </div>
                      </div>
                      <div className="embedding-model-container">
                        <p className="topic-generation-subtitle">
                          Embedding model
                          <Tooltip Content title="To embed data" arrow>
                            <InfoOutlinedIcon
                              fontSize="small"
                              style={{ marginLeft: "5px", cursor: "pointer" }}
                            />
                          </Tooltip>
                        </p>
                        <div className="embedding-model-input-box">
                          {/* <TextField
                        label="Top N"
                        type="number"
                        name="embedding_model_top_n"
                        value={topicConfig.embedding_model_top_n}
                        onChange={handleTopicConfigChange}
                        size="small"
                      /> */}
                          <FormControl sx={{ minWidth: 200 }} size="small">
                            <InputLabel id="embedding-model-label">
                              Embedding Model
                            </InputLabel>
                            <Select
                              labelId="embedding-model-label"
                              id="embedding-model-select"
                              name="embedding_model"
                              value={topicConfig.embedding_model}
                              onChange={handleTopicConfigChange}
                              label="Embedding Model"
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="all-MiniLM-L6-v1">
                                All-MiniLM-L6-v1
                              </MenuItem>
                              <MenuItem value="all-MiniLM-L6-v2">
                                All-MiniLM-L6-v2
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      <div className="embedding-model-container">
                        <p className="topic-generation-subtitle">
                          Vectorizer Model{" "}
                          <Tooltip Content title="To tokenize topics." arrow>
                            {" "}
                            <InfoOutlinedIcon
                              fontSize="small"
                              style={{ marginLeft: "5px", cursor: "pointer" }}
                            />
                          </Tooltip>
                        </p>
                        <div className="Stop-word-input-box">
                          <FormControl sx={{ minWidth: 150 }} size="small">
                            <InputLabel id="Stop-word-label">
                              Stop word
                            </InputLabel>
                            <Select
                              labelId="Stop-word-label"
                              id="Stop-word-select"
                              name="vectorizer_stop_words"
                              value={topicConfig.vectorizer_stop_words}
                              onChange={handleTopicConfigChange}
                              label="Stop word"
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="english">english</MenuItem>
                            </Select>
                          </FormControl>
                          {/* <TextField
                        label="Lowercase"
                        type="number"
                        name="vectorizer_lowercase"
                        value={topicConfig.vectorizer_lowercase}
                        onChange={handleTopicConfigChange}
                        size="small"
                      /> */}
                        </div>
                      </div>
                    </div>
                    <div className="embedding-model-container">
                      <p className="topic-generation-subtitle">
                        UMAP Model{" "}
                        <Tooltip
                          Content
                          title="For best results, select all the models"
                          arrow
                        >
                          {" "}
                          <InfoOutlinedIcon
                            fontSize="small"
                            style={{ marginLeft: "5px", cursor: "pointer" }}
                          />
                        </Tooltip>
                      </p>
                      <div className="UMAP-Model-input-box">
                        <TextField
                          label="N_neighbour"
                          type="number"
                          name="umap_n_neighbors"
                          value={topicConfig.umap_n_neighbors}
                          onChange={handleTopicConfigChange}
                          size="small"
                        />
                        <TextField
                          label="N_Component"
                          type="number"
                          name="umap_n_components"
                          value={topicConfig.umap_n_components}
                          onChange={handleTopicConfigChange}
                          size="small"
                        />
                        <TextField
                          label="Min_Dist"
                          type="number"
                          name="umap_min_dist"
                          value={topicConfig.umap_min_dist}
                          onChange={handleTopicConfigChange}
                          size="small"
                        />
                        <FormControl sx={{ minWidth: 220 }} size="small">
                          <InputLabel id="Metric-label">Metric</InputLabel>
                          <Select
                            labelId="Metric-label"
                            id="Metric-select"
                            name="umap_metric"
                            value={topicConfig.umap_metric}
                            onChange={handleTopicConfigChange}
                            label="Metric"
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value="euclidean">euclidean</MenuItem>
                            <MenuItem value="manhattan">manhattan</MenuItem>
                          </Select>
                        </FormControl>
                        {/* <TextField
                      label="Metric"
                      type="number"
                      id="Metric"
                      //   value={chunkOverlap}
                      //   onChange={handleChunkOverlapChange}
                      size="small"
                    /> */}
                        <TextField
                          label="Learning Rate"
                          type="number"
                          name="umap_learning_rate"
                          value={topicConfig.umap_learning_rate}
                          onChange={handleTopicConfigChange}
                          size="small"
                        />
                      </div>
                    </div>
                    <div className="embedding-model-container">
                      <p className="topic-generation-subtitle">
                        HDB Scan Model{" "}
                        <Tooltip
                          Content
                          title="BERTopic supports HDBSCAN for clustering, offering flexibility with customizable parameters to optimize clustering performance."
                          arrow
                        >
                          {" "}
                          <InfoOutlinedIcon
                            fontSize="small"
                            style={{ marginLeft: "5px", cursor: "pointer" }}
                          />
                        </Tooltip>
                      </p>
                      <div className="HDB-Scan-input-box">
                        <TextField
                          label="Minimum Cluster Size"
                          type="number"
                          name="hdbscan_min_cluster_size"
                          value={topicConfig.hdbscan_min_cluster_size}
                          onChange={handleTopicConfigChange}
                          size="small"
                        />
                        {/* <TextField
                      label="Cluster selection method"
                      name="hdbscan_cluster_selection_method"
                      value={topicConfig.hdbscan_cluster_selection_method}
                      onChange={handleTopicConfigChange}
                      size="small"
                    /> */}
                        <FormControl sx={{ minWidth: 200 }} size="small">
                          <InputLabel id="Cluster selection method">
                            Cluster Model
                          </InputLabel>
                          <Select
                            labelId="Cluster selection method"
                            id="embedding-model-select"
                            name="hdbscan_cluster_selection_method"
                            value={topicConfig.hdbscan_cluster_selection_method}
                            onChange={handleTopicConfigChange}
                            label="Cluster Selection"
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value="eom">Eom</MenuItem>
                            <MenuItem value="leaf">Leaf</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl sx={{ minWidth: 220 }} size="small">
                          <InputLabel id="Metric-label">Metric</InputLabel>
                          <Select
                            labelId="Metric-label"
                            id="Metric-select"
                            name="hdbscan_metric"
                            value={topicConfig.hdbscan_metric}
                            onChange={handleTopicConfigChange}
                            label="Metric"
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value="euclidean">euclidean</MenuItem>
                            <MenuItem value="manhattan">manhattan</MenuItem>
                          </Select>
                        </FormControl>
                        <div>
                          <FormControlLabel
                            value="Allow sigle cluster"
                            control={
                              <Checkbox
                                checked={
                                  topicConfig.hdbscan_allow_single_cluster
                                }
                                onChange={handleTopicConfigChange}
                                name="hdbscan_allow_single_cluster"
                              />
                            }
                            label="Allow single cluster"
                            labelPlacement="end"
                          />
                        </div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={topicConfig.hdbscan_prediction_data}
                              onChange={handleTopicConfigChange}
                              name="hdbscan_prediction_data"
                            />
                          }
                          label="Prediction data"
                          labelPlacement="end"
                        />
                      </div>
                    </div>
                  </div>
                  {/* <TopicGenerationPage /> */}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default MedataForm;
