import { createSlice } from "@reduxjs/toolkit";
import {
  dataExtrationAction,
  deleteDataSourceAction,
  deleteImageFolderAction,
  fileUpload,
  fileValidationAction,
  getFileUpload,
  getFoldeAndFileListAction,
} from "../actions/dataIngestionActions";

const dataIngestionSlice = createSlice({
  name: "dataIngestionSlice",
  initialState: {
    data: [],
    status: "",
    isLoading: false,
    isActiveBtn: false,
    folderFileList: {},
    validFileName: true,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fileUpload.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(fileUpload.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isLoading = false;
        // state.data = payload;
      })
      .addCase(fileUpload.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
    builder
      .addCase(getFileUpload.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getFileUpload.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isLoading = false;
        state.data = payload.data;
      })
      .addCase(getFileUpload.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(deleteDataSourceAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(deleteDataSourceAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isLoading = false;
        state.data = payload.data;
      })
      .addCase(deleteDataSourceAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(dataExtrationAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
        state.isActiveBtn = false;
      })
      .addCase(dataExtrationAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isLoading = false;
        state.isActiveBtn = true;
        // state.data = payload.data;
      })
      .addCase(dataExtrationAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getFoldeAndFileListAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getFoldeAndFileListAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isLoading = false;
        state.folderFileList = payload?.data;
        // state.data = payload.data;
      })
      .addCase(getFoldeAndFileListAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(deleteImageFolderAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(deleteImageFolderAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isLoading = false;
        // state.data = payload.data;
      })
      .addCase(deleteImageFolderAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fileValidationAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(fileValidationAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isLoading = false;
        state.validFileName = true;
        // state.data = payload.data;
      })
      .addCase(fileValidationAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.validFileName = false;
        state.error = action.error.message;
      });
  },
});

export default dataIngestionSlice.reducer;
