import React, { useEffect, useRef, useState } from "react";
import TextArea from "../../components/Common/TextArea/TextArea";
import Button from "../../components/Button/Button";
import PDFRender from "../../components/Common/PDFRenderer/PDFRender";
import ExtractPDF from "../../components/Common/PDFRenderer/ExtractPDF";
import ReactPDF from "@react-pdf/renderer";
import axios from "axios";
import noFilesAvailable from "../../assets/no-file-available.jpg";
import { useDispatch, useSelector } from "react-redux";
import { ReviewTextCleaningAction } from "../../redux/actions/DataReviewAction";
import { getFileUpload } from "../../redux/actions/dataIngestionActions";
import Loader from "../../components/Common/LoadingModal/LoadingModal";
import { getConfigTextCleaning } from "../../redux/actions/subWorkspaceAction";
import NoFileAvailable from "./NoFileAvailable";
import "./CommonRender.scss";

const TextCleaningPage = () => {
  const [UpdatedImgFolderData, setUpdatedImgFolderData] = useState([]);
  const [loading, setloading] = useState(false);
  const [extractedText, setextractedText] = useState("");
  const { data, isLoading, imageUrls } = useSelector(
    (state) => state.DataReviewSlice
  );

  const currentPageref = useRef();
  const [fileExtension, setFileExtension] = useState("");
  const { fileId, isFilePresent, fileName, fileData, folderId } = useSelector(
    (state) => state.PipelineExecutionSlice
  );

  const [currentPage, setCurrentPage] = useState(0);
  const [numPages, setNumPages] = useState(null);
  const dispatch = useDispatch();
  const { textCleaningConfigs } = useSelector(
    (state) => state.ConfigurationSlice
  );
  useEffect(() => {
    dispatch(getConfigTextCleaning());
  }, []);

  useEffect(() => {
    if (isFilePresent) {
      dispatch(ReviewTextCleaningAction(fileId));
      dispatch(getFileUpload());
      setCurrentPage(1);
    }
  }, [fileId, isFilePresent]);

  useEffect(() => {
    const processFiles = async () => {
      setloading(true);
      let imgData = [];
      for (const file of fileData) {
        try {
          await dispatch(ReviewTextCleaningAction(file.id)).then((res) => {
            // console.log("res-->", res);
            if (res.payload) {
              imgData.push(res.payload?.data?.textcleaning_curatedcontent);
            }
          });
        } catch (error) {
          setloading(false);
          console.error("Error processing file:", file.id, error);
        }
      }
      // console.log("imgData-->>", imgData);
      setUpdatedImgFolderData(imgData);
      setloading(false);
    };

    if (fileData && fileData.length > 0) {
      processFiles();
    }
  }, [fileData, dispatch]);

  useEffect(() => {
    const getFileExtension = (fileName) => {
      const parts = fileName?.split(".");
      return parts?.length > 1 ? "." + parts.pop() : "";
    };

    setFileExtension(getFileExtension(fileName));
  }, [fileName]);

  return (
    <div>
      <Loader isOpen={loading ? loading : isLoading} />
      <h5 style={{ marginLeft: "20px" }}>
        Enhance insights by refining text data, removing noise and
        inconsistencies.
      </h5>

      {(data?.extracted_text?.length > 0 || fileData?.length > 0) &&
      isFilePresent ? (
        <div className="main_render_container">
          <div className="outer_render_box">
            <PDFRender
              numPages={numPages}
              setNumPages={setNumPages}
              currentPageref={currentPageref}
              fileExtension={fileExtension}
              fileData={fileData}
              folderId={folderId}
              extractedText={extractedText}
              fileId={fileId}
              fileName={fileName}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          </div>
          <div className="outer_render_box">
            <ExtractPDF
              configs={textCleaningConfigs}
              currentPage={currentPage}
              currentPageref={currentPageref}
              fileExtension={fileExtension}
              fileId={fileId}
              setextractedText={setextractedText}
              UpdatedImgFolderData={UpdatedImgFolderData}
              fileName={fileName}
              pageData={data?.textcleaning_curatedcontent}
            />
          </div>
        </div>
      ) : (
        <>
          <NoFileAvailable isfile={true} />
        </>
      )}
    </div>
  );
};

export default TextCleaningPage;
