import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import TextCleaningPage from "./TextCleaning";
import TextQualityPage from "./TextQuality";
import TextLengthPage from "./TextLength";
import SensitiveInfoPage from "./Sensetiveinfo";
import ProfanityCheckPage from "./ProfanityCheck";

import { Row } from "reactstrap";
import Tables from "./Tables";
import { getVideoFileAction } from "../../redux/actions/DataReviewAction";
import { useSelector, useDispatch } from "react-redux";
import { setFileUrlsAndOtherData } from "../../redux/slices/DataReviewSlice";

const TabsComponents = () => {
  const dispatch = useDispatch();
  const [fileExtension, setFileExtension] = useState("");
  const [activeTabIndex, setActiveTabIndex] = useState(0); // Track the active tab index
  const [imgloading, setimgloading] = useState(false);
  const { fileId, isFilePresent, fileName, fileData, folderId } = useSelector(
    (state) => state.PipelineExecutionSlice
  );
  const handleTabClick = (index) => {
    setActiveTabIndex(index);
  };

  
  //   const getFileExtension = (fileName) => {
  //     const parts = fileName?.split(".");
  //     return parts?.length > 1 ? "." + parts.pop() : "";
  //   };

  //   setFileExtension(getFileExtension(fileName));
  // }, [fileName]);

  // useEffect(() => {
  //   const handleAudioFile = async () => {
  //     try {
  //       setimgloading(true);
  //       dispatch(getVideoFileAction(fileId)).then((res) => {
  //         if (res.payload.status === 200 || res.payload.status === 201) {
  //           const blob = res.payload.data;
  //           const objectUrl = URL.createObjectURL(blob);
  //           dispatch(
  //             setFileUrlsAndOtherData({
  //               audiosrc: objectUrl,
  //               imgloading: imgloading,
  //             })
  //           );
  //           // setVideoSrc(objectUrl);
  //           // setAudioSrc(objectUrl);
  //           setimgloading(false);
  //         } else {
  //           setimgloading(false);
  //         }
  //       });
  //     } catch (error) {
  //       setimgloading(false);
  //       console.error("Error fetching audio file:", error.message);
  //     }
  //   };

  //   const extensions = [
  //     ".mp3",
  //     ".wav",
  //     ".ogg",
  //     ".mp4",
  //     ".webm",
  //     ".mov",
  //     ".avi",
  //     ".ogv",
  //   ];
  //   if (extensions.includes(fileExtension)) {
  //     handleAudioFile();
  //   }

  //   return () => {
  //     if (audioSrc) {
  //       URL.revokeObjectURL(audioSrc);
  //     }
  //   };
  // }, [fileExtension, fileId]);

  return (
    <Tabs selectedIndex={activeTabIndex} onSelect={handleTabClick}>
      <TabList style={{ paddingInline: "25px" }}>
        <Tab>Text Cleaning</Tab>
        <Tab>Text Quality</Tab>
        {/* <Tab>Text Length</Tab> */}
        <Tab>Sensitive info</Tab>
        <Tab>Profanity Check</Tab>
        <Tab>Tables</Tab>
      </TabList>

      <TabPanel>
        <Row>
          <TextCleaningPage />
        </Row>
      </TabPanel>
      <TabPanel>
        <Row>
          <TextQualityPage />
        </Row>
      </TabPanel>
      {/* <TabPanel>
        <Row>
          <TextLengthPage />
        </Row>
      </TabPanel> */}
      <TabPanel>
        <Row>
          <SensitiveInfoPage />
        </Row>
      </TabPanel>
      <TabPanel>
        <Row>
          <ProfanityCheckPage />
        </Row>
      </TabPanel>
      <TabPanel>
        <Row>
          <Tables />
        </Row>
      </TabPanel>
    </Tabs>
  );
};

export default TabsComponents;
