import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  data_extraction,
  file_upload,
  file_validation_api,
  image_folder,
} from "../../services/postServices";
import {
  folder_and_file_list,
  get_dataIngestion_file,
} from "../../services/getServices";
import {
  delete_dataSource,
  delete_image_folder,
} from "../../services/deleteServices";
import { toast } from "react-toastify";

export const fileUpload = createAsyncThunk(
  "fileUpload",
  async ({ selectedFile, workspace_id, folderId, onUploadProgress }) => {
    try {
      const formData = new FormData();
      if (folderId) {
        formData.append("file", selectedFile);
        formData.append("workspace_id", workspace_id);
        formData.append("folder_id", folderId);
        formData.append("folder_id", folderId);
        formData.append("storage_type", "local");
      } else {
        formData.append("file", selectedFile);
        formData.append("workspace_id", workspace_id);
        formData.append("storage_type", "local");
      }

      const response = await file_upload(formData, onUploadProgress);
      // console.log(response);
      return response.data; // Assuming the API returns the uploaded file data
      // }
    } catch (error) {
      console.log(error);

      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);

export const getFileUpload = createAsyncThunk("getFileUpload", async () => {
  try {
    let workspace = JSON.parse(sessionStorage.getItem("workspace"));
    const data = await get_dataIngestion_file(workspace?.workspace_id);
    // console.log("dataingestion files", data);
    if (data.status === 200) {
      // sessionStorage.setItem("file_id", data?.data[0]?.file_id);
      // console.log("data?.data---????", data?.data);
      return data;
    } else {
      throw data;
    }
  } catch (error) {
    if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error. Please try again later.");
    }
    return error;
  }
});
export const deleteDataSourceAction = createAsyncThunk(
  "deleteDataSourceAction",
  async (value) => {
    try {
      const data = await delete_dataSource(value);
      // console.log("dataingestion files", data);
      if (data.status === 200) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);
export const dataExtrationAction = createAsyncThunk(
  "dataExtrationAction",
  async (value) => {
    try {
      const data = await data_extraction(value);
      if (data.status === 200 || data.status === 201) {
        toast.success("File extracted Successfully.");
        return data;
      } else {
        toast.error("Something went wrong. Please try again!");
        throw data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.error || "An error occurred");
      return error;
    }
  }
);

export const createImageFolderAction = createAsyncThunk(
  "createImageFolderAction",
  async (value) => {
    try {
      // console.log("value", value);
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      const payload = {
        workspace_id: workspace?.workspace_id,
        folder_name: value,
      };
      const data = await image_folder(payload);
      if (data.status === 200 || data.status === 201) {
        return data;
      } else {
        toast.error("Something went wrong Please try again !");
        throw data;
      }
    } catch (error) {
      console.log("error->>", error);
      // if (error.response && error.response.status === 500) {
      //   toast.error("Internal Server Error. Please try again later.");
      // }
      return error;
    }
  }
);
export const getFoldeAndFileListAction = createAsyncThunk(
  "getFoldeAndFileListAction",
  async (value) => {
    try {
      // console.log("value", value);
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      const data = await folder_and_file_list(workspace?.workspace_id);
      if (data.status === 200 || data.status === 201) {
        return data;
      } else {
        toast.error("Something went wrong Please try again !");
        throw data;
      }
    } catch (error) {
      console.log("error->>", error);
      // if (error.response && error.response.status === 500) {
      //   toast.error("Internal Server Error. Please try again later.");
      // }
      return error;
    }
  }
);
export const deleteImageFolderAction = createAsyncThunk(
  "deleteImageFolderAction",
  async (file_id) => {
    try {
      const data = await delete_image_folder(file_id);
      if (data.status === 200 || data.status === 201) {
        return data;
      } else {
        toast.error("Something went wrong Please try again !");
        throw data;
      }
    } catch (error) {
      console.log("error->>", error);
      // if (error.response && error.response.status === 500) {
      //   toast.error("Internal Server Error. Please try again later.");
      // }
      return error;
    }
  }
);
export const fileValidationAction = createAsyncThunk(
  "fileValidationAction",
  async (value) => {
    try {
      const response = await file_validation_api(value);
      return response; // Assuming the API returns the uploaded file data
      // }
    } catch (error) {
      error.response.status == 400 && toast.error(error.response.data.detail);
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);
