import React, { useEffect, useState } from "react";
import { IconButton, List, ListItem, ListItemText } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
// import configIcon from "../../../assets/settings_4582037.png";
// import FilterFramesRoundedIcon from "@mui/icons-material/FilterFramesRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));


function ConfigrationTooltip({ configs }) {
  const [configurations, setConfigurations] = useState([]);
  useEffect(() => {
    if (configs && typeof configs === "object") {
      const filteredProperties = Object.entries(configs)
        .filter(([key, value]) => value === 1)
        .map(([key, value]) => {
          const formattedKey = key.replace(/^is/, "");
          const words = formattedKey.split(/(?=[A-Z])/);
          const formattedWords = words.join(" ");
          return {
            key: formattedWords,
            value,
          };
        });

      setConfigurations(filteredProperties);
    } else {
      setConfigurations([]); // Reset configurations if configs is invalid
    }
  }, [configs]);

  return (
    <>
      <BootstrapTooltip
        style={{ padding: "0" }}
        title={configurations?.map((item) => {
          return (
            <>
              {configurations?.length ? (
                <p className="tooltip-content">{item?.key}</p>
              ) : (
                <p className="tooltip-content">No configuration set.</p>
              )}
            </>
          );
        })}
      >
        
      </BootstrapTooltip>
    </>
  );
}

export default ConfigrationTooltip;
