import React, { useEffect, useState } from "react";

import s from "./Button.module.scss";

function Button({
  label,
  onClick,
  colorCode,
  activeBtn,
  isDisabled,
  IsExtracted,
  isImageIdPresent,
}) {
  return (
    <div className={s.btnBox}>
      <button
        disabled={IsExtracted}
        style={{ cursor: IsExtracted ? "not-allowed" : "pointer" }}
        className={`${activeBtn ? s.saveBtn : s.otherBtn} ${
          isDisabled ? s.disabledBtn : ""
        }`}
        onClick={onClick}
      >
        {label}
      </button>
    </div>
  );
}

export default Button;
