import { DELETE_API } from "./HTTTPS";

const BASE_URL = "aura-studio";

export const delete_workspace = (id) => {
  return DELETE_API(`${BASE_URL}/workspace/delete/${id}/`);
};
export const delete_dataSource = (id) => {
  return DELETE_API(`${BASE_URL}/dataingestion/delete/${id}/`);
};
export const delete_image_folder = (id) => {
  return DELETE_API(`${BASE_URL}/dataingestion/delete/folder/${id}/`);
};
