import React from "react";
import s from "./NoFileAvaiable.module.scss";
import noFilesAvailable from "../../assets/no-file-available.jpg";
import LottieFiles from "../../components/Common/Lottie/LottieFIles";
import nofileAvailableLottie from "../../assets/lottie/nodatafound.json";
function NoFileAvailable({ isfile, isChunksPage }) {
  return (
    <div className={s.noFilesAvailableBox}>
      {/* <img
        src={process.env.PUBLIC_URL + noFilesAvailable}
        alt="noFilesAvailable"
        
      /> */}
      <LottieFiles file={nofileAvailableLottie} height={230} width={350} />
      <div className={s.noFilesAvailableContent}>
        {isfile ? (
          <p className={s.title}>
            No data found, Please verify if the file was uploaded and extracted.
          </p>
        ) : isChunksPage ? (
          <p className={s.title}>
            Please select data source to perform next step.
          </p>
        ) : (
          <p className={s.title}>
            Please initiate table extraction with the attached data source.
          </p>
        )}
      </div>
    </div>
  );
}

export default NoFileAvailable;
