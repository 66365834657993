import React from "react";

const Select = ({ defaultValue, options, label, ...rest }) => {
  return (
    <div className="form-group">
      {label && <label htmlFor={rest.id || rest.name}>{label}</label>}
      <select
        className="form-control"
        id={rest.id || rest.name}
        defaultValue={defaultValue || ""}
        {...rest}
      >
        <option value="" disabled>
          {defaultValue === "" ? "SELECT" : ""}
        </option>
        {options &&
          options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
    </div>
  );
};

export default Select;
