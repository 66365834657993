import React, { useEffect, useState } from "react";
import s from "./summarize.module.scss";
import Button from "../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  GenerateQaPairs,
  getsaveQaPairs,
  saveQaPairs,
} from "../../redux/actions/InsightsActions";
import "react-tabulator/lib/styles.css";
import { ReactTabulator } from "react-tabulator";
import "react-tabulator/lib/styles.css";
import "react-tabulator/lib/css/tabulator.min.css";
import Loader from "../../components/Common/LoadingModal/LoadingModal";
import { toast } from "react-toastify";
import nodatafound from "../../assets/No_Data_Found.jpg";
import { Box, Modal } from "@mui/material";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "10px",
  p: 3,
};

const Qna = () => {
  const dispatch = useDispatch();
  const { isLoading, savedQaPairs } = useSelector(
    (state) => state.InsightsSlice
  );

  const { pathname } = useLocation();

  const {
    fileId,
    isFilePresent,
    fileName,
    fileData,
    imageId,
    isImageSelected,
  } = useSelector((state) => state.PipelineExecutionSlice);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const imgId = query.get("id");

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [state, setState] = useState({
    value: 10,
    qnaPairs: [],
    showContent: false,
    savedData: [],
    radioOption: "generated", // Default radio option
  });

  useEffect(() => {
    setState({
      radioOption: "generated",
      savedData: [],
    });
  }, [fileId]);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleValueChange = (event) => {
    setState({ ...state, value: Number(event.target.value) });
  };

  const createQnaPairs = async () => {
    const value = {
      total_qa_pairs: state.value,
      file_id: pathname === "/template/Insights" && imageId ? imageId : fileId,
    };
    const res = await dispatch(GenerateQaPairs(value));

    if (res.payload?.status === 200 || res.payload?.status === 201) {
      toast.success("QA pairs generated successfully");
      setState({ ...state, qnaPairs: res.payload.data, showContent: true });
    } else {
      toast.error("Please extract the file!.");
    }
  };

  const saveQnaPairs = () => {
    handleCloseDialog();
    dispatch(saveQaPairs(state.qnaPairs));
  };

  const handleRadioButtonChange = (event) => {
    const { value } = event.target;

    // Update the radioOption state based on the selected value
    setState({ ...state, radioOption: value });

    // If selecting "Saved Q&A", fetch and update saved data
    if (value === "saved") {
      dispatch(
        getsaveQaPairs(
          pathname == "/template/Insights" && imageId ? imageId : fileId
        )
      )
        .then((res) => {
          if (res.payload?.data) {
            setState({
              ...state,
              savedData: res.payload.data,
              radioOption: "saved",
              showContent: true,
            });
          } else {
            setState({
              ...state,
              radioOption: "saved",
              showContent: false,
            });
          }
        })
        .catch((error) => {
          setState({
            ...state,
            radioOption: "saved",
            showContent: false,
          });
          console.error("Error fetching saved Q&A pairs:", error);
        });
    } else if (value === "generated") {
      state.qnaPairs?.ques_ans_pairs
        ? setState({
            ...state,
            radioOption: "generated",
            showContent: true,
          })
        : setState({
            ...state,
            radioOption: "generated",
            showContent: false,
          });
    }
  };

  const columns = [
    {
      title: "Question",
      field: "question",
      width: "50%",
      formatter: "textarea",
    },
    { title: "Answer", field: "answer", width: "50%", formatter: "textarea" },
  ];

  return (
    <div className={s.container}>
      <Loader isOpen={isLoading} />
      <div className={s.GenerateSummaryBox}>
        <div className={s.GenerateSummary}>
          <p>Create </p>
          <input
            type="number"
            className={s.wordLimitInput}
            value={state.value}
            onChange={handleValueChange}
          />
          <p>Q-n-A Pairs</p>
        </div>
        <div className={s.GSBtn}>
          <Button
            // style={{ cursor: isFilePresent ? "pointer" : "not-allowed" }}
            label={"Create Q-n-A Pairs"}
            activeBtn={true}
            IsExtracted={!isImageSelected || state.radioOption === "saved"}
            isImageIdPresent={!imageId}
            //  onClick={isFilePresent ? createQnaPairs : null}
            onClick={createQnaPairs}
          />
        </div>
      </div>

      <div
        style={{
          overflow: "auto",
          maxHeight: "100%",
          width: "95%",
        }}
      >
        <div
          className={s.radioGroup}
          style={{
            display: "flex",
            gap: "20px",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <input
              type="radio"
              style={{ width: "20px", height: "20px" }}
              name="content"
              value="generated"
              checked={state.radioOption === "generated"}
              onChange={handleRadioButtonChange}
            />
            <label style={{ marginRight: "20px" }}> Generated Q & A</label>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <input
              type="radio"
              name="content"
              style={{ width: "20px", height: "20px" }}
              value="saved"
              checked={state.radioOption === "saved"}
              onChange={handleRadioButtonChange}
            />
            <label>Saved Q & A</label>
          </div>
        </div>

        {state.showContent ? (
          state.radioOption === "saved" ? (
            <ReactTabulator
              data={state?.savedData?.ques_ans_pairs}
              columns={columns}
              options={{ layout: "fitColumns", resizableColumns: true }}
            />
          ) : (
            <>
              <ReactTabulator
                data={state.qnaPairs?.ques_ans_pairs}
                columns={columns}
                options={{ layout: "fitColumns", resizableColumns: true }}
              />
              <div className={s.ButtonContainer}>
                <Button label="Save" activeBtn onClick={handleOpenDialog} />
              </div>
              <Modal
                open={isDialogOpen}
                onClose={handleCloseDialog}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div className={s.DeleteBox}>
                    <p>
                      {" "}
                      Are you sure you want to replace saved Q & A pairs with
                      genrated Q & A pairs ?
                    </p>
                    <div className={s.btnBox}>
                      <Button label="Yes" activeBtn onClick={saveQnaPairs} />
                      <Button label="No" onClick={handleCloseDialog} />
                    </div>
                  </div>
                </Box>
              </Modal>
            </>
          )
        ) : (
          <div className={s.nodatafoundbox}>
            <img
              src={process.env.PUBLIC_URL + nodatafound}
              height={180}
              width={230}
              alt="nodatafound"
            />
            <h5>No Q & A pairs found. Please create Q & A pairs.</h5>
          </div>
        )}
      </div>
    </div>
  );
};

export default Qna;
