import { createSlice } from "@reduxjs/toolkit";
import {
  ExtractTableAction,
  PerformCurationAllAction,
  ProfanityCheckCurationAction,
  SaveProfanityCheckCurationAction,
  SaveSensetiveInfoCheckCurationAction,
  SaveTextCleaningCurationAction,
  SaveTextQualityCheckCurationAction,
  SensetiveInfoCheckCurationAction,
  TextCleaningCurationAction,
  TextQualityCheckCurationAction,
  ValidationExecutionFlow,
} from "../actions/PipelineExecutionAction";

const PipelineExecutionSlice = createSlice({
  name: "PipelineExecutionSlice",
  initialState: {
    data: {},
    validationData: null,
    status: "",
    isvalidation: false,
    isLoading: false,
    isFilePresent: false,
    fileId: "",
    fileData: null,
    imageId: "",
    folderId: "",
    fileName: "",
    isImageSelected: false,
  },
  reducers: {
    setFileAvailability(state, { payload }) {
      state.isFilePresent = payload?.fileIdPresent;
      state.fileId = payload?.fileId;
      state.fileData = payload?.fileData;
      state.imageId = payload?.imageId;
      state.folderId = payload?.folderId;
      state.fileName = payload?.fileName;
      state.isImageSelected = payload?.isImageSelected;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(TextCleaningCurationAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(TextCleaningCurationAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        // state.data = payload;
        state.isLoading = false;
      })
      .addCase(TextCleaningCurationAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(TextQualityCheckCurationAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(
        TextQualityCheckCurationAction.fulfilled,
        (state, { payload }) => {
          state.status = "success";
          // state.data = payload;
          state.isLoading = false;
        }
      )
      .addCase(TextQualityCheckCurationAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(SensetiveInfoCheckCurationAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(
        SensetiveInfoCheckCurationAction.fulfilled,
        (state, { payload }) => {
          state.status = "success";
          // state.data = payload;
          state.isLoading = false;
        }
      )
      .addCase(SensetiveInfoCheckCurationAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(ProfanityCheckCurationAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(ProfanityCheckCurationAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        // state.data = payload;
        state.isLoading = false;
      })
      .addCase(ProfanityCheckCurationAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(SaveTextCleaningCurationAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(
        SaveTextCleaningCurationAction.fulfilled,
        (state, { payload }) => {
          state.status = "success";
          // state.data = payload;
          state.isLoading = false;
        }
      )
      .addCase(SaveTextCleaningCurationAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(SaveTextQualityCheckCurationAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(
        SaveTextQualityCheckCurationAction.fulfilled,
        (state, { payload }) => {
          state.status = "success";
          // state.data = payload;
          state.isLoading = false;
        }
      )
      .addCase(SaveTextQualityCheckCurationAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(SaveSensetiveInfoCheckCurationAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(
        SaveSensetiveInfoCheckCurationAction.fulfilled,
        (state, { payload }) => {
          state.status = "success";
          // state.data = payload;
          state.isLoading = false;
        }
      )
      .addCase(
        SaveSensetiveInfoCheckCurationAction.rejected,
        (state, action) => {
          state.status = "failed";
          state.isLoading = false;
          state.error = action.error.message;
        }
      );
    builder
      .addCase(SaveProfanityCheckCurationAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(
        SaveProfanityCheckCurationAction.fulfilled,
        (state, { payload }) => {
          state.status = "success";
          // state.data = payload;
          state.isLoading = false;
        }
      )
      .addCase(SaveProfanityCheckCurationAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(ExtractTableAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(ExtractTableAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        // state.data = payload;
        state.isLoading = false;
      })
      .addCase(ExtractTableAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(ValidationExecutionFlow.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(ValidationExecutionFlow.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.validationData = payload;
        state.isvalidation = false;
        state.isLoading = false;
      })
      .addCase(ValidationExecutionFlow.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(PerformCurationAllAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(PerformCurationAllAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isvalidation = false;
        state.isLoading = false;
      })
      .addCase(PerformCurationAllAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { setFileAvailability } = PipelineExecutionSlice.actions;

export default PipelineExecutionSlice.reducer;
