import React from "react";

import s from "./ConfigureChecks.module.scss";
import { styled } from "@mui/material/styles";
// import Tooltip from "@material-ui/core/Tooltip";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

function ConfigureChecks(props) {
  const { onClick, option, label, title } = props;
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow placement="top" classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  return (
    <>
      <BootstrapTooltip style={{ padding: "0" }} title={title}>
        <div className={s.configureContainer}>
          <div className={s.textCleaningBox}>
            <div className={s.textCleaningCheck}>
              <label>{label}</label>
            </div>
            <label className="switch">
              <input
                name=""
                type="checkbox"
                checked={option}
                onChange={onClick}
              />

              <span className="slider"></span>
            </label>
          </div>
        </div>
      </BootstrapTooltip>
    </>
  );
}

export default ConfigureChecks;
