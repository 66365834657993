import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  create_knowledge_graph,
  update_knowledge_graph,
} from "../../services/postServices";
import { get_knowledge_graph_data } from "../../services/getServices";

export const createKnowledgeGraphAction = createAsyncThunk(
  "createKnowledgeGraphAction",
  async (payload) => {
    try {
      const data = await create_knowledge_graph(payload);
      if (data?.status === 200 || data?.status === 201) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      } else if (error?.response?.status === 404) {
        toast.error(error?.response?.data?.error);
      }
      return error;
    }
  }
);

export const getKnowledgeGraphDataAction = createAsyncThunk(
  "getKnowledgeGraphDataAction",
  async (fileId) => {
    try {
      const data = await get_knowledge_graph_data(fileId);
      if (data?.status === 200 || data?.status === 201) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);
export const updateKnowledgeGraphData = createAsyncThunk(
  "updateKnowledgeGraphData",
  async ({ payload, fileId }) => {
    try {
      const data = await update_knowledge_graph(payload, fileId);
      if (data?.status === 200 || data?.status === 201) {
        toast.success("knowledge graph data updated successfully.");
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);
