import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
// import CircularProgress from "@mui/material/CircularProgress";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import s from "./LoadingModel.module.scss";
// import loadingLottie from "../../../assets/lottie/loading.json";
import loadingLottie from "../../../assets/lottie/loading3.json";
import Lottie from "react-lottie";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #fff",
  boxShadow: 24,
  borderRadius: "10px",
  // p: 4,
  paddingBottom: "10px",
  paddingInline: "40px",
};

export default function Loader({ isOpen }) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(isOpen);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={s.LoaderBox}>
            {/* <CircularProgress color="primary" /> */}
            {/* <CircularProgress
              variant="indeterminate"
              disableShrink
              sx={{
                color: (theme) =>
                  theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
                animationDuration: "550ms",
                position: "absolute",
                left: 0,
                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: "round",
                },
              }}
              size={40}
              thickness={4}
              // {...props}
            /> */}
            <Lottie options={defaultOptions} height={100} width={100} />
            <p style={{ fontSiz: "12px" }}>Please Wait...</p>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
