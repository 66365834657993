import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import docImage from "../../../assets/wordfile.png";
import pdfImage from "../../../assets/pdfimg.png";
import imagefile from "../../../assets/imagefile.png";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import folderImage from "../../../assets/folderIcon.png";
import audioIcon from "../../../assets/audio.png";
import txtImage from "../../../assets/txtIcon.png";
import videoFile from "../../../assets/videoIcon.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getFileUpload,
  getFoldeAndFileListAction,
} from "../../../redux/actions/dataIngestionActions";
import { setFileAvailability } from "../../../redux/slices/pipelinExecutionSlice";
import "./configrationDetails.css";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import ChunkStepper from "../../../pages/Chunks/ChunkStepper";

export default function BasicSelect() {
  const history = useHistory();
  const { pathname } = useLocation();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const dispatch = useDispatch();
  const [filename, setFilename] = useState("");
  const [imgName, setImgName] = useState("");

  const [filteredCombinedData, setfilteredCombinedData] = useState([]);

  const { data, isLoading, isActiveBtn, folderFileList } = useSelector(
    (state) => state.dataIngestionSlice
  );
  const { fileId, isFilePresent, fileName, fileData, imageId } = useSelector(
    (state) => state.PipelineExecutionSlice
  );
  const [extractedFiles, setExtractedFiles] = useState([]);

  useEffect(() => {
    dispatch(getFileUpload());
  }, [dispatch]);

  useEffect(() => {
    if (folderFileList) {
      setfilteredCombinedData(
        folderFileList?.folders?.concat(folderFileList?.files)?.map((item) => {
          if (
            item.files && Array.isArray(item?.files) && item.files.length > 0
              ? item.files.every((item) => item.IsExtracted)
              : false
          ) {
            return { ...item, IsExtracted: true };
          }
          return item;
        })
      );
    }
  }, [folderFileList]);

  useEffect(() => {
    if (filteredCombinedData?.length > 0) {
      const extracted = filteredCombinedData?.filter(
        (data) => data?.IsExtracted
      );
      setExtractedFiles(extracted);
    }
  }, [filteredCombinedData]);
  useEffect(() => {
    dispatch(getFoldeAndFileListAction());
  }, []);

  useEffect(() => {
    // Retrieve the selected filename from local storage
    const storedFilename = sessionStorage.getItem("selectedFilename");
    if (storedFilename) {
      setFilename(storedFilename);
    }
  }, []);

  const handleChange = (event) => {
    setImgName("");
    const selectedFileName = event.target.value;
    dispatch(
      setFileAvailability({
        fileIdPresent: true,
        isImageSelected: false,
      })
    );
    setFilename(selectedFileName);
    // Find the selected file's ID
    const selectedFile = extractedFiles.find(
      (file) =>
        file.file_name === selectedFileName || file.name === selectedFileName
    );

    if (selectedFile) {
      sessionStorage.setItem("file_id", selectedFile.id);
      dispatch(
        setFileAvailability({
          fileIdPresent: true,
          fileId: selectedFile.id,
          imageId: "",
          folderId: selectedFile.id,
          // folderId: "",
          isImageSelected: !selectedFile?.files?.length ? true : false,
          fileData: selectedFile?.files,
          fileName: selectedFile?.original_file_name || selectedFile?.name,
        })
      );
    }
  };

  const handleImageChange = (event) => {
    const selectedFileName = event.target.value;
    setImgName(selectedFileName);
    const selectedFile = fileData.find(
      (file) => file.original_file_name === selectedFileName
    );
    if (selectedFile) {
      const isImageSelected = imageId
        ? filename.length > 0 && selectedFileName.length > 0
        : fileId.length > 0;

      dispatch(
        setFileAvailability({
          fileIdPresent: true,
          fileId: selectedFile?.id,
          imageId: selectedFile.id,
          fileData: fileData,
          isImageSelected: fileData?.length ? true : false,
          // filename.length && selectedFileName.length ? true : false,
          folderId: selectedFile.folder_id,
          fileName: selectedFile?.original_file_name,
        })
      );
    }
  };

  const getFileIcon = (fileName) => {
    const extension = fileName.split(".").pop();
    switch (extension) {
      case "pdf":
        return <img height={20} src={pdfImage} alt="doc" />;
      case "mp3":
      case "wav":
      case "ogg":
      case "acc":
      case "flac":
        return <img height={20} src={audioIcon} alt="mp3" />;
      case "doc":
      case "docx":
        return <img height={20} src={docImage} alt="doc" />;
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
      case "webp":
        // return <ImageIcon />;
        return <img height={20} src={imagefile} alt="doc" />;
      case "mp4":
      case "mov":
      case "webm":
      case "avi":
      case "ogv":
        return <img height={20} src={videoFile} alt="video" />;
      case "txt":
        return <img height={20} src={txtImage} alt="txt" />;
      case "docx":
        return <TextSnippetIcon />;
      default:
        return <img height={20} src={folderImage} alt="doc" />;
    }
  };

  return (
    <Box
      sx={{
        m: 0.5,
        display: "flex",
        gap: "20px",
        justifyContent:
          pathname === "/template/ChunksCreation" && "space-between",
        marginLeft: "40px",
        marginBottom: "20px",
      }}
    >
      <FormControl sx={{ width: "400px" }} size="small">
        <InputLabel id="demo-simple-select-label">
          Extracted Data Source
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={filename}
          label="Extracted Data Source"
          onChange={handleChange}
          MenuProps={MenuProps}
        >
          {extractedFiles.map((file) => (
            <MenuItem
              // disabled={
              //   pathname === "/template/pipelineexecution" &&
              //   file?.IsPipelineExecuted
              // }
              key={file.id}
              value={file.file_name || file.name}
            >
              <div className="file-and-folder-box">
                <div> {getFileIcon(file.original_file_name || file.name)}</div>
                <div> {file.original_file_name || file.name}</div>
              </div>
            </MenuItem>
          ))}
        </Select>
        {pathname !== "/template/QnA" && (
          <p className="NoteMessage">
            Note : Above selected file will be processed here.
          </p>
        )}
      </FormControl>
      {fileData &&
        (pathname == "/template/Insights" ||
          pathname == "/template/Embeddings" ||
          pathname === "/template/download" ||
          pathname === "/template/KnowledgeGraph") && (
          <FormControl sx={{ width: "400px" }} size="small">
            <InputLabel id="demo-simple-select-label">Images</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={imgName}
              label="Images"
              onChange={handleImageChange}
              MenuProps={MenuProps}
            >
              {Array.isArray(fileData) &&
                fileData?.map((file) => (
                  <MenuItem key={file.id} value={file.original_file_name}>
                    <div className="file-and-folder-box">
                      <div> {getFileIcon(file.original_file_name)}</div>
                      <div> {file.original_file_name}</div>
                    </div>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      {isFilePresent && pathname === "/template/ChunksCreation" && (
        <ChunkStepper />
      )}
    </Box>
  );
}
