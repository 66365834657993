// import React from "react";
// import { ResponsiveScatterPlot } from "@nivo/scatterplot";
// import emebeddingData from "./staticEmbedding.json";
// import { BorderBottom } from "@mui/icons-material";
// import s from "./EmbeddingsChart.module.scss";

// const data = [
//   {
//     id: "group A",
//     data: [
//       { x: 1, y: 2 },
//       { x: 2, y: 3 },
//       { x: 3, y: 4 },
//       // more data points
//     ],
//   },
//   {
//     id: "group B",
//     data: [
//       { x: 2, y: 5 },
//       { x: 3, y: 6 },
//       { x: 4, y: 7 },
//       // more data points
//     ],
//   },
// ];

// const MyScatterPlot = () => (
//   <div style={{ height: "500px" }}>
//     <div className={s.titleBox}>
//       <p className={s.graphicalTitle}>Graphical represntation of embeddings</p>
//     </div>
//     <ResponsiveScatterPlot
//       data={emebeddingData}
//       margin={{ top: 40, right: 40, bottom: 60, left: 60 }}
//       xScale={{ type: "linear", min: 0, max: "auto" }}
//       yScale={{ type: "linear", min: 0, max: "auto" }}
//       axisTop={null}
//       axisRight={null}
//       axisBottom={{
//         orient: "bottom",
//         legend: "x axis",
//         legendPosition: "middle",
//         legendOffset: 46,
//       }}
//       axisLeft={{
//         orient: "left",
//         legend: "y axis",
//         legendPosition: "middle",
//         legendOffset: -40,
//       }}
//       colors={{ scheme: "nivo" }}
//       pointSize={10}
//       pointColor={{ from: "color", modifiers: [] }}
//       pointBorderWidth={2}
//       pointBorderColor={{ from: "serieColor", modifiers: [] }}
//       enableGridX={true}
//       enableGridY={true}
//     />
//   </div>
// );

// export default MyScatterPlot;
// ScatterPlot.js
import React, { useState } from "react";

import s from "./EmbeddingsChart.module.scss";
import noGraphAvailable from "../../assets/No_Data_Found.jpg";
import { useSelector } from "react-redux";
import ZoomInIcon from "@mui/icons-material/ZoomIn";

import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";

const ScatterPlot = ({ chartImageUrl }) => {
  const { emebeddingData } = useSelector((state) => state.DataOutputSlice);
  const [zoomLevel, setZoomLevel] = useState(1);

  const handleZoomIn = () => {
    setZoomLevel((prevZoomLevel) => Math.min(prevZoomLevel + 0.1, 2));
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoomLevel) => Math.max(prevZoomLevel - 0.1, 0.1));
  };

  const handleResetZoom = () => {
    setZoomLevel(1);
  };

  return (
    <div className={s.container}>
      <div className={s.titleBox}>
        <p className={s.graphicalTitle}>
          Graphical representation of embeddings
        </p>
        <div>
          <button
            className={s.prevControl}
            onClick={handleZoomIn}
            disabled={zoomLevel === 2}
          >
            <ZoomInIcon />
          </button>
          <button
            className={s.prevControl}
            onClick={handleZoomOut}
            disabled={zoomLevel === 0.1}
          >
            <ZoomOutIcon />
          </button>
          <button className={s.prevControl} onClick={handleResetZoom}>
            <AspectRatioIcon />
          </button>
        </div>
      </div>
      {!emebeddingData ? (
        <div className={s.noChartAvailableBox}>
          <img
            height={200}
            width={270}
            src={noGraphAvailable}
            alt="No graph available"
          />
          <p className={s.noGraphTitle}>
            Please create embeddings to see graphical representation of
            embeddings
          </p>
        </div>
      ) : (
        <div className={s.imageWrapper}>
          <img
            className={s.chartImage}
            style={{
              transform: `scale(${zoomLevel})`,
              transformOrigin: "top left",
              transition: "transform 0.2s",
            }}
            src={chartImageUrl?.objectUrl}
            alt="chart"
          />
        </div>
      )}
    </div>
  );
};

export default ScatterPlot;
