import React, { useState, useEffect } from "react";
import "./TextCleaning.css";
import { useDispatch, useSelector } from "react-redux";
import { getAuthToken } from "../../../services/HTTTPS";
import {
  ConfigProfanityCheck,
  getConfigProfanityCheck,
  putConfigProfanityCheck,
} from "../../../redux/actions/subWorkspaceAction";
import Button from "../../../components/Button/Button";
import TextArea from "../../../components/Common/TextArea/TextArea";
import ConfigureChecks from "../../../components/Common/ConfigureChecks/ConfigureChecks";
import Loader from "../../../components/Common/LoadingModal/LoadingModal";
import { toast } from "react-toastify";
import { configurationStatus } from "../../../ENUM";
const ProfanityCheckPage = () => {
  let workspace = JSON.parse(sessionStorage.getItem("workspace"));

  const dispatch = useDispatch();
  //const config = useSelector(state => state.ConfigurationSlice.getConfigProfanityCheck);
  const { data, isLoading } = useSelector((state) => state.ConfigurationSlice);

  const [options, setOptions] = useState({
    isBasicWordFiltering: 0,
    isLeetSpeakHandling: 0,
    isContextualProfanityDetection: 0,
    isWildcardsandRegex: 0,
    isPhoneticMatching: 0,
    isLanguageSpecificProfanityChecks: 0,
  });

  useEffect(() => {
    dispatch(getConfigProfanityCheck());
  }, []);

  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      isBasicWordFiltering: data?.isBasicWordFiltering || 0,
      workspace_id: workspace.workspace_id,
    }));
  }, [data]);

  const handleSwitchChange = (optionName, isChecked) => {
    const newValue = isChecked ? 1 : 0;
    setOptions((prevOptions) => ({
      ...prevOptions,
      [optionName]: newValue,
    }));
  };

  const handleSave = () => {
    const authToken = getAuthToken();
    if (authToken) {
      if (!data?.config_id) {
        dispatch(ConfigProfanityCheck(options)).then((res) => {
          if (res.payload.status == 200 || res.payload.status == 201) {
            dispatch(getConfigProfanityCheck());
            toast.success(configurationStatus?.Create);
          }
        });
      } else {
        const configId = data?.config_id;
        dispatch(putConfigProfanityCheck({ options, configId })).then((res) => {
          if (res.payload.status === 200 || res.payload.status == 201) {
            dispatch(getConfigProfanityCheck());
            toast.success(configurationStatus?.Create);
          }
        });
      }
    } else {
      console.log("no auth token");
    }
  };

  const handleReset = () => {
    setOptions((prev) => ({
      ...prev,
      isBasicWordFiltering: 0,
      workspace_id: workspace.workspace_id,
    }));
  };

  return (
    <div style={{ paddingInline: "30px" }}>
      <Loader isOpen={isLoading} />
      <h5>
        Detecting and filtering out profane language to maintain professionalism
        and integrity in the data
      </h5>
      <div className="ProfanityCheckBox">
        <ConfigureChecks
          onClick={() =>
            handleSwitchChange(
              "isBasicWordFiltering",
              options.isBasicWordFiltering === 0
            )
          }
          option={options.isBasicWordFiltering === 1}
          label="Basic Word Filtering"
          title = "Create a list of explicit profanities and filter out any text containing these words."
        />
      </div>
      {/* <TextArea
        onChange={() => {
          console.log("Hii add someting in this onchage!");
        }}
        width={"95%"}
        value={summaryValue}
        isDisabled={true}
        placeHolder={"Basic Word Filtering"}
      /> */}

      <div className="ButtonContainer">
        <Button label="Update" activeBtn onClick={handleSave} />
        <Button label="Reset" onClick={handleReset} />
      </div>
    </div>
  );
};

export default ProfanityCheckPage;
