import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "@mui/base/Dropdown";
import { Menu } from "@mui/base/Menu";
import { MenuButton as BaseMenuButton } from "@mui/base/MenuButton";
import { MenuItem as BaseMenuItem, menuItemClasses } from "@mui/base/MenuItem";
import { styled } from "@mui/system";
import {
  Box,
  Modal,
  TextField,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  Radio,
} from "@mui/material";
import { CssTransition } from "@mui/base/Transitions";
import { PopupContext } from "@mui/base/Unstable_Popup";
import AddIcon from "@mui/icons-material/Add";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import BackupIcon from "@mui/icons-material/Backup";
import axios from "axios";
import s from "./DropdownMenu.module.scss";
import Button from "../../Button/Button";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  getFileUpload,
  getFoldeAndFileListAction,
} from "../../../redux/actions/dataIngestionActions";
import {
  fetchBlobListForConnector,
  fetchIngestionContainer,
  uploadBlob,
} from "../../../pages/DynamicForm/DynamicFormServices";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "30%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "10px",
  p: 3,
};

function DropdownMenu({
  onChange,
  setFoldeName,
  folderId,
  hanldeCreateFolder,
  setNewFolderData,
  getRootProps,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [isDataConnectorOpen, setIsDataConnectorOpen] = useState(false);
  const [connectors, setConnectors] = useState([]);
  const [selectedConnectors, setSelectedConnectors] = useState({});
  const [isBlobListOpen, setIsBlobListOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedConnector, setSelectedConnector] = useState(null);
  const [blobList, setBlobList] = useState([]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getFileUpload());
  }, [dispatch]);

  const fetchIngestionContainerData = async () => {
    try {
      const data = await fetchIngestionContainer();
      setConnectors(data);

      const initialSelected = {};
      data.forEach((connector) => {
        initialSelected[connector._id] = false;
      });
      setSelectedConnectors(initialSelected);
    } catch (error) {
      console.error("Error fetching ingestion container:", error);
    }
  };
  const handleConnectorCheckboxChange = (event, connectorId) => {
    setSelectedConnectors((prev) => ({
      ...prev,
      [connectorId]: event.target.checked,
    }));
  };

  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const createHandleMenuClick = (menuItem) => {
    return () => {
      console.log(`Clicked on ${menuItem}`);
    };
  };

  const handleBlobStorage = () => {
    setIsDataConnectorOpen(true);
    fetchIngestionContainerData();
  };

  const handleCloseDataConnector = () => {
    setIsDataConnectorOpen(false);
  };

  const handleCloseBlobList = () => {
    setIsBlobListOpen(false);
  };

  const handleFileSelection = (blob) => {
    setSelectedFile(blob);
  };

  const fetchBlobList = async () => {
    if (selectedConnector) {
      try {
        const blobs = await fetchBlobListForConnector(selectedConnector);
        setBlobList(blobs);
        setIsBlobListOpen(true);
        setIsDataConnectorOpen(false);
      } catch (error) {
        console.error("Error fetching blob list:", error);
      }
    }
  };

  const uploadSelectedBlob = async () => {
    const token = sessionStorage.getItem("token");
    const workspaceString = sessionStorage.getItem("workspace");

    if (!token || !workspaceString || !selectedFile) {
      console.error("Token, workspace, or selected file not found");
      return;
    }

    let workspace;
    try {
      workspace = JSON.parse(workspaceString);
    } catch (error) {
      console.error("Error parsing workspace data:", error);
      return;
    }

    if (!workspace || !workspace?.workspace_id) {
      console.error("Invalid workspace data");
      return;
    }

    try {
      const response = await uploadBlob(
        token,
        selectedFile?.blobUrl,
        selectedConnector,
        workspace?.workspace_id
      );

      if (response.status === 201 || response.status === 200) {
        toast.success("File uploaded successfully.");
        handleCloseBlobList();
        // dispatch(getFoldeAndFileListAction());
        // dispatch(getFileUpload());
        dispatch(getFoldeAndFileListAction());
      } else {
        toast.error("Something went wrong while uploading the file");
      }
    } catch (error) {
      console.error("Error uploading blob:", error);
      toast.error("Something went wrong while uploading the file");
    }
  };
  const handleConnectorRadioChange = (connectorId) => {
    setSelectedConnector(connectorId);
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleCloseDialog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <h5>New Folder</h5>
            <div className={s.folderInput}>
              <TextField
                label="Folder Name"
                id="outlined-size-small"
                size="small"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setFoldeName(e.target.value);
                }}
                fullWidth
              />
            </div>
            <div className={s.btnBox}>
              <Button label="Cancel" onClick={handleCloseDialog} />
              <Button
                label="Create"
                activeBtn
                onClick={() => {
                  hanldeCreateFolder(name);
                  handleCloseDialog();
                }}
              />
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={isDataConnectorOpen}
        onClose={handleCloseDataConnector}
        aria-labelledby="data-connector-modal"
        aria-describedby="select-data-connectors"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            p: 4,
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          <h5 id="data-connector-modal">Select a Data Connector</h5>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid #ccc",
              paddingBottom: "10px",
              marginBottom: "10px",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          >
            <p style={{ fontWeight: "bold" }}>Name</p>
            <p style={{ fontWeight: "bold" }}>Type</p>
          </div>
          <List>
            {connectors.map((connector) => (
              <ListItem
                key={connector._id}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Radio
                    checked={selectedConnector === connector._id}
                    onChange={() => handleConnectorRadioChange(connector._id)}
                  />
                  <span>{connector.connector_settings.connectorName}</span>
                </div>
                <span>Azure Blob Storage</span>
              </ListItem>
            ))}
          </List>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              label="Next"
              activeBtn={selectedConnector !== null}
              onClick={fetchBlobList}
            />
          </div>
        </Box>
      </Modal>

      <Modal
        open={isBlobListOpen}
        onClose={handleCloseBlobList}
        aria-labelledby="blob-list-modal"
        aria-describedby="select-a-blob"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            p: 4,
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          <h5 id="blob-list-modal">Select a Blob</h5>
          <List>
            {blobList.map((blob, index) => (
              <ListItem key={index}>
                <FormControlLabel
                  control={
                    <Radio
                      checked={selectedFile && selectedFile.blob === blob.blob}
                      onChange={() => handleFileSelection(blob)}
                      value={blob.blob}
                      name="blob-selection-radio"
                    />
                  }
                  label={blob.blob}
                />
              </ListItem>
            ))}
          </List>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              label="Select"
              activeBtn={selectedFile !== null}
              onClick={uploadSelectedBlob}
            />
          </div>
        </Box>
      </Modal>

      <Dropdown>
        <MenuButton>
          <AddIcon style={{ marginRight: "10px" }} />
          New{" "}
        </MenuButton>
        <Menu slots={{ listbox: AnimatedListbox }}>
          <MenuItem onClick={createHandleMenuClick("Choose File")}>
            <label htmlFor="fileInput" style={{ cursor: "pointer" }}>
              {" "}
              <DriveFolderUploadIcon style={{ marginRight: "10px" }} />
              Upload File From Local Device
            </label>
          </MenuItem>
          <input
            id="fileInput"
            accept={
              folderId
                ? "image/* , audio/mpeg, audio/wav, audio/ogg , .mp4, .mov, .webm, .avi, .ogv"
                : "application/pdf, text/plain, application/msword , audio/mpeg, audio/wav, audio/ogg , .mp4, .mov, .webm, .avi, .ogv"
            }
            type="file"
            style={{ display: "none" }}
            onChange={onChange}
          />
          {!folderId && (
            <MenuItem onClick={createHandleMenuClick("Create Folder")}>
              <span onClick={handleOpenDialog}>
                <CreateNewFolderIcon style={{ marginRight: "10px" }} /> Create
                Folder
              </span>
            </MenuItem>
          )}
          <MenuItem onClick={handleBlobStorage}>
            <span style={{ cursor: "pointer" }}>
              <BackupIcon style={{ marginRight: "10px" }} />
              Upload File From Cloud Services
            </span>
          </MenuItem>
        </Menu>
      </Dropdown>
    </>
  );
}

export default DropdownMenu;

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#99CCF3",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E6",
  700: "#0059B3",
  800: "#004C99",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Listbox = styled("ul")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width: 200px;

  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  box-shadow: 0px 4px 30px ${
    theme.palette.mode === "dark" ? grey[900] : grey[200]
  };
  z-index: 1;

  .closed & {
    opacity: 0;
    transform: scale(0.95, 0.8);
    transition: opacity 200ms ease-in, transform 200ms ease-in;
  }
  
  .open & {
    opacity: 1;
    transform: scale(1, 1);
    transition: opacity 100ms ease-out, transform 100ms cubic-bezier(0.43, 0.29, 0.37, 1.48);
  }

  .placement-top & {
    transform-origin: bottom;
  }

  .placement-bottom & {
    transform-origin: top;
  }
  `
);

const AnimatedListbox = React.forwardRef(function AnimatedListbox(props, ref) {
  const { ownerState, ...other } = props;
  const popupContext = React.useContext(PopupContext);

  if (popupContext == null) {
    throw new Error(
      "The `AnimatedListbox` component cannot be rendered outside a `Popup` component"
    );
  }

  const verticalPlacement = popupContext.placement.split("-")[0];

  return (
    <CssTransition
      className={`placement-${verticalPlacement}`}
      enterClassName="open"
      exitClassName="closed"
    >
      <Listbox {...other} ref={ref} />
    </CssTransition>
  );
});

AnimatedListbox.propTypes = {
  ownerState: PropTypes.object.isRequired,
};

const MenuItem = styled(BaseMenuItem)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor:pointer;
  user-select: none;

  &:last-of-type {
    border-bottom: none;
  }

  &:focus {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${menuItemClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }
  `
);

const MenuButton = styled(BaseMenuButton)(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 10px;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  cursor:pointer;
  transition: all 150ms ease;
  cursor: pointer;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[200] : grey[900]};
 

  &:hover {
    background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
  }

  &:active {
    background: ${theme.palette.mode === "dark" ? grey[700] : grey[100]};
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${
      theme.palette.mode === "dark" ? blue[300] : blue[200]
    };
    outline: none;
  }
  `
);
