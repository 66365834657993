// -- React and related libs
import React, { useState } from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter, Redirect } from "react-router";

// -- Third Party Libs
import PropTypes from "prop-types";

// -- Custom Components
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import Breadcrumbs from "../Breadbrumbs/Breadcrumbs";
import Dashboard from "../../pages/dashboard/Dashboard";
import WorkSpace from "../../pages/myWorkspace/myWorkspace";
import Notifications from "../../pages/DataIngestion/DataIngetion";
import pipelineExecutions from "../../pages/pipelineexecution/pipelineExecutions";
import TabsComponents from "../../pages/DataReview/tabs";
import MetaDataTabs from "../../pages/MetaData/MetaDataTabs";
import Insightstabs from "../../pages/Insights/Tabs";
import Tables from "../../pages/tables/Tables";
import Connectors from "../../pages/DataSourceConnectors/Connectors";
// import Charts from "../../pages/uielements/charts/Charts";
// import Icons from "../../pages/uielements/icons/IconsPage";
// import Maps from "../../pages/uielements/maps/google/GoogleMapPage";
import { ToastContainer } from "react-toastify";

// -- Component Styles
import s from "./Layout.module.scss";
import Tabscomponent from "../../pages/DataReview/tabs";
import Download from "../../pages/download/download";
import Embeddings from "../../pages/Embeddings/Embeddings";
import ChunksCreation from "../../pages/Chunks/ChunksCreation";
import ChatBot from "../../pages/ChatBot/ChatBot";
import KnowladgeGraph from "../../pages/KnowladgeGraph/KnowladgeGraph";
import DynamicForm from "../../pages/DynamicForm/DynamicForm";
import Detail from "../../pages/DynamicForm/Detail";
import FineTuningTabs from "../../pages/FineTuning/FineTuningTabs";

const Layout = (props) => {
  const [selectedWorkspace, setSelectedWorkspace] = useState("My Workspace");

  const handleWorkspaceSelection = (name) => {
    setSelectedWorkspace(name);
  };

  return (
    <div className={s.root}>
      <div className={s.wrap}>
        <Header />
        <Sidebar
          selectedWorkspace={selectedWorkspace}
          onWorkspaceSelection={handleWorkspaceSelection}
        />
        {/* <main className={s.content}> */}
        {/* <Breadcrumbs url={props.location.pathname} /> */}
        <Switch>
          <Route
            path="/template"
            exact
            render={() => <Redirect to="/template/dashboard" />}
          />
          <Route path="/template/dashboard" exact component={Dashboard} />
          <Route path="/template/workspace" exact>
            <WorkSpace onWorkspaceSelection={handleWorkspaceSelection} />
          </Route>
          <Route path="/template/tables" exact component={Tables} />
          <Route
            path="/template/notifications"
            exact
            component={Notifications}
          />
          <Route
            path="/template/pipelineexecution"
            exact
            component={pipelineExecutions}
          />
          <Route path="/template/DataReview" exact component={TabsComponents} />
          <Route path="/template/MetaData" exact component={MetaDataTabs} />
          <Route path="/template/Insights" exact component={Insightstabs} />
          <Route path="/template/Embeddings" exact component={Embeddings} />
          <Route
            path="/template/KnowledgeGraph"
            exact
            component={KnowladgeGraph}
          />
          <Route
            path="/template/ChunksCreation"
            exact
            component={ChunksCreation}
          />
          <Route path="/template/Connectors" exact component={Connectors} />
          <Route path="/template/download" exact component={Download} />
          <Route path="/template/QnA" exact component={ChatBot} />
          <Route path="/template/DynamicForm" exact component={Detail} />
          <Route path="/template/FineTuning" exact component={FineTuningTabs} />

          <Route
            path="/template/ui-elements"
            exact
            render={() => <Redirect to={"/template/ui-elements/charts"} />}
          />
          {/* <Route path="/template/ui-elements/charts" exact component={Charts} /> */}
          {/* <Route path="/template/ui-elements/icons" exact component={Icons} /> */}
          {/* <Route path="/template/ui-elements/maps" exact component={Maps} /> */}
          <Route path="*" exact render={() => <Redirect to="/error" />} />
        </Switch>
        {/* </main> */}
        <Footer />
      </div>
    </div>
  );
};

Layout.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
  };
}

export default withRouter(connect(mapStateToProps)(Layout));
