import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Row } from "reactstrap";
import FineTuneUploadTabs from "./FineTuneUploadTabs";
import AzureOpenAitabs from "./AzureOpenAi/AzureOpenAitabs"


const FineTuningTabs = () => {

  const [activeTabIndex, setActiveTabIndex] = useState(0); // Track the active tab index

  const handleTabClick = (index) => {
    setActiveTabIndex(index);
  };
  return (
    <Tabs selectedIndex={activeTabIndex} onSelect={handleTabClick}>
      <TabList style={{ paddingInline: "15px" }}>
        <Tab>Open AI</Tab>
        <Tab>Azure Open AI</Tab>
        <Tab>Granite</Tab>
      </TabList>
      <TabPanel>
        <Row>
          <FineTuneUploadTabs />
        </Row>
      </TabPanel>
      <TabPanel>
        <Row>
          <AzureOpenAitabs />
        </Row>
      </TabPanel>
    </Tabs>
  );
};

export default FineTuningTabs;
