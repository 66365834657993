const data = {
    "name": "Workspace ",
    "children": [
        {
            "name": "Workspace-1",
            "children": [
                {
                    "name": "PDFs",
                    "number": "3",
                    "children": [
                        {
                            "name": "Tables",
                            "number": "6",
                        },
                        {
                            "name": "Embeddings",
                            "number": "8",
                        },
                        {
                            "name": "Tokens",
                            "number": "1",
                        }
                    ]
                },
                {
                    "name": "Images",
                    "number": "6",
                    "children": [
                        {
                            "name": "Tables",
                            "number": "7",
                        },
                        {
                            "name": "Embeddings",
                            "number": "6",
                        },
                        {
                            "name": "Tokens",
                            "number": "1",
                        }
                    ]
                },
                {
                    "name": "Text",
                    "number": "7",
                    "children": [
                        {
                            "name": "Tables",
                            "number": "6",
                        },
                        {
                            "name": "Embeddings",
                            "number": "1",
                        },
                        {
                            "name": "Tokens",
                            "number": "1",
                        }
                    ]
                },
                {
                    "name": "Docx/ Doc",
                    "number": "3",
                    "children": [
                        {
                            "name": "Tables",
                            "number": "2",
                        },
                        {
                            "name": "Embeddings",
                            "number": "6",
                        },
                        {
                            "name": "Tokens",
                            "number": "6",
                        }
                    ]
                }
            ]
        },
        {
            "name": "Workspace-2",
            "children": [
                {
                    "name": "PDFs",
                    "number": "3",
                    "children": [
                        {
                            "name": "Tables",
                            "number": "2",
                        },
                        {
                            "name": "Embeddings",
                            "number": "6",
                        },
                        {
                            "name": "Tokens",
                            "number": "6",
                        }
                    ]
                },
                {
                    "name": "Images",
                    "number": "3",
                    "children": [
                        {
                            "name": "Tables",
                            "number": "3",
                        },
                        {
                            "name": "Embeddings",
                            "number": "4",
                        },
                        {
                            "name": "Tokens",
                            "number": "1",
                        }
                    ]
                },
                {
                    "name": "Text",
                    "number": "3",
                    "children": [
                        {
                            "name": "Tables",
                            "number": "2",
                        },
                        {
                            "name": "Embeddings",
                            "number": "6",
                        },
                        {
                            "name": "Tokens",
                            "number": "6",
                        }
                    ]
                },
                {
                    "name": "Docx/ Doc",
                    "number": "3",
                    "children": [
                        {
                            "name": "Tables",
                            "number": "2",
                        },
                        {
                            "name": "Embeddings",
                            "number": "6",
                        },
                        {
                            "name": "Tokens",
                            "number": "6",
                        }
                    ]
                }
            ]
        },
        {
            "name": "Workspace-4",
            "children": [
                {
                    "name": "PDFs",
                    "children": [
                        {
                            "name": "Tables"
                        },
                        {
                            "name": "Embeddings"
                        },
                        {
                            "name": "Tokens"
                        }
                    ]
                },
                {
                    "name": "Images",
                    "children": [
                        {
                            "name": "Tables"
                        },
                        {
                            "name": "Embeddings"
                        },
                        {
                            "name": "Tokens"
                        }
                    ]
                },
                {
                    "name": "Text",
                    "children": [
                        {
                            "name": "Tables"
                        },
                        {
                            "name": "Embeddings"
                        },
                        {
                            "name": "Tokens"
                        }
                    ]
                },
                {
                    "name": "Docx/ Doc",
                    "children": [
                        {
                            "name": "Tables"
                        },
                        {
                            "name": "Embeddings"
                        },
                        {
                            "name": "Tokens"
                        }
                    ]
                }
            ]
        },
         {
            "name": "Workspace-3",
            "children": [
                {
                    "name": "PDFs",
                    "children": [
                        {
                            "name": "Tables"
                        },
                        {
                            "name": "Embeddings"
                        },
                        {
                            "name": "Tokens"
                        }
                    ]
                },
                {
                    "name": "Images",
                    "children": [
                        {
                            "name": "Tables"
                        },
                        {
                            "name": "Embeddings"
                        },
                        {
                            "name": "Tokens"
                        }
                    ]
                },
                {
                    "name": "Text",
                    "children": [
                        {
                            "name": "Tables"
                        },
                        {
                            "name": "Embeddings"
                        },
                        {
                            "name": "Tokens"
                        }
                    ]
                },
                {
                    "name": "Docx/ Doc",
                    "children": [
                        {
                            "name": "Tables"
                        },
                        {
                            "name": "Embeddings"
                        },
                        {
                            "name": "Tokens"
                        }
                    ]
                }
            ]
        },
        
    ]
}

export default data;
