import { PUT_API } from "./HTTTPS";

const BASE_URL = "aura-studio";

export const put_text_cleaning = (PAYLOAD, configId) => {
  return PUT_API(
    `${BASE_URL}/workspace/workspace-configs/text_cleaning/${configId}/`,
    PAYLOAD
  );
};

export const put_text_quality = (PAYLOAD, configId) => {
  return PUT_API(
    `${BASE_URL}/workspace/workspace-configs/text_quality_check/${configId}/`,
    PAYLOAD
  );
};

export const put_text_length = (PAYLOAD, configId) => {
  return PUT_API(
    `${BASE_URL}/workspace/workspace-configs/text_length_check/${configId}/`,
    PAYLOAD
  );
};

export const put_sensetive_info = (PAYLOAD, configId) => {
  return PUT_API(
    `${BASE_URL}/workspace/workspace-configs/text_sensitive_info_check/${configId}/`,
    PAYLOAD
  );
};

export const put_text_profanity = (PAYLOAD, configId) => {
  return PUT_API(
    `${BASE_URL}/workspace/workspace-configs/text_profanity_check/${configId}/`,
    PAYLOAD
  );
};

export const put_text_tables = (PAYLOAD, configId) => {
  return PUT_API(
    `${BASE_URL}/workspace/workspace-configs/text_extraction/${configId}/`,
    PAYLOAD
  );
};

export const edit_workspace = (PAYLOAD, workspaceId) => {
  return PUT_API(`${BASE_URL}/workspace/update/${workspaceId}/`, PAYLOAD);
};
