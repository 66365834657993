import React, { useEffect, useRef, useState } from "react";
import TextArea from "../../components/Common/TextArea/TextArea";
import Button from "../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  ReviewSensetiveInfoCheckAction,
  ReviewTextQualityAction,
} from "../../redux/actions/DataReviewAction";
import PDFRender from "../../components/Common/PDFRenderer/PDFRender";
import ExtractPDF from "../../components/Common/PDFRenderer/ExtractPDF";
import { getFileUpload } from "../../redux/actions/dataIngestionActions";
import Loader from "../../components/Common/LoadingModal/LoadingModal";
import { getConfigSensetiveInfo } from "../../redux/actions/subWorkspaceAction";
import NoFileAvailable from "./NoFileAvailable";
import { HOST } from "../../API/axios";
import "./CommonRender.scss";

const SensitiveInfoPage = () => {
  const [leftTextArea, setLeftTextArea] = useState("");
  const { data, isLoading } = useSelector((state) => state.DataReviewSlice);
  const [fileExtension, setFileExtension] = useState("");
  const [loading, setloading] = useState(false);
  const [extractedText, setextractedText] = useState("");
  const [UpdatedImgFolderData, setUpdatedImgFolderData] = useState([]);
  const { fileId, isFilePresent, fileName, fileData, folderId } = useSelector(
    (state) => state.PipelineExecutionSlice
  );

  const [rightTextArea, setRightTextArea] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const leftViewerRef = useRef(null);
  const rightViewerRef = useRef(null);
  const dispatch = useDispatch();
  const { sensetiveInfoConfigs } = useSelector(
    (state) => state.ConfigurationSlice
  );
  useEffect(() => {
    dispatch(getConfigSensetiveInfo());
  }, []);

  useEffect(() => {
    dispatch(ReviewSensetiveInfoCheckAction(fileId));
    // dispatch(getFileUpload());
  }, [fileId]);

  useEffect(() => {
    const processFiles = async () => {
      setloading(true);
      let imgData = [];
      for (const file of fileData) {
        try {
          await dispatch(ReviewSensetiveInfoCheckAction(file.id)).then(
            (res) => {
              if (res.payload) {
                imgData.push(
                  res.payload?.data?.sensitiveinfocheck_curatedcontent
                );
              }
            }
          );
        } catch (error) {
          setloading(false);
          console.error("Error processing file:", file.id, error);
        }
      }
      setUpdatedImgFolderData(imgData);
      setloading(false);
    };

    if (fileData && fileData.length > 0) {
      processFiles();
    }
  }, [fileData, dispatch]);

  useEffect(() => {
    const getFileExtension = (fileName) => {
      const parts = fileName?.split(".");
      return parts?.length > 1 ? "." + parts.pop() : "";
    };

    setFileExtension(getFileExtension(fileName));
  }, [fileName]);

  return (
    <div>
      <Loader isOpen={loading ? loading : isLoading} />
      <h5 style={{ marginLeft: "20px" }}>
        Identifying and removing sensitive information to ensure data privacy
        and compliance
      </h5>
      {(data?.extracted_text?.length > 0 || fileData?.length > 0) &&
      isFilePresent ? (
        <div className="main_render_container">
          <div className="outer_render_box">
            <PDFRender
              numPages={numPages}
              setNumPages={setNumPages}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              fileExtension={fileExtension}
              fileId={fileId}
              folderId={folderId}
              fileData={fileData}
              extractedText={extractedText}
              fileName={fileName}
              pdfurl={`${HOST}/aura-studio/workspace_files/pdf/6478cffd-8493-484f-af7e-c98dd152949c_sample.pdf`}
            />
          </div>
          <div className="outer_render_box">
            <ExtractPDF
              configs={sensetiveInfoConfigs}
              currentPage={currentPage}
              fileExtension={fileExtension}
              fileId={fileId}
              UpdatedImgFolderData={UpdatedImgFolderData}
              fileName={fileName}
              setextractedText={setextractedText}
              pageData={data?.sensitiveinfocheck_curatedcontent}
            />
          </div>
        </div>
      ) : (
        <NoFileAvailable isfile={true} />
      )}
      {/* <div className="ButtonContainer">
        <Button label={"Save"} activeBtn />
        <Button label={"Reset"} />
      </div> */}
    </div>
  );
};

export default SensitiveInfoPage;
