import { Axios } from "axios";
import { getAuthToken } from "../../services/HTTTPS";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { create_workspace, text_cleaning } from "../../services/postServices";
import {
  Get_Workspace_List,
  workspace_dashboard,
} from "../../services/getServices";
import { edit_workspace } from "../../services/putServices";
import { delete_workspace } from "../../services/deleteServices";
import { toast } from "react-toastify";

export const WorkspaceListAction = createAsyncThunk(
  "WorkspaceListAction",
  async (value) => {
    try {
      // let PAYLOAD = value
      //console.log("value", value);
      const data = await Get_Workspace_List(value);

      if (data && data.response) {
        //console.log("data", data);
        return data.data;
      } else {
        throw data;
      }
    } catch (error) {
       if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error. Please try again later.");
     } 
      return error;
    }
  }
);
export const CreateWorkspaceAction = createAsyncThunk(
  "CreateWorkspaceAction",
  async (value) => {
    try {
      // let PAYLOAD = value
      //console.log("value", value);
      const data = await create_workspace(value);

      if (data && data.status === 201) {
        //console.log("data", data);
        return data;
      } else {
        throw data;
      }
    } catch (error) {
       if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error. Please try again later.");
     } 
      return error;
    }
  }
);
export const EditWorkspaceAction = createAsyncThunk(
  "EditWorkspaceAction",
  async (workspaceDetails) => {
    try {
      let value = {
        workspace_name: workspaceDetails.workspace_name,
        short_description: workspaceDetails.short_description,
        detail_description: workspaceDetails.detail_description,
        workspace_type: workspaceDetails.workspace_type,
      };
      //console.log("workspaceDetails-><>><><><>", workspaceDetails);
      // let PAYLOAD = value
      //console.log("value", workspaceDetails);
      const data = await edit_workspace(value, workspaceDetails?.workspace_id);

      if (data && data.status === 201) {
        //console.log("data", data);
        return data.data;
      } else {
        throw data;
      }
    } catch (error) {
       if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error. Please try again later.");
     } 
      return error;
    }
  }
);
export const DeleteWorkspaceAction = createAsyncThunk(
  "DeleteWorkspaceAction",
  async (workspaceDetails) => {
    try {
      let value = {
        workspace_name: workspaceDetails.workspace_name,
        short_description: workspaceDetails.short_description,
        detail_description: workspaceDetails.detail_description,
        workspace_type: workspaceDetails.workspace_type,
        is_deleted: workspaceDetails.is_deleted,
      };
      // let PAYLOAD = value
      //console.log("value", workspaceDetails);
      const data = await delete_workspace(
        workspaceDetails?.workspace_id,
        value
      );

      if (data && data.status === 201) {
        //console.log("data", data);
        return data.data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error. Please try again later.");
     } 
      return error;
    }
  }
);
export const WorkspaceDashboardAction = createAsyncThunk(
  "WorkspaceDashboardAction",
  async () => {
    try {
      const data = await workspace_dashboard();
      if ((data && data.status === 201) || data.status === 200) {
        return data.data;
      } else {
        throw data;
      }
    } catch (error) {
      //console.log("error---tok check token", error);
      if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error. Please try again later.");
     } 
      return error;
    }
  }
);
