import React, { useState, useEffect } from "react";
import filterIcon from "../../assets/filter.png";

const TokenTracingTable = ({ data }) => {
  const handleElementClick = () => {
    console.log("Element clicked!");
  };

  const elements = document.getElementsByClassName("filter-container");
  console.log("elements-->>", elements);

  if (elements.length > 0) {
    Array.from(elements).forEach((element) => {
      element.addEventListener("click", handleElementClick);
    });
  }

  const totals = {
    token: 0,
    completionToken: 0,
    totalToken: 0,
    tokenCost: 0,
    latency: 0,
  };

  data.forEach((row) => {
    totals.token += row.token || 0;
    totals.completionToken += row.completionToken || 0;
    totals.totalToken += row.totalToken || 0;
    totals.tokenCost += row.tokenCost || 0;
    totals.latency += row.latency || 0;
  });

  return (
    <div className="tooltip-content">
      <div className="tooltip-header">
        <p>gpt4o</p>
        <div
          className="filter-container"
          id="filterBox"
          onClick={handleElementClick}
          //   onclick={() => handleClick()}
        >
          <img src={filterIcon} className="filer-icon" alt="filter" />
          <p>Filter</p>
        </div>
      </div>
      <table className="tooltip-table">
        <thead>
          <tr className="tooltip-row header">
            <th className="tooltip-column">Sr.No</th>
            <th className="tooltip-column">Question</th>
            <th className="tooltip-column">Prompt Token</th>
            <th className="tooltip-column">Completion Token</th>
            <th className="tooltip-column">Total Token</th>
            <th className="tooltip-column">Token Cost</th>
            <th className="tooltip-column">Latency</th>
            <th className="tooltip-column">Creation Time</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((row, index) => (
            <tr key={index} className="tooltip-row">
              <td className="tooltip-column">{index + 1}</td>
              <td className="tooltip-column">{row.question}</td>
              <td className="tooltip-column">{row.token}</td>
              <td className="tooltip-column">{row.completionToken}</td>
              <td className="tooltip-column">{row.totalToken}</td>
              <td className="tooltip-column">$ {row.tokenCost}</td>
              <td className="tooltip-column">{row.latency}</td>
              <td className="tooltip-column">{row.creationTime}</td>
            </tr>
          ))}

          <tr className="tooltip-row totals-row">
            <td className="tooltip-column">Total</td>
            <td className="tooltip-column"></td>
            <td className="tooltip-column">{totals.token}</td>
            <td className="tooltip-column">{totals.completionToken}</td>
            <td className="tooltip-column">{totals.totalToken}</td>
            <td className="tooltip-column">$ {totals.tokenCost.toFixed(2)}</td>
            <td className="tooltip-column"></td>
            <td className="tooltip-column"></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TokenTracingTable;
