import React, { useState } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import AzureUploadJson from "./FineTuneAzureopenAI";
import AzureOpenAiRaft from "./AzureOpenAiRaft";


export default function CenteredTabs() {
  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Json File" />
        <Tab label="Create JSON File" />
      </Tabs>
      {value === 0 && <AzureUploadJson />}
      {value === 1 && <AzureOpenAiRaft />}
    </Box>
  );
}
