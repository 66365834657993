import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import rootreducer from "../reducers";

export default configureStore({
  reducer: rootreducer,
  // applyMiddleware(ReduxThunk),
  // middleware: getDefaultMiddleware(), // Use only default middleware
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
  devTools: process.env.NODE_ENV !== "production", // Enable Redux DevTools in development
});
