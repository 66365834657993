import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import s from "./Sidebar.module.scss";
import LinksGroup from "./LinksGroup/LinksGroup.js";
import { changeActiveSidebarItem } from "../../actions/navigation.js";
import SofiaLogo from "../Icons/SofiaLogo.js";
import cn from "classnames";
import SettingsIcon from "@mui/icons-material/Settings";
import DownloadIcon from "@mui/icons-material/Download";
import MovingIcon from "@mui/icons-material/Moving";
import HubIcon from "@mui/icons-material/Hub";
import InsightsIcon from "@mui/icons-material/Insights";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import PreviewIcon from "@mui/icons-material/Preview";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DatasetIcon from "@mui/icons-material/Dataset";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import DeblurIcon from "@mui/icons-material/Deblur";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min.js";
import Button from "../Button/Button.js";

const Sidebar = (props) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { activeItem = "", ...restProps } = props;
  const [subLocation, setsubLocation] = useState([
    "/template/tables",
    "/template/notifications",
    "/template/pipelineexecution",
    "/template/DataReview",
    "/template/Insights",
    "/template/ChunksCreation",
    "/template/Embeddings",
    "/template/MetaData",
    ,
    "/template/KnowladgeGraph",
    "/template/download",
    "/template/QnA",
    "/template/KnowledgeGraph",
    "/template/Connectors", // Added Connectors page
    "/template/FineTuning", // fine tuning tab
  ]);
  const [burgerSidebarOpen, setBurgerSidebarOpen] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState("My Workspace");
  let workspaceName = JSON.parse(sessionStorage.getItem("workspace"));

  useEffect(() => {
    if (props.sidebarOpened) {
      setBurgerSidebarOpen(true);
    } else {
      setTimeout(() => {
        setBurgerSidebarOpen(false);
      }, 0);
    }
  }, [props.sidebarOpened]);

  useEffect(() => {
    // Update selected workspace when props change
    setSelectedWorkspace(props.selectedWorkspace || "My Workspace");
  }, [props.selectedWorkspace]);

  // Update showDetails when selectedWorkspace changes
  useEffect(() => {
    setShowDetails(
      selectedWorkspace !== "My Workspace" || subLocation.includes(pathname)
    );
  }, [selectedWorkspace, pathname]);

  useEffect(() => {
    if (pathname === "/template/workspace") {
      // setShowDetails(false);
      setSelectedWorkspace("My Workspace");
    }
  }, [pathname]);

  const handleConnectionsClick = () => {
    history.push("/template/Connectors");
  };

  const isSubLocationOrShowDetails =
    subLocation.includes(pathname) || showDetails;

  return (
    <nav className={cn(s.root, { [s.sidebarOpen]: burgerSidebarOpen })}>
      <header className={s.logo}>
        <SofiaLogo />
        <span className={s.title}></span>
      </header>
      <ul className={s.nav}>
        {/* <div>
          <LinksGroup
            onActiveSidebarItemChange={(activeItem) =>
              props.dispatch(changeActiveSidebarItem(activeItem))
            }
            activeItem={props.activeItem}
            header="Dashboard"
            isHeader
            isSubHeader={false}
            // iconName={<i className={'eva eva-home-outline'}/>}
            link="/template/dashboard"
            index="dashboard"
          />
        </div> */}

        {isSubLocationOrShowDetails ? (
          // <LinksGroup
          //   onActiveSidebarItemChange={(activeItem) =>
          //     props.dispatch(changeActiveSidebarItem(activeItem))
          //   }
          //   activeItem={props.activeItem}
          //   header={
          //     !workspaceName?.workspace_name.length
          //       ? "My Workspace"
          //       : workspaceName?.workspace_name
          //   }
          //   isHeader
          //   isSubHeader={false}
          //   isWorkSpace={true}
          //   link="/template/workspace"
          //   index={props.selectedWorkspace}
          // />
          <></>
        ) : (
          <LinksGroup
            onActiveSidebarItemChange={(activeItem) =>
              props.dispatch(changeActiveSidebarItem(activeItem))
            }
            activeItem={props.activeItem}
            header={"My Workspace"}
            isHeader
            isSubHeader={false}
            isWorkSpace={true}
            link="/template/workspace"
            index={"workspace"}
          />
        )}
        {isSubLocationOrShowDetails && (
          <>
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={props.activeItem}
              header="Configuration"
              isSubHeader={true}
              isHeader
              iconName={<SettingsIcon />}
              link="/template/tables"
              index="Configuration"
            />
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={props.activeItem}
              header="Data Ingestion"
              iconName={<HubIcon />}
              isSubHeader={true}
              isHeader
              link="/template/notifications"
              index="Data Ingestion"
            />
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={props.activeItem}
              header="Pipeline Execution"
              isSubHeader={true}
              isHeader
              iconName={<AccountTreeIcon />}
              link="/template/pipelineexecution"
              index="Pipeline Execution"
            />
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={props.activeItem}
              header="Data Review"
              isSubHeader={true}
              // iconName={<PreviewIcon />}
              iconName={<AssignmentIcon />}
              isHeader
              link="/template/DataReview"
              index="Data Review"
            />
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={props.activeItem}
              header="Insights"
              isSubHeader={true}
              isHeader
              link="/template/Insights"
              iconName={<InsightsIcon />}
              index="Insights"
            />
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={props.activeItem}
              header="Define Chunks"
              isSubHeader={true}
              isHeader
              link="/template/ChunksCreation"
              iconName={<DeblurIcon />}
              index="Define Chunks"
            />
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={props.activeItem}
              header="Embeddings"
              isSubHeader={true}
              isHeader
              link="/template/Embeddings"
              iconName={<SettingsEthernetIcon />}
              index="Embeddings"
            />
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={props.activeItem}
              header="Knowledge Graph"
              isSubHeader={true}
              isHeader
              link="/template/KnowledgeGraph"
              iconName={<ScatterPlotIcon />}
              index="KnowledgeGraph"
            />
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={props.activeItem}
              header="Yavi.Bot"
              isSubHeader={true}
              iconName={<TipsAndUpdatesIcon />}
              isHeader
              link="/template/QnA"
              index="Yavi.Bot"
            />

            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={props.activeItem}
              header="Download"
              isSubHeader={true}
              iconName={<DownloadIcon />}
              isHeader
              link="/template/download"
              index="Download"
            />
            {/* <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={props.activeItem}
              header="Fine Tune"
              isSubHeader={true}
              iconName={<DeviceHubIcon />}
              isHeader
              link="/template/FineTuning"
              index="FineTuning"
            /> */}
          </>
        )}
      </ul>
      <div style={{ marginBottom: "10px", marginRight: "5px" }}>
        <Button
          label="Connections"
          activeBtn={true}
          onClick={handleConnectionsClick}
        />
      </div>
    </nav>
  );
};

Sidebar.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  activeItem: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    activeItem: store.navigation.activeItem,
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
