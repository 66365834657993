import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  get_extracted_table_details,
  get_profanityCheck_curation,
  get_sensetiveInfoCheck_curation,
  get_textCleaning_curation,
  get_textQualityCheck_curation,
} from "../../services/getServices";
import {
  extract_table,
  perform_curation_all,
  profanityCheck_curation,
  save_profanityCheck_curation,
  save_sensetiveInfoCheck_curation,
  save_textCleaning_curation,
  save_textQualityCheck_curation,
  sensetiveInfoCheck_curation,
  textCleaning_curation,
  textQualityCheck_curation,
  validation_execution,
} from "../../services/postServices";
import { toast } from "react-toastify";
// let workspace = "";
// let workspace = JSON?.parse(sessionStorage.getItem("workspace"));
// let file_id = "";
// let file_id = JSON?.parse(sessionStorage.getItem("file_id"));

export const TextCleaningCurationAction = createAsyncThunk(
  "TextCleaningCurationAction",
  async () => {
    try {
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      let file_id = JSON.parse(sessionStorage.getItem("file_id"));
      let value = {
        workspace_id: workspace?.workspace_id,
        file_id: file_id,
      };
      const data = await textCleaning_curation(value);

      if ((data && data.status === 201) || data.status === 200) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);

export const TextQualityCheckCurationAction = createAsyncThunk(
  "TextQualityCheckCurationAction",
  async () => {
    try {
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      let file_id = JSON.parse(sessionStorage.getItem("file_id"));
      let value = {
        workspace_id: workspace?.workspace_id,
        file_id: file_id,
      };

      const data = await textQualityCheck_curation(value);

      if ((data && data.status === 201) || data.status === 200) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);
export const SensetiveInfoCheckCurationAction = createAsyncThunk(
  "SensetiveInfoCheckCurationAction",
  async () => {
    try {
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      let file_id = JSON.parse(sessionStorage.getItem("file_id"));
      let value = {
        workspace_id: workspace?.workspace_id,
        file_id: file_id,
      };
      const data = await sensetiveInfoCheck_curation(value);

      if ((data && data.status === 201) || data.status === 200) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);
export const ProfanityCheckCurationAction = createAsyncThunk(
  "ProfanityCheckCurationAction",
  async () => {
    try {
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      let file_id = JSON.parse(sessionStorage.getItem("file_id"));
      let value = {
        workspace_id: workspace?.workspace_id,
        file_id: file_id,
      };
      const data = await profanityCheck_curation(value);

      if ((data && data.status === 201) || data.status === 200) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);

export const SaveTextCleaningCurationAction = createAsyncThunk(
  "SaveTextCleaningCurationAction",
  async (value) => {
    try {
      const data = await save_textCleaning_curation(value);

      if ((data && data.status === 201) || data.status === 200) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);
export const SaveTextQualityCheckCurationAction = createAsyncThunk(
  "SaveTextQualityCheckCurationAction",
  async (value) => {
    try {
      const data = await save_textQualityCheck_curation(value);

      if ((data && data.status === 201) || data.status === 200) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);

export const SaveSensetiveInfoCheckCurationAction = createAsyncThunk(
  "SaveSensetiveInfoCheckCurationAction",
  async (value) => {
    try {
      const data = await save_sensetiveInfoCheck_curation(value);

      if ((data && data.status === 201) || data.status === 200) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);

export const SaveProfanityCheckCurationAction = createAsyncThunk(
  "SaveProfanityCheckCurationAction",
  async (value) => {
    try {
      const data = await save_profanityCheck_curation(value);
      if ((data && data.status === 201) || data.status === 200) {
        toast.success("Curation performed successfully.");
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);

export const ExtractTableAction = createAsyncThunk(
  "ExtractTableAction",
  async (fileId) => {
    try {
      const value = {
        file_id: fileId,
      };
      const data = await extract_table(value);
      if ((data && data.status === 201) || data.status === 200) {
        sessionStorage.setItem("table_id", data?.data?.id);
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      console.log(error);
      // if (error.response && error.response.status === 500) {
      //   toast.error("Internal Server Error. Please try again later.");
      // }

      return error;
    }
  }
);
export const ValidationExecutionFlow = createAsyncThunk(
  "ValidationExecutionFlow",
  async () => {
    try {
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      const value = {
        workspace_id: workspace?.workspace_id,
      };
      const data = await validation_execution(value);
      if ((data && data.status === 201) || data.status === 200) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      toast.error(error.response?.data?.error);
      // console.log("error", error.response?.data?.error);
      return error;
    }
  }
);

// peform all curation in one api "PerformCurationAllAction"
export const PerformCurationAllAction = createAsyncThunk(
  "PerformCurationAllAction",
  async (value) => {
    try {
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      let payload = {
        workspace_id: workspace.workspace_id,
        file_id: value,
      };
      const data = await perform_curation_all(payload);
      if ((data && data.status === 201) || data.status === 200) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);
