import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  create_chunks,
  create_embeddings,
  create_topic_configration,
  create_topic_metadata,
  keyword_config,
  meta_data_creation,
  get_inserted_pinecode_data,
  insert_pinecone_config,
  pinecode_insert_vectors,
  create_Tables_chunks,
  create_embedding_chart,
  insert_embedding_faiss_db,
  insert_embedding_chromaDb,
  store_embedding_chromaDb,
} from "../../services/postServices";

import {
  retrive_metadata,
  retrive_metadata_configrations,
  retrive_chunks,
  download_embeddings,
  retrive_embeddings,
  retrive_topic_configration,
  retrive_topic_metadata,
  retrive_Tables_chunks,
  render_chart_image,
  get_chromaDB_data,
} from "../../services/getServices";

//137.117.45.236:5008/aura-studio/datachunks/retrieve-chunks/<int:file_id>/

export const CreateChunksAction = createAsyncThunk(
  "CreateChunksAction",
  async (value) => {
    try {
      let payload = {
        ...value,
      };
      const data = await create_chunks(payload);
      if (data && data.response) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      } else if (error?.response?.status === 404) {
        toast.error(error?.response?.data?.error);
      }
      return error;
    }
  }
);
export const getChunkAction = createAsyncThunk(
  "getChunkAction",
  async (fileId) => {
    try {
      const data = await retrive_chunks(fileId);

      if (data.status === 200 || data.status === 201) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);
export const CreateChunksTablesAction = createAsyncThunk(
  "CreateChunksTablesAction",
  async (value) => {
    try {
      let payload = {
        ...value,
      };
      const data = await create_Tables_chunks(payload);
      if (data.status === 200 || data.status === 201) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      if (error.response?.status === 404) {
        toast.error(error?.response?.data?.error);
      }
      return error;
    }
  }
);
export const getChunkTablesAction = createAsyncThunk(
  "getChunkTablesAction",
  async (fileId) => {
    try {
      const data = await retrive_Tables_chunks(fileId);
      if (data.status === 200 && data.status === 201) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);
export const createEmbeddingsActions = createAsyncThunk(
  "createEmbeddingsActions",
  async (payload) => {
    try {
      const data = await create_embeddings(payload);
      if (data.status === 200 || data.status === 201) {
        toast.success("Embeddings created successfully.");
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);
export const getEmbeddingsAction = createAsyncThunk(
  "getEmbeddingsAction",
  async (fileId) => {
    try {
      const data = await retrive_embeddings(fileId);
      if (data.status === 200 || data.status === 201) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      // if (error.response && error.response.status === 500) {
      //   toast.error("Internal Server Error. Please try again later.");
      // }
      return error;
    }
  }
);

export const CreateKeywordsAction = createAsyncThunk(
  "CreateKeywordsAction",
  async (value) => {
    try {
      const data = await keyword_config(value);
      if (data.status === 200 && data.status === 201) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);
export const GetCreateKeywordsAction = createAsyncThunk(
  "GetCreateKeywordsAction",
  async (fileId) => {
    try {
      const data = await retrive_metadata_configrations(fileId);
      if (data.status === 200 && data.status === 201) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);

export const CreateMetaDataAction = createAsyncThunk(
  "CreateMetaDataAction",
  async (value) => {
    try {
      let payload = {
        ...value,
      };
      const data = await meta_data_creation(payload);
      if (data.status === 200 && data.status === 201) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);

export const GetMetaDataAction = createAsyncThunk(
  "GetMetaDataAction",
  async (fileId) => {
    try {
      const data = await retrive_metadata(fileId);
      // console.log("chunk data--> data", data);
      if (data.status === 200 && data.status === 201) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);

export const CreateTopicAction = createAsyncThunk(
  "CreateTopicAction",
  async (value) => {
    try {
      const data = await create_topic_configration(value);
      // console.log("embeddings data-->>", data);
      if (data.status === 200 && data.status === 201) {
        // toast.success("Embeddings created successfully.");
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);

export const getCreateTopicAction = createAsyncThunk(
  "getCreateTopicAction",
  async (fileId) => {
    try {
      const data = await retrive_topic_configration(fileId);
      // console.log("chunk data--> data", data);
      if (data.status === 200 && data.status === 201) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);

export const CreateTopicMetadataAction = createAsyncThunk(
  "CreateTopicMetadataAction",
  async (value) => {
    try {
      const data = await create_topic_metadata(value);
      // console.log("embeddings data-->>", data);
      if (data.status === 200 && data.status === 201) {
        // toast.success("Embeddings created successfully.");
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);
export const getCreateTopicMetadataAction = createAsyncThunk(
  "getCreateTopicMetadataAction",
  async (fileId) => {
    try {
      const data = await retrive_topic_metadata(fileId);
      // console.log("chunk data--> data", data);
      if (data.status === 200 && data.status === 201) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);
export const downloadEmbeddingsAction = createAsyncThunk(
  "downloadEmbeddingsAction",
  async (fileId) => {
    try {
      const data = await download_embeddings(fileId);
      // console.log("download embedding", data);
      if (data.status === 200 || data.status === 201) {
        toast.success("Embeddings downloaded successfullly.");
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);
export const insertPineconeConfigEmbeddingsAction = createAsyncThunk(
  "insertPineconeConfigEmbeddingsAction",
  async (payload) => {
    try {
      const data = await insert_pinecone_config(payload);
      // console.log("chunk data--> data", data);
      if (data.status === 200 || data.status === 201) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response)
        if (error.response && error.response.status === 500) {
          toast.error("Internal Server Error. Please try again later.");
        }
      return error;
    }
  }
);
export const pinecodeInsertVectorAction = createAsyncThunk(
  "pinecodeInsertVectorAction",
  async (payload) => {
    try {
      const data = await pinecode_insert_vectors(payload);
      if (data.status === 200 || data.status === 201) {
        toast.success("Embeddings inserted to pinecone database successfully.");
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      // console.log("error-->>", error);
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);
export const getIninsertedePineconeData = createAsyncThunk(
  "getIninsertedePineconeData",
  async (payload) => {
    try {
      const data = await get_inserted_pinecode_data(payload);
      // console.log("chunk data--> data", data);
      if (data.status === 200 || data.status === 201) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal server error, please try after some time.");
      }
      return error;
    }
  }
);
export const createEmbeddingsChartAction = createAsyncThunk(
  "createEmbeddingsChartAction",
  async (payload) => {
    try {
      const data = await create_embedding_chart(payload);
      // console.log("chunk data--> data", data);
      if (data.status === 200 || data.status === 201) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);
export const renderChartImageAction = createAsyncThunk(
  "renderChartImageAction",
  async (payload) => {
    try {
      const data = await render_chart_image(payload);
      // console.log("chart img data--> data", data);
      if (data.status === 200 || data.status === 201) {
        const blob = new Blob([data.data], { type: "image/png" });
        const objectUrl = URL.createObjectURL(blob);
        return { objectUrl, status: data.status };
      } else {
        throw data;
      }
    } catch (error) {
      // console.log("error");
    }
  }
);

export const insertEmbeddingsToFaissDBAction = createAsyncThunk(
  "insertEmbeddingsToFaissDBAction",
  async (payload) => {
    try {
      const data = await insert_embedding_faiss_db(payload);
      // console.log("chunk data--> data", data);
      if (data.status === 200 || data.status === 201) {
        toast.success("Embeddings inserted successfully to Faiss DB");
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal server error, please try after some time.");
      }
      return error;
    }
  }
);
export const insertEmbeddingsToChormaDBAction = createAsyncThunk(
  "insertEmbeddingsToChormaDBAction",
  async (payload) => {
    try {
      const data = await insert_embedding_chromaDb(payload);
      // console.log("chunk data--> data", data);
      if (data.status === 200 || data.status === 201) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal server error, please try after some time.");
      }
      return error;
    }
  }
);
export const storeEmbeddingsToChormaDBAction = createAsyncThunk(
  "storeEmbeddingsToChormaDBAction",
  async (payload) => {
    try {
      const data = await store_embedding_chromaDb(payload);
      // console.log("chunk data--> data", data);
      if (data.status === 200 || data.status === 201) {
        toast.success("Embeddings inserted successfully to Faiss DB");
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal server error, please try after some time.");
      }
      return error;
    }
  }
);
export const getEmbeddingsDataOfChromaDBAction = createAsyncThunk(
  "getEmbeddingsDataOfChromaDBAction",
  async (payload) => {
    try {
      const data = await get_chromaDB_data(payload);
      // console.log("chunk data--> data", data);
      if (data.status === 200 || data.status === 201) {
        toast.success("Embeddings inserted successfully to Faiss DB");
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal server error, please try after some time.");
      }
      return error;
    }
  }
);
