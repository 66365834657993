import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect, useSelector } from "react-redux";

import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  InputGroupAddon,
  InputGroup,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  FormGroup,
} from "reactstrap";

import { logoutUser } from "../../actions/auth";
import { closeSidebar, openSidebar } from "../../actions/navigation";
import MenuIcon from "../Icons/HeaderIcons/MenuIcon";
import WorkspaceModal from "../../pages/myWorkspace/newWorkspace.js";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import SearchBarIcon from "../Icons/HeaderIcons/SearchBarIcon";
import SearchIcon from "../Icons/HeaderIcons/SearchIcon";
import Tooltip from "@mui/material/Tooltip";
import ProfileIcon from "../../assets/navbarMenus/pfofileIcons/ProfileIcon";
import MessagesIcon from "../../assets/navbarMenus/pfofileIcons/MessagesIcon";
import TasksIcon from "../../assets/navbarMenus/pfofileIcons/TasksIcon";

import logoutIcon from "../../assets/navbarMenus/pfofileIcons/logoutOutlined.svg";
import basketIcon from "../../assets/navbarMenus/basketIcon.svg";
import calendarIcon from "../../assets/navbarMenus/calendarIcon.svg";
import envelopeIcon from "../../assets/navbarMenus/envelopeIcon.svg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import mariaImage from "../../assets/navbarMenus/mariaImage.jpg";
import notificationImage from "../../assets/navbarMenus/notificationImage.jpg";
import userImg from "../../assets/user.svg";
import backButton from "../../assets/backButton.png";
import Avatar from "@mui/material/Avatar";
import { deepOrange, deepPurple } from "@mui/material/colors";

import s from "./Header.module.scss";
import "animate.css";
import ContentHeader from "../Common/ContentHeader/ContentHeader";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min.js";
import { logoutSuccess } from "../../redux/slices/authSlice.js";
import { logOutAction } from "../../redux/actions/AuthAction.js";
import Logout from "../../pages/login/logout.js";
import { Box, display } from "@mui/system";

const Header = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const { pathname } = useLocation();
  const toggleModal = () => setModal(!modal);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const { isLogout } = useSelector((state) => state.authSlice);
  let workspace = JSON.parse(sessionStorage.getItem("workspace"));
  const capitalizeFirstLetterOfWords = (str) => {
    if (!str) return "";
    return str?.replace(/\b\w/g, (char) => char?.toUpperCase());
  };
  let capitalizedWorkspaceName = capitalizeFirstLetterOfWords(
    workspace?.workspace_name
  );

  const useData = JSON.parse(sessionStorage.getItem("user"));

  const toggleNotifications = () => {
    setNotificationsOpen(!notificationsOpen);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleSidebar = () => {
    if (props.sidebarOpened) {
      props.dispatch(closeSidebar());
    } else {
      const paths = props.location.pathname.split("/");
      paths.pop();
      props.dispatch(openSidebar());
    }
  };

  useEffect(() => {
    if (isLogout) {
      sessionStorage.clear();
      history.push("/login");
    }
  }, [isLogout]);

  const handleLogout = () => {
    props.dispatch(logOutAction());
  };
  const styles = {
    marginTop: pathname === "/template/workspace" ? "0" : "30px",
    marginBottom: "40px",
  };

  return (
    <>
      <Navbar className={`${s.root} d-print-none`}>
        <div>
          {/* <div
            className={
              pathname === "/workspace"
                ? "d-flex justify-content-between align-items-center mb-3"
                : "d-flex justify-content-between align-items-center mb-3"
            }
            style={styles}
          >
            <div className={s.leftSideTitle}>
              <h5
                style={{ display: "inline-block" }}
              >{`Workspace : ${capitalizedWorkspaceName}`}</h5>{" "}
            </div>
          </div> */}
          <NavLink
            onClick={() => toggleSidebar()}
            className={`d-md-none mr-3 ${s.navItem}`}
            href="#"
          >
            <MenuIcon className={s.menuIcon} />
          </NavLink>
        </div>

        <Nav>
          {/* <div className="title-container">
            <h2>AURA Data Studio</h2>
          </div> */}

          <Dropdown
            nav
            isOpen={menuOpen}
            toggle={() => toggleMenu()}
            className="tutorial-dropdown mr-2 mr-sm-3"
            style={{ marginRight: "24px" }}
          >
            {/* <div style={{ display: "flex" }}> */}
            {/* <DropdownToggle nav>
              <div className={s.navbarBlock}>
                <i className={"eva eva-bell-outline"} />
                <div className={s.count}></div>
              </div>
            </DropdownToggle> */}
            <DropdownMenu
              right
              className="navbar-dropdown notifications-dropdown"
            >
              <DropdownItem>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#5474f8"
                >
                  <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h240l80 80h320q33 0 56.5 23.5T880-640v400q0 33-23.5 56.5T800-160H160Zm0-80h640v-400H447l-80-80H160v480Zm0 0v-480 480Z" />
                </svg>
                <span>
                  New workspace with name 'Rulebook' is created and ready to use
                  for data processing.
                </span>
              </DropdownItem>
              <DropdownItem>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#5474f8"
                >
                  <path d="M680-80q-83 0-141.5-58.5T480-280q0-83 58.5-141.5T680-480q83 0 141.5 58.5T880-280q0 83-58.5 141.5T680-80Zm67-105 28-28-75-75v-112h-40v128l87 87Zm-547 65q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h167q11-35 43-57.5t70-22.5q40 0 71.5 22.5T594-840h166q33 0 56.5 23.5T840-760v250q-18-13-38-22t-42-16v-212h-80v120H280v-120h-80v560h212q7 22 16 42t22 38H200Zm280-640q17 0 28.5-11.5T520-800q0-17-11.5-28.5T480-840q-17 0-28.5 11.5T440-800q0 17 11.5 28.5T480-760Z" />
                </svg>
                <span>1 File is processing...</span>
              </DropdownItem>
              {/* <DropdownItem>
                <img src={envelopeIcon} alt="Envelope Icon" />
                <span>you have 2 new messages</span>
              </DropdownItem> */}
            </DropdownMenu>
            {/* </div> */}
          </Dropdown>

          <Dropdown
            isOpen={notificationsOpen}
            toggle={() => toggleNotifications()}
            nav
            id="basic-nav-dropdown"
            className="ml-5"
          >
            <Tooltip title="Back to my workspace">
              {/* <img
                src={backButton}
                alt="back"
                height={40}
                width={40}
                onClick={() => {
                  history.push("/template/workspace");
                }}
                style={{ marginRight: "30px" }}
              /> */}
            </Tooltip>
            <DropdownToggle nav caret className="navbar-dropdown-toggle">
              <span
                className={`${s.avatar} rounded-circle float-left mr-2`}
                style={{ marginRight: "12px" }}
              >
                {/* <img src={userImg} alt="User" /> */}
                <Avatar sx={{ bgcolor: "#4d53e0" }}>
                  {useData?.username.toUpperCase().charAt(0)}
                </Avatar>
              </span>
              <span className="small d-none d-sm-block ml-1 mr-2 body-1">
                {useData?.username}
              </span>
            </DropdownToggle>
            <DropdownMenu
              className="navbar-dropdown profile-dropdown"
              style={{ width: "194px" }}
            >
              <DropdownItem className={s.dropdownProfileItem}>
                <ProfileIcon />
                <span>Profile</span>
              </DropdownItem>

              <NavItem>
                <NavLink onClick={() => handleLogout()} href="#">
                  <button
                    className="btn btn-primary rounded-pill mx-auto logout-btn"
                    type="submit"
                  >
                    <img
                      src={process.env.PUBLIC_URL + logoutIcon}
                      alt="Logout"
                    />
                    <span className="ml-1">Logout</span>
                  </button>
                </NavLink>
              </NavItem>
            </DropdownMenu>
          </Dropdown>
        </Nav>
      </Navbar>
      {/* (pathname !== "/template/QnA" && ( */}
      {pathname !== "/template/QnA" && (
        <ContentHeader toggleModal={toggleModal} />
      )}
      <Modal
        isOpen={modal}
        toggle={toggleModal}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={toggleModal}>New My Workspace Setup</ModalHeader>
        <ModalBody>
          <WorkspaceModal
            isOpen={modal}
            toggleModal={toggleModal}
            // onWorkspaceCreated={handleWorkspaceCreated}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  sidebarOpened: PropTypes.bool,
};

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
  };
}

export default withRouter(connect(mapStateToProps)(Header));
