import { createSlice } from "@reduxjs/toolkit";
import {
  CreateChunks,
  CreateChunksAction,
  createEmbeddingsActions,
  CreateKeywords,
  CreateKeywordsAction,
  CreateMetaDataAction,
  getChunkAction,
  GetCreateKeywordsAction,
  GetMetaDataAction,
  getEmbeddingsAction,
  CreateTopicMetadataAction,
  CreateTopicAction,
  getCreateTopicAction,
  getCreateTopicMetadataAction,
  getIninsertedePineconeData,
  CreateChunksTablesAction,
  getChunkTablesAction,
  renderChartImageAction,
  pinecodeInsertVectorAction,
  insertEmbeddingsToFaissDBAction,
  insertEmbeddingsToChormaDBAction,
  storeEmbeddingsToChormaDBAction,
  getEmbeddingsDataOfChromaDBAction,
} from "../actions/DataOutputActions";

const DataOutputSlice = createSlice({
  name: "DataOutputSlice",
  initialState: {
    chunksData: {},
    metaDataConfigration: {},
    ChunksTablesData: {},
    metaData: {},
    visualizeKeywords: {},
    TopicmetaDataConfigration: {},
    visulazieTopic: {},
    chunksTable: {},
    metaDataConfigration: {},
    metaData: {},
    visualizeKeywords: {},
    status: "",
    emebeddingData: null,
    isFilePresent: false,
    fileId: "",
    isLoading: false,
    pineconedata: [],
    chromaDB_data: [],
    folderChunkData: [],
    folderTableChunkData: [],
    chartImageUrl: "",
    fileIDSerial: 1,
  },
  reducers: {
    setfolderChunkData(state, { payload }) {
      state.folderChunkData = payload?.data;
    },
    setFolderTableChunkData(state, { payload }) {
      state.folderTableChunkData = payload?.data;
    },
    setfileIDSerial(state, { payload }) {
      state.fileIDSerial = payload?.data;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(CreateChunksAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(CreateChunksAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.chunksData = payload?.data;
        state.isLoading = false;
      })
      .addCase(CreateChunksAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getChunkAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getChunkAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.chunksData = payload?.data;
        state.isLoading = false;
      })
      .addCase(getChunkAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(CreateChunksTablesAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(CreateChunksTablesAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.chunksTable = payload?.data;
        state.isLoading = false;
      })
      .addCase(CreateChunksTablesAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getChunkTablesAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getChunkTablesAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.ChunksTablesData = payload?.data;
        state.isLoading = false;
      })
      .addCase(getChunkTablesAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(createEmbeddingsActions.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(createEmbeddingsActions.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.emebeddingData = payload?.data;
        state.isLoading = false;
      })
      .addCase(createEmbeddingsActions.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(GetCreateKeywordsAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(GetCreateKeywordsAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.metaData = payload?.data;
        state.isLoading = false;
      })
      .addCase(GetCreateKeywordsAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(CreateKeywordsAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(CreateKeywordsAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.metaDataConfigration = payload?.data;
        state.isLoading = false;
      })
      .addCase(CreateKeywordsAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(CreateMetaDataAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(CreateMetaDataAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.visualizeKeywords = payload?.data;
        state.isLoading = false;
      })
      .addCase(CreateMetaDataAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(GetMetaDataAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(GetMetaDataAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.visualizeKeywords = payload?.data;
        state.isLoading = false;
      })
      .addCase(GetMetaDataAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getEmbeddingsAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getEmbeddingsAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.emebeddingData = payload?.data;
        state.isLoading = false;
      })
      .addCase(getEmbeddingsAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(CreateTopicAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(CreateTopicAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.TopicmetaDataConfigration = payload?.data;
        state.isLoading = false;
      })
      .addCase(CreateTopicAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getCreateTopicAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getCreateTopicAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.visulazieTopic = payload?.data;
        state.isLoading = false;
      })
      .addCase(getCreateTopicAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(CreateTopicMetadataAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(CreateTopicMetadataAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.visulazieTopic = payload?.data;
        state.isLoading = false;
      })
      .addCase(CreateTopicMetadataAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getCreateTopicMetadataAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getCreateTopicMetadataAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.visulazieTopic = payload?.data;
        state.isLoading = false;
      })
      .addCase(getCreateTopicMetadataAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(getIninsertedePineconeData.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getIninsertedePineconeData.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.pineconedata = payload?.data;
        state.isLoading = false;
      })
      .addCase(getIninsertedePineconeData.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(pinecodeInsertVectorAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(pinecodeInsertVectorAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isLoading = false;
      })
      .addCase(pinecodeInsertVectorAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(renderChartImageAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(renderChartImageAction.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.chartImageUrl = payload;
        state.isLoading = false;
      })
      .addCase(renderChartImageAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(insertEmbeddingsToFaissDBAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(
        insertEmbeddingsToFaissDBAction.fulfilled,
        (state, { payload }) => {
          state.status = "success";
          state.isLoading = false;
        }
      )
      .addCase(insertEmbeddingsToFaissDBAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(insertEmbeddingsToChormaDBAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(
        insertEmbeddingsToChormaDBAction.fulfilled,
        (state, { payload }) => {
          state.status = "success";
          state.chartImageUrl = payload;
          state.isLoading = false;
        }
      )
      .addCase(insertEmbeddingsToChormaDBAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(storeEmbeddingsToChormaDBAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(
        storeEmbeddingsToChormaDBAction.fulfilled,
        (state, { payload }) => {
          state.status = "success";
          state.isLoading = false;
        }
      )
      .addCase(storeEmbeddingsToChormaDBAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getEmbeddingsDataOfChromaDBAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(
        getEmbeddingsDataOfChromaDBAction.fulfilled,
        (state, { payload }) => {
          state.status = "success";
          state.chromaDB_data = payload;
          state.isLoading = false;
        }
      )
      .addCase(getEmbeddingsDataOfChromaDBAction.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});
export const { setfolderChunkData, setFolderTableChunkData, setfileIDSerial } =
  DataOutputSlice.actions;
export default DataOutputSlice.reducer;
