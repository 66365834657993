// WorkspaceModal.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import "./WorkspaceModal.css";
import {
  CreateWorkspaceAction,
  EditWorkspaceAction,
  WorkspaceDashboardAction,
  WorkspaceListAction,
} from "../../redux/actions/MainWorkspaceAction";
import Loader from "../../components/Common/LoadingModal/LoadingModal";
import { toast } from "react-toastify";

const WorkspaceModal = ({
  isOpen,
  toggleModal,
  onWorkspaceCreated,
  editWorkspacesValue,
  isEdit,
}) => {
  const dispatch = useDispatch();

  const { WorkspaceData, isLoading, status } = useSelector(
    (state) => state.WorkspaceSlice
  );
  const [error, setError] = useState({
    workspace_name: "",
    short_description: "",
    detail_description: "",
  });
  const [workspaceDetails, setWorkspaceDetails] = useState({
    workspace_name: "",
    short_description: "",
    detail_description: "",
    workspace_type: "",
  });

  // Handle Input values with regex
  const handleChange = (e) => {
    const { name, value } = e.target;

    const MAX_WORKSPACE_NAME_LENGTH = 50;
    const MAX_SHORT_DESCRIPTION_WORDS = 100;
    const MAX_DETAIL_DESCRIPTION_WORDS = 500;

    switch (name) {
      case "workspace_name":
        const workspaceNameRegex = new RegExp(
          `^.{0,${MAX_WORKSPACE_NAME_LENGTH}}$`
        );
        if (!workspaceNameRegex.test(value)) {
          return;
        }
        break;
      case "short_description":
        const shortDescriptionRegex = new RegExp(
          `^.{0,${MAX_SHORT_DESCRIPTION_WORDS}}$`
        );
        if (!shortDescriptionRegex.test(value)) {
          return;
        }
        break;
      case "detail_description":
        const detailDescriptionRegex = new RegExp(
          `^.{0,${MAX_DETAIL_DESCRIPTION_WORDS}}$`
        );
        if (!detailDescriptionRegex.test(value)) {
          return;
        }
        break;

      default:
        break;
    }
    setWorkspaceDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  // Manage Error msg if value present in the input.
  useEffect(() => {
    const { workspace_name, short_description, workspace_type } =
      workspaceDetails;
    const newError = {};

    if (workspace_name?.trim()) {
      newError.workspace_name = "";
    }
    if (short_description?.trim()) {
      newError.short_description = "";
    }
    if (workspace_type?.trim()) {
      newError.workspace_type = "";
    }

    setError((prev) => ({
      ...prev,
      ...newError,
    }));
  }, [workspaceDetails]);

  // Set values for update.
  useEffect(() => {
    setWorkspaceDetails((prevDetails) => ({
      ...prevDetails,
      workspace_name: editWorkspacesValue?.workspace_name,
      short_description: editWorkspacesValue?.short_description,
      detail_description: editWorkspacesValue?.detail_description,
      workspace_type: editWorkspacesValue?.workspace_type,
      workspace_id: editWorkspacesValue?.id,
    }));
  }, [editWorkspacesValue]);

  // Sumbit data
  const handleSave = () => {
    const {
      workspace_id,
      workspace_name,
      short_description,
      workspace_type,
      detail_description,
    } = workspaceDetails;

    if (
      workspace_name?.trim() &&
      short_description?.trim() &&
      workspace_type?.trim()
    ) {
      const action = editWorkspacesValue
        ? EditWorkspaceAction
        : CreateWorkspaceAction;

      const payload = editWorkspacesValue
        ? {
            workspace_id,
            workspace_name,
            short_description,
            workspace_type,
            detail_description,
          }
        : {
            workspace_name,
            short_description,
            workspace_type,
            detail_description,
          };

      dispatch(action(payload))
        .then((res) => {
          if (res.payload?.status === 201 || res.payload?.status === 200) {
            toggleModal();
            const successMessage = editWorkspacesValue
              ? "Workspace Updated Successfully"
              : "Workspace Created Successfully";
            toast.success(successMessage);
            dispatch(WorkspaceListAction());
            dispatch(WorkspaceDashboardAction());
          }
        })
        .catch((error) => {
          console.error("Failed to save workspace:", error);
        });
    } else {
      // Handle errors for missing or invalid fields
      const newError = {};
      if (!workspace_name?.trim()) {
        newError.workspace_name = "Please enter workspace name.";
      }
      if (!short_description?.trim()) {
        newError.short_description = "Please enter short description.";
      }
      if (!workspace_type?.trim()) {
        newError.workspace_type = "Please select workspace type.";
      }
      setError(newError);
    }
  };

  const handleReset = () => {
    toggleModal();
    // setWorkspaceDetails({
    //   workspace_name: "",
    //   short_description: "",
    //   detail_description: "",
    //   workspace_type: "",
    //   workspace_id: editWorkspacesValue?.id,
    // });
  };

  return (
    <>
      <Loader isOpen={isLoading} />
      <Modal
        isOpen={isOpen}
        toggle={toggleModal}
        className="modal-dialog-centered"
        style={{ maxWidth: "800px" }}
      >
        <ModalHeader toggle={toggleModal}>
          {isEdit ? (
            <>
              <span> Update My Workspace </span>
              <span style={{ color: "#036aed" }}> {`> Setup`} </span>
            </>
          ) : (
            <>
              <span> New My Workspace </span>{" "}
              <span style={{ color: "#036aed" }}> {`> Setup`} </span>
            </>
          )}
          {/* <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={toggleModal}
            style={{ border: "none" }}
          > */}
          {/* <span aria-hidden="true">&times;</span> */}
          {/* </button> */}
        </ModalHeader>

        <ModalBody style={{ maxHeight: "80vh", overflowY: "auto" }}>
          <Form>
            <FormGroup row className="mb-3">
              <Label
                for="workspace_name"
                sm={3}
                className="label-box"
                style={{ color: "#16365f" }}
              >
                Workspace Name
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  name="workspace_name"
                  id="workspace_name"
                  placeholder=""
                  // placeholder="Travel-RnF-Space"
                  style={{ height: "40px", color: "#6b859e" }}
                  value={workspaceDetails.workspace_name}
                  onChange={handleChange}
                />
                <p style={{ color: "red", fontSize: "14px" }}>
                  {error?.workspace_name}
                </p>
              </Col>
            </FormGroup>
            <FormGroup row className="mb-3">
              <Label for="short_description" sm={3} className="label-box">
                Short Description
              </Label>
              <Col sm={9}>
                <Input
                  type="textarea"
                  name="short_description"
                  id="short_description"
                  // placeholder="Test workspace to clean travel review data"
                  placeholder=""
                  style={{ height: "100px", color: "#6b859e" }}
                  value={workspaceDetails.short_description}
                  onChange={handleChange}
                />
                <p style={{ color: "red", fontSize: "14px" }}>
                  {error?.short_description}
                </p>
              </Col>
            </FormGroup>
            <FormGroup row className="mb-3">
              <Label for="detail_description" sm={3} className="label-box">
                Detail Description
              </Label>
              <Col sm={9}>
                <Input
                  type="textarea"
                  name="detail_description"
                  id="detail_description"
                  // placeholder="This is a travel review and feedback reviews content cleaning and curation workspace."
                  placeholder=""
                  style={{ height: "150px", color: "#6b859e" }}
                  value={workspaceDetails.detail_description}
                  onChange={handleChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row className="mb-3">
              <Label for="workspace_type" sm={3} className="label-box">
                Workspace Type
              </Label>
              <Col sm={9}>
                <div className="custom-dropdown">
                  <Input
                    type="select"
                    name="workspace_type"
                    id="workspace_type"
                    style={{ height: "40px", color: "#6b859e" }}
                    value={workspaceDetails.workspace_type}
                    onChange={handleChange}
                  >
                    <option value="">Select Workspace</option>
                    <option value="My Workspace">My Workspace</option>
                  </Input>
                  <div className="dropdown-arrow"></div>
                  <p style={{ color: "red", fontSize: "14px" }}>
                    {error?.workspace_type}
                  </p>
                </div>
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={handleReset}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default WorkspaceModal;
