// -- React and related libs
import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { Switch, Route, Redirect } from "react-router-dom";

// -- Redux
import { connect } from "react-redux";

// -- Custom Components
import LayoutComponent from "./components/Layout/Layout";
import ErrorPage from "./pages/error/ErrorPage";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";

// -- Redux Actions
import { logoutUser } from "./actions/auth";

// -- Third Party Libs
import { ToastContainer } from "react-toastify";

// -- Services
import isAuthenticated from "./services/authService";

// -- Component Styles
import "./styles/app.scss";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom";
import { logoutSuccess } from "./redux/slices/authSlice";

const PrivateRoute = ({ dispatch, component, ...rest }) => {
  let userData = JSON.parse(sessionStorage.getItem("user"));
  if (!isAuthenticated(userData?.access)) {
    dispatch(logoutSuccess());
    return <Redirect to="/login" />;
  } else {
    return (
      <Route
        {...rest}
        render={(props) => React.createElement(component, props)}
      />
    );
  }
};

const App = (props) => {
  return (
    <>
      <div>
        <ToastContainer theme="colored" />
        <BrowserRouter>
          <Switch>
            <Route
              path="/"
              exact
              render={() => <Redirect to="/template/workspace" />}
            />
            <PrivateRoute
              path="/template"
              dispatch={props.dispatch}
              component={LayoutComponent}
            />
            <Route path="/login" exact component={Login} />
            <Route path="/error" exact component={ErrorPage} />
            <Route path="/register" exact component={Register} />
            <Route component={ErrorPage} />
            <Route
              path="*"
              exact={true}
              render={() => <Redirect to="/error" />}
            />
          </Switch>
        </BrowserRouter>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(App);

// import React from "react";
// import { Switch, Route, Redirect } from "react-router-dom";
// import { HashRouter } from "react-router-dom";
// import { ToastContainer } from "react-toastify";
// import { useSelector } from "react-redux";

// import LayoutComponent from "./components/Layout/Layout";
// import ErrorPage from "./pages/error/ErrorPage";
// import Login from "./pages/login/Login";
// import Register from "./pages/register/Register";

// import isAuthenticated from "./services/authService";

// import "./styles/app.scss";

// const PrivateRoute = ({ component: Component, ...rest }) => {
//   const isAuthenticatedState = useSelector(state => state.auth.isAuthenticated);

//   return (
//     <Route
//       {...rest}
//       render={props =>
//         isAuthenticatedState ? (
//           <Component {...props} />
//         ) : (
//           <Redirect to="/login" />
//         )
//       }
//     />
//   );
// };

// const App = () => {
//   return (
//     <div>
//       <ToastContainer />
//       <HashRouter>
//         <Switch>
//           <Route path="/" exact render={() => <Redirect to="/dashboard" />} />
//           <Route path="/" exact render={() => <Redirect to="/dashboard"/>}/>
//           <PrivateRoute path="/" component={LayoutComponent} />
//           <Route path="/login" exact component={Login} />
//           <Route path="/error" exact component={ErrorPage} />
//           <Route path="/register" exact component={Register} />
//           <Route component={ErrorPage}/>
//           <Route path='*' exact={true} render={() => <Redirect to="/error" />} />
//         </Switch>
//       </HashRouter>
//     </div>
//   );
// }

// export default App;
