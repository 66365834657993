import React, { useState } from "react";
import "react-tabs/style/react-tabs.css"; // Import the styles
import TabsComponent from "./Tabs/TabsComponent.js";
const Tables = function () {
  const [activeTabIndex, setActiveTabIndex] = useState(0); // Track the active tab index
  const handleTabClick = (index) => {
    setActiveTabIndex(index);
  };

  return (
    <div>
      <div>
        <TabsComponent
          activeTabIndex={activeTabIndex}
          handleTabClick={handleTabClick}
        />
      </div>
    </div>
  );
};

export default Tables;
