import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  get_extracted_table_details,
  get_extracted_tables,
  get_fileData_chatbot,
  get_image_file,
  get_pdf_file,
  get_profanityCheck_data,
  get_sensetiveInfo_data,
  get_tables_json,
  get_text_cleaning_data,
  get_text_quality_data,
  get_url_render_folder,
  get_video_file_data,
  render_documents,
} from "../../services/getServices";
import { toast } from "react-toastify";
import { converte_excel_to_json } from "../../services/postServices";
import { save_edited_table } from "../../services/postServices";
export const ReviewTextCleaningAction = createAsyncThunk(
  "ReviewTextCleaningAction",
  async (fileId) => {
    try {
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      // let file_id = JSON.parse(sessionStorage.getItem("file_id"));
      const data = await get_text_cleaning_data(workspace.workspace_id, fileId);

      if (data && data.response) {
        //console.log("data", data);
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);

export const ReviewTextQualityAction = createAsyncThunk(
  "ReviewTextQualityAction",
  async (fileId) => {
    try {
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      // let file_id = JSON.parse(sessionStorage.getItem("file_id"));
      //console.log("file_id in text cleaning", file_id);
      const data = await get_text_quality_data(workspace.workspace_id, fileId);

      if (data && data.response) {
        //console.log("data", data);
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);
export const ReviewSensetiveInfoCheckAction = createAsyncThunk(
  "ReviewSensetiveInfoCheckAction",
  async (fileId) => {
    try {
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      const data = await get_sensetiveInfo_data(workspace.workspace_id, fileId);

      if (data && data.response) {
        //console.log("data", data);
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);
export const ReviewProfanityCheckAction = createAsyncThunk(
  "ReviewProfanityCheckAction",
  async (fileId) => {
    try {
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      const data = await get_profanityCheck_data(
        workspace.workspace_id,
        fileId
      );

      if (data && data.response) {
        //console.log("data", data);
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);

export const RenderPdfFileAction = createAsyncThunk(
  "RenderPdfFileAction",
  async (value) => {
    try {
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      let file_id = JSON.parse(sessionStorage.getItem("file_id"));
      const data = await get_pdf_file(file_id);

      if (data) {
        //console.log("data", data);
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);
export const RenderExtractedTable = createAsyncThunk(
  "RenderExtractedTable",
  async (value) => {
    try {
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      let file_id = JSON.parse(sessionStorage.getItem("file_id"));
      const data = await get_extracted_tables(file_id);

      if (data) {
        //console.log("data", data);
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      toast.error(error.response.data.detail);
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);

// Action for table wise Json view

export const getTablesJsonView = createAsyncThunk(
  "getTablesJsonView",
  async (fileId) => {
    try {
      // let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      let file_id = JSON.parse(sessionStorage.getItem("file_id"));
      const data = await get_tables_json(fileId);

      if (data) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      toast.error(error.response.data.detail);
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);

export const GetExtractedTablesDetailsAction = createAsyncThunk(
  "GetExtractedTablesDetailsAction",
  async (imageId) => {
    try {
      let fileId = JSON.parse(sessionStorage.getItem("file_id"));
      //console.log("fileId--->", fileId);
      const data = await get_extracted_table_details(imageId);
      if ((data && data.status === 201) || data.status === 200) {
        //console.log("extracted Data ", data?.data);
        sessionStorage.setItem("table_id", data?.data?.id);
        return data;
      } else {
        //console.log("data", data);
        throw data;
      }
    } catch (error) {
      // if (error.response?.data?.error) {
      //   toast.error(error.response?.data?.error);
      // }
      // if (error.response?.data?.detail) {
      //   toast.error(error.response?.data?.detail);
      // }
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);
export const renderDocumentsAction = createAsyncThunk(
  "renderDocumentsAction",
  async () => {
    try {
      let fileId = JSON.parse(sessionStorage.getItem("file_id"));
      //console.log("fileId--->", fileId);
      const data = await render_documents(fileId);
      if ((data && data.status === 201) || data.status === 200) {
        return data;
      } else {
        //console.log("data", data);
        throw data;
      }
    } catch (error) {
      //console.log("error------>>trext cleaning", error);
      toast.error(error.response?.data?.error);
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);
export const getUrlToRenderImageFolderAction = createAsyncThunk(
  "getUrlToRenderImageFolderAction",
  async (folder_id) => {
    try {
      //console.log("fileId--->", fileId);
      const data = await get_url_render_folder(folder_id);
      if ((data && data.status === 201) || data.status === 200) {
        return data;
      } else {
        //console.log("data", data);
        throw data;
      }
    } catch (error) {
      //console.log("error------>>trext cleaning", error);
      toast.error(error.response?.data?.error);
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);

//post action to converte excel to json
export const convertExceltoJson = createAsyncThunk(
  "convertExceltoJson",
  async (fileID) => {
    try {
      let workspace = JSON.parse(sessionStorage.getItem("workspace"));
      let payload = {
        workspace_id: workspace.workspace_id,
        file_id: fileID,
      };
      const data = await converte_excel_to_json(payload);

      if (data && data.response) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);

export const saveEditedTableAction = createAsyncThunk(
  "saveEditedTableAction",
  async ({ blob, fileId, tablefileName, setIsLoading }) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("edited_file", blob, `Edited_${tablefileName}`);
      formData.append("file_id", fileId);
      const payload = {
        file_id: fileId,
        edited_file: formData,
      };
      //console.log("fileId--->", fileId);
      const data = await save_edited_table(formData);
      if ((data && data.status === 201) || data.status === 200) {
        setIsLoading(false);
        toast.success("Tables updated successfully.");
        return data;
      } else {
        toast.error("Something went wrong while updating table.");
        setIsLoading(false);
        throw data;
      }
    } catch (error) {
      //console.log("error------>>trext cleaning", error);
      setIsLoading(false);
      toast.error(error.response?.data?.error);
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);

// export const convertExceltoJson = createAsyncThunk(
//   "convertExceltoJson",
//   async () => {
//     try {
//       let file = JSON.parse(sessionStorage.getItem("file"));
//       const value = {
//         file_id: file?.file_id,
//       };
//       const data = await converte_excel_to_json(value);
//       if ((data && data.status === 201) || data.status === 200) {
//         return data;
//       } else {
//         throw data;
//       }
//     } catch (error) {
//       if (error.response && error.response.status === 500) {
//         toast.error("Internal Server Error. Please try again later.");
//       }
//       toast.error(error.response?.data?.error);
//       // console.log("error", error.response?.data?.error);
//       return error;
//     }
//   }
// );

export const getVideoFileAction = createAsyncThunk(
  "getVideoFileAction",
  async (fileID) => {
    try {
      const data = await get_video_file_data(fileID);

      if (data && data.response) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);

export const renderImageFiles = createAsyncThunk(
  "renderImageFiles",
  async (url) => {
    try {
      const data = await get_image_file(url);

      if (data && data.status === 200) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);

export const renderFileForChatBot = createAsyncThunk(
  "renderFileForChatBot",
  async (fileID) => {
    try {
      const data = await get_fileData_chatbot(fileID);

      if (data.status === 200 && data.status === 201) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      }
      return error;
    }
  }
);
