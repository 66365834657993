import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Col,
  Row,
  Progress,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import ApexActivityChart from "./components/ActivityChart.js";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";

import s from "./Dashboard.module.scss";

import { useDispatch, useSelector } from "react-redux";
import { WorkspaceDashboardAction } from "../../redux/actions/MainWorkspaceAction.js";
 import MyResponsiveTree from "./components/Tree.js"
 import { ResponsiveTree, ResponsiveTreeCanvas } from '@nivo/tree'
 import am5index from "@amcharts/amcharts5/index";
import am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import data from "./components/Sampledata.js";
// import data from "./components/data.js"

import MyTreeChart from "./components/MyTreechart.js";
import CircleTreeChart from "./components/CircleTreeComponnet.js";
import MyForceDirectedChart from "./components/CircleTreeComponnet.js";
import {CardComponent, InvestmentHeader} from "./components/cardComponent.js"
import DataSourceTable from "./components/DataSourceTable.js";




const Dashboard = (onWorkspaceSelection) => {
  const dispatch = useDispatch();
   const { WorkspaceData, dashboardData, isLoading, status } = useSelector(
    (state) => state.WorkspaceSlice
  );
  const investments = [
    { type: 'Workspace-1', investmentAmt: '4', marketValue: '4', overallGL: '4', todaysGL: '1',initiallyOpen: true },
    { type: 'Workspace-2', investmentAmt: '1', marketValue: '2', overallGL: '2', todaysGL: '3'},
    { type: 'Workspace-3', investmentAmt: '3', marketValue: '0', overallGL: '0', todaysGL: '0'},
    { type: 'Workspace-4', investmentAmt: '2', marketValue: '2', overallGL: '1', todaysGL: '1'},
  ];

  const dataSources = [
  { name: 'Workspace-1', pdfs: 4, docs: 4, texts: 4, images: 1 },
  { name: 'Workspace-2', pdfs: 1, docs: 2, texts: 2, images: 3 },
  { name: 'Workspace-3', pdfs: 3, docs: 0, texts: 0, images: 0 },
  { name: 'Workspace-4', pdfs: 2, docs: 0, texts: 2, images: 1 },
];
  const [checkboxes, setCheckboxes] = useState([true, false])

  const toggleCheckbox = (id) => {
    setCheckboxes(checkboxes => checkboxes
      .map((checkbox, index) => index === id ? !checkbox : checkbox ))
  }

   useEffect(() => {
    dispatch(WorkspaceDashboardAction());
  }, []);



  return (
    <div>
      <Row>
          <div className={s.cardMainContainer}>
        <div className={`${s.cardContainer} ${s.cardContainer1}`}>
          <div className={s.contentContainer}>
            <WorkspacesIcon className={s.cardContainerText1} />
            <div>
              <h5 className={`${s.cardContainerText1} ${s.cardContainerText}`}>
                Total Workspaces
              </h5>
              <h1>{dashboardData?.total_workspaces_count}</h1>
            </div>
          </div>
        </div>
        <div className={`${s.cardContainer} ${s.cardContainer2}`}>
          <div className={s.contentContainer}>
            <ToggleOnIcon className={s.cardContainerText2} />
            <div>
              <h5 className={`${s.cardContainerText2} ${s.cardContainerText}`}>
                Active Workspaces
              </h5>
              <h1>{dashboardData?.active_workspaces_count}</h1>
            </div>
          </div>
        </div>
        <div className={`${s.cardContainer} ${s.cardContainer3}`}>
          <div className={s.contentContainer}>
            <ToggleOffIcon className={s.cardContainerText3} />
            <div>
              <h5 className={`${s.cardContainerText3} ${s.cardContainerText}`}>
                Inactive Workspaces
              </h5>
              <h1>{dashboardData?.inactive_workspaces_count}</h1>
            </div>
          </div>
        </div>
        <div className={`${s.cardContainer} ${s.cardContainer4}`}>
          <div className={s.contentContainer}>
            <FolderCopyIcon className={s.cardContainerText4} />
            <div>
              <h5 className={`${s.cardContainerText4} ${s.cardContainerText}`}>
                Total File Processed
              </h5>
              <h1>{dashboardData?.processed_files_count}</h1>
            </div>
          </div>
        </div>
      </div>
      </Row>
      <div style={{padding:"20px",borderRadius: "10px", border: "1px solid #ddd" }}>
       {/* <h1>Investment Dashboard</h1>  */}
         <InvestmentHeader /> 
       {investments.map((investment, index) => (
        < CardComponent key={index} {...investment} />
      ))}  
      {/* <DataSourceTable dataSources={dataSources}/> */}
      {/* <CardComponent/> */}
      </div>
      <div style={{height:"500px", fontSize:"62px", marginBottom:"50px"}} className="Treecontainer" >
        {/* {MyResponsiveTree()} */}
        {/* <ResponsiveTree/> */}
        <MyResponsiveTree/>
      </div>
      
      {/* <MyTreeChart /> */}
      {/* <MyForceDirectedChart /> */}
    </div>
  )
}

export default Dashboard;
