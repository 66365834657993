import axios from "axios";
import { refreshAccessToken } from "../API/tokenService";

// cicd - UAT
export const HOST = "https://testapi.yavi.ai/";

// Development
// export const HOST = "http://137.117.45.236:5008/";

export const IFRAME_URL = "https://testbot.yavi.ai/";
// export const IFRAME_URL = "http://localhost:5173/";
// export const IFRAME_URL = "http://127.0.0.1:5173/";

// for live make it 0 and in dev make it 1
export const InsightLiveQnA = 0;

const axiosInstance = axios.create({
  baseURL: HOST,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      // && !originalRequest._retry
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axiosInstance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
      originalRequest._retry = true;
      isRefreshing = true;
      const refreshToken = sessionStorage.getItem("refresh");

      if (refreshToken) {
        try {
          const payload = { refresh: refreshToken };
          const access = await refreshAccessToken(payload);
          axiosInstance.defaults.headers.common["Authorization"] =
            "Bearer " + access;
          processQueue(null, access);
          isRefreshing = false;

          originalRequest.headers["Authorization"] = "Bearer " + access;

          return axiosInstance(originalRequest);
        } catch (refreshError) {
          if (window.location.pathname != "/login") {
            window.location.replace("/login");
            processQueue(refreshError, null);
            isRefreshing = false;
            return Promise.reject(refreshError);
          }
        }
      } else if (
        window.location.pathname !== "/login" ||
        window.location.pathname !== "/register"
      ) {
        // toast.error("Session expired, please login again.");
        // window.location.replace("/login");
        isRefreshing = false;
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
