// Download.js

import React, { useEffect } from "react";
import "./download.css"; // Import external styles

import Button from "../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadAllZipped,
  downloadCuratedContent,
  downloadQnaPairs,
  downloadSummary,
  downloadTables,
  downloadJson,
  getdownloadCuratedContent,
  getdownloadQnaPairs,
  getdownloadSummary,
} from "../../redux/actions/downloadActions";
import Loader from "../../components/Common/LoadingModal/LoadingModal";
import { GetExtractedTablesDetailsAction } from "../../redux/actions/DataReviewAction";

const Download = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.downloadSlice);

  const { isFilePresent, imageId, fileId, folderId, isImageSelected } =
    useSelector((state) => state.PipelineExecutionSlice);

  useEffect(() => {
    if (Number(folderId).length > 0) {
      dispatch(GetExtractedTablesDetailsAction(imageId));
    } else {
      dispatch(GetExtractedTablesDetailsAction(fileId));
    }
  }, [imageId, fileId]);

  const handleDownload = (value) => {
    switch (value) {
      case "curated_content":
        dispatch(downloadCuratedContent(imageId ? imageId : fileId));
        break;
      case "summary":
        dispatch(downloadSummary(imageId ? imageId : fileId));

        break;
      case "QnA_pairs":
        dispatch(downloadQnaPairs(imageId ? imageId : fileId));
        break;

      case "tables":
        dispatch(downloadTables(imageId ? imageId : fileId));
        break;

      case "JSON":
        dispatch(downloadJson(imageId ? imageId : fileId));
      default:
        break;
    }
  };

  const handleDownloadall = () => {
    dispatch(downloadAllZipped(imageId ? imageId : fileId));
  };

  const DownloadButton = ({
    label,
    value,
    disabled,
    isFilePresent,
    folderId,
  }) => {
    return (
      <>
        <div className="download-btn-box">
          <div className="download-btn">
            <p className="download-label">{label}</p>
            <span className="bottomBorder"></span>
            <button
              className={` ${isImageSelected ? "d-btn" : "all-d-btn-disabled"}`}
              onClick={() => handleDownload(value)}
              disabled={!isImageSelected}
              style={{ cursor: !isImageSelected ? "not-allowed" : "pointer" }}
            >
              Download
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="MainContainer">
      <Loader isOpen={isLoading} />
      <div className="container">
        <h5
          style={{
            marginTop: "30px",
            marginBottom: "30px",
            marginRight: "20px",
            marginLeft: "20px",
          }}
        >
          Download Processed Content, Summary, Q-n-A Pairs, and Tables
        </h5>
        <div className="Download-container">
          <div className="Download-btn-container">
            <DownloadButton
              label={"Curated Content"}
              value="curated_content"
              isFilePresent={!isFilePresent}
              disabled={!isFilePresent}
            />

            <DownloadButton
              label={"Summary"}
              value="summary"
              isFilePresent={!isFilePresent}
              disabled={!isFilePresent}
            />
            <DownloadButton
              label={"Q-n-A Pairs"}
              value="QnA_pairs"
              isFilePresent={!isFilePresent}
              disabled={!isFilePresent}
            />
            {/* // <DownloadButton label={"Embeddings"} value="embeddings"/> */}
            <DownloadButton
              label={"Tables"}
              value="tables"
              isFilePresent={!isFilePresent}
              disabled={!isFilePresent}
            />
            <DownloadButton
              label={"JSON"}
              value="JSON"
              isFilePresent={!isFilePresent}
              disabled={!isFilePresent}
              // className={folderId ? "d-btn" : "all-d-btn-disabled"}
            />
            <div>
              <button
                disabled={!isImageSelected}
                className={`Download-all-btn ${
                  isImageSelected ? "" : "all-btn-disabled"
                }`}
                style={{ cursor: isImageSelected ? "pointer" : "not-allowed" }}
                onClick={handleDownloadall}
              >
                Download All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
